artModule.factory('nDivideArray', function() {

    return function(array, number) {
        if (number === undefined) {
            number = 4;
        }
        var buckets = [];
        for (var j = 0; j < number; j++) {
            var chunk = [];
            for (var i = j; i < array.length ; i += number ) {
                chunk.push(array[i]);
            }
            buckets.push(chunk);
        }

        return buckets;
    };

}).factory('Debouncer', [
    '$timeout',
function(
    $timeout
){

    var definition = {};

    definition.stallEvent = function(method, ms) {

        ms = ms || 400;
        var timeoutID;

        return function(){

            var scope = this , args = arguments;
            $timeout.cancel(timeoutID);

            timeoutID = $timeout(function(){
                $(window).clearQueue();
                method.apply(scope, Array.prototype.slice.call(args));
            }, ms);

        };

    };

    return definition;

}]).factory('SortedInsert', function(){


    function _index_of(array, item, start, end, comparison) {

        var pivot = Math.floor(start + (end - start) / 2),
            value;

        if(end - start <= 1){

            return pivot;

        }

        value = comparison(array[pivot], item);

        if(value === 0) {

            return pivot;

        } else if(value < 0) {

            return _index_of(array, item, pivot, end, comparison);

        } else{

            return _index_of(array, item, start, pivot, comparison);

        }

    }

    return function(array, item, comparison){

        var index = _index_of(array, item, 0, array.length, comparison);

        array.splice(index, 0, item);

    };

}).factory("TileMasonry", function(){

    return function(pieces, columns, height_callback, initial_heights){

        var bucket_height = [],
            buckets = [],
            order = [],
            bucket_index,
            piece,
            i,
            sort_func = function(a, b){

                var result = bucket_height[a] - bucket_height[b]; //sort by shortest.

                if(result === 0){

                    return a - b; //left to right

                } else {

                    return result;

                }

            };

        initial_heights = initial_heights || [];

        //initialize data
        for(i=0; i<columns; i++){

            order.push(i);
            bucket_height.push(initial_heights[i]||0);
            buckets.push([]);

        }

        //since we know the only thing that can vary is a piece's height,
        for(i = 0; i<pieces.length; i++){

            piece = pieces[i];

            bucket_index = order[0];
            buckets[bucket_index].push(piece);

            bucket_height[bucket_index] +=  height_callback(piece);

            order.sort(sort_func);

        }

        return buckets;

    };

}).factory("ArrayTo2dColumnArray", function() {
/*
Given an array [0,1,2,3,4] and 3 columns, create:
[ [0,3], [1,4], [2] ] to power a screen that looks like: 0 | 1 | 2
                                                         3 | 4
*/
    return function(inputArray, amtCols) {
        var colIdx;
        var columns = [];

        for (var i = 0, c = inputArray.length; i < c; i++) {
            colIdx = i % amtCols;
            if (!columns[colIdx])
                columns[colIdx] = [];

            columns[colIdx].push(inputArray[i]);
        }
        return columns;
    };
}).factory("HtmlEscape", function() {
    return function(rawHtml) {
        return document.createElement('div')
           .appendChild(document.createTextNode(rawHtml))
           .parentNode
           .innerHTML;
    };
}).factory("artElementAttrsToObject", ["artCapitalizeFilter", function(artCapitalizeFilter){

    function _toCamelCase(string){

        var parts = string.split("-");

        for(var i=1; i < parts.length; i++){

            parts[i] = artCapitalizeFilter(parts[i]);

        }

        return parts.join("");

    }

    return function(element, prefix){

        var attrs = element.attributes,
            result = {};

        for(var i=0; i<attrs.length; i++){

            var attr = attrs[i],
                regExp = new RegExp("^"+prefix+"-(.*)$"),
                match = regExp.exec(attr.name);

            if(match){

                var name = _toCamelCase(match[1]);
                result[name] = attr.value;

            }

        }

        return result;

    };

}]);
