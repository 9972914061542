neonmobApp.controller("FavoritesListController", [
    '$scope',
    'artResourceService',
    '$http',
    'artConfig',
    'artPieceService',
    'artSett',
function(
    $scope,
    artResourceService,
    $http,
    artConfig,
    artPieceService,
    artSett
){

    var amtPerPage = 16, 
        page = 0,
        count = 0;
    var _ownershipPromise = artPieceService.syncOwnership();

    $scope.targetId = $scope.targetId || artConfig.targetId;

    $scope.model = {};
    $scope.model.pieces = [];
    $scope.model.viewer = null;
    $scope.model.target = null;
    $scope.paginatedResults = [];
    $scope.viewState = "loading";

    $scope.favoriteOptions = [
         {
            name: 'Favorite Cards',
            tab: 'cards'
        },
        {
            name: 'Favorite Series',
            tab: 'sets'
        },
        {
            name: 'Wishlist',
            tab: 'wishlist'
        }
    ];

    var _updateServerWithFavoriteChoice = function(object, failure) {

        object = object || null;

        if (object) {

            var url = "/api/pieces/" + object.id + "/favorite/";

            $http.post(url)
            .error(function(){

                failure();

            });

        } else {

            return false;

        }

    };

    var _determineContext = function(){

        if ($scope.model.pieces.length > 0) {

            return "comparison-data-available";

        } else if ($scope.model.viewer.username === $scope.model.target.username) {

            return "viewing-their-own-empty";

        }

        return "viewing-anothers-empty";

    };

    var resetPaginationValue = function(){

        page = 0;
        $scope.viewState = "loading";
        $scope.paginatedResults = [];
        $scope.model.pieces = [];
    };

    $scope.getNextPage = function () {

        if(!count) {

            count++;
            return;
        }

        page++;
        applyPagination();
    };

    function applyPagination() {

        if ($scope.viewState == "allPagesLoaded") return;

        var amtFilteredPieces = $scope.model.pieces.length;
        var limit = page * amtPerPage;

        $scope.paginatedResults = $scope.model.pieces.slice(0, limit);

        if (amtFilteredPieces && (amtFilteredPieces == $scope.paginatedResults.length))
            $scope.viewState = "allPagesLoaded";
    };

    $scope.getFavorites = function(selectedTab){
        if ( $scope.favoritesContext == "loading-favorites" ) {

            return;
        }

        resetPaginationValue();

        // filtering resets pagination
        page = 1;

        // Just to check, optimize later
        $scope.selectedTab = selectedTab;

        $scope.favoritesContext = "loading-favorites";

        var url = "/api/users/" + $scope.targetId + "/favorites";

        if($scope.selectedTab == 'wishlist') {

            //Update the url
            url = url + "?wish_list=True";
        };

        if(selectedTab == 'sets') {

            url = url + "/setts";
        };


        _ownershipPromise['finally'](function(){
            artResourceService
                .get(url)
                .success(function(data){

                    $scope.model.target = data.target;
                    $scope.model.viewer = data.viewer;
                    $scope.model.pieces = data.results;

                    $scope.favoritesContext = _determineContext();

                    applyPagination();
                    $scope.viewState = "loaded";

                    return true;

                })
                .error(function(){


                    $scope.favoritesContext = "error-loading";
                    $scope.model = {};

                    return false;

                });
        });

    };

    $scope.toggleFavorite = function(index) {

         if ($scope.model.pieces[index].favorite === true) {

             $scope.model.pieces[index].favorite = false;

         }  else {

             $scope.model.pieces[index].favorite = true;

         }

         _updateServerWithFavoriteChoice($scope.model.pieces[index], function(){

             $scope.model.pieces[index].favorite = !$scope.model.pieces[index].favorite;

         });

//        artPieceService.toggleFavorite($scope.model.pieces[index]);

    };

    $scope.toggleFavoriteSett = function(index) {

        artSett.toggleFavoriteSett($scope.model.pieces[index]);
    };


}]);
