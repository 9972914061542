packOpenModule.service('poMilestones', [
    '$http',
    '$interval',
    '$q',
    '$window',
    'artConfig',
    'artConfirm',
    'artSubscriptionService',
    'artUrl',
    'artUser',
    'poPackSelect',
    'animationService',
    function (
        $http,
        $interval,
        $q,
        $window,
        artConfig,
        artConfirm,
        artSubscriptionService,
        artUrl,
        artUser,
        poPackSelect,
        animationService
    ) {

        var poMilestones = {},
            interval,
            _totalCaratsEarned = 0,
            _rarityBadge = [],
            _milestoneData = [],
            _receivedPrints = [],
            _milestoneModalData = [],
            _userLevelUps = [],
            _milestoneRewardsArray = [],
            _sett,
            _rarityCount = {
                common: 0,
                uncommon: 0,
                rare: 0,
                veryRare: 0,
                extraRare: 0,
                chase: 0,
                variant: 0,
                legendary: 0,
                all_core_pieces: 0
            },
            _achievedRarity = {},
            modalHeaderText = {
                'series-complete': 'Series Completed',
                'series-complete-beginner': 'Awesome Job!',
                'series-complete-reward': "<div class='reward-carat-header'><span class='reward-header-msg'>Core Series Completed!</span></div>"
            }
            _levelUpWhenSeriesCompleted = false;

            var callback = function() {
//                $window.location.href = '/collect/';
            };

        function _resetMilestoneData() {
            _totalCaratsEarned = 0,
                _rarityBadge = [],
                _receivedPrints = [],
                _milestoneModalData = [],
                _userLevelUps = [],
                _milestoneRewardsArray = [],
                _rarityCount = {
                    common: 0,
                    uncommon: 0,
                    rare: 0,
                    veryRare: 0,
                    extraRare: 0,
                    chase: 0,
                    variant: 0,
                    legendary: 0,
                    all_core_pieces: 0
                },
                _achievedRarity = {};
            for (i = 0; i < _milestoneData.length; i++) {
                _milestoneData[i].animateCount = false;
            }
            ;
        };

        function isSpecial(rarity) {
            if (['chase', 'variant', 'legendary'].indexOf(rarity) > -1) {
                return true;
            }
            return false;
        };

        function isNewRarity() {
            for (var rarity in _rarityCount) {
                if (_rarityCount.hasOwnProperty(rarity) && _rarityCount[rarity]) {
                    return true;
                }
            }
            return false;
        };

        /*
        * User level up progress.
        * Level up model will only appear on completing rarity also by chance of achieving core milestone(ex.100 chase)
        * When you complete series, triggering level up model is not handled here.
        */
        function _showLevelupProgress() {
            if(!_levelUpWhenSeriesCompleted) {
                _userLevelUps.forEach(function (level) {
                    _milestoneRewardsArray.push(level);
                    artConfirm.showEarnedStatus(level);
                });
                _userLevelUps = [];
                return;
            }
        }

        /*
         * Pack open Animation starts here.
        */

        function _animateCount(rarity) {
            setTimeout(function () {
                rarity.animateCount = true;
            }, 1000);
            return;
        }

        poMilestones._applyProgressImage = function (rarity) {
            var rarityPercent = poMilestones.getPercentageCompleted(rarity.rarityProgressPercent);
            // If progress percent cross 99, then it will be achieved.
            // Milestone badge will replace progresss image.
            if (rarity.rarity !== "all_core_pieces") {
                //Check if the rarityprogresspercent value is 100, If true check if the rarity.achieved status is true,
                // if its not change the status to true so that the placeholder image gets hidden
                if (rarity.rarityProgressPercent === 100) {
                    if (!rarity.achieved) {
                        rarity.achieved = true;
                    }
                }
                ;
                return 'badge-' + rarity.rarity + '-' + rarityPercent;
            }
            if (poMilestones.calculatePercentage(rarity) === 100) {
                return 'badge-all_core_pieces';
            }
        }

        poMilestones.getPercentageCompleted = function (percentage) {
            if (percentage > 0 && percentage < 50) {
                return 33;
            } else if (percentage >= 50 && percentage <= 99) {
                return 66;
            } else if (percentage > 99) {
                return 99;
            }
        }

        poMilestones.calculatePercentage = function (rarity) {
            var owned = rarity.metrics.owned;
            var total = rarity.metrics.total;
            return Math.round((owned / total) * 100);
        };

        function _animateRarity(rarity) {
            _animateCount(rarity);
            if (rarity.rarity !== "all_core_pieces") {
                rarity.rarityProgressPercent = poMilestones.calculatePercentage(rarity);
                var progressPercent = poMilestones.getPercentageCompleted(rarity.rarityProgressPercent);
                if (progressPercent) {
                    rarity.fileName = rarity.rarity + '/' + progressPercent + '.json';
                    rarity.className = rarity.metrics.css_class;
                    rarity.callback = function () {
                        rarity.progressImgClass = poMilestones._applyProgressImage(rarity);
                    };
                    animationService.triggerAnimate(rarity).then(function () {
                        // Level up model will only appear on completing rarity also by chance of achieving core milestone(ex.100 chase)
                        _showLevelupProgress();
                    });
                }
                return;
            }
            rarity.progressImgClass = poMilestones._applyProgressImage(rarity);
        }

        // Animation ends here.

        function _updateRarityCount(rarityData, count) {
            rarityData.metrics.owned += count;
        }

        function _showRarityBadge(milestoneStat) {
            var index = null;

            if (!_rarityBadge.length) return;
            _rarityBadge.map(function (item, i) {
                if (item.type == 'all_rarity' && (item.rarity == milestoneStat.metrics.css_class)) {
                    index = i;
                    artSubscriptionService.broadcast('show-growl-notifications', [item]);
                    artSubscriptionService.broadcast('badge-achieved', [item]);
                } else if (item.type == 'all_pieces') { // not in use since we removed full series milestone
                    // Hack to use the same template as series rarity
                    item.type = 'all_rarity';
                    index = i;
                    artSubscriptionService.broadcast('show-growl-notifications', [item]);
                    artSubscriptionService.broadcast('badge-achieved', [item]);
                } else {
                    artSubscriptionService.broadcast('show-growl-notifications', [item]);
                    artSubscriptionService.broadcast('badge-achieved', [item]);
                }
            });

            if (index != null) {
                _rarityBadge.splice(index, 1);
            }
        };

        function _updateLocalRarityCount() {
            _receivedPrints.map(function (print) {
                if (print.is_new) {
                    _rarityCount[print.rarity.class]++;
                    if (!isSpecial(print.rarity.class)) {
                        _rarityCount['all_core_pieces']++;
                    }
                }
            });
        };

        function _updateRarityAnimation(rarityData) {
            if (!_rarityBadge.length) return;
            _rarityBadge.map(function (badge) {
                if (badge.achieved && badge.rarity === rarityData.rarity) {
                    _achievedRarity[badge.rarity] = true;
                }
            });
        };

        function _updateRarityAchievedFlag(rarityData) {
            if (!_rarityBadge.length) return;
            _rarityBadge.map(function (badge) {
                if (badge.achieved && badge.rarity === rarityData.rarity) {
                    rarityData.achieved = true;
                }
            });
        };

        function _modifyAllRarityStats() {
            for (i = 0; i < _milestoneData.length; i++) {
                cssClass = _milestoneData[i].metrics.css_class;
                if (_rarityCount[cssClass] > 0) {
                    _updateRarityCount(_milestoneData[i], _rarityCount[cssClass]);
                    _updateRarityAchievedFlag(_milestoneData[i]);
                    _showRarityBadge(_milestoneData[i]);
                    _rarityCount[cssClass] = 0;
                }
                ;
            }
            ;
        }

        function _updateMilestoneRarityStats() {
            var i,
                cssClass;

            if (!isNewRarity()) {
                $interval.cancel(interval);
                interval = undefined;

                _milestoneModalData.length && poMilestones.showMilestoneRewardModal();
                return;
            }

            for (i = 0; i < _milestoneData.length; i++) {
                cssClass = _milestoneData[i].metrics.css_class;
                if (_rarityCount[cssClass] > 0) {
                    _updateRarityAnimation(_milestoneData[i]);
                    _showRarityBadge(_milestoneData[i]);
                    _updateRarityCount(_milestoneData[i], _rarityCount[cssClass]);
                    _animateRarity(_milestoneData[i]);
                    _updateRarityAchievedFlag(_milestoneData[i]);
                    _rarityCount[cssClass] = 0;
                    break;
                }
                ;
            }
            ;

        };
        /*
        * Series completion model handled for gated user.
        * Handled levelup model only when series completed.
        *
        * Scenario:
        * Complete series as gated user and check for gated user series completion model.
        * Complete series as gated user and check for gated user levelup model.
        * order of appearing model for gated user (Series completion model, level up model)
        *
        * Complete series as non gated user and check for series completion model and reward model.
        * Complete series as non gated user and check for non gated user levelup model.
        * order of appearing model for gated user (Series completion model, reward model, level up model)
        */
        function _prepareRewards(rewards, data) {
            rewards.forEach(function (reward, index) {
                if(!artUser.areFeaturesGated()) {
                    var completionStat = {
                        rewardType: 'series-complete',
                        message: modalHeaderText['series-complete'],
                        okText: 'Claim Reward',
                        data: reward
                    };
                    var completionBonus = {
                        rewardType: 'series-complete-reward',
                        message: modalHeaderText['series-complete-reward'],
                        data: {
                            baseReward: reward.carats,
                            proBonus: reward.pro_bonus,
                            difficultyBonus: reward.difficulty_bonus,
                            totalReward: reward.total,
                            suggestedSett: data[index].data.results
                        }
                    };
                    _milestoneRewardsArray.push(completionStat);
                    _milestoneRewardsArray.push(completionBonus);
                    // When you complete series if chance of level up, level up info will pushed to _milestoneRewardsArray.
                    if(_levelUpWhenSeriesCompleted && _userLevelUps.length) {
                        _milestoneRewardsArray.push(_userLevelUps[0]);
                    }
                } else {
                    var completionStat = {
                        rewardType: 'series-complete-beginner',
                        message: modalHeaderText['series-complete-beginner'],
                        okText: 'GREAT!',
                        data: reward,
                    };
                    _milestoneRewardsArray.push(completionStat);
                    if(_levelUpWhenSeriesCompleted && _userLevelUps.length) {
                        _milestoneRewardsArray.push(_userLevelUps[0]);
                    }
                }
            });
        };

        function _prepareLevelUpModelData(levelUps) {
            var levelUpData;
            levelUps.forEach(function(level) {
                if(level['new_features'].length) {
                    levelUpData = {
                        rewardType: 'gated-level-up',
                        message: 'Congratulations',
                        okText: 'GREAT',
                        data: level,
                        parentClass: 'series-complete-reward',
                        hasCloseBtn: true,
                        callback: function() {
//                            $window.location.href = '/collect/';
                        }
                    }
                }
                else {
                    levelUpData = {
                        rewardType: 'level-up',
                        message: 'Level Up!',
                        okText: 'Great!',
                        data: level,
                        parentClass: 'series-complete-reward' // To do: Make class name generic.
                    };
                }
                _userLevelUps.push(levelUpData);
            });
        };

        function _overlayDismissAction(_state) {

            if (_state.name != 'pack-open-overlay') return;

            if (interval) {
                $interval.cancel(interval);
                interval = undefined;
            }

            _modifyAllRarityStats();

            poMilestones.showMilestoneRewardModal();
        };

        artSubscriptionService.subscribe("overlay-closing", _overlayDismissAction);
        artSubscriptionService.subscribe("overlay-changing", _overlayDismissAction);

        poMilestones.initPoMilestones = function () {
            _resetMilestoneData();
            _milestoneData = [];
            return poPackSelect.fetchSett().then(function (sett) {

                _sett = sett;
                return poMilestones.fetchMilestones();
            });
        };

        poMilestones.fetchMilestones = function () {

            var defer = $q.defer();

            $http.get('/api/collection-badges/metrics/' + _sett.id + '/user/' + artUser.toObject().id)
                .success(function (data) {
                    _milestoneData = data;
                    poMilestones.setProgressImage();
                    defer.resolve(_milestoneData);
                });

            return defer.promise;
        };

        poMilestones.setProgressImage = function () {
            _milestoneData.forEach(function (milestone) {
                milestone.rarityProgressPercent = poMilestones.calculatePercentage(milestone);
                milestone.progressImgClass = poMilestones._applyProgressImage(milestone);
            });
        }

        poMilestones.fetchSuggestedSetts = function (settId) {
            var suggestedSettsUrl = artUrl.updateParams(artConfig.api['api-suggested-sett-list'], {
                'sett_id': settId
            });
            return $http.get(suggestedSettsUrl);
        };

        poMilestones.isAnimating = function (rarity) {
            return _achievedRarity[rarity];
        };

        poMilestones.processSuggestionSet = function (data) {
            _prepareRewards(_milestoneModalData, data);
            if (_milestoneRewardsArray.length) {
                var firstReward = _milestoneRewardsArray.shift();
                firstReward.messageQueue = _milestoneRewardsArray;
                firstReward.parentClass = 'series-complete-reward';
                _milestoneModalData = [];
                if(firstReward.messageQueue.length) {
                    artConfirm.showEarnedStatus(firstReward, callback);
                } else {
                    artConfirm.showEarnedStatus(firstReward);
                }
                firstReward = [];
            }
        }

        poMilestones.showMilestoneRewardModal = function (modalData) {
            if (modalData && angular.isArray(modalData) && modalData.length) {
                _milestoneModalData = modalData;
            }
            if (_milestoneModalData.length) {
                var promiseCollection = [];
                _milestoneModalData.forEach(function (reward) {
                    promiseCollection.push(poMilestones.fetchSuggestedSetts(reward.sett.id));
                });

                $q.all(promiseCollection).then(function (data) {
                    poMilestones.processSuggestionSet(data);
                    _resetMilestoneData();
                });
            } else {
                _resetMilestoneData();
            }
        };

        poMilestones.updateRarityMilestones = function () {

            if (_totalCaratsEarned) {
                artUser.updateUserCarats(_totalCaratsEarned);
            }

            interval = $interval(function () {
                _updateMilestoneRarityStats();
            }, 1500);
        };

        poMilestones.settOpenPackData = function (data) {

            _receivedPrints = data.prints;
            _totalCaratsEarned = data.rewards.total_carats;

            if (data.badges) {
                _rarityBadge = data.badges;
            }
            if (data.rewards.all_core_pieces) {
                _milestoneModalData = [data.rewards.all_core_pieces];
                if(data.rewards.level_ups && data.rewards.level_ups.length) {
                    _levelUpWhenSeriesCompleted = true;
                }
            }
            if (data.rewards.level_ups && data.rewards.level_ups.length) {
                _prepareLevelUpModelData(data.rewards.level_ups);
            }
            _updateLocalRarityCount();
        };

        poMilestones.getMilestones = function () {
            return _milestoneData;
        };

        poMilestones.getSett = function (sett) {
            return _sett;
        };
        return poMilestones;

    }]);
