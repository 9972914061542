artModule.directive("artActivityFeed", function () {
    var artActivityFeed = {};

    artActivityFeed.scope = {
        settId: "=?artSettId",
        userId: "=?artUserId",
        creatorId: "=?artCreatorId",
        resultsPerPage: "=?resultsPerPage"
    };

    artActivityFeed.controller = "artActivityFeedController";

    artActivityFeed.templateUrl = "partials/activity-feed/compact/activity-feed.partial.html";

    return artActivityFeed;
}).controller("artActivityFeedController",  [
    "$scope",
    "artConfig",
    "artUrl",
    "artNodeHttpService",
    "artSubscriptionService",
    "artResponsive",
    "TileMasonry",
    "artActivityFeedFactory",
    "artObjectHelpers",
    function ($scope,
              artConfig,
              artUrl,
              artNodeHttpService,
              artSubscriptionService,
              artResponsive,
              TileMasonry,
              artActivityFeedFactory,
              artObjectHelpers
    ) {

        var columnWidth = 350;

        function getActivityFeedCountForTheDevice() {
            var count = 50, width = artResponsive.getWindowWidth();
            if (width < 640) {
                count = 5;
            } else if (width < 1050) {
                count = 20;
            }
            return count;
        }

        function currentPageInitObj() {
            return {
               page: 0,
               amount: getActivityFeedCountForTheDevice()
            }
        }

        var currentPage = currentPageInitObj();

        $scope.activityFeed = [];
        $scope.viewState = "loadingPage";

        var externalFilter = null;
        var feedItems = [];
        var activityFeedBaseUrl = "";

        function init() {
            if (externalFilter)
                return applyExternalFilter();

            currentPage = currentPageInitObj();

            $scope.activityFeed = [];
            $scope.viewState = "loadingPage";
            feedItems = [];

            activityFeedBaseUrl = artConfig["node-api-endpoint"] + "/activityfeed";

            if ($scope.settId)
                activityFeedBaseUrl += "/sett/" + $scope.settId + "/";
            else if ($scope.userId)
                activityFeedBaseUrl += "/user/" + $scope.userId + "/";
            else if ($scope.creatorId)
                activityFeedBaseUrl += "/creator/" + $scope.creatorId + "/"

            $scope.getNextPage();
        }

        function onFeedResults(data) {
            var changed = false;

            if (data.length > 0) {
                artActivityFeedFactory.processStories(data);
                feedItems = feedItems.concat(data);
                changed = true;
            }

            if (!changed && !feedItems.length) {
                $scope.viewState = "feedEmpty";
                return;
            }

            if (data.length < currentPage.amount)
                $scope.viewState = "allPagesLoaded";
            else
                $scope.viewState = "pageLoaded";

            if (!changed)
                return;

            $scope.activityFeed = TileMasonry(feedItems, getAmtColumns(), artObjectHelpers.getProperty("$height"));
        }

        function onFeedError(data) {
            $scope.viewState = "error";
        }

        function getAmtColumns() {
            var width = artResponsive.getWindowWidth();
            var amt = Math.floor(width / columnWidth);
            if (amt < 1) return 1;
            if (amt > 3) return 3;
            return amt;
        }

        var resizeListener = artResponsive.onResize(function () {
            $scope.activityFeed = TileMasonry(feedItems, getAmtColumns(), artObjectHelpers.getProperty("$height"));
        });

        $scope.getNextPage = function () {
            if ($scope.viewState === "allPagesLoaded" || $scope.viewState == "feedEmpty")
                return;

            $scope.viewState = "loadingPage";
            artNodeHttpService.get(artUrl.updateParams(activityFeedBaseUrl, {
                page: ++currentPage.page,
                amount: currentPage.amount
            })).then(onFeedResults, onFeedError);
        };

        function ownershipRefreshed() {
            if (feedItems.length === 0 || $scope.activityFeed.length === 0) return;
            // this is done to minimize in place to minimize redraw
            artActivityFeedFactory.setMasonryFeedOwnership($scope.activityFeed);
        }

        function feedFilterChangeRequested(change) {
            externalFilter = change;
            applyExternalFilter();
        }

        function applyExternalFilter() {
            currentPage = currentPageInitObj();

            $scope.activityFeed = [];
            $scope.viewState = "loadingPage";
            feedItems = [];

            activityFeedBaseUrl = artConfig["node-api-endpoint"] + "/activityfeed/" + externalFilter.type + "/"
            + externalFilter.id + "/";
            $scope.getNextPage();
        }

        // TODO - listen for badge-awards as well
        artSubscriptionService.subscribe("user-piece-ownership-refreshed", ownershipRefreshed);
        artSubscriptionService.subscribe("pack-open-finished", init);
        artSubscriptionService.subscribe("trade-accepted", init);
        artSubscriptionService.subscribe('request-activity-feed-filter', feedFilterChangeRequested);

        $scope.$on("$destroy", function () {
            resizeListener();
            artSubscriptionService.unsubscribe("user-piece-ownership-refreshed", ownershipRefreshed);
            artSubscriptionService.unsubscribe("pack-open-finished", init);
            artSubscriptionService.unsubscribe("trade-accepted", init);
            artSubscriptionService.unsubscribe('request-activity-feed-filter', feedFilterChangeRequested);
        });

        init();
}]);
