artModule.filter('index_type', function () {
    return function (input, index) {
        if (index === 0) {
            return "first";
        } else if (index === input.length - 1) {
            return "last";
        } else {
            return "middle";
        }
    };
})
    .filter('absolute_url', function () {
        return function (input) {
            if (!input) {
                return "";
            }
            //already absolute!
            if (/^https?:\/\//.test(input)) {
                return input;
            } else {
                var separator = input.charAt(0) === "/" ? "" : "/";
                return window.location.origin + separator + input;
            }
        };
    })
    .filter('ordinalIndicatorFilter', function () {
        return function(number) {
            var lastTwoDigit = number % 100,
                lastOneDigit = number % 10,
                suffix = 'th';
            if ([11, 12, 13].indexOf(lastTwoDigit) < 0) {
                if (lastOneDigit == 1) {
                    suffix = 'st';
                } else if (lastOneDigit == 2) {
                    suffix = 'nd';
                } else if (lastOneDigit == 3) {
                    suffix = 'rd';
                }
            }
            return number + suffix;
        }
    })
    .filter('abbreviate_zeros', function () {
        return function (input) {
            var integer, positive = false;
            // (1) + take in any type of var
            //     + if it's a string parse to int.
            if (typeof input === 'number' && input % 1 === 0) {
                integer = Math.floor(input);
            }
            else if (input === "unlimited") {
                return "&infin;";
            }
            else {
                input = input.toString().replace(/,/g, "");
                integer = parseInt(input, 10);
            }
            // (2) + Return an integer in the right range.
            //     + if the integer is out of this range, convert it.
            if (integer < 1000 && integer > -1000) {
                return "" + integer + "";
            }
            else if (isNaN(integer)) {
                return "Inception";
            }
            else {
                if (integer > -1) {
                    positive = true;
                }
                integer = Math.abs(integer);
                var decimalPlaces = Math.pow(10, 1);
                var abbreviations = ["k", "m", "b", "t"];
                for (var i = abbreviations.length - 1; i >= 0; i--) {
                    var size = Math.pow(10, (i + 1) * 3);
                    if (size <= integer) {
                        integer = Math.round(integer * decimalPlaces / size) / decimalPlaces;
                        if ((integer === 1000) && (i < abbreviations.length - 1)) {
                            integer = 1;
                            i++;
                        }
                        integer += abbreviations[i];
                        break;
                    }
                }
                if (!positive) {
                    integer = "-" + integer + "";
                }
                return "" + integer + "";
            }
        };
    })
    .filter("artCapitalize", function () {
        return function (input) {
            //http://stackoverflow.com/questions/4878756/javascript-how-to-capitalize-first-letter-of-each-word-like-a-2-word-city
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };
    })
    .filter('zeroOrNumber', function () {
        return function (number) {
            if (!number) {
                return 0;
            } else {
                return number;
            }
        };
    })
    .filter('monthName', function () {
        // snagged from SOF http://stackoverflow.com/questions/21480359/angularjs-show-name-of-the-month-given-the-month-number
        return function (monthNumber) {
            // 0 = January
            var monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
            return monthNames[monthNumber];
        };
    })
    .filter('floatToFraction', function() {
        return function (float, separator, reverseRatio) {
            var gcd = function(a, b) {
              if (b < 0.001) return a;                // Since there is a limited precision we need to limit the value.

              return gcd(b, Math.floor(a % b));           // Discard any fractions due to limitations in precision.
            };

            var len = float.toFixed(2).length - 2;

            var denominator = Math.pow(10, len);
            var numerator = float * denominator;

            var divisor = gcd(numerator, denominator);

            numerator /= divisor;
            denominator /= divisor;
            if (reverseRatio) {
                return (Math.floor(denominator) + (separator || '/') + Math.floor(numerator));
            }
            return (Math.floor(numerator) + (separator || '/') + Math.floor(denominator));
        }
    });
