creatorModule.controller("ctSubmitSettCtrl", [
    '$http',
    '$scope',
    '$window',
    'artMessage',
    'artOverlay',
    'artSubscriptionService',
    'ctSettCreator',
function(
    $http,
    $scope,
    $window,
    artMessage,
    artOverlay,
    artSubscriptionService,
    ctSettCreator
){
    
    $scope.getSett = ctSettCreator.getSett.bind(ctSettCreator);
    
    $scope.isAmateur = ctSettCreator.isAmateur.bind(ctSettCreator);

    $scope.submitSett = function(){
        var message = "Awesome! Your collection has been submitted to us for review. We'll be in touch if we have any questions!";

        $http.post(ctSettCreator.settSubmitUrl).success(function(data){
            artSubscriptionService.broadcast('sett-submitted');
            artOverlay.hide();
            artMessage.showInfo(message,
                    function(){
                        $window.location.href = ctSettCreator.dashboardUrl;
                    });

        }).error(function(data){
            artOverlay.hide();
            artMessage.showAlert("Something went wrong while submitting your collection :( Please reload the page and try again. If this problem persists, contact us at support@neonmob.com");
        });
    };

    $scope.paypalEmailAddress = null;

    $scope.savePaymentInfo = function () {
        if (!$scope.paypalEmailAddress) {
            return;
        }

        $http.patch(ctSettCreator.creatorSettingsUrl, {'paypal_email_address': $scope.paypalEmailAddress}).then(function (data) {
            artOverlay.hide("showNext");
        }).catch(function (data) {
            artOverlay.hide();
            artMessage.showAlert("Something went wrong while submitting your collection :( Please reload the page and try again. If this problem persists, contact us at support@neonmob.com");
        });
    };

}]);
