artModule.factory("artNotificationCenter", [
    '$rootScope',
    '$http',
    'artConfig',
    'artConstants',
    'artResource',
    'artResponsive',
    'artSubscriptionService',
    'artTitleNotify',
    'artUser',
    'artWebSocket',
function(
    $rootScope,
    $http,
    artConfig,
    artConstants,
    artResource,
    artResponsive,
    artSubscriptionService,
    artTitleNotify,
    artUser,
    artWebSocket
){

    var artNotificationCenter = {},
        _notifications,
        _unreadNotificationCount = {},
        _totalUnreadNotificationCount = 0,
        _state = null;

    artNotificationCenter.show = function(notificationType, options){

        artTitleNotify.stop();
        artNotificationCenter.getSuggestionsWhenNavClick();

        angular.element('html').addClass('notification-center-open');

        notificationType = notificationType || _getDefaultNotificationType();

        if(notificationType === "conversation"){
            _setStateToConversation(options);
        } else if( notificationType === "friends"){
            _state = {
                name: "friends"
            };

        } else if (notificationType === artConstants.MESSAGES_KEY) {
            _state = {
                name:notificationType,
                trades: artNotificationCenter.getNotificationsByType(artConstants.TRADES_KEY),
                notifications:artNotificationCenter.getNotificationsByType(artConstants.MESSAGES_KEY),
                emptyDisplay: "No "+notificationType,
                actionDisplay: "Mark all as read",
                icon: 'icon-' + notificationType,
                showActions: function(){
                    return _unreadNotificationCount[_state.name] > 0;
                },
                handleAction: function(actionType){
                    var ids = _state.notifications.map(function(notification) {
                        return notification.id;
                    });

                    artNotificationCenter.markNotificationsAsRead(ids, _state.name);
                }
            };
        } else if (notificationType === artConstants.MILESTONE_KEY) {
            _state = {
                name:notificationType,
                milestoneSuggestion:artNotificationCenter.getNotificationsByType(artConstants.MILESTONE_SUGGESTION_KEY),
                milestoneRecent:artNotificationCenter.getNotificationsByType(artConstants.MILESTONE_RECENT_KEY),
                milestoneCompleted:artNotificationCenter.getNotificationsByType(artConstants.MILESTONE_COMPLETED_KEY),
            }
        } else {
            _state = {
                name:notificationType,
                notifications:artNotificationCenter.getNotificationsByType(notificationType),
                emptyDisplay: "No "+notificationType,
                actionDisplay: "Mark all as read",
                icon: 'icon-' + notificationType,
                showActions: function(){
                    return _unreadNotificationCount[_state.name] > 0;
                },
                handleAction: function(){
                    var ids = _state.notifications.map(function(notification) {
                        return notification.id;
                    });

                    artNotificationCenter.markNotificationsAsRead(ids, _state.name);
                }
            };
        }

    };

    function _getDefaultNotificationType(){
        if(_unreadNotificationCount.trades > 0){
            return artConstants.TRADES_KEY;
        } else if(_unreadNotificationCount.messages > 0){
            return artConstants.MESSAGES_KEY;
        } else if(_unreadNotificationCount.notifications > 0){
            return artConstants.NOTIFICATIONS_KEY;
        } else if(artNotificationCenter.getNotificationsByType(artConstants.TRADES_KEY).length){
            return artConstants.TRADES_KEY;
        } else if(artNotificationCenter.getNotificationsByType(artConstants.MESSAGES_KEY).length > 0){
            return artConstants.MESSAGES_KEY;
        } else {
            return artConstants.NOTIFICATIONS_KEY;
        }
    }

    function _setStateToConversation(options){
        options = options || {};
        if(options.conversation){

            _loadConversation(options.conversation, options.conversationNavState);

        } else if(options.recipient) {

            artResource.create(artConfig.api['api-conversations-list'], {
                recipient:options.recipient
            }).then(function(data){
                _loadConversation(data, options.conversationNavState);
            }).catch(function(){
                artNotificationCenter.show(artConstants.MESSAGES_KEY);
            });

        } else {
            artNotificationCenter.show(artConstants.MESSAGES_KEY);
        }
    }

    function _loadConversation(conversation, conversationNavState){

        //Only update the messages if this is a new conversation state so we don't get a flicker for
        //showing the same conversation while we update the messages.
        if(_isNewConversationState(conversation)){

            _state = {
                name: "conversation",
                hideNav: true
            };

        }
        _state.conversation = conversation;
        _state.conversationNavState = conversationNavState;

    }

    function _isNewConversationState(conversation){

        return !_state || _state.name !== "conversation" || _state.conversation.id !== conversation.id;
    }

    artNotificationCenter.hide = function(){
        $rootScope.$evalAsync(function(){
            angular.element('html').removeClass('notification-center-open');
            _state = null;
        });
    };

    artNotificationCenter.getState = function(){
        return _state;
    };
    artNotificationCenter.getSuggestion = function(notificationType) {
        return _notifications[notificationType];
    };

    artNotificationCenter.getTradeOffer = function() {
        return _notifications[artConstants.TRADE_OFFERS_KEY];
    };

    function _getUnreadCount(notificationList){
        if(!notificationList){
            return 0;
        }
        return notificationList.reduce(function(prev, current){
            return (current.read ? 0 : 1) + prev;
        }, 0);
    }

    artNotificationCenter.getNotificationsByType = function(notificationType) {
        var milestoneCategory = ['suggestion', 'recent', 'completed'];
        if(milestoneCategory.indexOf(notificationType) != -1) {
            return _notifications[notificationType];
        }
        var sortedList = _notifications[notificationType];
        if(sortedList){
            return sortedList.list;
        } else {
            return [];
        }
    };

    artNotificationCenter.getUnreadNotificationCountByType = function(notificationType){
        return _unreadNotificationCount[notificationType] || 0;
    };

    artNotificationCenter.getTotalUnreadNotificationCount = function(){
        return _totalUnreadNotificationCount;
    };

    artNotificationCenter.markNotificationsAsRead = function(notificationIds, notificationType){
        notificationType = notificationType || artConstants.NOTIFICATIONS_KEY;

        if(notificationType === artConstants.TRADES_KEY) {
            return;
        }

        $http.post(artConfig.api['api-notifications'], {
            notification_type: notificationType, ids: notificationIds
        }).success(function() {
            var notifications = artNotificationCenter.getNotificationsByType(notificationType);

            if(!notifications){
                return;
            }
            for(var i=0; i<notifications.length; i++){
                var notification = notifications[i];
                if(notificationIds.indexOf(notification.id) !== -1 && !notification.read){
                    notification.read = true;
                    _unreadNotificationCount[notificationType]--;
                    _totalUnreadNotificationCount--;
                }
            }
        });

    };

    //Dismiss notifications
    artNotificationCenter.dismissTradeOffers = function(notificationId, notificationType){
        if(notificationType !== artConstants.TRADE_OFFERS_KEY) {
            return;
        }

        $http.post(artConfig.api['api-notifications'], {
            notification_type: notificationType,
            ids: notificationId
        }).success(function() {
            return true;
        });
    }
    function _computeNotificationCount(type){

        //remove existing count.
        _totalUnreadNotificationCount -= artNotificationCenter.getUnreadNotificationCountByType(type);

        //update new values.
        var unreadCount = _getUnreadCount(artNotificationCenter.getNotificationsByType(type));
        _unreadNotificationCount[type] = unreadCount;
        _totalUnreadNotificationCount += unreadCount;

    }

    function _refreshTradeOfferData(type) {
        if(type === artConstants.TRADES_KEY || type === artConstants.NOTIFICATIONS_KEY) {
            var trade_offers = artNotificationCenter.getTradeOffers(artConstants.TRADE_OFFERS_KEY)
            artSubscriptionService.broadcast('trade-updated', trade_offers);
        }
        return;
    }

    function _getDupIndex(item, list){
        for(var i = 0; i < list.length; i++){
            var item2 = list[i];
            if(item2 !== item && item2.id === item.id){
                return i;
            }
        }

        return null;
    }

    artNotificationCenter.getMilestoneSuggestions = function(type) {
        if (!artUser.isAuthenticated()) {
            return null;
        }
        return artWebSocket.connectSortedList("/" + type, {});
    }

    artNotificationCenter.getTradeExpirationTime = function (trade) {
        var proposedTime = trade.actor.time;
        var expireTime = new Date(proposedTime);

        expireTime.setHours(expireTime.getHours() + artConstants.TRADE_EXPIRE_HOURS);

        return expireTime;
    }

    function _connectToList(type, onAdd){
        if (!artUser.isAuthenticated()) {
            return null;
        }

        return artWebSocket.connectSortedList("/"+type, {
            id: artUser.getId(),
            sort: function(a, b){
                if(type === artConstants.TRADE_OFFERS_KEY){
                    return (a.object.completed - b.object.completed)
                }
                else {
                    return (new Date(b.actor.time)) - (new Date(a.actor.time));
                }

            },
            onLoadInitial: function(){
                _computeNotificationCount(type);
            },
            onAddItem: function(data){
                var dupIndex = _getDupIndex(data, this.list);
                if(dupIndex !== null){
                    this.list.splice(dupIndex, 1);
                }

                _computeNotificationCount(type);
                //Checks if the object added is trade related before calling the  refresh data method

                if(data.object && data.object.noun === "trade") {
                    _refreshTradeOfferData(type);
                }

                if(onAdd){
                    onAdd(data);
                }
            },
            onRemoveItem: function (data){
                _computeNotificationCount(type);
                if(data.object && data.object.noun === "trade" && data.verb_phrase === "withdrew") {
                    _refreshTradeOfferData(type);
                }
            }
        });
    }

    artNotificationCenter.getSuggestionsWhenNavClick = function() {
        _notifications.completed = artNotificationCenter.getMilestoneSuggestions(artConstants.MILESTONE_COMPLETED_KEY);
        _notifications.suggestion = artNotificationCenter.getMilestoneSuggestions(artConstants.MILESTONE_SUGGESTION_KEY);
        _notifications.recent = artNotificationCenter.getMilestoneSuggestions(artConstants.MILESTONE_RECENT_KEY);
    };

    artNotificationCenter.getTradeOffers = function (type) {
        _notifications[artConstants.TRADE_OFFERS_KEY] = _connectToList(type);
        return _notifications[artConstants.TRADE_OFFERS_KEY];
    }

    artNotificationCenter.fetchTradeOffers = function () {
         return _notifications[artConstants.TRADE_OFFERS_KEY];
    }
    function _sendGrowlNotification(tileNotifyText, type, data) {
        if (artResponsive.WINDOW_VISIBLE && _state && _state.name === "conversation" && data.object.id === _state.conversation.id) {
            return;
        }

        var notification = angular.extend({}, data, {
            type: type
        });

        if (!artResponsive.WINDOW_VISIBLE) {
            artTitleNotify.start(tileNotifyText);
        }

        artSubscriptionService.broadcast("upsert-growl-notification", notification);
    }

    _notifications = {
        messages: _connectToList(artConstants.MESSAGES_KEY, function(data){
            if(data.is_friend && data.actor.id !== artUser.getId() && !data.read){
                _sendGrowlNotification(data.actor.first_name + " says...",
                    artConstants.MESSAGES_KEY,
                    data);
            }
        }),
        trades: _connectToList(artConstants.TRADES_KEY, function(data){
            if (data.actor.id !== artUser.getId() && !data.read) {
                _sendGrowlNotification(data.actor.first_name + " " + data.verb_phrase + "...",
                    artConstants.TRADES_KEY,
                    data);
            }
        }),
        notifications: _connectToList(artConstants.NOTIFICATIONS_KEY),
    };

    return artNotificationCenter;

}]);
