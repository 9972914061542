artModule
    .factory("popoverMenuService", [
        '$timeout',
        'artSubscriptionService',
        function ($timeout,
                  artSubscriptionService) {

            var _popoverTrigger = null,
                _popoverTemplate = null,
                _popoverData = null,
                _popoverClasses = '',
                _popoverIsOpen = false,
                _tether = null,
                _popoverActiveClass = 'nm-popover--active',
                _html = angular.element('html'),
                _popoverId = '#nm-popover',
                popoverMenuService = {};
                _settDetails = {};

            // GETTERS

            popoverMenuService.getPopoverTemplate = function () {

                return _popoverTemplate;

            };

            popoverMenuService.getSettDetails = function () {

                return _settDetails;

            };

            popoverMenuService.getPopoverTrigger = function () {

                return _popoverTrigger;

            };

            popoverMenuService.getPopoverData = function () {

                return _popoverData;

            };

            popoverMenuService.getPopoverClasses = function () {

                return _popoverClasses;

            };

            popoverMenuService.isOpen = function () {

                return _popoverIsOpen;

            };

            // SETTERS

            popoverMenuService.open = function (options) {
                popoverMenuService.untether();
                popoverMenuService.setPopoverData(options.data);
                popoverMenuService.setSettDetails(options.sett);
                popoverMenuService.setPopoverTemplate(options.template);
                popoverMenuService.setPopoverTrigger(options.trigger);
                popoverMenuService.setPopoverClasses(options['classes']);

                //kill tooltips
                angular.element('.tip').tooltip('hide');

                //this guarantees applying the changes to the popover directive BEFORE tethering
                //and positioning the popover
                $timeout(function () {
                    _html.addClass(_popoverActiveClass);

                    if (popoverMenuService.getPopoverClasses()) {
                        _html.addClass(_popoverActiveClass + '--' + popoverMenuService.getPopoverClasses());
                    }

                    $timeout(function () {
                        _popoverIsOpen = true;
                        popoverMenuService.tether();
                    });
                });
            };

            popoverMenuService.close = function () {
                _popoverIsOpen = false;

                popoverMenuService.untether();

                $timeout(function () {
                    _html.removeClass(_popoverActiveClass);
                    if (popoverMenuService.getPopoverClasses()) {
                        _html.removeClass(_popoverActiveClass + '--' + popoverMenuService.getPopoverClasses());
                    }

                    artSubscriptionService.broadcast('popover-closed', _popoverTrigger);
                    _popoverTemplate = null;
                    _popoverClasses = '';
                    _popoverData = {};
                    _settDetails = {};
                    _popoverTrigger = null;
                });
            };

            popoverMenuService.setPopoverTemplate = function (tpl) {
                if (tpl !== _popoverTemplate) {
                    _popoverTemplate = tpl;
                }
            };

            popoverMenuService.setPopoverTrigger = function (trigger) {
                if (trigger !== _popoverTrigger) {
                    _popoverTrigger = trigger;
                }
            };

            popoverMenuService.setPopoverData = function (data) {
                if (data !== _popoverData) {
                    _popoverData = data;
                }
            };

            popoverMenuService.setSettDetails = function (data) {
                if (data !== _settDetails) {
                    _settDetails = data;
                }
            };

            popoverMenuService.setPopoverClasses = function (classes) {
                if (classes !== _popoverClasses) {
                    _popoverClasses = classes;
                }
            };

            popoverMenuService.untether = function () {
                if (_tether) {
                    _tether.destroy();
                }
                delete _tether;
                _tether = null;
            };

            popoverMenuService.position = function () {
                if (_tether && _tether.position) {
                    _tether.position();
                }
            };

            popoverMenuService.tether = function () {
                if (!_popoverTrigger || !angular.element(_popoverId)) {
                    return;
                }
                if (_tether && _tether.destroy) {
                    _tether.destroy();
                }

                _tether = new Tether({
                    element: angular.element(_popoverId),
                    target: _popoverTrigger,
                    attachment: 'top center',
                    targetAttachment: 'bottom center',
                    constraints: [{
                        to: 'window',
                        attachment: 'together',
                        pin: true
                    }]
                });
                _tether.position();
            };

            return popoverMenuService;

        }
    ])
    .controller("popoverMenuController", [
        '$scope',
        'popoverMenuService',
        function ($scope,
                  popoverMenuService) {

            $scope.getPopoverData = popoverMenuService.getPopoverData.bind(popoverMenuService);
            $scope.getSettDetails = popoverMenuService.getSettDetails.bind(popoverMenuService);

            $scope.getImageUrl = function() {
                var popoverData = $scope.getPopoverData();
                if(popoverData.badge)
                     return popoverData.badge.image_url;

                return popoverData.imageUrl;
            };

            $scope.getPopoverTemplate = popoverMenuService.getPopoverTemplate.bind(popoverMenuService);
            $scope.getPopoverClasses = popoverMenuService.getPopoverClasses.bind(popoverMenuService);
            $scope.openPopover = popoverMenuService.open.bind(popoverMenuService);
            $scope.closePopover = popoverMenuService.close.bind(popoverMenuService);
            $scope.isOpen = popoverMenuService.isOpen.bind(popoverMenuService);
        }
    ])
    .directive('popoverMenu', function () {
        var directive = {};
        directive.controller = "popoverMenuController";
        directive.templateUrl = "partials/menus/popover-menu.partial.html";
        return directive;
    })
    .controller("popoverTriggerController", [
        '$scope',
        '$element',
        'popoverMenuService',
        function ($scope,
                  $element,
                  popoverMenuService) {
            $scope.popoverTriggerEvent = $scope.popoverTriggerEvent || 'click';
            $scope.popoverData = $scope.popoverData || {};
            $scope.openPopover = function () {

                popoverMenuService.open({
                    trigger: $element,
                    template: $scope.popoverTemplate,
                    sett: $scope.popoverSett,
                    data: $scope.popoverData,
                    classes: $scope.popoverClasses
                });
            };

            $element.on(
                $scope.popoverTriggerEvent,
                $scope.openPopover
            );
        }
    ])
    .directive('popoverTrigger', function () {
        var directive = {};
        directive.scope = {
            popoverTemplate: '@',
            popoverData: '=?',
            popoverSett: '=?',
            popoverTriggerEvent: '@',
            popoverClasses: '@',
        };

        directive.controller = "popoverTriggerController";
        return directive;
    })
    .controller("popoverDismissController", [
        '$scope',
        '$element',
        'popoverMenuService',
        function ($scope,
                  $element,
                  popoverMenuService) {
            $scope.popoverTriggerEvent = $scope.popoverTriggerEvent || 'click';
            $scope.closePopover = popoverMenuService.close.bind(popoverMenuService);

            $element.on(
                $scope.popoverTriggerEvent,
                $scope.closePopover
            );
        }
    ])
    .directive('popoverDismiss', function () {
        var directive = {};
        directive.controller = "popoverDismissController";
        return directive;
    });
