artModule.directive('artInlineEditable', function(){
    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope: {
            "text"  : "=artInlineEditable",
            "limit" : "=artInlineLimit",
            "url"   : "=artInlineUrl",
            "name"  : "=artInlineName",
            "model" : "=artInlineModel"
        },

        controller: [
            '$scope',
            '$element',
            '$timeout',
            'artResource',
            'artMessage',
        function(
            $scope,
            $element,
            $timeout,
            artResource,
            artMessage
        ){
            $scope.editActive = false;
            $scope.changed = false;
            $scope.saving = false;

            var inputField = $element.find('.inline-editable-field--input');

            $scope.toggleEdit = function(event) {
                $scope.editActive = !$scope.editActive;
                if ($scope.editActive) {
                    $timeout( function(){
                        inputField.focus();
                    });
                }
            };

            $scope.textChanged = function(event) {
                $scope.changed = true;
            };

            $scope.updateModel = function() {

                if (!$scope.model.url) {
                    $scope.model.url = $scope.url;
                }

                artResource.update($scope.model).then(function(data){
                    $scope.saving = false;
                    $scope.changed = false;
            
                    if (!inputField.is(':focus')){
            
                        $scope.toggleEdit();
            
                    }
            
                }).catch(function(response){
                   
                   if (response.status === 400) {
                       
                        artMessage.showAlert("Unable to update - please try again");
                        $scope.saving = false;
                        $scope.toggleEdit();
                    
                    } else if (response.status === 503) {
                        
                        artMessage.showAlert("The site is experiencing high traffic - please refresh the page.");

                    } else {

                        artMessage.showAlert("Unable to save :( Please refresh the page and try again!");
                    
                    }
                });
            };
        }],

        templateUrl: "partials/component/inline-editable.partial.html"
    };

    return directiveDefinitionObject;
});