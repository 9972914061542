neonmobApp.directive('nmFavoritesList', function(){

    var directiveDefinitionObject = {};

    directiveDefinitionObject = {

        targetId: "=nmFavoritesListTargetId"

    };

    directiveDefinitionObject.controller = "FavoritesListController";

    directiveDefinitionObject.templateUrl = "partials/component/favorites-list.partial.html";

    return directiveDefinitionObject;

});