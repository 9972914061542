packOpenModule.controller("poPackSelectAndPurchaseController", [
    "$scope",
    "$window",
    "$http",
    "$filter",
    "$interval",
    "poPackSelect",
    "artUser",
    "artSubscriptionService",
    "clickStreaksService",
function(
    $scope,
    $window,
    $http,
    $filter,
    $interval,
    poPackSelect,
    artUser,
    artSubscriptionService,
    clickStreaksService
){

    $scope.sett = null;
    $scope.packTiers = [];
    $scope.isProUser = artUser.getProUserStatus();
    $scope.timerText = '...';

    var countdownInterval;
    
    $scope.totalRarities = 0

    $scope.canOpenPack = function(tier) {
        // Doing this here to avoid repetitive calls in backend and performance degradation.
        if (!tier.is_available) return false;
        if (tier.is_pro_tier && !$scope.isProUser) return false;
        if (tier.currency == 'credit' && !tier.is_pro_tier ) {
            // Allow users to Open credit packs after they have purchased sufficient credits
            return tier.can_user_open || (tier.price <= $scope.you.balance);
        }
        return tier.can_user_open;
    };

    $scope.gotoSubscription = function() {
        $window.location.href = "/subscription";
    }

    $scope.openPack = clickStreaksService.openPack.bind(clickStreaksService);

    $scope.getTimerToMidnight = function() {
        $scope.timerText = $filter('getTimerCountDown')();
    }

    $scope.getUnavailablePackTierCTA = function(tier) {
        if (tier.is_available) return 'Got invalid tier';  // For testing only

        if (tier.sold_out) return 'Sold Out';

        var distributionType = tier.distribution || 'replenishing';
        if (distributionType === 'replenishing') {
            if (!countdownInterval) {
                countdownInterval = $interval($scope.getTimerToMidnight, 1000);
            }
            return $scope.timerText;
        }
        if (distributionType === 'fixed') return 'Sold Out';
        if (distributionType === 'constant') return 'All Claimed';
    };

    $scope.getAvailablePackTierPrimaryCTA = function(tier) {
        if (!tier.is_available) return 'Got invalid tier';  // For testing only
        switch (tier.currency) {
            case 'freebie':
                if (tier.is_pro_tier && !$scope.isProUser) {
                    return 'FREE FOR PRO';
                } else {
                    return 'OPEN FREE PACK';
                }
            default:
                var price = $filter('zeroOrNumber')(tier.price), value = $filter('kFormatter')(price, precision=1);
                return value + ' ' + $filter('pluralize')(tier.currency, price);
        }
    };

    $scope.getOriginalPrice = function(tier) {
        if (tier.is_discounted_price) {
            return $filter('zeroOrNumber')(tier.original_price), value = $filter('kFormatter')(tier.original_price);
        } 
    };

    $scope.getDiscountMessage = function(tier) {
        if (tier.is_discounted_price) {
            return "You're getting " + tier.pro_discount + "% off paid packs with Pro!";
        }        
    }

    $scope.ifShowFreebiePackTierInfo = function(tier) {
        if (tier.currency != 'freebie') return false;
        if (tier.sold_out) return false;
        if (tier.is_pro_tier && !$scope.isProUser) return false;
        return true;
    };

    /*
    This is applicable only for Freebies. This also assumes that replenishing
    carat and credit packs don't need to show this info
    */
    $scope.getFreebiePackTierInfo = function(tier) {
        if (!$scope.ifShowFreebiePackTierInfo(tier)) {
            return 'Got invalid tier';
        }  // For testing only
        var distributionType = tier.distribution || 'replenishing';
        if (tier.is_available) {
            switch (tier.available) {
                // It can't be 0 as is_available would be false then.
                // -1 for constant distribution type. Not a practical case though
                case -1:
                    return 'Unlimited packs until sold out';
                case 1:
                    return '1 free pack';
                default:
                    return tier.available + ' free packs';
            }
        } else if (distributionType === 'replenishing') {
            return 'A new pack is replenishing';
        }
    }

    $scope.getNonReplenishingAvailabilityInfo = function(tier) {
        var distributionType = tier.distribution || 'replenishing';
        if (!$scope.canOpenPack(tier) || distributionType == 'replenishing') return 'Got invalid tier';
        if (tier.available == -1) return "Unlimited packs available";
        return $filter('number', "fractionSize")(tier.available) + "/" +  $filter('number', "fractionSize")(tier.count) + " packs";
    };

    $scope.you = artUser.toObject();
    clickStreaksService.setYouObject($scope.you);

    poPackSelect.fetchSett().then(function(sett){

        $scope.sett = sett;

        // Updating available 'rarity' count
        $scope.totalRarities = $scope.sett.core_stats.length + $scope.sett.special_stats.length

        //hardcoded for 'api-pack-tiers-list'
        $http.get('/api/pack-tiers/?sett_id='+sett.id).success(function(data){
            $scope.packTiers = data;
        });

    });

    function updateUserCreditBalance(data) {
        $scope.you.balance = data.balance
    }

    artSubscriptionService.subscribe("credits-purchased", updateUserCreditBalance);

    $scope.$on('$destroy', function () {
        if (!countdownInterval){
            return;
           }
           $interval.cancel(countdownInterval);
           countdownInterval = undefined;
    });

}]);
