artModule.directive('artShareButton', function () {
    var directiveDefinitionObject;
    directiveDefinitionObject = {
        scope: {
            piece: "=?artSharePiece",
            url: "@artShareUrl",
            message: "@artShareMessage",
            imageUrl: "@artImageUrl",
            badge: "=?artShareBadge",
            buttonClass: "@artButtonClass",
            shimmer: "@artShimmer",
            brag: "=?artBrag",
            pack: "=?artSharePack",
            shareSource: "=?artShareSource"
        },
        controller: [
            '$scope',
            'artPieceService',
            'artShare',
            'artUser',
            function (
                      $scope,
                      artPieceService,
                      artShare,
                      artUser) {

                $scope.brag = $scope.brag || false;
                $scope.shimmer = $scope.shimmer || false;

                $scope.getShareChannels = function () {
                    return artShare.getChannels();
                };

                $scope.getShareChannelWidth = function () {
                    return {
                        width: Math.floor(100 / $scope.getShareChannels().length) + "%"
                    };
                };

                $scope.isPublic = function () {
                    return !artUser.isAuthenticated();
                };

                $scope.getMessage = function () {
                    if ($scope.message)
                        return $scope.message;

                    var message = "";

                    if ($scope.piece) {
                        message = $scope.generatePieceMessage();
                    } else {
                        message = $scope.generateBadgeMessage();
                    }

                    return message;
                };

                $scope.generateBadgeMessage = function () {
                    var message = "";
                    var alteredDescription = $scope.badge.description;

                    alteredDescription = alteredDescription.toLowerCase();

                    if (alteredDescription.substr(-1).match(/\.|,|!/)) {
                        alteredDescription = alteredDescription.slice(0, -1);
                    }

                    alteredDescription = alteredDescription.replace(/your/i, "my");

                    if ($scope.badge.sett) {
                        alteredDescription = alteredDescription + " of " + $scope.badge.sett.name;
                    }

                    message = "I just " + alteredDescription + " on @NeonMob"
                    return message
                };

                $scope.generatePieceMessage = function () {
                    var message = "";

                    if ($scope.brag) {
                        message = makeBragMessage($scope.piece)
                    }
                    else {
                        message = makeShareMessage($scope.piece)
                    }

                    return message;
                };

                function makeBragMessage(piece) {
                    var creatorTwitterName = "";

                    if (piece.set.creator.twitter_username) {
                        creatorTwitterName = " by @" + piece.set.creator.twitter_username;
                    }

                    return getRandomBragMessage(creatorTwitterName)
                    return "I'm killing it on @NeonMob today!" + creatorTwitterName
                }

                function getRandomBragMessage(creatorTwitterName){
                    const shareMessage = [
                    "I'm killing it on @NeonMob today!" + creatorTwitterName,
                    "I just got this amazing card on @NeonMob," + creatorTwitterName + " come join and you might get a copy yourself 😁",
                    "Behold my beautiful @NeonMob card" + creatorTwitterName,
                    "I have obtained dopamine through this rectangle" + creatorTwitterName + ".  Join @NeonMob. today for your own chance to experience dopamine.",
                    "The card gods have smiled upon me this day on @NeonMob" + creatorTwitterName,
                    "I just got this card" + creatorTwitterName + " on @NeonMob and I'm ready to make it my entire personality",
                    "Please be aware of this @NeonMob card" + creatorTwitterName + ".  Thank you.",
                    "Happiness is stored in this authentic NeonMob art rectangle" + creatorTwitterName + ".",
                    "Pulling this card on @NeonMob" + creatorTwitterName + " is so much better than getting poked in the eye",
                    "At last, card came home on @NeonMob" + creatorTwitterName,
                    "Can you feel the adorbs? I can! 🥹 Get this card" + creatorTwitterName + " on @NeonMob",
                    "Too. Much. Cuteness. " + creatorTwitterName,                    
                    "Come check out my @NeonMob art museum right here on my phone," + creatorTwitterName,
                    "I just pulled this amazing trading card " + creatorTwitterName + " on @Neonmob. What cards will you pull?",
                    "Psst. Psst. I got what you need. Wanna trade? Join me on @Neonmob",
                    "I just hit the @NeonMob card jackpot!" + creatorTwitterName,
                    "You too can get this card" + creatorTwitterName + " but only on @NeonMob.",
                    "This is my digital trading card" + creatorTwitterName + "! Pull your own on @Neonmob"
                    ]
                    const randomMessageIndex = Math.floor(Math.random() * shareMessage.length);
                    return shareMessage[randomMessageIndex];
                }

                function makeShareMessage(piece) {
                    var creatorTwitterName = "";

                    if (piece.set.creator.twitter_username) {
                        creatorTwitterName = " by @" + piece.set.creator.twitter_username;
                    }
                    
                    return getRandomShareMessage(creatorTwitterName);                   
                }

                function getRandomShareMessage(creatorTwitterName){
                    const shareMessage = [
                    "I'm obsessed with this card" + creatorTwitterName + " I got it on @NeonMob",
                    "I got this amazing card on @NeonMob," + creatorTwitterName + " come join and you might get a copy yourself 😁",
                    "Behold my beautiful @NeonMob card" + creatorTwitterName,
                    "I have obtained dopamine through this rectangle" + creatorTwitterName + ".  Join @NeonMob. today for your own chance to experience dopamine.",
                    "The card gods have smiled upon me this day on @NeonMob",
                    "I got this card" + creatorTwitterName + " on @NeonMob and I'm ready to make it my entire personality",
                    "Please be aware of this @NeonMob card" + creatorTwitterName + ".  Thank you.",
                    "Happiness is stored in this authentic NeonMob art rectangle" + creatorTwitterName + ".",
                    "Pulling this card on @NeonMob" + creatorTwitterName + " is so much better than getting poked in the eye",
                    "At last, card came home on @NeonMob",
                    "Can you feel the adorbs? I can! 🥹 Get this card" + creatorTwitterName + " on @NeonMob",
                    "Too. Much. Cuteness. " + creatorTwitterName,                    
                    "Come check out my @NeonMob art museum right here on my phone.",
                    "I pulled this amazing trading card " + creatorTwitterName + " on @Neonmob. What cards will you pull?",
                    "Psst. Psst. I got what you need. Wanna trade? Join me on @Neonmob",
                    "I hit the @NeonMob card jackpot!",
                    "You too can get this card" + creatorTwitterName + " but only on @NeonMob.",
                    "This is my digital trading card" + creatorTwitterName + "! Pull your own on @Neonmob"
                    ]
                    const randomMessageIndex = Math.floor(Math.random() * shareMessage.length);
                    return shareMessage[randomMessageIndex];
                }

                $scope.getPublicUrl = function () {
                    var publicUrl = "";

                    if ($scope.piece) {
                        if ($scope.brag) {
                            var homepage = window.location.origin;
                            publicUrl = homepage + "/activityfeed/pack-opened/" + $scope.pack.id;
                        } else {
                            publicUrl = $scope.piece.public_url;
                        }
                    } else if ($scope.badge) {
                        publicUrl = $scope.badge.public_url;
                    }

                    if (publicUrl === "") {
                        publicUrl = $scope.url;
                    }

                    return publicUrl;
                };


                $scope.getShareUrl = function() {
                    var publicUrl = $scope.getPublicUrl();
                    var shareSource = $scope.getShareSource();
                    if(!publicUrl || !shareSource) {
                        console.error("publicUrl:", publicUrl, "shareSource", shareSource);
                        return null;
                    }

                    return artShare.createShareUrl(publicUrl, shareSource);
                };

                $scope.getImageUrl = function () {
                    var imageUrl = "";

                    if ($scope.imageUrl){
                        imageUrl = $scope.imageUrl;
                    } else if ($scope.piece) {
                        imageUrl = artPieceService.getPromoImageUrl($scope.piece);
                    } else if ($scope.badge){
                        imageUrl = $scope.badge.image_url;
                    }

                    return imageUrl;
                };

                $scope.getButtonText = function () {
                    if ($scope.shareSource == 'share')
                        return "Share your collection & earn carats";
                    return $scope.brag ? "Brag!" : "Share";
                };

                $scope.getShareDark = function () {
                    if ($scope.shareSource == 'share' || $scope.shareSource == 'submissions')
                        return "dark";
                    return  "";
                };

                $scope.getShareSource = function () {
                    if ($scope.shareSource)
                        return $scope.shareSource;

                    return $scope.brag ? "brag" : "share";
                };

            }],
        templateUrl: "partials/art/share/share-button.partial.html"
    };
    return directiveDefinitionObject;
});
