artModule.factory("artFriendsService", [
    "$http",
    "$q",
    '$rootScope',
    'artConfig',
    'artResource',
    'artSubscriptionService',
    'artUserConnections',
    'artUrl',
    'artUser',
function(
    $http,
    $q,
    $rootScope,
    artConfig,
    artResource,
    artSubscriptionService,
    artUserConnections,
    artUrl,
    artUser
){

    var artFriendsService = {};
    var _friendsList = [];
    var _friendPromise = null;
    var _onlineFriendCount = 0;
    var _retrievingMoreFriends = false;

    if (artUser.isAuthenticated()) {
        _friendPromise = artResource.retrievePaginated(artConfig.api['api-friend-list']).then(function(data){
            _friendsList = data;
            _friendsList.forEach(artFriendsService.connectToFriend);
        });
    }

    artFriendsService.isVerified = artUser.isVerified();

    artFriendsService.getFriendsList = function(){
        return _friendsList;
    };

    artFriendsService.addFriend = function(userId){

        return _friendPromise.then(function(){
            artResource.create(artConfig.api['api-friend-list'], {
                id: userId
            }).then(function(friend){
                _friendsList.push(friend);
                _friendsList.count++;
                artFriendsService.connectToFriend(friend);

                if(artUserConnections.isUserOnline(friend.id)){
                    _onlineFriendCount++;
                }

                $rootScope.$broadcast("updatedFriends", {userId: friend.id, isFriend: true});
            });
        });
    };

    artFriendsService.removeFriend = function(userId){
        return _friendPromise.then(function(){
            var index, friend;
            _friendsList.forEach(function(f, i){
                if(f.id === userId){
                    friend = f;
                    index = i;
                }
            });

            if(friend){
                return artResource.destroy(friend).then(function(){

                    if(artUserConnections.isUserOnline(friend.id)){
                        _onlineFriendCount--;
                    }

                    _disconnectFromFriend(friend);
                    _friendsList.splice(index, 1);
                    _friendsList.count--;

                    $rootScope.$broadcast("updatedFriends", {userId: friend.id, isFriend: false});
                });
            } else {
                return artResource.destroy({
                    // api-friend-detail url.
                    url: "/api/friend/" + userId
                });
            }
        });

    };

    artFriendsService.loadMoreFriends = function(){
        if(_friendsList.next && !_retrievingMoreFriends){
            _retrievingMoreFriends = true;
            _friendsList.retrieveNext().then(function(){
                _friendsList.forEach(artFriendsService.connectToFriend);
                _retrievingMoreFriends = false;
            });
        }
    };

    artFriendsService.connectToFriend = function(friend) {
        // Guard against connecting multiple times to the same friend.
        if(!friend.isOnline){
            friend.disconnect = artUserConnections.connectToUser(friend.id);
            friend.isOnline = artUserConnections.isUserOnline.bind(artUserConnections, friend.id);
        }
    }

    $rootScope.$on("artUserConnections.changed", function(ev, status){

        var friend = _getFriendById(status.id);
        if(!friend){
            return;
        }

        if(status.connected){
            if(status.initialConnection){
                var notification = {
                    message: friend.first_name + " is online!",
                    type: "friend-online",
                    friend: friend
                };
                artSubscriptionService.broadcast("show-growl-notifications", [notification]);
            }
            _onlineFriendCount++;
        } else {
            _onlineFriendCount--;
        }
    });

    function _getFriendById(friendId){
        for(var i = 0; i < _friendsList.length; i++){
            if(_friendsList[i].id === friendId){
                return _friendsList[i];
            }
        }
        return null;
    }

    function _disconnectFromFriend(friend){
        friend.disconnect();
    }

    function _isUserInFriendList(user){
        for(var i = 0; i < _friendsList.length; i++){
            if(_friendsList[i].id === user.id){
                return true;
            }
        }
        return false;
    }

    artFriendsService.isFriend = function(user){

        if( artUser.isYou(user) ) {
            return false;
        }

        if(_isUserInFriendList(user)){
            return $q.when({
                is_friend:true
            });
        } else {
            // DJANGO ENDPOINT NAME: api-friend-detail
            var url = artUrl.join(artConfig.api['api-friend-list'], user.id, true);
            return $q.when($http.get(url)).then(function(response){
                return response.data;
            });
        }

    };


    artFriendsService.getOnlineFriendsCount = function(){
        return _onlineFriendCount;
    };

    return artFriendsService;

}]);
