packOpenModule.directive('poReceivePiecesCarousel', [
    'Debouncer',
function(
    Debouncer
){

    var directiveDefinitionObject = {};

    directiveDefinitionObject.scope = {
        prints: "=poReceivePiecesCarousel",
        growth: "=poReceivePiecesCarouselGrow",
        tallestPiece: "=poTallestPiece",
        maxWidth: "=poMaxWidth",
        maxHeight: "=poMaxHeight"
    };

    directiveDefinitionObject.controller = "poReceivePiecesCarouselController";

    directiveDefinitionObject.link = function(scope, element) {

        $(window).on('resize.pack-open-receive', Debouncer.stallEvent( function( ev ) {

            scope.delay(function(){

                scope.resize();

            });


        }, 500 ));

    };

    directiveDefinitionObject.templateUrl = "partials/pack-open/pack-open-receive-carousel.partial.html";

    return directiveDefinitionObject;

}])
.controller('poReceivePiecesCarouselController', [
    '$scope',
    '$element',
    '$timeout',
    'artResponsive',
    'EnvironmentCalculations',
    'artUser',
    'artPieceService',
    '$animate',
function(
    $scope,
    $element,
    $timeout,
    artResponsive,
    EnvironmentCalculations,
    artUser,
    artPieceService,
    $animate
){

    $animate.enabled(false, $element);

    $scope.$on('$destroy', function() {

        $(window).off('resize.pack-open-receive');

    });


    var _SIZES = {

        STANDARD:{
            WIDTH:      320,
            HEIGHT:     480
        },
        MOBILE: {
            WIDTH:      260,
            HEIGHT:     260
        },
        VIEWPORT: {
            SMALLER:    320 * 3,
            MOBILE:     260 * 3
        }

    };

    var _CONSTRAINTS = {

        STANDARD:{
            WIDTH:      320,
            HEIGHT:     480
        },
        MOBILE: {
            WIDTH:      240,
            HEIGHT:     260
        }

    };

    var _TIMES = {

        META_REFRESH_SPEED: 300,
        FLIP_PAUSE: 0

    };

    var watchLock = false;

    var rotateLock = false;

    $scope.delay = function(callback, time) {

        time = time || 0;

        $timeout(function(){

            if (callback) {
                callback();
            }

        }, time);

    };

    $scope.rotate = function(index) {

        // The cards are rotating, don't even try this.
        if (rotateLock === true) {

            return false;

        }

        // Out of Bounds Edge Case
        if (index < 0 || index >= $scope.prints.length) {

            return false;

        }

        rotateLock = true;

        if ($scope.growth === true) {

            rotateLock = false;

            return false;

        } else {

            // Push the cards left
            if ( index > $scope.middleIndex ) {

                carousel.prepareSlideBehavior(function() {

                    watchLock = true;

                    carousel.rotateLeft(function(){

                        rotateLock = false;

                    });

                }, true);


            // Push the cards right
            } else if ( index < $scope.middleIndex ) {

                carousel.prepareSlideBehavior(function() {

                    watchLock = true;

                    carousel.rotateRight(function(){

                        rotateLock = false;

                    });

                }, false);


            // Initiate middle
            } else {

                if (carousel.windowWidth() <= _SIZES.VIEWPORT.MOBILE) {

                    carousel.toggleMobile();

                }

                rotateLock = false;

            }

        }

    };

    $scope.resize = function() {

        carousel.resize();

    };

    // ===================================================================

    // Built off this problem child.

    $scope.$watchCollection('prints', function() {

        if (watchLock === false ) {

            watchLock = true;

            $scope.revealMeta = false;

            carousel.insert(function(){

                watchLock = false;

            }, true);

        }

    }, true);

    // ===================================================================

    var carousel = {};

    carousel.setHeight = function(){

        if($scope.tallestPiece && $scope.tallestPiece.piece_assets){

            var pieceInfoHeight = 34,
                maxHeight       = $scope.maxHeight + pieceInfoHeight,
                hToWRatio       = 1/artPieceService.getImageRatio(artUser, $scope.tallestPiece, "large");

            var newHeight = $scope.maxWidth * hToWRatio + pieceInfoHeight;
            if(newHeight > maxHeight){
                newHeight = maxHeight;
            }

            $scope.carouselHeight =  newHeight;
        }
    };

    carousel.toggleMobile = function() {

        $scope.mobileMeta = !$scope.mobileMeta;

    };

    carousel.rotateRight = function( callback ) {

        var i = 0,
            middleIndex = $scope.middleIndex,
            max = $scope.prints.length - 1;

        var last = $scope.prints[max];

        $scope.prints[middleIndex].middle = false;

        for (i = max; i > 0; i--) {

            $scope.prints[i] = $scope.prints[i - 1];
            $scope.prints[i].ready = true;

        }

        $scope.prints[0] = last;

        $scope.prints[middleIndex].middle = true;
        $scope.currentPrint = $scope.prints[middleIndex];

        carousel.metaRefresh( callback );

    };

    carousel.rotateLeft = function( callback ) {

        var i = 0,
            middleIndex = $scope.middleIndex,
            max = $scope.prints.length - 1,
            first = $scope.prints[0];

        $scope.prints[middleIndex].middle = false;

        for (i = 0; i < max; i++) {

            $scope.prints[i] = $scope.prints[i + 1];
            $scope.prints[i].ready = true;

        }

        $scope.prints[max] = first;

        $scope.prints[middleIndex].middle = true;
        $scope.currentPrint = $scope.prints[middleIndex];

        carousel.metaRefresh( callback );

    };

    carousel.metaRefresh = function( callback, delay ) {

        delay = delay || _TIMES.META_REFRESH_SPEED;

        $timeout(function(){

            if (callback) {
                callback();
            }
            $scope.$broadcast("artVideoTriggerPlay");
            $scope.revealMeta = true;

        }, delay);

    };

    carousel.prepareSlideBehavior = function( callback, reverse ) {

        $scope.revealMeta = false;

        if (reverse === true) {

            $scope.reverse = true;

        }
        else {

            $scope.reverse = false;

        }

        $timeout(function(){

            if (callback) {
                callback();
            }

        }, 100);

    };

    carousel.windowWidth = function() {

        return artResponsive.getWindowWidth();

    };

    carousel.resize = function(objectSize, parentWidth) {

        objectSize    = objectSize || _SIZES.STANDARD;
        parentWidth   = parentWidth || EnvironmentCalculations.getWidth($element);

        var windowWidth = carousel.windowWidth();

        if (windowWidth <= _SIZES.VIEWPORT.SMALLER) {

            objectSize = _SIZES.MOBILE;

        }

        carousel.setHeight();



        var carouselWidth   = (objectSize.WIDTH * $scope.prints.length) + objectSize.WIDTH,
            rightOffset     = objectSize.WIDTH;

        if ($scope.prints.length > 1) {

            carouselWidth += objectSize.WIDTH;

            if ($scope.prints.length % 2 == 1) {

                rightOffset += objectSize.WIDTH;

            }

        } else {

            carouselWidth = objectSize.WIDTH;
            rightOffset = 0;

        }

        if (carouselWidth < parentWidth) {

            leftOffset = 0;

        } else {

            leftOffset = (carouselWidth - parentWidth) / 2;
            rightOffset += leftOffset;

        }

        $scope.topOffset = 0;
        $scope.rightOffset = rightOffset;
        $scope.leftOffset = leftOffset;
        $scope.carouselWidth = carouselWidth;
        $scope.mobileMeta = false;

    };

    carousel.scrub = function( array ) {

        array = array || [];

        for (var i = array.length - 1; i > -1; i--) {

            if (array[i].middle) {
                array[i].middle = false;
            }

        }

        return array;

    };

    carousel.update = function(delay, middleIndex, callback) {

        middleIndex = middleIndex || 0;
        delay = delay || 0;

        $scope.resize();
        $timeout(function() {

            if ($scope.prints.length > 0) {

                $scope.currentPrint = $scope.prints[middleIndex];

                $scope.prints[middleIndex].ready = true;

                $scope.revealMeta = true;
                $scope.prints[middleIndex].flipped = true;
                $scope.prints[0].first = false;

                if (callback) {
                    callback();
                }

            }
            else {

                if (callback) {
                    callback();
                }

            }

        }, delay);


    };

    carousel.insert = function(callback, update) {

        var middleIndex = 0;

        $scope.prints = carousel.scrub($scope.prints);

        if ($scope.prints.length > 0) {

            if ($scope.prints.length > 1) {

                for (var j = 0; j < $scope.prints.length; j++) {

                    if ( $scope.prints[j].ready === false ) {
                        break;
                    }

                    middleIndex += 1;

                }

            }

            $scope.prints[middleIndex].middle = true;

        }

        $scope.middleIndex = middleIndex;

        if (update) {
            carousel.update(_TIMES.FLIP_PAUSE, middleIndex, callback);
        }

    };

    // ===================================================================

    // $scope Vars

    $scope.currentPrint = null;
    $scope.carousel = carousel;
    $scope.mobileMeta = false;
    $scope.topOffset = 0;
    $scope.metaOffset = 640;


    $scope.hideOnMobile = function(){

        return artResponsive.getWindowWidth() >= 620;

    };

    $scope.checkAmountToGift = function(){

        var value = artPieceService.getPrintCount(artUser, $scope.currentPrint.id);

        if (value > 1) {

            return true;

        }

        return false;

    };

    $scope.showDetailsMessage = function() {
        return $scope.$parent.currentState === 'prints-all-reveal';
    };

    // ===================================================================

}]);