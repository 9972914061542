artModule.directive("artPieceAsset", function () {
    var artPieceAsset = {};

    artPieceAsset.scope = {
        piece: "=artPieceAsset",
        requestedSize: "=?artSize",
        width: "=?artWidth",
        height: "=?artHeight",
        fluid: "=?artFluid",
        isPublic: "=?artPublic",
        showLoading: "=?artShowLoading",
        isPackOpenPage: "=?artPackOpenPage"
    };

    artPieceAsset.controller = [
        "$scope",
        "artPieceService",
        "artUser",
        "wsLumberjack",
        "artConstants",
        function ($scope,
                  artPieceService,
                  artUser,
                  wsLumberjack,
                  artConstants) {

            var self = this;
            var pieceData = {};

            // exposed on the controller object for tests
            // TODO - the code below points at a structure which would be nice to get from the mid-tier
            self.init = function () {
                $scope.requestedSize = $scope.requestedSize || "large";
                $scope.fluid = !!$scope.fluid;
                $scope.isPublic = !!$scope.isPublic;
                $scope.showLoading = $scope.showLoading !== false;
                $scope.settVersion = artConstants.VERSION_TYPES.limited;

                var assetType = calcAssetType();
                var adaptor = adaptors[assetType];
                pieceData = adaptor.getData();

                $scope.pieceClass = "piece-" + assetType;
                $scope.assetType = assetType;
                $scope.sizeName = adaptor.getSize();

                $scope.videoSources = [];
                $scope.imageUrl = "";
                $scope.posterUrl = "";

                if(!pieceData) return;

                if (assetType === "video") {
                    $scope.videoSources = pieceData.sources;

                    var posterData = artPieceService.getImageData(artUser, $scope.piece, $scope.sizeName, $scope.isPublic);
                    if(posterData)
                        $scope.posterUrl = posterData.url;
                } else {
                    $scope.imageUrl = pieceData.url;
                }

                $scope.calcedWidth = getWidth();
                $scope.calcedHeight = getHeight();
                $scope.dimensionStyle = getDimensionStyle();
            };

            // used by Sett creator only
            var redrawAssetListener = $scope.$on("artRedrawAsset", function (event, pieceId) {
                if ($scope.piece.id != pieceId) return;
                self.init();
            });

            function calcAssetType() {
                var adaptor = adaptors[$scope.piece.asset_type];
                if (adaptor && adaptor.isValid())
                    return $scope.piece.asset_type;

                return "image";
            }

            function getVideoSize() {
                return ({
                    medium: "medium",
                    large: "large",
                    xlarge: "large"
                })[$scope.requestedSize];
            }

            var adaptors = {
                video: {
                    getSize: getVideoSize,

                    isValid: function () {
                        try {
                            var canViewVideo = $scope.isPublic
                                || (artUser.isAuthenticated() && artPieceService.hasPiece(artUser, $scope.piece));
                            if (!canViewVideo) return false;

                            if ($scope.fluid) return false;
                            if (!getVideoSize()) return false;
                            if (!this.getData()) return false;

                            return true;
                        } catch (e) {
                            wsLumberjack.exception(e);
                            return false;
                        }
                    },

                    getData: function () {
                        var size = getVideoSize();
                        var result = $scope.piece.piece_assets.video[size];

                        if (!result) {
                            console.error("WARN: no video source found for size:", size);
                        }
                        return result;
                    }
                },
                image: {
                    getSize: function () {
                        return $scope.requestedSize;
                    },

                    isValid: function () {
                        return true;
                    },

                    getData: function () {
                        return artPieceService.getImageData(
                            artUser,
                            $scope.piece,
                            $scope.requestedSize,
                            $scope.isPublic
                        );
                    }
                }
            };

            function getWidth() {
                var data = pieceData,
                    ratio = data.width / data.height,
                    width;

                if ($scope.fluid) return null;

                if ($scope.width && $scope.height) {
                    if (ratio > $scope.width / $scope.height) {
                        width = $scope.width;
                    } else {
                        width = Math.ceil($scope.height * ratio);
                    }
                } else if ($scope.width) {
                    width = $scope.width;
                } else if ($scope.height) {
                    width = Math.ceil($scope.height * ratio);
                } else {
                    width = data.width;
                }

                return Math.min(width, data.width);
            }

            function getHeight() {
                var data = pieceData,
                    ratio = data.width / data.height,
                    height;

                if ($scope.fluid) return null;

                if ($scope.width && $scope.height) {
                    if (ratio < $scope.width / $scope.height) {
                        height = $scope.height;
                    } else {
                        height = Math.ceil($scope.width / ratio);
                    }
                } else if ($scope.height) {
                    height = $scope.height;
                } else if ($scope.width) {
                    height = Math.ceil($scope.width / ratio);
                } else {
                    height = data.height;
                }

                return Math.min(height, data.height);
            }

            function getDimensionStyle() {
                if ($scope.fluid) return {};

                return {
                    width: $scope.calcedWidth + "px",
                    height: $scope.calcedHeight + "px"
                };
            }

            $scope.$on("$destroy", function () {
                redrawAssetListener();
            });

            self.init();
        }];

    artPieceAsset.templateUrl = "partials/art/piece/piece-asset.partial.html";

    return artPieceAsset;

});
