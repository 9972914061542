artModule.controller("artFreebiesNotificationsController", [
    '$scope',
    'artUser',
    'artFreebiesPollService',
    function ($scope,
              artUser,
              artFreebiesPollService) {

        $scope.proStatus    = artUser.getProUserStatus();
        $scope.viewState    = artFreebiesPollService.getViewState.bind(artFreebiesPollService);
        $scope.labelText    = artFreebiesPollService.getLabelText.bind(artFreebiesPollService);
        $scope.freebieCount = artFreebiesPollService.getFreebieCount.bind(artFreebiesPollService);
        $scope.tipText      = artFreebiesPollService.getTipText.bind(artFreebiesPollService);

        var freebiesRemaining = artFreebiesPollService.getFreebiesRemaining();

        var freebiesWatch = $scope.$watch(artUser.getFreebiesRemaining, function(newValue) {
            if (freebiesRemaining === newValue) {
                return;
            }

            freebiesRemaining = newValue;
            if (freebiesRemaining >= artUser.getFreebiesLimit()) {
                    return artFreebiesPollService.freebiesState();
                }
                artFreebiesPollService.countdownState();
        });

        $scope.$on('$destroy', function() {
            artFreebiesPollService.stopCountdown();
            artFreebiesPollService.startFreebiePoll();
            if (freebiesWatch) freebiesWatch();
        });

        $scope.$on("streak-refresh-freebie", function() {
            return artFreebiesPollService.freebiePollState();
        });

        artFreebiesPollService.startupState();

}]);
