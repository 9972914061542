artModule.directive("artScroll", function(){
    var artScroll = {};

    artScroll.scope = {
        event: "&artScroll"
    };

    artScroll.link = function(scope, element){

        element.bind("scroll", function(ev){
            scope.$evalAsync(function(){
                scope.event({
                    $event:ev
                });
            });
        });

        scope.$on("$destroy", function(){
            element.unbind("scroll");
        });

    };

    return artScroll;

});