neonmobApp.controller("MilestonesListController", [
    '$scope',
    '$window',
    '$location',
    'artResourceService',
    'artConfig',
    'artResource',
    'artConfirm',
    'ProfileUser',
function(
    $scope,
    $window,
    $location,
    artResourceService,
    artConfig,
    artResource,
    artConfirm,
    ProfileUser
) {

    $scope.targetId = artConfig.targetId;
    ProfileUser.fetchUser().then(function(response) {
        $scope.user = response.data;
    });

    $scope.collection = [];
    $scope.rarity = [];
    $scope.streak = [];
    $scope.trade = [];
    $scope.discard = [];
    $scope.time = [];
    $scope.rank = [];
    $scope.completion_order = [];
    $scope.difficulty = [];
    $scope.series_completion = [];
    $scope.debugData = {
        "grantPointsURL": artConfig['api-grant-points'],
        "points": null,
        "reset": false
    };

    var queryParams = $location.search();

    /*
     * Sorting functionality start's here.
    */
    $scope.sortOptions = [
        {
            id: 'name',
            selected: false,
            sortOrder: 'desc',
            name: 'Series'
        },
        {
            id: 'milestones',
            selected: false,
            sortOrder: 'desc',
            name: 'Milestone'
        },
        {
            id: 'rank',
            selected: false,
            sortOrder: 'desc',
            name: 'Rank'
        },
        {
            id: 'completion_time',
            selected: false,
            sortOrder: 'desc',
            name: 'Time'
        },
        {
            id: 'completion_order',
            selected: false,
            sortOrder: 'desc',
            name: 'Order'
        }
    ];

    function  _deSelectSortOption(option) {
        $scope.sortOptions.forEach(function(arr, index){
            if(arr.name !== option.name) {
                $scope.sortOptions[index].selected = false;
                $scope.sortOptions[index].sortOrder = 'desc';
            }
        });
    }
    var updateSortOption = function(option) {
        _deSelectSortOption(option);
        if(!option.selected) {
            option.selected = true;
            return;
        }
        if(option.selected && option.sortOrder === 'desc') {
            option.sortOrder = 'asc';
            return;
        }

        if(option.selected && option.sortOrder === 'asc') {
            option.sortOrder = 'desc';
            option.selected = false;
            return;
        }
    };
    var updateParamObj = function() {

        if (!$scope.sortOptions) return;

        $scope.paramObj = {};
        $scope.sortOptions.map(function(option) {
            if(option.selected) {
                $scope.paramObj.key = option.id;
                $scope.paramObj.value = option.sortOrder;
            }
        });
    };

    $scope.sortBy = function(field) {
        updateSortOption(field);
        updateParamObj(field);
        _fetchTabSpecificMilestones($scope.selectedTab);
    };
    // Sorting end's here.

    /*
    * Milestone tab options.
    */
    $scope.milestoneOptions = [

        {
            name: 'Overview',
            tab: 'progress'
        },
        {
            name: 'Core Milestones',
            tab: 'core'
        },
        {
            name: 'Series Milestones',
            tab: 'series'
        },
        {
            name: 'Special Milestones',
            tab: 'special'
        }
    ];

   /*
    * Milestones tab on click trigger.
   */
    $scope.onTabClick = function(selectedTab) {
        $scope.selectedTab = selectedTab;
        $scope.showLoader = true;
        _fetchTabSpecificMilestones($scope.selectedTab);
    };

    /*
     * Parse core milestone data.
     * {badge} - array of objects.
    */
    function _parseBadgeData(badges) {
        if(!badges.length) {
            return;
        }
        badges.forEach(function(badge) {
            switch(badge.type) {
                case 'trade_actions':
                    badge.emptyPlaceholder = 'emptyTrading';
                    $scope.trade.push(badge);
                    break;
                case 'freebie_opening_streak':
                    badge.emptyPlaceholder = 'emptyStreaks';
                    $scope.streak.push(badge);
                    break;
                case 'collection_size':
                    badge.emptyPlaceholder = 'emptyCollection';
                    $scope.collection.push(badge);
                    break;
                case 'rarity_count':
                    badge.emptyPlaceholder = 'emptyRarity';
                    $scope.rarity.push(badge);
                    break;
                case 'discarding':
                    badge.emptyPlaceholder = 'emptyDiscarding';
                    $scope.discard.push(badge);
                    break;
                case 'series_completion_time':
                    badge.emptyPlaceholder = 'emptySeriesTime';
                    $scope.time.push(badge);
                    break;
                case 'series_completion_order':
                    badge.emptyPlaceholder = 'emptySeriesOrder';
                    $scope.completion_order.push(badge);
                    break;
                case 'series_completion':
                    badge.emptyPlaceholder = 'emptySeriesCompletion';
                    $scope.series_completion.push(badge);
                    break;
                case 'difficulty_completion':
                    badge.emptyPlaceholder = 'emptyDifficultyCompletion';
                    $scope.difficulty.push(badge);
                    break;
            }
        });
    }

    /*
    * Infinite Scroll functionality.
    */
    $scope.getNextPage = function() {
        if($scope.loading || $scope.loadingMore || !$scope.results.next) {
            return;
        }
        $scope.loadingMore = true;
        $scope.results.retrieveNext().then(function() {
            $scope.loadingMore = false;
        });
    };

    /*
     * Fetch User Stats.
    */
    function _fetchUserStats() {
        var userStatsURL = artConfig.api['api-user-stats-all'];
        artResourceService.get(userStatsURL, false).success(function(data) {
            $scope.userStats = data;
          }).error(function() {
              NM.error("Sorry, I could not load user stats. Please try again.");
        });
    }

    /*
     * Fetch Series Difficulty Stats.
    */
    function _fetchSettDifficultyStats() {
        var difficultyStatsURL = artConfig.api['api-difficulty-stats'];
        artResourceService.get(difficultyStatsURL, false).success(function(data) {
            $scope.showLoader = false;
            $scope.statsDifficulty = data;
          }).error(function() {
              NM.error("Sorry, I could not load series difficulty stats. Please try again.");
        });
    }

    /*
     * Fetch special milestones.
    */
    function _fetchSpecialMiletone() {
        var seriesMilestoneURl = artConfig.api['api-site-badges'] + "?special=true";
        artResourceService.get(seriesMilestoneURl, false).success(function(data) {
            $scope.showLoader = false;
            $scope.specialMilestone = data;
          }).error(function() {
              NM.error("Sorry, I could not load your milestones. Please try again.");

        });
    }
    /*
     * Form URL for Series milestones with Sorting params.
    */
    function _getSeriesMilestoneUrl(paramObj) {

        var url = artConfig.api['api-user-sett-milestones'];
        if (paramObj && paramObj.key) {
            url = artConfig.api['api-user-sett-milestones'] + "?" + paramObj.key + "=" + paramObj.value;
        }
        if(!paramObj && queryParams.settId) {
            url = artConfig.api['api-user-sett-milestones'] + "?sett_id=" + queryParams.settId;
        }

        return url;
    };

    /*
     * Fetch sett specific achieved milestones.
    */
    function _fetchSeriesSpecificMiletone() {
        artResource.retrievePaginated(_getSeriesMilestoneUrl($scope.paramObj))
        .then(function(data) {
            $scope.results = data;
            $scope.loading = false;
        }).catch(function() {
            NM.error("Sorry, I could not load your milestones. Please try again.");
        });
    }

    /*
     * Fetch all user sett collection's rarity count.
    */
    function _getTotalUserSettRarityCount() {
        var url = artConfig.api['api-sett-badges'];
        artResourceService.get(url, false).success(function(data) {

            $scope.showLoader = false;
            $scope.achievedRarityCount = data;
            }).error(function() {
                NM.error("Sorry, I could not load your milestones. Please try again.");

        });
    }

    /*
    * Trigger Tab specific milestones api.
    */
    function _fetchTabSpecificMilestones(tabName) {
        $scope.loading = true;
        $scope.loadingMore = false;
        if(tabName === 'core') {
            _getCoreSpecificMilestone();
        }
        if(tabName === 'series') {
            _getTotalUserSettRarityCount();
            _fetchSeriesSpecificMiletone();
        } if(tabName === 'special') {
            _fetchSpecialMiletone();
        }
    }

    /*
    * Fetch core milestones.
    */
    function _getCoreSpecificMilestone() {
        var url = artConfig.api['api-site-badges'];
        artResourceService.get(url, false).success(function(data) {
            _parseBadgeData(data);
            $scope.showLoader = false;
        }).error(function() {
            NM.error("Sorry, I could not load your milestones. Please try again.");
        });
    }

    function _makeProgress() {
        $scope.progressBarWidth = 0;
        var progressInterval = setInterval(frame, 10);
        function frame() {
            if (($scope.user && $scope.progressBarWidth >= $scope.user.level.current_progress) || $scope.progressBarWidth >= 100) {
                clearInterval(progressInterval);
                $scope.$apply();
            } else {
                $scope.progressBarWidth++;
            }
        }
    }

    /*
    * Load Overview page by default(on load).
    */
    function _loadInitial() {
        _fetchUserStats();
        _fetchSettDifficultyStats();
        _makeProgress();
    };

    if($scope.selectedTab === 'progress') {
        _loadInitial();
    }

    if($scope.selectedTab === 'core') {
        _getCoreSpecificMilestone();
    }

    $scope.defaultTab = 'progress';
    if(queryParams.settId) {
        $scope.defaultTab = 'series'
    }

    /*
    * Grant points to target user. Only to extend support to test animation.
    */
    $scope.grantPoints = function() {
        var payload = {
            "user_id": $scope.targetId,
            "points": $scope.debugData.points,
            "reset_level": $scope.debugData.reset
        };
        artResource.create(artConfig['api-grant-points'], payload).then(function(levelData) {
            var overlayData, updatedLevel;
            updatedLevel = levelData.level_ups[levelData.level_ups.length - 1];
            $scope.debugData.points = null;
            if (!updatedLevel) {
                $window.location.reload();
                return;
            }

            overlayData = {
                rewardType   : 'level-up',
                message      : 'Level Up!',
                okText       : 'Great!',
                data         : updatedLevel,
                parentClass : 'series-complete-reward' // To do: Make class name generic.
            };
            artConfirm.showEarnedStatus(overlayData, function() {
                $window.location.reload();
            });
        });
    }
}]);
