artModule.directive("artSettLarge", [
    'artSett',
function(
    artSett
){
    var artSettLarge = {};

    artSettLarge.scope = {
        sett: '=artSettLarge',
        preloadImages: '=?preloadImages'
    };

    artSettLarge.templateUrl = "partials/art/sett/sett-large.partial.html";

    artSettLarge.link = function(scope){
        if (scope.preloadImages !== false) {
            scope.fadeClass = 'fade-in--loading';
            artSett.preloadImages(scope.sett, 'large', 4).finally(function () {
                scope.fadeClass = 'fade-in--loaded';
            });
        }
    };

    return artSettLarge;
}]);