artModule.factory("artString", function(){

    var artString = {};

    artString.getArticle = function(string){

        return (/^[aAeEiIoOuU]/).test(string) ? "an" : "a";

    };

    return artString;

});