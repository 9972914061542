artModule.factory("artMessage", [
    "$timeout",
function(
    $timeout
){
    var _message = null,
        _subtext=null,
        _styleClass = null,
        _iconClass = null,
        _blurClass = null,
        _visible = false,
        _callback = null,
        _showOk = true,
        _okText = "OK",
        _showCancel = false,
        _closeMs = 0,
        _messageQueue = [],
        _closeTimerId = null,
        _MESSAGES_TAG_MAP = {
            'info':'icon-checkmark',
            'warning':'icon-warning',
            'error':'icon-warning',
            'success':'icon-checkmark'
        };

    function _show(params){
        _message = params.message;
        _subtext = params.subtext;
        _iconClass = params.iconClass;
        _styleClass = params.styleClass;
        _blurClass = params.blurClass;
        _visible = true;
        _showCancel = params.showCancel;
        _showOk = params.showOk;
        _okText = params.okText || "OK";
        _closeMs = params.closeMs || 0;

        if(_closeMs > 0) {
            _closeTimerId = $timeout(_close, _closeMs);
        }

        // forces a //VOID: force a scope apply if one did not occur.
        $timeout(function(){});
    }

    function _showNextMessage(){
        var message = _messageQueue.shift(),
            iconClass = _MESSAGES_TAG_MAP[message.tags]||'checkmark';
        _show({
            message: message.message,
            iconClass: iconClass,
            showCancel: false,
            showOk: true,
            closeMs: 0
        });
    }

    function _close(canceled) {
        $timeout.cancel(_closeTimerId);

        _visible = false;

        if(_messageQueue.length > 0){
            return _showNextMessage();
        }

        if(_callback){
            _callback(canceled);
            _callback = null;
        }
    }

    var artMessage = {
        ALERT_ICON_CLASS: "icon-warning",
        CONFIRM_ICON_CLASS: "icon-checkmark",

        getMessage:function(){
            return _message;
        },

        getSubtext:function(){
            return _subtext;
        },

        getIconClass:function(){
            return _iconClass;
        },

        getStyleClass:function(){
            return _styleClass;
        },

        getBlurClass:function() {
            return _blurClass;
        },

        getOkText:function(){
            return _okText;
        },

        isVisible:function(){
            return _visible;
        },

        hasCancelButton:function(){
            return _showCancel;
        },

        hasOkButton:function() {
            return _showOk;
        },

        closeMs:function() {
            return _closeMs;
        },

        showAlert:function(messages, callback){
            var message = null,
                subtext = null;
            if (typeof messages === 'string'){
                message = messages;
            } else {
                message = messages.message;
                subtext = messages.subtext;
            }

            _show({
                message: message,
                subtext: subtext,
                iconClass: artMessage.ALERT_ICON_CLASS,
                showCancel: false,
                showOk: true
            });
            _callback = callback || null;
        },
        /* ShowAlertWithCancel
        * @param {Object} message - Alert message.
        * @param {function} callback.
        * @param {string} OKTEXT- Text for OK button.
        * @param {bool} `true` - blur background.
        */
        showAlertWithCancel: function(messages, callback, okText, blurClass) {
            var message = null,
                subtext = null;
            if (typeof messages === 'string'){
                message = messages;
            } else {
                message = messages.message;
                subtext = messages.subtext;
            }

            _show({
                message: message,
                subtext: subtext,
                iconClass: artMessage.ALERT_ICON_CLASS,
                blurClass: blurClass || false,
                showCancel: true,
                showOk: true,
                okText: okText
            });
            _callback = callback || null;
        },

        showInfo:function(message, callback, okText, infoIconClass, styleClass){
            _show({
                message: message,
                styleClass: styleClass,
                iconClass: infoIconClass || artMessage.CONFIRM_ICON_CLASS,
                showCancel: false,
                showOk: true,
                okText: okText
            });
            _callback = callback || null;
        },

        showConfirm:function(message, callback) {
            _show({
                message: message,
                iconClass: artMessage.CONFIRM_ICON_CLASS,
                showCancel: true,
                showOk: true
            });
            _callback = callback || null;
        },

        showInfoAutoClose:function(params) {
            var message = params.message;
            var closeMs = params.closeMs || 3000;
            _callback = params.callback || null;

            _show({
                message: message,
                iconClass: artMessage.CONFIRM_ICON_CLASS,
                showCancel: false,
                showOk: true,
                closeMs: closeMs
            });
        },

        showMessages:function(messages, callback){
            _messageQueue.push.apply(_messageQueue, messages);
            _showNextMessage();
            _callback = callback || null;
        },

        close: _close
    };

    return artMessage;
}]);
