artModule.service('artShowCarats', [
	'artOverlay',
function(
	artOverlay
){

	return function() {

		 artOverlay.bindNameToTemplateUrl("carats", "partials/art/carats/overlay-carats.partial.html", "modal");

        return artOverlay.show("carats", null, true, 'theme-light');
	}
}])
