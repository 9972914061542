artModule.directive("ctSettPreview", [
    'artConstants',
    'artSett',
    'ctSettCreator',
function(
    artConstants,
    artSett,
    ctSettCreator
){
    var ctSettPreview = {};

    ctSettPreview.templateUrl = "partials/creator/editor/sett-preview.partial.html";

    ctSettPreview.link = function(scope){
        scope.fadeClass = 'fade-in--loading';
        artSett.preloadImages(scope.sett, 'large', 4).finally(function () {
            scope.fadeClass = 'fade-in--loaded';
        });

        scope.updatePreviewPieces = function(){
            ctSettCreator.updatePreviewPieces(scope.previewPieces).finally(init);
        };

        function init() {
            scope.sett = ctSettCreator.getSett();
            scope.pieces = ctSettCreator.getSettPieces();
            scope.previewPieces = ctSettCreator.getPreviewPieces();

            var nonPreviewPieces = scope.pieces.filter(function(piece){
                return piece.preview_order >= artConstants.MAX_PREVIEW_PIECES;
            });

            var i;

            scope.availablePreviewPieces = [];
            for(i=0; i<scope.previewPieces.length; i++){
                scope.availablePreviewPieces[i] = nonPreviewPieces.concat([scope.previewPieces[i]]);
            }

            var _sortAvailablePieces = function(a, b) {
                var rarityDiff = a.rarity.rarity - b.rarity.rarity;
                if(rarityDiff === 0){
                    return a.name.localeCompare(b.name);
                }
                return rarityDiff;
            };

            for(i=0; i<scope.availablePreviewPieces.length; i++){
                scope.availablePreviewPieces[i].sort(_sortAvailablePieces);
            }
        }

        init();

    };

    return ctSettPreview;
}]);