neonmobApp.filter('range', function() {

    return function(range){

        range = range || 0;
        var object = new Array( range );

        var i = 0;
        for (; i < object.length; i++) {
            object[i] = i;
        }

        return object;

    };

});