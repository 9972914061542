neonmobApp.factory('nmPieceDetailService', [
    "$http",
    "$location",
    "$q",
    "artOverlay",
    "artPieceService",
    "artUrl",
    "artUser",
function(
    $http,
    $location,
    $q,
    artOverlay,
    artPieceService,
    artUrl,
    artUser
){

    var nmPieceDetailService = {},
        _isOverlay = false,
        _pieces = [],
        _index = null,
        _targetUser = null,
        _piecePromisesCache = null,
        _fetch = true,
        _public = false;

    nmPieceDetailService.hasCollection = function(){

        return _pieces.length > 1;

    };

    nmPieceDetailService.getPieceOwner = function(){

        return _targetUser;

    };

    nmPieceDetailService.startPieceDetail = function(targetUser, pieces, pieceId, fetch){

        _pieces = pieces;
        _targetUser = targetUser;
        _index = _findPieceId(pieceId);
        _piecePromisesCache = {};
        _fetch = fetch !== false;
        _public = false; //always reset!

    };

    nmPieceDetailService.startPieceDetailOverlay = function(targetUser, pieces, pieceId, fetch) {

        nmPieceDetailService.startPieceDetail(targetUser, pieces, pieceId, fetch);

        _isOverlay = true;

        artOverlay.show("piece-detail");

    };

    nmPieceDetailService.makePublic = function(){

        _public = true;

    };

    nmPieceDetailService.isPublic = function(){

        return _public;

    };

    nmPieceDetailService.fetchCurrentPiece = function(){

        var piece = _pieces[_index],
            promise = _fetchPiece(piece);

        if (_isOverlay === true) {

            artOverlay.showLoading(promise);

        }

        promise.then(function(piece){

            _fetchPiece(_pieces[_getNextIndex(_index)]);
            _fetchPiece(_pieces[_getPreviousIndex(_index)]);

            return piece;

        });

        return promise;

    };

    nmPieceDetailService.fetchNextPiece = function(){

        _index = _getNextIndex();

        return nmPieceDetailService.fetchCurrentPiece();

    };

    nmPieceDetailService.fetchPreviousPiece = function(){

        _index = _getPreviousIndex();

        return nmPieceDetailService.fetchCurrentPiece();

    };

    nmPieceDetailService.changeWindowLocation = function(piece){

        $location.path(artUrl.removeDomain(piece.public_url));

    };

    nmPieceDetailService.removePiece = function(piece) {

        if(_pieces.length == 1) {

            return;
        }

        var index = _findPieceId(piece.id);
        _pieces.splice(index, 1);    
    };

    var _findPieceId = function(pieceId){
        for (var i = 0; i < _pieces.length; i++) {
            if (_pieces[i].id === pieceId) {
                return i;
            }
        }
        return 0;
    };

    var _getUserPieceDetailUrl = function(targetId, pieceId){
        // API REVERSE: api-user-piece-detail
        return "/api/users/" + targetId + "/piece/" + pieceId + "/detail/";

    };

    var _convertToPrint = function(piece){

        var newPiece = piece;

        if(!piece.prints){

            newPiece = $.extend({ prints:[] }, piece);

        } else if(piece.prints.length > 0){

            newPiece = $.extend({}, piece, piece.prints[0]);
            newPiece.id = piece.id;

        }

        return newPiece;

    };

    var _fetchPiece = function(piece){

        var promise = _piecePromisesCache[piece.id];

        if (!promise) {

            var pieceDefer = $q.defer();

            artPieceService.syncOwnership()['finally'](function(){

                if(_fetch){

                    var url = _targetUser ? _getUserPieceDetailUrl(_targetUser.id, piece.id) : piece.api_url;

                    if(!url) {
                        url = _getUserPieceDetailUrl(piece.owner_id, piece.id);
                    }

                    $http.get(url).then(function(response){

                        var pieceDetail = _convertToPrint(response.data);

                        //preload images
                        artPieceService.preloadImages(artUser, [pieceDetail], 'xlarge', _public)['finally'](function(){

                            pieceDefer.resolve(pieceDetail);

                        });

                    }).catch(function(data, status){

                        pieceDefer.reject({
                            data: data,
                            status: status
                        });

                    });

                } else {

                    artPieceService.preloadImages(artUser, [piece], 'xlarge', _public)['finally'](function(){

                        var pieceDetail = _convertToPrint(piece);

                        pieceDefer.resolve(pieceDetail);

                    });

                }

            });

            promise = pieceDefer.promise;
            _piecePromisesCache[piece.id] = promise;

        }

        return promise;

    };

    var _getNextIndex = function() {

        return (_index + 1) % _pieces.length;

    };

    var _getPreviousIndex = function() {

        return (_pieces.length + _index - 1) % _pieces.length;

    };

    return nmPieceDetailService;

}]).directive("nmShowPieceDetail", ['nmPieceDetailService', 'artUser', function(nmPieceDetailService, artUser){

    var nmShowPieceDetail = {};

    nmShowPieceDetail.scope = {
        piece:"=nmShowPieceDetail",
        owner:"=?nmShowPieceDetailOwner",
        collection:"=?nmShowPieceDetailCollection"
    }

    nmShowPieceDetail.link = function(scope, element){

        scope.owner = scope.owner || artUser.toObject();
        scope.collection = scope.collection || [scope.piece];

        element.attr("href", element.attr("href") || "");

        element.bind("click.nmshowpiecedetail", function(){

            nmPieceDetailService.startPieceDetailOverlay(scope.owner, scope.collection, scope.piece.id);

            if(!artUser.isAuthenticated()){

                nmPieceDetailService.makePublic();

            }

            return false;

        });

        scope.$on("$destroy", function(){

            element.unbind("click.nmshowpiecedetail");

        });

    };

    return nmShowPieceDetail;

}]);
