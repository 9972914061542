neonmobApp.directive('nmAvatar', [
    'artUser',
function(
    artUser
) {

    var directiveDefinitionObject, _AVATAR_SIZES;

    _AVATAR_SIZES = {

        'very-small': 30,
        'small': 40,
        'medium': 80,
        'large': 100

    };

    directiveDefinitionObject = {

        scope: {
            size:"@nmAvatarSize",
            user:"=?nmAvatar",
            showName:"=nmAvatarShowName",
            showLevel:"=nmAvatarShowLevel"
        },

        controller: [
            '$scope',
            'artConfig',
            'wsLumberjack',
        function(
            $scope,
            artConfig,
            wsLumberjack
        ){

            var verySmall = false;

            if ($scope.size) {
                if ($scope.size === 'very-small') {
                    // Use the small image size.
                    $scope.size = 'small';

                    // Set a boolean for special conditions.
                    verySmall = true;
                }
            }
            else {
                $scope.size = "small";
            }

            // I like hacks.
            if (verySmall) {
                $scope.dimension = _AVATAR_SIZES['very-small'];
            }
            else {
                $scope.dimension = _AVATAR_SIZES[$scope.size];
            }

            $scope.getAvatarSource = function(){
                //if avatar rendered without user, assume it is for currently signed in user
                var avatar = null;
                try{

                    if($scope.user){
                        if($scope.user.avatar){ //has the user uploaded an avatar?
                            avatar = $scope.user.avatar[$scope.size];
                        }
                    } else {
                        return artUser.getAvatarSource($scope.size);
                    }

                } catch(e){

                    wsLumberjack.error("Could not read the avatar for the user:" + e.trace);

                }

                return avatar||artConfig.defaultAvatarUrl;

            };
            $scope.defaultAvatarUrl = artConfig.defaultAvatarUrl;

        }],

        templateUrl: "partials/component/avatar.partial.html"

    };

    return directiveDefinitionObject;

}]).directive('onErrorSrc', function() {
    return {
        link: function(scope, element, attrs) {
          element.bind('error', function() {
            if (attrs.src != attrs.onErrorSrc) {
              attrs.$set('src', attrs.onErrorSrc);
            }
          });
        }
    }
});
