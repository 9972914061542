artModule.directive("artSettCard", function(
){
    var artSettCard = {};
    artSettCard.scope = {
        sett: '=artSettCard',
        index: '=?artSettCardIndex',
        user: '=artSettCardUser',
        footerType: '=?artSettCardFooter',
        callback: '&artSettCardCallback',
        footerFlag: '=?artSettCardFooterFlag'
    };
    artSettCard.controller = "artSettCardController";
    artSettCard.templateUrl = "partials/profile-new/sett-card.partial.html";

    return artSettCard;

}).directive("artSettCardFull", function(
){
    var artSettCardFull = {};
    artSettCardFull.scope = {
        sett: '=artSettCardFull',
        index: '=?artSettCardIndex',
        user: '=artSettCardUser',
        footerType: '=?artSettCardFooter',
        callback: '&artSettCardCallback'
    };
    artSettCardFull.controller = "artSettCardController";
    artSettCardFull.templateUrl = "partials/profile-new/sett-card-full.partial.html";

    return artSettCardFull;

}).controller("artSettCardController", [
    "$scope",
    '$window',
    "artAnalytics",
    "artUser",
    "artUrl",
function(
    $scope,
    $window,
    artAnalytics,
    artUser,
    artUrl
) {

    $scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);

    $scope.triggerSignUp = function (sett) {
        var data = {};

        data.page = 'sett-card';
        data.settId = sett.id;
        data.settName = sett.name;

        artAnalytics.track('Clicked Sett Card', data );

        var signupUrl = artUrl.updateParams("/signup", {collection: sett.name, url: sett.links.permalink});

        $window.location.href = signupUrl;
    };

}]);
