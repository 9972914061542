artModule.filter('textToHtml', [
    '$sanitize',
    function ($sanitize) {
        return function (text) {
            if (!text) return text;
            var html = text.replace(/\&#13;/g, '').replace(/\n|\&#10;/g, '<br/>');
            return $sanitize(html);
        };
}]).filter('escapeHTML', function() {
  return function(text) {
      if (text) {
        return text.
            replace(/&/g, '&amp;').
            replace(/</g, '&lt;').
            replace(/>/g, '&gt;').
            replace(/'/g, '&#39;').
            replace(/"/g, '&quot;');
      }
      return '';
  };
}).filter('convertMarkdown', function(escapeHTMLFilter){
    return function(text) {
        var converter = new showdown.Converter();
        var markdownText = converter.makeHtml(escapeHTMLFilter(text));
        return markdownText.replace('<a', '<a target="_blank"');
    };
});