artModule.factory("artWebSocket", [
    "$rootScope",
    "artConfig",
function(
    $rootScope,
    artConfig
){

    function _wrapApply(callback, context){
        return function(data){
            $rootScope.$apply(callback.bind(context || window, data));
        };
    }

    /**
     *
     * @param options
     *  options.sort optional sort function to handle sorting the list when updates occur.
     *  options.id = the id of the list that this is connected to.
     * @constructor
     */
    function SortedListWebSocket(socket, options){

        this.socket = socket;
        this._id = options.id || null;
        this._sortMethod = options.sort;
        this.list = [];
        this.loading = true;
        this.totalCount = 0;
        this._onLoadInitial = options.onLoadInitial;
        this._onLoad = options.onLoad;
        this._onAddItem = options.onAddItem;
        this._onRemoveItem = options.onRemoveItem;

        this.socket.on("reconnect", function(){
            this.socket.emit("rejoin", {
                id: this._id
            });
        }.bind(this));

        this.socket.on("loadInitial", _wrapApply(function(data){

            this.loading = false;
            if( data.results && data.results.length) {
                this.list = data.results.concat(this.list);
                this.totalCount = data.count;
            }
            this.sort();

            if(this._onLoadInitial){
                this._onLoadInitial(data);
            }

        }, this));

        this.socket.on("load", _wrapApply(function(data){

            this.loading = false;
            this.list = data.concat(this.list);
            this.sort();

            if(this._onLoad){
                this._onLoad(data);
            }

        }, this));

        this.socket.on("addItem", _wrapApply(function(item){

            this._addItem(item);
            if(this._onAddItem){
                this._onAddItem(item);
            }

        }, this));

        this.socket.on("removeItem", _wrapApply(function(item){

            this._removeItem(item);
            if(this._onRemoveItem){
                this._onRemoveItem(item);
            }

        }, this));

        this.socket.emit("join", {
            id: this._id
        });

    }

    SortedListWebSocket.prototype.leave = function(){

        this.socket.emit("leave", {
            id: this._id
        });

        this.socket.removeAllListeners();

    };

    SortedListWebSocket.prototype.sort = function(){

        this.list.sort(this._sortMethod);

    };

    SortedListWebSocket.prototype.addItem = function(item){

        this._addItem(item);
        this.socket.emit("requestAddItem", {
            id: this._id,
            item: item
        });

    };

    SortedListWebSocket.prototype.removeItem = function(item){

        this._removeItem(item);
        this.socket.emit("requestRemoveItem", {
            id: this._id,
            item: item
        });

    };

    SortedListWebSocket.prototype.fetchMore = function(){
        this.loading = true;
        this.socket.emit("requestLoad", {
            id: this._id,
            lastItem: this.list[0]
        });
    };

    SortedListWebSocket.prototype._addItem = function(item){

        this.list.push(item);
        this.totalCount++;
        this.sort();

    };

    SortedListWebSocket.prototype._removeItem = function(item){
        var index = null;

        for (var i = 0; i < this.list.length; i++) {
            if (item.id === this.list[i].id) {
                index = i;
                break;
            }
        }
        
        if(index !== null){
            this.list.splice(index, 1);
            this.totalCount--;
        }

        this.sort();

    };


    var _sockets = {};

    var artWebSocket = {};

    //This is a raw socket io object, so you must invoke angular digest loops when needed.
    artWebSocket.connect = function(namespace){

        namespace = namespace || "";

        var socket = _sockets[namespace];

        if(!socket){

            socket = io.connect(artConfig["node-api-endpoint"] + namespace, {transports: ['websocket']});

            socket.on("disconnect", function(){

                delete _sockets[namespace];

            });

            _sockets[namespace] = socket;
        }

        return socket;

    };

    artWebSocket.connectSortedList = function(namespace, options){

        return new SortedListWebSocket(artWebSocket.connect(namespace), options);

    };


    return artWebSocket;

}]);
