artModule.directive("artCreateButton", [
    '$http',
    '$window',
    'artMessage',
    'artConfig',
    function ($http,
              $window,
              artMessage,
              artConfig) {

        var ctCreateButton = {};

        ctCreateButton.link = function (scope, element) {
            element.click(function () {
                $http.post(artConfig.api['api-collection-list']).success(function (data) {
                    $window.location.href = data.editor_url;
                }).error(function (data) {
                    artMessage.showAlert("Unable to create a new sett. Please contact support@neonmob.com if this " +
                    "problem persists.");
                });
            });
        };

        return ctCreateButton;
    }]);
