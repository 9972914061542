artModule.directive("artSettChecklist", function () {
    var directiveDefinitionObject = {};

    var _VARIANT = "variant",
        _MAX_COLUMNS = 5;

    directiveDefinitionObject.scope = {
        sett: "=artSettChecklist",
        user: "=user",
    };

    directiveDefinitionObject.controller = [
        '$http',
        '$scope',
        'artAnalytics',
        'artPieceService',
        'artSett',
        'artUser',
        function ($http,
                  $scope,
                  artAnalytics,
                  artPieceService,
                  artSett,
                  artUser
                  ) {
            var _STATES = {
                LOADING: "loading",
                STANDARD: "show-standard",
                ALL: "show-all",
                ERROR: "error"
            };

            $scope.hasPieces = function(rarity) {
                return rarity.total > 0;
            };

            $scope.getOwnedCount = function () {
                if (!artUser.isAuthenticated()) return $scope.getPieceCount();
                return artSett.getCoreOwnedCount($scope.sett);
            };

            $scope.getPieceCount = function () {
                return artSett.getCorePieceCount($scope.sett);
            };

            $scope.hasSpecialRarities = artSett.hasSpecialRarities.bind(artSett);

            $scope.getColumnCount = function (rarity) {
                if (rarity.class_name === _VARIANT) {
                    return artSett.hasChase($scope.sett) ? _MAX_COLUMNS - 1 : _MAX_COLUMNS;
                } else {
                    return 1;
                }
            };

            function _getPiecesUrl() {
                return $scope.sett.links.piece_names;
            }

            $scope.isError = function () {
                return $scope.currentState === _STATES.ERROR;
            };

            $scope.currentState = _STATES.LOADING;
            $scope.pieces = null;

            artPieceService.syncOwnership($scope.user)['finally'](function () {
                _getPiecesUrl();
                $http.get(_getPiecesUrl()).success(function (pieces) {
                    $scope.pieces = pieces;
                    $scope.currentState = _STATES.ALL;
                }).error(function () {
                    $scope.pieces = [];
                    $scope.currentState = _STATES.ERROR;
                });
            });

            $scope.showList = true;
            $scope.showHideList = function() {
                $scope.showList = !$scope.showList;
            }
        }];
    directiveDefinitionObject.templateUrl = "partials/art/sett-checklist.partial.html";
    return directiveDefinitionObject;
});
