artModule.directive("artSettChecklistRarityGroup", function () {
    var directiveDefinitionObject = {};

    directiveDefinitionObject.scope = {
        pieces: "=artSettChecklistRarityGroup",
        rarity: "=artSettChecklistRarity",
        columns: "&artSettChecklistColumns",
        user: "=artSettChecklistUser",
    };

    directiveDefinitionObject.controller = [
        '$scope',
        'artPieceService',
        'artUtils',
        'artUser',
        function ($scope,
                  artPieceService,
                  artUtils,
                  artUser) {

            // Check user is authenticated(Login status).
            if (artUser.isAuthenticated()) {
                $scope.loggedInUserId = artUser.getId();
            }
            $scope.filteredPieces = [];

            function _filterPieces() {
                $scope.filteredPieces = [];
                for (var i = 0; i < $scope.pieces.length; i++) {
                    var piece = $scope.pieces[i];
                    if (piece.rarity['class'] === $scope.rarity.class_name) {
                        $scope.filteredPieces.push(piece);
                    }
                }
                $scope.pieceColumns = artUtils.splitArrayByColumn($scope.filteredPieces, $scope.columns() || 1);
            }

            _filterPieces();

            $scope.isOwned = function (piece) {
                return artPieceService.hasPiece($scope.user, piece);
            };

            $scope.getPieceCount = function () {
                return $scope.rarity.total;
            };

            $scope.getOwnedCount = function () {
                    return artPieceService.getPieceCount($scope.user, $scope.filteredPieces);
            };
        }];
    directiveDefinitionObject.templateUrl = "partials/art/sett-checklist-rarity-group.partial.html";
    return directiveDefinitionObject;
});
