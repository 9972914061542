artModule.directive("artSettSmall", [
    '$state',
    '$window',
    'artSett',
    'artUser',
    'ProfileUser',
function(
    $state,
    $window,
    artSett,
    artUser,
    ProfileUser
){
    var artSettSmall = {};

    artSettSmall.scope = {
        sett: '=artSettSmall',
        preloadImages: '=?preloadImages'
    };

    artSettSmall.templateUrl = "partials/art/sett/sett-small.partial.html";

    artSettSmall.link = function(scope){

        scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);
        scope.user = artUser.toObject();
        scope.currentState = $state.current.name;
        scope.canFavorite = artUser.canIDo('favorite');

        if (ProfileUser.userUrl) {

            ProfileUser.fetchUser().then(function(response){
                scope.targetUser = response.data;
            });
        }

        if (scope.preloadImages !== false) {
            scope.fadeClass = 'fade-in--loading';
            artSett.preloadImages(scope.sett, 'medium', 2).finally(function () {
                scope.fadeClass = 'fade-in--loaded';
            });
        }

        scope.toggleFavoriteSett = function(sett, event) {
            if (event) event.stopPropagation();
            artSett.toggleFavoriteSett(sett);
        };

        scope.getTooltipUserText = function() {
            if(scope.targetUser && !artUser.isYou(scope.targetUser)) {

                return scope.targetUser.first_name + "'s";
            }
            return 'Your';
        };

        scope.getCurrentUser = function() {
            if (!scope.isAuthenticated) {
                return scope.targetUser;
            }
            if(scope.targetUser && !artUser.isYou(scope.targetUser)) {
                return scope.targetUser;
            }
            return scope.user;
        };

        scope.settRedirect = function(event) {

            if (event) event.stopPropagation();

            var url = scope.sett.links.permalink;
            if(artSett.isCollecting(scope.sett) || (!scope.isAuthenticated() && scope.getCurrentUser())) {
                url = artSett.getUserSettLink(scope.sett, scope.getCurrentUser());
            }

            $window.location.href = url;
        };

        scope.getRedirectLink = function(event) {
            if (event) event.stopPropagation();

            var url = scope.sett.links.permalink;
            if(artSett.isCollecting(scope.sett) || (!scope.isAuthenticated() && scope.getCurrentUser())) {
                url = artSett.getUserSettLink(scope.sett, scope.getCurrentUser());
            }

            return url;
        };
    };

    return artSettSmall;
}]);
