neonmobApp.directive('pieceMessage', function(){
    var directiveDefinitionObject;

    directiveDefinitionObject = {
        scope: {
            piece  :"=pieceMessage"
        },

        controller: [
            '$scope',
            'artPieceService',
            'artPrintUtils',
            'poPackSelect',
        function(
            $scope,
            artPieceService,
            artPrintUtils,
            poPackSelect
        ){

            poPackSelect.fetchPack().then(function(pack){
                $scope.pack = pack;
            });

            function _getRarityClass(){

                return $scope.piece.rarity['class'];

            }

            function _getRarityName(){

                return $scope.piece.rarity.name;

            }

            $scope.isVisible = function(){

                return $scope.piece && artPrintUtils.isRad( $scope.piece );

            };

            $scope.showBragButton = function() {

                return $scope.isVisible() &&
                    window.nmFeatureEnabled('bragging');

            };

            $scope.isDuplicate = function() {
                return !artPieceService.isNewForYou($scope.piece);
            };

            $scope.getMessage = function() {

                if (!$scope.piece) {
                    return false;
                }

                var rarityClass = _getRarityClass();

                if( artPrintUtils.isRare( $scope.piece ) ){

                    var rarityName = _getRarityName();
                    var article;

                    if(/^[aAeEiIoOuU]/.test(rarityName)){
                        article = "an";
                    } else {
                        article = "a";
                    }

                    return article+" <span class='text-rarity-"+rarityClass+"'>" + rarityName + "</span>!";

                } else if( artPrintUtils.isLowPrintNumber( $scope.piece ) ) {

                    return "print #" + $scope.piece.print_num+"!";

                } else if($scope.isDuplicate()){

                    return "a duplicate print!";

                }

            };

        }],

        templateUrl: "partials/pack-open/piece-message.partial.html"
    };

    return directiveDefinitionObject;

});
