artModule.directive('artReward', function() {

    var artReward;

    artReward = {
        scope: {
            data 		: '=data',
            templateUrl : '=templateUrl'
		},
        controller : "rewardsController",
		templateUrl: "partials/art/rewards/rewards.partial.html"
	};
	return artReward;
}).controller("rewardsController",  [
    "$scope",
    "animationService",
    "artUser",
    function (
        $scope,
        animationService,
        artUser
    ) {

        $scope.triggerTitleAnimation = false;
        $scope.isProUser = artUser.getProUserStatus();

        $scope.startAnimation = function (data) {
            if(!data.level) return;

            var animationObj = {};
            $scope.dataCopy = {};

            animationObj.className = 'level-anim';
            animationObj.fileName = 'levels/'+ (data.level - 1)  + '.json';
            angular.extend($scope.dataCopy, $scope.data);
            $scope.data.name = data.previous_level_name;
            $scope.data.level = data.level - 1;
            $scope.data.icon_color = artUser.getUserLevel().icon_color;

            animationService.triggerAnimate(animationObj).then(function() {
                $scope.triggerTitleAnimation = true;
                artUser.updateUserLevel($scope.dataCopy);
                setTimeout(function() {
                    $scope.triggerTitleAnimation = false;
                    angular.extend($scope.data, $scope.dataCopy);
                    $scope.$apply()
                }, 800);
            })
            return;
        };

    }]);
