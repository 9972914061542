packOpenModule.directive('poReceivePieces', function(){

    var directiveDefinitionObject;

    directiveDefinitionObject = {};

    directiveDefinitionObject.controller = "poReceivePiecesController";

    directiveDefinitionObject.templateUrl = "partials/pack-open/pack-open-receive.partial.html";

    return directiveDefinitionObject;

})
.controller('poReceivePiecesController', [
    '$scope',
    '$timeout',
    'artPieceService',
    'artPrintUtils',
    'artResponsive',
    'artSubscriptionService',
    'artUser',
    'EnvironmentCalculations',
    'poPackSelect',
    'poRoute',
    'poMilestones',
function(
    $scope,
    $timeout,
    artPieceService,
    artPrintUtils,
    artResponsive,
    artSubscriptionService,
    artUser,
    EnvironmentCalculations,
    poPackSelect,
    poRoute,
    poMilestones
){

    // ===================================================================

    $scope.$on("show-stack-plane", function(){
        $timeout(function(){
            $scope.showInitialElements = true;
        });

    });

    $scope.$on("pack-open-completed", function(){
        $scope.showInitialElements = false;
        _fetchPack();

    });

    // ===================================================================

    // Generic Vars

    var states = [
        "prints-start",
        "prints-untouched",
        "prints-single-reveal",
        "prints-all-reveal",
        "prints-loading"
    ];

    var _TIME = {

        DELAY_PACK_SLIDE_DOWN: 800,

        REVEAL_PAUSE: 300,

        REVEAL_RAD_CARD_PAUSE: 600,

        CARD_SLIDE_UP_DURATION: 0,

        PAUSE: 200,

        DELAY_INSERT: 0

    };

    var CONSTANTS = {

        MOBILE_EXCLAMATION_HEIGHT:  50,
        EXCLAMATION_HEIGHT:         70

    };

    var lockBehavior = false;

    // ===================================================================


    function _setTallestPiece( pack ){

        if(pack.prints.length > 0){

            var tallestRatio = 0,
                currentRatio = 0;

            for (var i = 0; i < pack.prints.length; i++) {

                var piece = pack.prints[i];

                currentRatio = 1/artPieceService.getImageRatio(artUser, piece, "large");

                if( currentRatio >  tallestRatio ){
                    tallestRatio = currentRatio;
                    $scope.tallestPiece = piece;
                }

            }
        }
    }

    function _setIsNew( pack ){

        for (var i = 0; i < pack.prints.length; i++) {

            var piece       = pack.prints[i];

            if (piece.is_new){
                piece.isNew = true;
            }
        }

    }

    function _exteriorUpdate() {

        artSubscriptionService.broadcast('pack-consumed');

    }


    function _fetchPack(){

        $scope.currentState = states[4];

        $scope.revealedStack = [];

        $scope.unrevealedStack = [];

        poPackSelect.fetchSett().then(function(sett){

            $scope.sett = sett;
            // Emit this event to catch if the pack is open before clicking on
            // cross button to close the open pack screen
            $scope.$emit("pack-open-done");
            return poPackSelect.fetchPack();

        }).then(function(pack){

            pack = pack || null;

            if (pack === null) {

                poRoute.finish();

                return false;

            }

            for (var i = 0; i < pack.prints.length; i++) {

                var piece = pack.prints[i];

                piece.flipped = false;

                piece.ready = false;

            }

            _setIsNew( pack );
            _setTallestPiece( pack );

            $scope.unrevealedStack = pack.prints;

            $scope.pack = pack;

            _exteriorUpdate();

            // $scope.pack.badges = [{rarity: 'rare', achieved: true, carats: 200}] // Mock data.
            poMilestones.settOpenPackData($scope.pack);
            $scope.cardCounter = 0;

            $scope.phaseOne(function(){

                $timeout(function(){

                    $scope.phaseTwo();

                }, _TIME.DELAY_PACK_SLIDE_DOWN );

            });

        });

    }

    _fetchPack();

    // ===================================================================

    // Card Operations

    var card = {};

    card.reveal = function(object) {

        if ($scope.revealedStack.length > 1) {

            $scope.revealedStack = EnvironmentCalculations.insertMiddle($scope.revealedStack, object);

        }
        else {

            $scope.revealedStack.push(object);

        }

    };

    card.prepInsert = function(callback) {

        lockCardInsert = true;
        card.performInsert(function(){

            lockCardInsert = false;

            if (callback) {
                callback();
            }

        });

    };

    card.nonStopFlow = function() {

        var nextPrint = $scope.unrevealedStack[0];

        $timeout(function(){

            card.prepInsert(function(){

                if ( $scope.unrevealedStack.length > 0 ) {

                    var pause       = _TIME.REVEAL_PAUSE;

                    if( artPrintUtils.isRad( nextPrint ) || artPieceService.isNewForYou( nextPrint ) ){

                        pause = _TIME.REVEAL_RAD_CARD_PAUSE;

                    }

                    $timeout(function(){

                        card.nonStopFlow();

                    }, pause);

                }
                else {

                    lockBehavior = false;

                }

            });

        }, _TIME.DELAY_INSERT );

    };

    card.performInsert = function(callback) {

        var object = {};

        object = $scope.unrevealedStack[0];

        $scope.unrevealedStack.shift();

        if ($scope.unrevealedStack.length > 0) {

            $timeout(function(){

                card.reveal(object);

            });

            $timeout(function(){

                $timeout(function(){

                    if (callback) {
                        callback();
                    }

                }, _TIME.CARD_SLIDE_UP_DURATION );

            }, _TIME.PAUSE);

        }
        else {

            $timeout(function(){
                $scope.phaseFour(function(){

                    card.reveal(object);

                    $timeout(function(){

                        $timeout(function(){

                            if (callback) {
                                callback();
                            }

                        }, _TIME.CARD_SLIDE_UP_DURATION );

                    }, _TIME.PAUSE);

                });

            });

        }

    };

    // ===================================================================

    // $scope methods accessed in the partial

    $scope.showFlipAll = function(){

        return  $scope.sett
                && $scope.showInitialElements === true;

    };

    $scope.revealStack = function(){

        $scope.phaseTwo();

    };

    $scope.revealAllCards = function(){

        if (lockBehavior === true) {

            return false;

        }

        lockBehavior = true;

        $scope.phaseThree(function(){

            card.nonStopFlow();

        });

    };

    $scope.revealSingleCard = function() {

        if (lockBehavior === true) {

            return false;

        }

        lockBehavior = true;

        if ($scope.currentState === "prints-untouched") {

            $scope.phaseThree(function(){

                card.prepInsert(function(){

                    lockBehavior = false;
                    $scope.cardCounter ++;

                });

            });

        }
        else if ($scope.currentState === "prints-single-reveal") {

            card.prepInsert(function(){

                lockBehavior = false;
                $scope.cardCounter ++;

            });

        }

    };

    $scope.delay = function(callback, time) {

        time = time || 0;

        $timeout(function(){

            if (callback) {
                callback();
            }

        }, time);

    };

    $scope.phaseOne = function(callback) {

        $scope.currentState = states[0];

        $timeout(function(){

            if (callback) {
                callback();
            }

        });

    };

    $scope.phaseTwo = function(callback) {

        $scope.currentState = states[1];

        $timeout(function(){

            if (callback) {
                callback();
            }

        });

    };

    $scope.phaseThree = function(callback) {

        $scope.currentState = states[2];

        $timeout(function(){

            if (callback) {
                callback();
            }

        });

    };

    $scope.phaseFour = function(callback) {

        $timeout(function(){

            $scope.currentState = states[3];

            if (callback) {
                callback();
            }

            //add badge notifications!

            $scope.pack.revealedStack = $scope.revealedStack;
            poMilestones.updateRarityMilestones();
        });

    };

    $scope.showOpenMore = function(){

        return $scope.sett;

    };

    $scope.$on('$destroy', function() {

        artSubscriptionService.unsubscribeAll('show-series-milestone');
    })

    var _RESPONSIVE = {
        PO_CAROUSEL_SIZE:320,
        PO_CAROUSEL_SIZE_MOBILE:260,
        PO_MOBILE_RESPONSIVE_BREAKPOINT:780
    };

    artResponsive.applyAtBreakpoints($scope, [_RESPONSIVE.PO_MOBILE_RESPONSIVE_BREAKPOINT]);

    $scope.getMaxWidth = function(){

        if(artResponsive.getWindowWidth() > _RESPONSIVE.PO_MOBILE_RESPONSIVE_BREAKPOINT){

            return _RESPONSIVE.PO_CAROUSEL_SIZE - 20;

        } else {

            return _RESPONSIVE.PO_CAROUSEL_SIZE_MOBILE - 20;

        }

    };

    $scope.getMaxHeight = function(){

        var contentHeight = $('.pack-open--content').outerHeight();

        if(artResponsive.getWindowWidth() > _RESPONSIVE.PO_MOBILE_RESPONSIVE_BREAKPOINT){

            if( contentHeight < 320 ){
                contentHeight = 320;
            }

            return contentHeight
                    - CONSTANTS.EXCLAMATION_HEIGHT * 2
                    - 20 * 3;

        } else {

            return contentHeight
                    - CONSTANTS.MOBILE_EXCLAMATION_HEIGHT * 2
                    - 20 * 2;

        }

    };
}]);
