creatorModule.directive("ctCreatedSetts", function(){

    var ctCreatedSetts = {};

    ctCreatedSetts.scope = {
        creator:        '=',
        pageSize:       '@',
        releasedOption: '=',
        settType: "=",
        searchString: "="
    };

    ctCreatedSetts.controller = [
        "$scope",
        'artInfiniteScroller',
        'artUrl',
        'artUser',
        'artSett',
        "ctConfig",
    function(
        $scope,
        artInfiniteScroller,
        artUrl,
        artUser,
        artSett,
        ctConfig
    ){

        $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);
        $scope.sortField = 'modified';
        $scope.sortReverse = true;

        function getCollectionApiUrl(){

            return artUrl.updateParams(ctConfig['api-collection-list'], getFilters());

        }

        $scope.orderBy = function( field ){

            $scope.sortField = field;
            $scope.sortReverse = !$scope.sortReverse;

        };

        $scope.settStatus = function (sett) {
            var filterTypeVal = artSett.publishedFilterType( sett );
            if (sett.discontinued) return "discontinued";
            switch (filterTypeVal) {
                case 0:
                    return "created";

                case 1:
                    return "in-progress";

                case 2:
                    return "submitted";

                case 3:
                    return "published";

                default:
                    return "";
            }
        };

        $scope.shouldShowSett = function( sett ) {
            return (artSett.publishedFilterType( sett ) === $scope.releasedOption || $scope.releasedOption === -1) &&
                (!$scope.creator || $scope.creator === -1 || $scope.creator === sett.creator.id) &&
                (sett.sett_type === $scope.settType || $scope.settType === -1 || $scope.settType === -1);
        };

        var getFilters = function(){
            var filters = {};
            if ($scope.creator >=0) filters['creator'] = $scope.creator;
            if ($scope.pageSize) filters['pageSize'] = $scope.pageSize;
            if ($scope.releasedOption >= 0) filters['status'] = $scope.releasedOption;
            if ($scope.settType >= 0) filters['settType'] = $scope.settType;
            if ($scope.searchString) filters['searchString'] = $scope.searchString;
            filters['order'] = $scope.sortReverse? 'desc' : 'asc';

            return filters;
        };


        // suck it todd and tag and cameron and rogie and jason and julie and everyone in the world but not mike. mike's cool.
        $scope.infiniteScroller = artInfiniteScroller.create("#set-creator--created-setts, #set-creator--published-setts", getCollectionApiUrl());

        $scope.$watch(getCollectionApiUrl, function(newValue, oldValue){
            if(newValue !== oldValue){
                $scope.infiniteScroller = artInfiniteScroller.create("#set-creator--created-setts, #set-creator--published-setts", newValue);
                $scope.infiniteScroller.getNextPage();
            }
        });

        $scope.showEmptyState = function(){

            return !$scope.infiniteScrollHasMore || $scope.infiniteScrollList.count === 0;
        };

        $scope.addDomain = function(url){
            return artUrl.addDomain(url);
        };

        $scope.getFacebookPromoteText = function (sett){
            return 'I released a series of my art on NeonMob called "' + sett.name + '"! Collect it with me here:';
        };

        $scope.getTwitterPromoteText = function (sett){
            return 'I released a series of my art on @NeonMob called "' + sett.name + '"! Collect it with me here:';
        };

        $scope.getSettTypeDisplay = function(sett) {
            return artSett.getSettTypeDisplay(sett.sett_type);
        };

    }];

    ctCreatedSetts.templateUrl = function (elem, attr) {
        attr.releaseStatus = attr.releaseStatus || "created";

        return "partials/creator/" + attr.type + "/" + attr.releaseStatus + "-setts.partial.html";
    };

    return ctCreatedSetts;

});
