////////////////////////////////////////////////////////////////
//
//    COLLECTION/SET OPTION DIRECTIVE
//    scoping and partial information for set options
//
////////////////////////////////////////////////////////////////

neonmobApp.directive('nmCollectionSetOption', function() {

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope : {
            set          : "=nmCollectionSetOption",
            on_selected  : "=nmCollectionSetOptionOnSelect" //Optional, a callback if this element is clicked.
        },

        controller : [
            '$scope',
            'artConstants',
        function(
            $scope,
            artConstants){
            $scope.settVersion = artConstants.VERSION_TYPES.limited;

            $scope.handleClick = function(){

                if($scope.on_selected){

                    $scope.on_selected($scope.set);

                }
            };
        }],

        templateUrl : "partials/collection/collection-set-option.partial.html"

    };

    return directiveDefinitionObject;

});
