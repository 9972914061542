packOpenModule.controller("poPromoPackFinishController", [
    "$scope",
    "$cookies",
    "artSetCookie",
    "artShowRewards",
    "poRoute",
    "poPackSelect",
function(
    $scope,
    $cookies,
    artSetCookie,
    artShowRewards,
    poRoute,
    poPackSelect
){

    poPackSelect.fetchSett().then(function(sett){
        $scope.sett = sett;
    });

    $scope.close = function(){
        poRoute.finish();
        if ($cookies.show_rewards) {
            artShowRewards("onboarding");
            // tried to use $cookies but it wasn't working so using artSetCookie
            artSetCookie("show_rewards", "", -1);
        }
    };

}]);
