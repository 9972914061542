creatorModule.controller('ctEditorController', [
    '$cookies',
    '$http',
    '$filter',
    '$scope',
    '$timeout',
    '$window',
    'artConstants',
    'artMessage',
    'artOverlay',
    'artSett',
    'artSubscriptionService',
    'artUser',
    'artProgress',
    'ctSettCreator',
    'artSetCookie',
    'artUploader',
function(
    $cookies,
    $http,
    $filter,
    $scope,
    $timeout,
    $window,
    artConstants,
    artMessage,
    artOverlay,
    artSett,
    artSubscriptionService,
    artUser,
    artProgress,
    ctSettCreator,
    artSetCookie,
    artUploader
) {

    $scope.getSett = ctSettCreator.getSett.bind(ctSettCreator);
    $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);
    $scope.isCreator = artUser.isCreator.bind(artUser);
    _packTierTemplateDetails = {};

    if(!$cookies.settCreatorSeen){
        artMessage.showInfo(
            "Welcome to the NeonMob series creator tools! This is where the magic happens: where you upload your art & write your descriptions. Make sure to check out the Creator Handbook for tips on creating a successful series, and more!",
            null,
            "Get This Party Started!"
        );
    }
    artSetCookie("settCreatorSeen", "true", 360); //keep for a long time!

    artOverlay.bindNameToTemplateUrl("validationErrors", "partials/creator/editor/validation-errors.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl("publishSett", "partials/creator/editor/publishing-status.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl("submitSett", "partials/creator/editor/submit-sett.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl("submitSettPaymentInfo", "partials/creator/editor/submit-sett-payment-info.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl("sharePublicPreview", "partials/creator/editor/share-public-preview.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl("deleteSett", "partials/creator/editor/delete-sett.partial.html", "modal");

    $scope.adminDashboardUrl     = ctSettCreator.adminDashboardUrl;
    $scope.dashboardUrl = ctSettCreator.dashboardUrl;

    $scope.showHelpFor = function( fieldName, data ){

        var tpl = "partials/creator/help-messages/" + fieldName + ".partial.html";
        artOverlay.bindNameToTemplateUrl("collection-creator-help", tpl, "modal");
        artOverlay.show( 'collection-creator-help', data );

    };

    $scope.isProgressActive = function(piece){

        return artProgress.isActive(piece.id.toString());

    };

    $scope.isProcessingImage = function(piece){

        return !$scope.isProgressActive(piece) &&
               (!piece.piece_assets || Object.keys(piece.piece_assets).length === 0) &&
               (!piece.images || Object.keys(piece.images).length === 0); //backwards compatibility.

    };


    $scope.isReleased = function(){
        return (ctSettCreator.sett && new Date(ctSettCreator.sett.released) < new Date());
    };

    $scope.getCreators = function() {
        return ctSettCreator.creators;
    };


    $scope.updateRoyaltyRate = function($event) {
        $scope.updateObject($scope.getSett());
        $event.stopPropagation(); // Without this the slider
                                  // never gets "un-clicked". Something somewhere
                                  // might be calling preventDefault or angular is
                                  // being weird
    };

    $scope.updateObject = function(obj, formField){
        if(!formField || formField.$valid){
            return ctSettCreator.updateObject(obj).then(function(data){
                $scope.errors = {};
            }).catch(function(data){
                $scope.errors = data.fields;
            });
        }

    };

    $scope.deleteSett = function(){
        artMessage.showConfirm("Are you sure you want to delete this series?", function(canceled){
            if (!canceled) {
                artOverlay.show("deleteSett");
                ctSettCreator.deleteObject($scope.getSett()).then(function(){
                    if (artUser.isStaff()) {
                        $window.location = ctSettCreator.adminDashboardUrl;
                    } else {
                        $window.location = ctSettCreator.dashboardUrl;
                    }
                    artOverlay.hide();
                });
            }
        });
    };

    $scope.showDiscontinue = function(){
        return $scope.isCreatorAdmin() && $scope.getSett().released && !$scope.getSett().discontinued;
    };

    $scope.discontinueSett = function(){
        artMessage.showConfirm("Are you sure you want to discontinue this series?", function(canceled){
            if (!canceled) {
                $http.post($scope.getSett().discontinue_url).then(function(){
                    artMessage.showInfo("Series has been discontinued.");
                }).catch(function(response){
                    artMessage.showAlert({message: response.data.detail});
                });
            }

        });
    };

    $scope.getSelectedPiece = function(){

        return ctSettCreator.getSelectedPiece();

    };

    $scope.getBuilderStatus = function(){

      var status = '';

      if( ctSettCreator.getSett().released){

        status = "Released " + $filter("date")(ctSettCreator.getSett().released,'longDate');

      }  else if( ctSettCreator.getCoreSettPieces().length < ctSettCreator.getMinCorePieces() ) {

        status = "You’re on your way to creating a series! Add a minimum of 15 cards, as evenly as you can across the five core rarities.";

      }  else if( ctSettCreator.canPreviewSett() || $scope.isCreatorAdmin() ){

        status = "Tip: Assign cards as evenly as you can across rarities.";

      }

      return status;

    };

    $scope.isValidPiece = function(piece){

        return ctSettCreator.isValidPiece(piece);

    };

    /*
    * Validate input box, preview button status.
    */
    $scope.validatePackTemplate = function(fromPreviewButton) {
        var alertMessage = ctSettCreator.canPreviewSettFromPackTemplateSelectionPage();
        if(alertMessage) {
            artMessage.showAlert(alertMessage);
            return false;
        }
        fromPreviewButton && ctSettCreator.fromPreviewStatus(fromPreviewButton);

        return true;
    }
    $scope.validateSett = function() {
        if(!$scope.canPreviewSett() && !artUser.isCreatorAdmin()) {
            artMessage.showAlert($scope.getBuilderStatus());
        } else {
            //refresh the pieces!
            ctSettCreator.refreshPieces();
            artOverlay.show("validationErrors");
        }
    };

    /*
    * Persist pack tier.
    */
   $scope.savePackTierTemplateData = function() {
        if(ctSettCreator.getDisabledStatus().disabled) {
            return true;
        }
        var packTierData = ctSettCreator.getSavedTemplateData();
        var packTiers = {};
        if(!packTierData) {
            packTiers.tiers = [];
        } else {
            packTiers.tiers = packTierData.filter(function(tier) {
                return tier.total_packs > 0;
            }) .map(function(tier) {
                if(tier.total_packs) {
                    return {
                        'id': tier.id,
                        'total_packs': tier.total_packs,
                    }
                }
            });
    }

    ctSettCreator.savePackTierTemplateToPersist(packTiers);
    return true;
}

    $scope.showUnsubmit = function(){
        var sett = ctSettCreator.getSett();
        return sett && sett.status === artConstants.SETT_STATUS_OPTIONS.submitted && artUser.isCreatorAdmin();
    };

    $scope.unsubmit = function(){
        var sett = ctSettCreator.getSett(),
            originalStatus = sett.status;
        sett.status = artConstants.SETT_STATUS_OPTIONS.editing;
        sett.submitted = null;
        ctSettCreator.updateObject(sett).then(function(){
            artMessage.showInfo(
                    'The series "' +
                    sett.name +
                    '" has been sent back to the artist. Please be sure to send an explanation email to <a href="mailto:' +
                    ctSettCreator.creatorEmail +
                    '">' +
                    ctSettCreator.creatorEmail +
                    '</a>!'
            );
        }).catch(function(){
            artMessage.showAlert("Oops! The series count not update. Please refresh and try again or contact support@neonmob.com.");
            sett.status = originalStatus;
        });

    };

    $scope.showPublishSett = function () {
        return ctSettCreator.getSett() && artUser.isCreatorAdmin();
    };

    $scope.$on("Pack-tier-template", function(event, packTierInfo){
       _packTierTemplateDetails = packTierInfo;
    });

    $scope.publishSett = function() {
        if (artUser.isCreatorAdmin()) {
            artMessage.showConfirm("Are you sure you want to publish this series?", function(canceled){
                if (!canceled) {
                    $http.post(ctSettCreator.settPublishUrl, _packTierTemplateDetails).success(function(data){
                        artOverlay.show("publishSett");
                        artProgress.start('publish-completion');
                        $scope.pollStatus(data.status_url);
                    }).error(function(data){
                        artProgress.cancel('publish-completion');
                        artOverlay.hide();
                        artMessage.showAlert('Collection cannot be published. Please refresh and try again or contact support@neonmob.com');
                    });
                }
            });
        }
    };

    $scope.showSubmitSett = function () {
        return ctSettCreator.getSett() && !artUser.isCreatorAdmin();
    };

    $scope.submitSett = function(){
        if (!artUser.isCreatorAdmin()){

            if(artSett.isAmateurSett($scope.getSett())) {
                return artOverlay.show("submitSett");
            }

            if(!ctSettCreator.hasPaypalEmail) {
                artOverlay.show("submitSettPaymentInfo").then(function(ev){
                    if(ev.status === "showNext"){
                        artOverlay.show("submitSett");
                    }
                });
            } else {
                artOverlay.show("submitSett");
            }
        }
    };

    $scope.sharePublicPreview = function(){
        artOverlay.show("sharePublicPreview");
    };

    artSubscriptionService.subscribe("show-publishing-status", function(url) {
        artOverlay.show("publishSett");
        artProgress.start('publish-completion');
        $scope.pollStatus(url);
    });
    $scope.$on("$destroy", function () {
        artSubscriptionService.unsubscribe("show-publishing-status");
    });
    $scope.pollStatus = function(url){
        $http.get(url).success(function(data){
            if(data.status==="complete"){

                artProgress.end("publish-completion");
                $timeout(function(){
                    artOverlay.hide();
                    artSubscriptionService.broadcast('sett-released');

                    var discontinue_date = new Date(data.discontinue_date);
                    artMessage.showInfo("Collection has been published! Out of Print On: " + discontinue_date,
                    function(){
                        $window.location.href = data.redirect_url;
                    });

                }, 1000);

            } else {
                $timeout(function(){
                    $scope.pollStatus(url);
                }, 5000);

            }

        });

    };


    $scope.settUploader = artUploader.NULL_UPLOADER;

    $scope.uploadCover = function(pickEvent){
        $scope.settUploader = artUploader(pickEvent.form, "sett");
        $scope.settUploader.upload("sett-cover").then(ctSettCreator.updateCover);
    };

    $scope.editor = {
        'mode': 'sett-creator'
    };

    artSubscriptionService.subscribe("show-preview", function(){
        $scope.editor.mode = 'preview';
    });

    $scope.$on("$destroy", function () {
        artSubscriptionService.unsubscribe("show-preview");
    });

    artSubscriptionService.subscribe("select-pack-template", function() {
        ctSettCreator.fetchLEPackTemplates().then(function(packTemplates) {
            ctSettCreator.setLEPackTemplates(packTemplates);
            $scope.editor.mode = 'select-pack-template';
        });
    });

    $scope.$on("$destroy", function () {
        artSubscriptionService.unsubscribe("select-pack-template");
    });

    $scope.goToEditor = function(){
        $scope.editor.mode = 'sett-creator';
    };

    $scope.showPackTemplateSelection = function() {
        return $scope.editor.mode == 'select-pack-template';
    };

    $scope.showEditor = function(){
        return $scope.editor.mode == 'sett-creator';
    };

    $scope.showPreview = function(){
        return $scope.editor.mode == 'preview';
    };

    $scope.disableUploads = function() {
        return !artUser.isCreatorAdmin() && !artSett.isEditing($scope.getSett());
    };

    $scope.canPreviewSett = ctSettCreator.canPreviewSett.bind(ctSettCreator);
    $scope.previewButtonStatus = ctSettCreator.previewButtonStatus.bind(ctSettCreator);

}]);
