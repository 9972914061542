packOpenModule.factory("poOnboardingPackOpen", [
    '$cookies',
    'artSetCookie',
    'artUser',
    'poRoute',
    'wsLumberjack',
function(
    $cookies,
    artSetCookie,
    artUser,
    poRoute,
    wsLumberjack
){
    var poOnboardingPackOpen = {};
    
    poOnboardingPackOpen.openPack = function(){
        if(!artUser.isAuthenticated() || !$cookies.referral_sett_id){
            return;
        }

        artSetCookie("referral_sett_id", "", -1);
        if (artUser.getUrl('signup_sett_url')) {
            poRoute.launchOpenOnboardingPack(artUser.getUrl('signup_sett_url'));
        } else {
            wsLumberjack.exception(new Error("Missing signup_sett_url"));
        }
    };
    return poOnboardingPackOpen;

}]).controller("poOnboardingPackFinishController", [
    '$scope',
    'artConstants',
    'artShowRewards',
    'artUser',
    'poRoute',
    'poPackSelect',
function(
    $scope,
    artConstants,
    artShowRewards,
    artUser,
    poRoute,
    poPackSelect
){
    $scope.getFreebiesRemaining = artUser.getFreebiesRemaining.bind(artUser);
    
    poPackSelect.fetchSett().then(function(sett){
        $scope.sett = sett;
    });

    $scope.isGrabBag = function() {
        return $scope.sett.id === artConstants['GRAB_BAG_SETT_ID'];
    };

    $scope.close = function(){
        poRoute.finish();
        artShowRewards('onboarding');
    };

}
]);