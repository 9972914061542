//wayback.archive-it.org/22769/20240513212157/https://https//github.com/sparkalow/angular-truncate/blob/master/src/truncate.js

artModule.filter('willTruncateWords', function () {

    return function (input, words, tolerance) {
        var tolerance = tolerance || 0,
            willTruncate = false;

        if (isNaN(words) == false && words > 0 && input) {
                if (input.split(/\s+/).length > words + tolerance) {

                    willTruncate = true;

                }
        }

        return willTruncate;
    };

});

artModule.filter('truncateWords', function (willTruncateWordsFilter) {

    return function (input, words, tolerance) {
        if (willTruncateWordsFilter(input, words, tolerance)) {
                input = input.split(/\s+/).slice(0, words).join(' ') + '...';
        }

        return input;

    };

});

artModule.filter('truncateLetters', function () {
    return function (input, words) {
        if(input.length > words){
            input = input.slice(0, words) + '...';
        }
        return input;
    };

});
