artModule.factory("artNodeHttpService", [
    "$http",
    "$q",
    function ($http,
              $q) {

        function handleError(defer) {
            return function (data, status) {
                defer.reject({data: data, status: status});
            }
        }

        function addConfig(config) {
            config = config || {};
            config.withCredentials = true;
            return config;
        }

        function get(url, config) {
            config = addConfig(config);

            var defer = $q.defer();

            $http.get(url, config)
                .success(defer.resolve.bind(defer))
                .error(handleError(defer));

            return defer.promise;
        }

        function post(url, body, config) {
            config = addConfig(config);

            var defer = $q.defer();

            $http.post(url, body, config)
                .success(defer.resolve.bind(defer))
                .error(handleError(defer));

            return defer.promise;
        }

        return {
            get: get,
            post: post
        };
    }]);
