window.artModule = angular.module('Art', [
    'ngSanitize',
    'ngCookies',
    'ngAnimate',
    'Woodsaw',
    'Once',
    'infinite-scroll',
    'ui.router',
]).config(["$locationProvider", function($locationProvider){

    $locationProvider.html5Mode(true);

}]);
