artModule.directive("artCopyField", function(){

    var artCopyField = {};

    artCopyField.scope = {
        fieldValue:   '=artCopyField'
    };

    artCopyField.controller = [
        "$scope",
        "$timeout",
    function(
        $scope,
        $timeout
    ){

        $scope.isCopying = false;

        $scope.afterCopy = function (fieldValue) {
            $scope.textToCopy = fieldValue;
            $scope.isCopying = true;
            $timeout(function () {
                $scope.isCopying = false;
            }, 2000);
        };

    }];

    artCopyField.templateUrl = "partials/art/utils/copy-field.partial.html";

    return artCopyField;

});