artModule.factory("artEmailValidator", [function () {
    var artEmailValidator = {};
    var EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    artEmailValidator.isValidEmail = function (emailAddr) {
        return EMAIL_REGEX.test(emailAddr);
    }

    return artEmailValidator;
}]);
