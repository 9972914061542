artModule.factory("CollectionService", [
	'$http',
	'$q',
	'artConfig',
    'artPieceService',
	"artUser",
	'ProfileUser',
	function($http,
					 $q,
					 artConfig,
                     artPieceService,
					 artUser,
					 ProfileUser) {

		var CollectionService = {};
		var isAuthenticated = artUser.isAuthenticated();

		var _collectedSettsPromise = $q.all({
            user: ProfileUser.fetchUser(),
            collectedSetts: ProfileUser.fetchCollectedSets(),
            ownership: artPieceService.syncOwnership()
        }).then(function(results) {
            return {
                user:results.user.data,
                collectedSetts:results.collectedSetts
            };
        });

        CollectionService.fetchCollectionUser = function(){
            return _collectedSettsPromise.then(function(results){
                return results.user;
            });
        };

		CollectionService.fetchPrints = function(settSlug) {
            return fetchSettUrlData(
                settSlug,
                artConfig["node-api-endpoint"] + "/user/{userId}/sett/{settId}"
            ).then(function(results){
                return {
                    sett:results.sett,
                    prints:results.data
                };
            });
		};

		CollectionService.fetchMilestones = function(settSlug) {
            return fetchSettUrlData(settSlug, "/api/collection-badges/{settId}/user/{userId}").then(function(results){
                return {
                    sett:results.sett,
                    badges:results.data
                };
            });
		};

		var fetchSettUrlData = function(settSlug, url) {
			return _collectedSettsPromise.then(function (collectionData) {
				var sett;
				var collectedSetts = collectionData.collectedSetts;
				var user = collectionData.user;

				for (var i = 0; i < collectedSetts.length; i++) {
					if (collectedSetts[i].name_slug === settSlug) {
						sett = collectedSetts[i];
						break;
					}
				}

                if(!sett){
                    return {sett:null, data:null};
                }
                var extraData = reverseUrl(url, user.id, sett.id);

                return $q.all({
                    data: $http.get(extraData)
                }).then(function(results){
                    return {
                        sett:sett,
                        data:results.data.data
                    };
                });
			});
		};

        function reverseUrl(url, userId, settId){
                // Build url
                var userIdPattern = /\{userId\}/;
                var settIdPattern = /\{settId\}/;

                return url.replace(userIdPattern, userId).replace(settIdPattern, settId);
        }

		CollectionService.setupFilters = function(ret) {
				var raritiesTemplate = [{
						id: "common",
						name: "Common",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Core Rarity",
						selected: false
				}, {
						id: "uncommon",
						name: "Uncommon",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Core Rarity",
						selected: false
				}, {
						id: "rare",
						name: "Rare",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Core Rarity",
						selected: false
				}, {
						id: "veryRare",
						name: "Very Rare",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Core Rarity",
						selected: false
				}, {
						id: "extraRare",
						name: "Extra Rare",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Core Rarity",
						selected: false
				},{
						id: "chase",
						name: "Chase",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Special Rarity",
						selected: false
				}, {
						id: "variant",
						name: "Variant",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Special Rarity",
						selected: false
				}, {
				}, {
						id: "legendary",
						name: "Legendary",
						count: 0,
						ownedCount: 0,
						label: "",
						group: "Special Rarity",
						selected: false
				}];

				var additionalFilters = {
						favorites: 0,
						duplicates: 0
				};

				var ownershipFilters = {
						owned: {
								id: "owned",
								count: 0,
								label: "Owned"
						},
						unowned: {
								id: "unowned",
								count: 0,
								label: "Unowned"
						},
						all: {
								id: "all",
								count: 0,
								label: "All"
						}
				};

				var duplicateFilter = {
					id: "duplicate",
					label: "Duplicates (" + additionalFilters.duplicates + ")",
					count: additionalFilters.duplicates,
					group: "Miscellaneous"
				};

				ret.duplicateFilter = duplicateFilter;

				ret.filterOptions = [];

				// setup pieces for easier filtering and set rarity counts
				var piece;
				var rarities = raritiesTemplate.concat();
				var amtRarities = rarities.length;
				for (var i = 0, c = ret.pieces.length; i < c; i++) {
						piece = ret.pieces[i];
						piece.$owned = !!piece.own_count;

						if (piece.$owned)
								ownershipFilters.owned.count++;
						else
								ownershipFilters.unowned.count++;

						piece.$duplicate = piece.own_count > 1;
						piece.$rarity = piece.rarity.class;

						if (piece.$duplicate)
								additionalFilters.duplicates++;
						if (piece.$owned && piece.favorite)
								additionalFilters.favorites++;

						for (var j = 0; j < amtRarities; j++) {
								if (rarities[j].id != piece.$rarity) continue;

								rarities[j].count++;

								if (piece.$owned)
										rarities[j].ownedCount++;
						}
				}

				if (isAuthenticated)
					ret.favoriteFilter = CollectionService.createFavoriteFilter();

				// rarity labels
				var rarity;
				for (var i = 0; i < amtRarities; i++) {
						rarity = rarities[i];
						// exclude filters with a 0 piece count
						if (!rarity.count) continue;

						// chase and variant don't show piece counts
						if (~["variant", "chase", "legendary"].indexOf(rarity.id))
								rarity.label = rarity.name + " (" + rarity.ownedCount + ")";
						else
								rarity.label = rarity.name + " (" + rarity.ownedCount + "/" + rarity.count + ")";

						ret.filterOptions.push(rarity);
				}

				ret.ownershipFilters = ownershipFilters;
		}

		CollectionService.createFavoriteFilter = function(amt) {
			amt = amt || 1;
			return {
					id: "favorite",
					label: "Favorites (" + amt + ")",
					count: amt,
					group: "Miscellaneous",
					selected: false,
			};
		};

		var collectionViewFilters,
			collectionViewSort,
			activeFilters = {},
			collectionSeriesSort;

		var resetFilterObj  = function(obj) {

			obj.name 		= obj.defaultName;
			obj.key 		= "";
			obj.class 		= obj.iconClasses[obj.clickCount];
			obj.selected 	= false;
		};

		var changeBtnState  = function(obj) {
			obj.name 		= obj.names[obj.clickCount - 1];
			obj.key 		= obj.keys[obj.clickCount - 1];
			obj.class 		= obj.iconClasses[obj.clickCount - 1];
			obj.selected 	= true;
		};

		var updateClickCount = function(obj, optionsArray) {
			if( obj.clickCount === optionsArray.length ) {
				obj.clickCount  = 0;
				return;
			}

			obj.clickCount++;
		};

		var btnToggle = {

			changeBtn 	: function() {

				updateClickCount(this, this.names);

				if(!this.clickCount) {

					resetFilterObj(this);
					return;
				}

				changeBtnState(this);
			}
		};

		CollectionService.createCollectionViewFilters = function() {

			var filterArray = [
				{
					clickCount  : 0,
					name        : 'Favorites',
					key         : "",
					defaultName : 'Favorites',
					class       : 'icon-like',
					id          : 'favorite',
					names       : ['Favorites'],
					keys        : ['Favorites'],
					selected    : false,
					iconClasses : ['icon-like'],
					changeBtn   : btnToggle.changeBtn,
                    activeClass: "icon-liked"
				},
			];
			
			if (!artUser.canIDo('favorite')) {
				filterArray = [];
			}

			collectionViewFilters = filterArray;
			return collectionViewFilters;
		};
        CollectionService.createCollectionSeriesSort = function() {
            var sortArray = [
				{
					name        : 'Recency',
					id          : 'recency',
					desc        : false,
					clickCount  : 0
				},
                {
					name        : 'Alphabetical : A to Z',
					id          : 'name_a_to_z',
					desc        : false,
					clickCount  : 0
				},
                {
					name        : 'Alphabetical : Z to A',
					id          : 'name_z_to_a',
					desc        : false,
					clickCount  : 0
				},
				{
					name        : 'Scarcity : High to Low',
					id          : 'scarcity',
					desc        : true,
					clickCount  : 0
				},
				{
					name        : 'Copies Owned',
					id          : 'own_count',
					desc        : true,
					clickCount  : 0
				},
                {
					name        : 'Print # : Low to High',
					id          : 'print_no',
					desc        : true,
					clickCount  : 0
				},
			];

			collectionSeriesSort = sortArray;

			return collectionSeriesSort;
		};

		CollectionService.createCollectionViewSort = function() {

			var sortArray = [
				{	clickCount 	: 0,
					name 		: 'Recently Collected',
					id 			: 'last_acquired',
					key			: 'last_acquired',
					options 	: ['desc']
				},
				{	clickCount 	: 0,
					name 		: 'Alphabetical: A to Z',
					id 			: 'alphabetical_az',
					key			: 'alphabetical',
					options 	: ['asc']
				},
				{	clickCount 	: 0,
					name 		: 'Alphabetical: Z to A',
					id 			: 'alphabetical_za',
					key			: 'alphabetical',
					options 	: ['desc']
				},
				{	clickCount 	: 0,
					name 		: 'Date of Release',
					id 			: 'release_date',
					key			: 'release_date',
					options 	: ['asc']
				},
				{	clickCount 	: 0,
					name 		: 'Discontinue Date',
					id 			: 'discontinue_date',
					key			: 'discontinue_date',
					options 	: ['asc']
				},
				{	clickCount 	: 0,
					name 		: 'Difficulty : Easiest to Hardest',
					id 			: 'difficulty_e_to_h',
					key			: 'difficulty',
					options 	: ['asc']
				},
				{	clickCount 	: 0,
					name 		: 'Difficulty : Hardest to Easiest',
					id 			: 'difficulty_h_to_e',
					key			: 'difficulty',
					options 	: ['desc']
				},
                {	clickCount 	: 0,
					name 		: '% Series Complete : Least to Most',
					id 			: 'percent_owned',
					key			: 'percent_owned',
					options 	: ['asc']
				}
			];

			collectionViewSort = sortArray;

			return collectionViewSort;
		};

		CollectionService.createCollectionCardsSort = function() {
            var sortArray = [
				{
					name 		: 'Recently Collected',
					id 			: 'recency',
					key			: 'acquired',
					options     : "desc"
				},
                {
					name 		: 'Alphabetical : A to Z',
					id 			: 'name_a_to_z',
					key			: 'alphabetical',
					options     : "asc"
				},
                {
					name 		: 'Alphabetical : Z to A',
					id 			: 'name_z_to_a',
					key			: 'alphabetical',
					options     : "desc"
				},
				{
					name 		: 'Scarcity : High to Low',
					id 			: 'scarcity',
					key			: 'scarcity',
					options     : "asc"
				},
				{
					name 		: 'Copies Owned',
					id 			: 'own_count',
					key			: 'own_count',
					options     : "desc"
				},
                {
					name 		: 'Print # : Low to High',
					id 			: 'print_num',
					key			: 'print_num',
					options     : "asc"
				},
			];

			CollectionCardsSort = sortArray;

			return CollectionCardsSort;
		};

		CollectionService.activeCollectionViewFilters = function() {

			collectionViewFilters.map(function(item) {

				if( !item.selected && activeFilters[item.id] ) {
					delete activeFilters[item.id];
				}

				if( item.selected && item.key) {

					activeFilters[item.id] = item.key;
				}
			});

			return activeFilters;
		};

		var sortedObj = {};
		CollectionService.updateActiveFilter = function(selectedSort) {

			if(sortedObj && (sortedObj.id !== selectedSort.id)) {
				sortedObj.clickCount = 0;
				delete activeFilters[sortedObj.key];
			};

			updateClickCount(selectedSort, selectedSort.options);

			if( !selectedSort.clickCount ) {

				delete activeFilters[selectedSort.key];
				return;
			};

			sortedObj = selectedSort;
			activeFilters[selectedSort.key] = selectedSort.options[selectedSort.clickCount - 1];
		}

		return CollectionService;

}]);
