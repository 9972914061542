artModule.factory("artConfirm", [
    "$timeout",
function(
    $timeout
){
    var _message = null,
        _subtext=null,
        _desctext = null,
        _parentClass = null,
        _iconClass = null,
        _theme = "white",
        _visible = false,
        _callback = null,
        _showOk = true,
        _okText = "OK",
        _showMore = false,
        _closeMs = 0,
        _closeModel = false,
        _level = 0,
        _messageQueue = [],
        _closeTimerId = null,
        _carats_earned = null,
        _carats_balance = null,
        _leftDiscardCarat = null,
        _totalDiscardCarat = null,
        _templateData = null,
        _templateUrl = null,
        _templates = {
            'carat'                     :  'partials/art/rewards/carats.partial.html',
            'milestone'                 :  'partials/art/rewards/milestone.partial.html',
            'series-complete'           :  'partials/art/rewards/series-complete.partial.html',
            'series-complete-beginner'  :  'partials/art/rewards/series-complete-beginner.partial.html',
            'level-up'                  :  'partials/art/rewards/level-up-model.html',
            'gated-level-up'            :  'partials/art/rewards/gated-experience-level-model.html',
            'welcome-model'             :  'partials/art/rewards/welcome-model.html',
            'series-complete-reward'    :  'partials/art/rewards/series-complete-reward.partial.html',
        };
        _templateStaticProcessor = {
            'gated-level-up': function(data) {
                var tipObj = data['levelup_tip'], tip = tipObj.tip, iconClasses = tipObj.iconClasses;
                for (var indx in iconClasses) {
                    tip = tip.replace('{icon' + indx + '}', '<i class="' + iconClasses[indx] + '"></i>');
                }
                tipObj.tip = tip;
            }
        }

    function _show(params){
        _message = params.message;
        _subtext = params.subtext;
        _desctext = params.desctext;
        _iconClass = params.iconClass;
        _visible = true;
        _showMore = params.showMore;
        _showOk = params.showOk;
        _okText = params.okText || "OK";
        _closeMs = params.closeMs || 0;
        _closeModel = params.hasCloseBtn || params.subtext.hasCloseBtn || false;
        _leftDiscardCarat = params.carats_left || null;
        _totalDiscardCarat = params.totalDiscardCarat || null;
        _templateUrl = _templates[params.rewardType];
        _templateData = params.data;
        _staticProcessor = _templateStaticProcessor[params.rewardType];
        _level = params.data.level;

        if (_staticProcessor) {
            _staticProcessor(params.data);
        }

        if(_closeMs > 0) {
            _closeTimerId = $timeout(_close, _closeMs);
        }

        // forces a //VOID: force a scope apply if one did not occur.
        $timeout(function(){});
    }

    function _showNextMessage(){
        _messageQueue.forEach(function(message) {
            _show({
                message         : message.message,
                data            : message.data,
                rewardType      : message.rewardType,
                iconClass       : artConfirm.CARATS,
                subtext         : message,
                showMore        : false,
                showOk          : true,
                okText          : message.okText || 'Close',
            });
            _messageQueue.shift();
        });
    }

    function _close(canceled) {
        $timeout.cancel(_closeTimerId);

        if(_messageQueue.length > 0){
            return _showNextMessage();
        }

        _visible = false;
        _parentClass = null;

        if(_callback){
            _callback(canceled);
        }
    }

    function _themeClass(){
        if(_theme === "white")
            return "confirm-white";
        if(_theme === "black")
            return "confirm-black";

    }

    var pluraliseText = function(text, num) {

        if(!text || !num) {

            return;
        }

        if (num>1) {

            return text + 's';
        }

        else {

            return text;
        }
    }

    var artConfirm = {
        ALERT_ICON_CLASS: "icon-warning",
        CONFIRM_ICON_CLASS: "icon-checkmark",
        CARATS: "carat",

        getMessage:function(){
            return _message;
        },
        getTemplateUrl:function(){
            return _templateUrl;
        },
        getTemplateData:function(){
            return _templateData;
        },
        getLevel:function() {
            return _level;
        },
        getThemeClass:function(){
            return _themeClass();
        },
        getSubtext:function(){
            return _subtext;
        },
        getDesctext:function() {
            return _desctext;
        },

        getIconClass:function(){
            return _iconClass;
        },
        getParentClass:function(){
            return _parentClass;
        },

        getOkText:function(){
            return _okText;
        },
        hasCloseBtn:function() {
            return _closeModel;
        },
        isVisible:function(){
            return _visible;
        },

        hasMoreButton:function(){
            return _showMore;
        },

        hasOkButton:function() {
            return _showOk;
        },

        closeMs:function() {
            return _closeMs;
        },

        leftDiscardCarat:function() {

            return _leftDiscardCarat;
        },

        caratsEarned:function() {
            return _carats_earned;
        },
        caratsBalance:function() {
            return _carats_balance;
        },

        totalDiscardCarat:function() {

            return _totalDiscardCarat;
        },

        calProgressPercent:function() {

            return ((_totalDiscardCarat - _leftDiscardCarat)/_totalDiscardCarat)*100;
        },

        showAlert:function(messages, callback){
            var message = null,
                subtext = null;
            if (typeof messages === 'string'){
                message = messages;
            } else {
                message = messages.message;
                subtext = messages.subtext;
            }

            _show({
                message: message,
                subtext: subtext,
                iconClass: artConfirm.ALERT_ICON_CLASS,
                showMore: false,
                showOk: true
            });
            _callback = callback || null;
        },

        showAlertWithCancel: function(messages, callback) {
            var message = null,
                subtext = null;
            if (typeof messages === 'string'){
                message = messages;
            } else {
                message = messages.message;
                subtext = messages.subtext;
            }

            _show({
                message: message,
                subtext: subtext,
                iconClass: artConfirm.ALERT_ICON_CLASS,
                showMore: true,
                showOk: true
            });
            _callback = callback || null;
        },


        showConfirm:function(message, callback) {
            _show({
                message: message,
                iconClass: artConfirm.CONFIRM_ICON_CLASS,
                showMore: true,
                showOk: true
            });
            _callback = callback || null;
        },

        showFreebieEarned:function(data, callback) {
            _show({
                message  : 'Hooray!',
                iconClass: artConfirm.CONFIRM_ICON_CLASS,
                subtext  : 'You Earned ' + data.freePackNum + ' free ' + pluraliseText('pack', data.freePackNum),
                desctext : 'You earned enough carats for ' + data.freePackNum + ' ' + pluraliseText('pack', data.freePackNum) + '. Continue to discard for carats!',
                showMore : true,
                showOk   : true
            });

            _callback = callback || null;
        },

        showEarnedStatus:function(data, callback) {
            _show({
                message         : data.message,
                data            : data.data,
                rewardType      : data.rewardType,
                iconClass       : artConfirm.CARATS,
                subtext         : data,
                showMore        : false,
                showOk          : true,
                okText          : data.okText || 'Close',
                hasCloseBtn     : data.hasCloseBtn
            });
            _parentClass    = data.parentClass || null;
            _messageQueue   = data.messageQueue || [];
            _callback       = callback || data.callback || null;
        },

        close: _close
    };

    return artConfirm;
}]);
