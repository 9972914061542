artModule.factory("artTitleNotify", function(){

    var artTitleNotify = {};

    var _timeoutId;
    var _originalTitle = null;
    var _notifyTitle = null;
    var _originalFavicon = null;
    var _notifyFavicon = null;
    var _favicon = angular.element("#nm-favicon");

    artTitleNotify.start = function(notifyTitle){

        if(notifyTitle === _notifyTitle){
            return; //do nothing if we are already animating this title.
        }

        artTitleNotify.stop();

        _notifyTitle = notifyTitle;
        _originalTitle = angular.element("title").text();
        _notifyFavicon = _favicon.attr("notification-href");
        _originalFavicon = _favicon.attr("href");

        var animate = function(toggle){
            _timeoutId = setTimeout(function(){
                angular.element("title").text(toggle ? _originalTitle : _notifyTitle);
                _favicon.attr("href",toggle ? _originalFavicon : _notifyFavicon);
                animate(!toggle);
            }, 2000);
        };

        animate();

    };

    artTitleNotify.stop = function(){

        clearTimeout(_timeoutId);

        if(_originalTitle){
            angular.element("title").text(_originalTitle);
            _favicon.attr("href",_originalFavicon);
        }

        _notifyTitle = null;
        _originalTitle = null;

    };

    return artTitleNotify;

});
