creatorModule.controller("ctPackPromoController", [
    '$scope',
    '$timeout',
    'artInfiniteScroller',
    'artMessage',
    'artOverlay',
    'artResource',
    'ctConfig',
    'ctSelectedSett',
function(
    $scope,
    $timeout,
    artInfiniteScroller,
    artMessage,
    artOverlay,
    artResource,
    ctConfig,
    ctSelectedSett
){

    $scope.packCountOptions = [
        {label: "1 Pack",   value: 1},
        {label: "2 Packs",  value: 2},
        {label: "3 Packs",  value: 3},
        {label: "4 Packs",  value: 4},
        {label: "5 Packs",  value: 5},
        {label: "6 Packs",  value: 6},
        {label: "7 Packs",  value: 7},
        {label: "8 Packs",  value: 8},
        {label: "9 Packs",  value: 9},
        {label: "10 Packs", value: 10}
    ];

    $scope.newPromoCode = {
        max_redemption_count: -1,
        code: null,
        expired: null
    };

    $scope.editOptions = {
        unlimitedMaxRedemptions:true
    };

    $scope.getSett = ctSelectedSett.getSelectedSett.bind(ctSelectedSett);
    $scope.today = new Date().toISOString().slice(0,10);

    
    if( $(".pack-promo--table").length ){
        artInfiniteScroller.bindToScope($scope, ".pack-promo--table", ctConfig['api-packpromocode-list']);
    }

    $scope.changeUnlimitedMaxRedemptions = function(){
        if($scope.editOptions.unlimitedMaxRedemptions){
            $scope.newPromoCode.max_redemption_count = -1;
        } else {
            $scope.newPromoCode.max_redemption_count = 1000;
        }
    };

    $scope.createCode = function(){
        $scope.newPromoCode.sett = $scope.getSett().id;
        $scope.newPromoCode.code = $scope.newPromoCode.code.toUpperCase();
        artResource.create(ctConfig['api-packpromocode-list'], $scope.newPromoCode).then(function(data){
            artOverlay.hide();
            artMessage.showInfo("Promo code for " + $scope.getSett().name + " created!");
        }).catch(function(data){
            $scope.fieldErrors = data.fields;
            $scope.formError = data.detail;
        });
    };

    $scope.deleteCode = function(packPromo){
        artResource.destroy(packPromo).then(function(){
            artMessage.showInfo("Promo code " + packPromo.code + " deleted.");
            index = $scope.infiniteScrollList.indexOf(packPromo);
            $scope.infiniteScrollList.splice(index, 1);
        }).catch(function(){
            artMessage.showAlert("Unable to delete code :( Please try again!");
        });
    };

    $scope.expireCode = function(packPromo){
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        packPromo.expiration_date = yesterday.toISOString().slice(0,10);
        artResource.update(packPromo).then(function(data){
            artMessage.showInfo("Promo code " + packPromo.code + " has been expired.");
        }).catch(function(){
           artMessage.showAlert("Unable to expire code :( Please try again!");
        });
    };

    $scope.showExpireAction = function(packPromo){
        var today = new Date();
        var expirationDate = new Date(packPromo.expiration_date);
        return expirationDate >= today;
    };

    $scope.getCopyUrl = function(packPromo){
        return packPromo.redemption_url;
    };

    $scope.afterUrlCopy = function(packPromo){
        $scope.textToCopy = packPromo.redemption_url;
        var code = packPromo.code;
        packPromo.code = 'Copied!';
        $timeout(function () {
            packPromo.code = code;
        }, 1000);
    };

    $scope.getRedemptionUrl = function(){
        // hard coding redeem url
        // this is to give admins early access to the url so they
        // don't have to hunt it down
        var url = "https://www.neonmob.com/redeem/";
        if($scope.newPromoCode.code) url = url + $scope.newPromoCode.code;
        return url;
    };

}]).filter('isUnlimited', function(){
    return function(input) {
        return input === -1 ? 'Unlimited' : input;
    };
});
