publicModule.controller("publicNavigationController", [
    '$scope',
    '$window',
    'artAnalytics',
    'artConstants',
    'artFriendsService',
    'artNotificationCenter',
    'artResponsive',
    'artSubscriptionService',
    'artUser',
    'artCreditRewardService',
function(
    $scope,
    $window,
    artAnalytics,
    artConstants,
    artFriendsService,
    artNotificationCenter,
    artResponsive,
    artSubscriptionService,
    artUser,
    artCreditRewardService
){
    $scope.showNavRewards = false;
    $scope.showFriends = artUser.canIDo('friends');
    $scope.canTrade = artUser.canIDo('trade');
    $scope.areFeaturesGated = artUser.areFeaturesGated();
    $scope.showDisplayCase = true; //always show displayCase

    // Display the carats detail in navbar
    $scope.caratBalance = artUser.getUserCarats.bind(artUser);
    if(artUser.isAuthenticated()) {
        $scope.creditBalance = artUser.getCredits.bind(artUser);

        // Display credit reward bar at bottom
        if (artUser.hasRewards()) {

            artCreditRewardService.showCreditReward();
        }
    }
    $scope.triggerSignUp = function() {
        $window.location.href = '/signup';
    };

    $scope.toggleMobileNav = function($event){
        var closeEvent = artResponsive.isTouchDevice()? 'click.mobile-nav' : 'click.mobile-nav';

        $('html').toggleClass('mobile-nav-active');
        if( $('html').hasClass('mobile-nav-active') ){
            $event.stopPropagation();
            $('.neonmob--content,.primary-navigation.mobile-nav').on(closeEvent,
                function(){
                    $('html').removeClass('mobile-nav-active');
                    $('.neonmob--content,.primary-navigation.mobile-nav').off(closeEvent);
                }
            );
        }
    };

    $scope.toggleMobileNavRewards = function() {

        $scope.showNavRewards = !$scope.showNavRewards;
    };

    $scope.newUserNavVariant = artUser.hasVariantNav();

    $scope.getOnlineFriendsCount = artFriendsService.getOnlineFriendsCount.bind(artFriendsService);

    $scope.getUnreadTradeCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
        artNotificationCenter,
        artConstants.TRADES_KEY
    );

    $scope.getUnreadMessageCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
        artNotificationCenter,
        artConstants.MESSAGES_KEY
    );

    $scope.getUnreadMessageAndTradeCount = function(){
      return $scope.getUnreadTradeCount() + $scope.getUnreadMessageCount();
    };

    function _trackCreditPurchase(purchaseData){
        var transaction = JSON.parse(purchaseData.transaction_log);
        var amountPaid = transaction.payload.amount / 100.0;

        artAnalytics.track("Client Completed Order", {
            value: amountPaid.toFixed(2),
            currency: "USD",
            processor: "stripe"
        });
    }

    artSubscriptionService.subscribe("credits-purchased", _trackCreditPurchase);

}]);



