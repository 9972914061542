// A migrated version of this factory existed but due to this factory being used more, it was removed
// part of 0bd194c2f838b4cafb0bec1949401cfb690551c9. Added it for reference when we finally migrate to that.
artModule.factory("artAnalytics", [
    "$document",
    "$location",
    "$rootScope",
    "artConstants",
    "artUser",
    "artElementAttrsToObject",
function(
    $document,
    $location,
    $rootScope,
    artConstants,
    artUser,
    artElementAttrsToObject
){

    var artAnalytics = {};

    function trackEnabled() {
        // Track only if authenticated or anonymous tracking enabled
        return artUser.isAuthenticated() || artConstants.SEGMENT_ANONYMOUS_TRACKING;
    }

    artAnalytics.track = function(event, properties, options, callback){
        if (!trackEnabled()) return;
        properties = angular.extend({

            url: $location.absUrl(),
            title: angular.element("title").text(),
            appId: "Web/Browser"

        }, properties||{});

        analytics.track(event, properties, options, callback);

    };

    artAnalytics.page = function(){
        if (!trackEnabled()) return;
        analytics.page.apply(analytics, arguments);
    };

    artAnalytics.getUid = function(){

        return window.mixpanel ? window.mixpanel.get_distinct_id() : null;

    };

    artAnalytics.autoTrack = function(){

        $document.on("click", ".btn, [ng-click], [nm-event]", function(ev){

            var buttonId = ev.currentTarget.id,
                buttonEvent = ev.currentTarget.getAttribute("nm-event") ;

            if(buttonEvent){

                //grab all extra properties!
                var properties = artElementAttrsToObject(ev.currentTarget, "nm-event");
                properties.buttonId = buttonId;
                properties.appId = "Web/Browser";

                artAnalytics.track(buttonEvent, properties);

            }

        });

        $rootScope.$on("$locationChangeSuccess", function(ev, newUrl, oldUrl){
            if (!trackEnabled()) return;

            if (newUrl === oldUrl) return;

            var name = "";
            // Sett detail cards page
            if(newUrl.match(/\/user\/[^\/]+\/cards/)){
                name = "Sett Detail Cards Page";
            }
            // Sett detail page
            else if(newUrl.match(/\/user\/[^\/]+/) || newUrl.match(/\/series\/[^\/]+/)){
                name = "Sett Detail Page";
            }
            // Onboarding page
            else if(newUrl.match(/\/signup\/step[0-9]{1}/)){
                var _match = newUrl.match(/\/signup\/step([0-9]{1})/)
                name = "Viewed Onboarding Step " + _match[1];
            }
            // Your collection cards page
            else if(newUrl.match(/\/cards/)){
                name = "User collection cards";
            }
            // Your collection page
            else if(newUrl.match(/\/collection/)){
                name = "User collection";
            }
            // Profile activity page
            else if(newUrl.match(/\/activity/)){
                name = "Profile activity";
            }
            // Popular Submissions page
            else if(newUrl.match(/\/vote\/popular/)){
                name = "Popular Submissions";
            }
            // Newest Submissions page
            else if(newUrl.match(/\/vote\/newest/)){
                name = "Newest Submissions";
            }
            // Liked Submissions page
            else if(newUrl.match(/\/vote\/liked/)){
                name = "Liked Submissions";
            }
            // My Submissions page
            else if(newUrl.match(/\/vote\/yours/)){
                name = "My Submissions";
            }
            // Approved Submissions page
            else if(newUrl.match(/\/vote\/in-progress/)){
                name = "Approved Submissions";
            }
            // Published Submissions page
            else if(newUrl.match(/\/vote\/published/)){
                name = "Published Submissions";
            }
            artAnalytics.page(name, {clientRouting:true});
        });

    };

    return artAnalytics;

}]);
