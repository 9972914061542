neonmobApp.directive('nmMilestonesSpecial', function() {

    var directiveDefinitionObject = {};

    directiveDefinitionObject.controller = "MilestonesListController";

    directiveDefinitionObject.templateUrl = "partials/badges/milestones-special.partial.html";

    return directiveDefinitionObject;

});
