// Unabashedly stolen from: http://stackoverflow.com/questions/13925462/angularjs-reverse-checkbox-state
// After searching for awahile, this appears to be the easiest way to reverse the values of a checkbox
// (if the model is true, checkbox is unchecked, if the model is false, checkbox is checked)
neonmobApp.directive('nmInverseCheckbox', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(val) { return !val; });
      ngModel.$formatters.push(function(val) { return !val; });
    }
  };
});