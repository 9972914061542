/**
 * This directive will allow you to fetch a paginated response from Django Rest Framework and automatically
 * account for infinite scroll.
 *
 * The scope variables list, loading, and hasMore are exposed for more ui control.
 * Pass in the url to connect to as the parameter for this directive (as a string attr).
 */
artModule.directive("artInfiniteScroll", [
    "$timeout",
    "artResource",
    "artSubscriptionService",
    function (
              $timeout,
              artResource,
              artSubscriptionService) {

        var artInfiniteScroll = {},
            _PIXEL_THRESHOLD = 500;

        artInfiniteScroll.link = function (scope, element, attrs) {

            var scrollRegion = element.scrollParent();

            scope.infiniteScrollLoading = false;
            scope.infiniteScrollHasMore = true;
            scope.infiniteScrollList = [];

            attrs.$observe('artInfiniteScroll', setup.bind(this));

            artSubscriptionService.subscribe('reload-infinite-scroll', function (url) {
                url = url || attrs.artInfiniteScroll;
                scope.infiniteScrollList = [];
                scope.infiniteScrollLoading = false;
                scope.infiniteScrollHasMore = false;
                setup(url);
            });

            function scrolledToBottom() {
                var region = getRegion();

                var scrollHeight = region[0].scrollHeight,
                    regionHeight = region.height(),
                    scrollTop = scrollRegion.scrollTop();

                var pixelsFromBottom = scrollHeight - (scrollTop + regionHeight);

                return pixelsFromBottom <= _PIXEL_THRESHOLD;
            }

            function getRegion() {
                var region = scrollRegion;

                if (region[0].documentElement)
                    region = $(region[0].documentElement);

                return region;
            }

            function setup(url) {
                if (!url) return;
                if (scope.infiniteScrollLoading) return;

                scope.infiniteScrollLoading = true;

                var renderMoreAttempts = 0,
                    renderMoreMax = 5

                var renderMore = function () {
                    scope.infiniteScrollLoading = true;
                    scope.infiniteScrollList.retrieveNext().then(function () {
                        scope.infiniteScrollLoading = false;
                        scope.infiniteScrollHasMore = scope.infiniteScrollList.next !== null;
                    });
                };

                var renderTillOverflow = function () {

                    var region = getRegion(),
                        scrollHeight = region[0].scrollHeight,
                        notOverflowed = scope.infiniteScrollList.next && scrollHeight <= region.height();

                    //if were under the max AND we havent overflowed  yet
                    if (notOverflowed && renderMoreAttempts <= renderMoreMax) {

                        renderMoreAttempts++;
                        scope.infiniteScrollLoading = true;
                        scope.infiniteScrollList.retrieveNext().then(function () {
                            scope.infiniteScrollLoading = false;
                            scope.infiniteScrollHasMore = scope.infiniteScrollList.next !== null;

                            $timeout(function () {
                                renderTillOverflow();
                            });

                        });

                    }
                }

                artResource.retrievePaginated(url).then(function (list) {
                    scope.infiniteScrollList = list;
                    scope.infiniteScrollLoading = false;
                    scope.infiniteScrollHasMore = scope.infiniteScrollList.next;

                    renderTillOverflow();

                    scrollRegion
                        .unbind("scroll.artinfinitescroll")
                        .bind("scroll.artinfinitescroll", function (ev) {
                            if (!scope.infiniteScrollList.next) {
                                scrollRegion.unbind("scroll.artinfinitescroll");
                                return;
                            }
                            if (scope.infiniteScrollLoading) return;

                            if (scrolledToBottom())
                                renderMore();
                        });
                });
            }

            setup(attrs.artInfiniteScroll);

            scope.$on("$destroy", function () {
                scrollRegion.unbind("scroll.artinfinitescroll");
                artSubscriptionService.unsubscribeAll('reload-infinite-scroll');
            });
        };
        return artInfiniteScroll;

    }]);