artModule.directive('artSettStatusMessage', [
    "$filter",
    "artSett",
    "artUser",
    'dateFilter',
function (
    $filter,
    artSett,
    artUser,
    dateFilter
) 
{
    function _simpleDateTime(dateString) {
        if (typeof dateString != 'string') {
            return null;
        }
        dateString = dateString.replace(/^([0-9]{4}-[0-9]{2}-[0-9]{2})\s([0-9]{2}:[0-9]{2}:[0-9]{2}.*)/, "$1T$2");
        shortDate = dateFilter(new Date(dateString), 'shortDate')
        shortTime = dateFilter(new Date(dateString), 'shortTime')
        return shortDate + " " + shortTime;        
    }

    var artSettStatusMessage = {};

    artSettStatusMessage.scope = {
        sett: "=artSettStatusMessage"
    };
    artSettStatusMessage.link = function(scope) {
        scope.comingSoon = false;
        if(new Date(scope.sett.released) > new Date() && new Date(scope.sett.published) <= new Date()) {
            scope.comingSoon = true;
            scope.releasedDate = _simpleDateTime(scope.sett.released);
        } else {
            scope.releasedDate = _simpleDateTime(scope.sett.released);
        }
        scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);
        scope.isSettSoldOut = artSett.isSoldOut.bind(artSett, scope.sett);

        scope.discontinueOnDate = function(){
            if (scope.sett.edition_size === "limited") {
                return null;
            }
            return scope.sett.discontinue_date ? _simpleDateTime(scope.sett.discontinue_date) : null;
        };

        scope.getAvailability = function(){
            if(scope.isSettSoldOut()){
                return "Out of Print " + _simpleDateTime(scope.sett.discontinued);
            }

            if (scope.sett.edition_size === "limited") {
                if(scope.sett.percent_sold_out >= 50 && scope.sett.freebies_discontinued){
                    return scope.sett.percent_sold_out + "% Packs Claimed";
                }
            }
            return null;
        };

        scope.getFreePackAvailability = function(){
            if (scope.sett.edition_size === "limited") {
                if (artSett.isSoldOut(scope.sett)){
                    return null;
                }
                if (scope.sett.freebies_discontinued){
                    return "Free Packs All Claimed";
                }
                if (!artSett.hasDailyFreebiesForSett(scope.sett))
                {
                    return $filter("textToHtml")("<div>Free Packs Claimed Today</div><div>Return Tomorrow for More</div>");
                }
                
                if (scope.sett.free_packs_claimed_percent >= 50){
                    return scope.sett.free_packs_claimed_percent + "% Free Packs Claimed";
                }
            }

            return null;
        };


    };
    artSettStatusMessage.templateUrl = "partials/art/sett-status-message.partial.html";

    return artSettStatusMessage;
}]);
