creatorModule.controller("ctCreditSummary", [
    '$scope',
    'artResource',
    'ctConfig',
    function(
        $scope,
        artResource,
        ctConfig
    ){


    $scope.creditSummary = [];
    $scope.loading = true;

    artResource.retrieve( ctConfig['api-royalties-credit-summary'] ).then(function(data){
        $scope.accountsUserTotal = data.accounts_user_total;
        $scope.creditSummary = data.credit_summary;
        $scope.loading = false;
    });


}]);