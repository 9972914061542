creatorModule.controller('ctSettEditorCtrl', [
    '$scope',
    'artConstants',
    'artUser',
    'ctSettCreator',
function(
    $scope,
    artConstants,
    artUser,
    ctSettCreator
){

    $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);
    $scope.getSett = ctSettCreator.getSett.bind(ctSettCreator);
    $scope.getGenres = ctSettCreator.getGenres.bind(ctSettCreator);
    $scope.disable_discontinue = false;
    $scope.minDate = new Date();

    $scope.discontinueTimes = ["Time of Day (UTC)"];
    $scope.releaseTimes = ["Time of Day (UTC)"];

    if($scope.getSett().version ===  artConstants.VERSION_TYPES.limited) {
        $scope.disable_discontinue = true;
    }

    for (var i = 0; i < 24; i++) {
        $scope.discontinueTimes.push(i + ":00");
        $scope.releaseTimes.push(i + ":00");
    }

    var discontinue_date = $scope.getSett().discontinue_date;
    var released = $scope.getSett().released;
    $scope.discontinueTime = {time: "Time of Day (UTC)"};
    $scope.releaseTime = {time: "Time of Day (UTC)"};

    if (discontinue_date) {
        $scope.discontinueTime.time = discontinue_date.getUTCHours() + ":00";
    }

    if (released) {
        $scope.releaseTime.time = released.getUTCHours() + ":00";
    }

    $scope.settTypeOptions = [
        {id: artConstants.SETT_TYPE_VALUES.normal, label: "Pro", showOption: true},
        {id: artConstants.SETT_TYPE_VALUES['promo-only'], label: "Promo Only", showOption: true},
        {id: artConstants.SETT_TYPE_VALUES.amateur, label: "Amateur", showOption: true}
    ];

    $scope.versionTypeOptions = [
        {id: artConstants.VERSION_TYPES.unlimited, label: "Unlimited", showOption: true},
        {id: artConstants.VERSION_TYPES.limited, label: "Limited", showOption: true}
    ];

    $scope.isNormalSett = function(){
        return $scope.getSett().sett_type === artConstants.SETT_TYPE_VALUES['normal'];
    };

    $scope.promoOnlySett = function(){
        return $scope.getSett().sett_type === artConstants.SETT_TYPE_VALUES['promo-only'];
    };

    $scope.defaultDiscontinueTime = function(defaultDiscontinueTime) {
        if(/^[a-zA-Z]/.test(defaultDiscontinueTime.time)) {
            defaultDiscontinueTime.time = "0:00"
        }
        var timeArray = defaultDiscontinueTime.time.split(":");
        var hours = parseInt(timeArray[0]);
        var minutes = parseInt(timeArray[1]);
        $scope.getSett().discontinue_date.setUTCHours(hours, minutes, 0, 1); // See long comment in updateDiscontinueDate
    }

    $scope.defaultReleaseTime = function(defaultReleaseTime) {
        if(/^[a-zA-Z]/.test(defaultReleaseTime.time)) {
            defaultReleaseTime.time = "0:00"
        }
        var timeArray = defaultReleaseTime.time.split(":");
        var hours = parseInt(timeArray[0]);
        var minutes = parseInt(timeArray[1]);
        $scope.getSett().released.setUTCHours(hours, minutes, 0, 1); // See long comment in updateReleaseDate
    }

    $scope.setReleaseTime = function (releaseTime) {
        if (releaseTime.time.match(/\d?\d:\d\d/)) { // Don't update if the select option is "Time of Day (UTC)"
            $scope.defaultReleaseTime(releaseTime);
            $scope.updateReleaseDate();
        }
    };

    $scope.setDiscontinueTime = function (discontinueTime) {
        if (discontinueTime.time.match(/\d?\d:\d\d/)) { // Don't update if the select option is "Time of Day (UTC)"
            $scope.defaultDiscontinueTime(discontinueTime);
            $scope.updateDiscontinueDate();
        }
    };

    $scope.updateDiscontinueDate = function () {
        if ($scope.getSett().discontinue_date !== null) {
            // Alright so here's the deal. If we pass back a Date object with 0 milliseconds when Django serializes the date
            // we get yyyy-MM-ddTHH:mm:ssZ instead of our usual yyyy-MM-ddTHH:mm:ss.SSSZ for reasons that still elude me
            // This is the path of least resistance to get the web and iOS apps working without other craziness
            $scope.defaultDiscontinueTime($scope.discontinueTime);
            $scope.getSett().discontinue_date.setMilliseconds(1);

            if($scope.discontinueTime.time !== "") {
                var timeArray = $scope.discontinueTime.time.split(":");
                var hours = parseInt(timeArray[0]);
                var minutes = parseInt(timeArray[1]);
                $scope.getSett().discontinue_date.setUTCHours(hours, minutes, 0);
            }
            else {
                $scope.discontinueTime.time = "0:00";
                $scope.getSett().discontinue_date.setUTCHours(0, 0, 0);
            }
        }

        $scope.updateObject($scope.getSett());
    };

    $scope.updateReleaseDate = function () {
        if ($scope.getSett().released !== null) {
            // Alright so here's the deal. If we pass back a Date object with 0 milliseconds when Django serializes the date
            // we get yyyy-MM-ddTHH:mm:ssZ instead of our usual yyyy-MM-ddTHH:mm:ss.SSSZ for reasons that still elude me
            // This is the path of least resistance to get the web and iOS apps working without other craziness
            $scope.defaultReleaseTime($scope.releaseTime);
            $scope.getSett().released.setMilliseconds(1);

            if($scope.releaseTime.time !== "") {
                var timeArray = $scope.releaseTime.time.split(":");
                var hours = parseInt(timeArray[0]);
                var minutes = parseInt(timeArray[1]);
                $scope.getSett().released.setUTCHours(hours, minutes, 0);
            }
            else {
                $scope.releaseTime.time = "0:00";
                $scope.getSett().released.setUTCHours(0, 0, 0);
            }
        }

        $scope.updateObject($scope.getSett());
    };

    $scope.updateCommissioned = function() {
        if ($scope.getSett().commissioned == true) {
            $scope.getSett().royalty_rate = 0;
        }
        $scope.updateObject($scope.getSett());
    };

    $scope.updateObject = function(obj, formField){
        $scope.disable_discontinue = false;
        if(obj.version ===  artConstants.VERSION_TYPES.limited) {
            $scope.disable_discontinue = true;
        }
        if(!formField || formField.$valid){
            return ctSettCreator.updateObject(obj).then(function(data){
                $scope.errors = {};
            }).catch(function(data){
                $scope.errors = data.fields;
            });
        }

    };

    $scope.getOriginalCoverImageUrl = function () {
        return $scope.getSett().sett_assets.original.url;
    };

}]);
