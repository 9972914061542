packOpenModule.factory('poPackSelect',[
    '$q',
    '$http',
    'artMessage',
    'artOverlay',
    'artPieceService',
    'artResource',
    'artSett',
    'artSubscriptionService',
    'artUser',
    'wsLumberjack',
function(
    $q,
    $http,
    artMessage,
    artOverlay,
    artPieceService,
    artResource,
    artSett,
    artSubscriptionService,
    artUser,
    wsLumberjack
){

    var _settPromise = null,
        _packPromise = null,
        _settListPromise = null,
        _pack = null,
        _sett = null,
        _packType,
        _selectType,
        _packsOpened = 0,
        _PIECE_IMAGE_SIZE = "large",
        _SETT_IMAGE_SIZE = "medium";


    function _createSettPromise(settOrSettUrl){
        var defer = $q.defer();

        artSett.getSettDetailsAndPreloadImages(settOrSettUrl, _SETT_IMAGE_SIZE).then(function(sett){
            _sett = sett;
            defer.resolve(sett);

            return sett;
        });
        return defer.promise;
    }

    function _preparePack(pack){

        artPieceService.addPrintOwnerships(artUser, pack.prints);

        var defer = $q.defer();

        artPieceService.preloadImagesSeries(artUser, pack.prints, _PIECE_IMAGE_SIZE).finally(function(){

            _settPromise.then(function(sett){

                //hydrate the pieces to have set info
                for(var i=0; i< pack.prints.length; i++){

                    pack.prints[i].set = sett;

                }
                _sett = sett;
                _pack = pack;
                defer.resolve(pack);

            });

        });

        return defer.promise;

    }

    function _createPackPromise(packOrUrl){

        var packPromise;

        if(angular.isObject(packOrUrl)){

            packPromise =  $q.when(packOrUrl);

        } else {

            packPromise = _settPromise.then(function(sett){

                return $http.post(packOrUrl).then(function(response){

                    return response.data;

                });

            });

        }

        return artPieceService.syncOwnership().then(function(){
            return packPromise.then(_preparePack);
        });

    }

    function _updatePackCounts(pack, sett){

        _packsOpened++;

        if(poPackSelect.isFreebie()){
            artUser.incrementSetFreebiesCollected(sett.id);
        }

    }

    var poPackSelect = {

        getTypeDisplay:function(){

            return _DISPLAY_NAME[_packType];

        },

        PURCHASED_PACK:0,
        FREEBIE_PACK:1,
        PROMO_PACK:4,
        ONBOARDING_PACK:5,

        startPurchasePack:function(){
            _packType = poPackSelect.PURCHASED_PACK;
        },

        startFreePack:function(){
            _packType = poPackSelect.FREEBIE_PACK;
        },
        setPackType:function(packType){

            _packType = packType;

        },

        setSelectType:function(selectType){
            _selectType = selectType;
        },

        isFreebie:function(){

            return _packType === poPackSelect.FREEBIE_PACK;

        },

        isPromoPack: function() {
            return _packType === poPackSelect.PROMO_PACK;
        },

        isOnboardingPack: function() {
            return _packType === poPackSelect.ONBOARDING_PACK;
        },

        isSpecialPack: function(){
            return _packType === poPackSelect.PROMO_PACK || _packType === poPackSelect.ONBOARDING_PACK;
        },

        startPackSelect:function(settOrSettUrl){
            _settPromise = _createSettPromise(settOrSettUrl);
            _settPromise.catch( function(error){
                poPackSelect.finish();
                NM.ajaxError(500);
                wsLumberjack.exception(error);
            });

            return _settPromise;

        },

        startPackOpen:function(packOrUrl){

            _packPromise = _createPackPromise(packOrUrl).then(function(pack){

                _settPromise.then(function(sett){

                    _updatePackCounts(pack, sett);

                });

                return pack;

            });

            _packPromise.catch(function(error){

                poPackSelect.finish();
                artOverlay.hide();

                if(error.status === 400){
                    artMessage.showAlert(error.data.detail);
                } else if (error.status){
                    NM.ajaxError(error.status);
                } else {
                    NM.ajaxError(500);
                    wsLumberjack.exception(error);
                }


            });

            return _packPromise;

        },

        startSettSelect:function(settListUrl){

            _settListPromise = artResource.retrievePaginated(settListUrl).then(function(response){
                return response;

            });

            return _settListPromise;

        },

        finish:function(){
            artSubscriptionService.broadcast("pack-open-finished", {
                packsOpened:_packsOpened
            });
            _packsOpened = 0;

        },

        fetchSett:function(){

            return _settPromise;

        },

        // Due to no direct access to sett promise, adding for testing only.
        setSett:function(settPromise){

            _settPromise = settPromise;

        },

        fetchPack:function(){

            return _packPromise;

        },

        fetchSettList:function(){

            return _settListPromise;

        },

        selectSett:function(sett){

            _settPromise = $q.when(sett);

            return _settPromise;

        },

        getSelectType:function(){
            return _selectType;
        }

    };

    var _DISPLAY_NAME = {};

    _DISPLAY_NAME[poPackSelect.PURCHASED_PACK] = "pack";
    _DISPLAY_NAME[poPackSelect.FREEBIE_PACK] = "freebie";
    _DISPLAY_NAME[poPackSelect.REWARD_PACK] = "reward pack";
    _DISPLAY_NAME[poPackSelect.PROMO_PACK] = "promo pack";
    _DISPLAY_NAME[poPackSelect.ONBOARDING_PACK] = "onboarding pack";

    _packType = poPackSelect.PURCHASED_PACK;

    return poPackSelect;

}]);
