artModule.directive("artDisplayCaseExpand", ["$timeout", function ($timeout) {

    function link($scope, $element, $attrs) {
        $scope.expandDisplay = function() {
          var height = parseInt($attrs["initialHeight"], 10);
          $element.find('.nm-display-case--column').each(function () {
              var elementHeight = $(this).height();
              if(elementHeight > height){
                  height = elementHeight;
              }
          });
          $timeout(function() {
              $scope.displayHeight = height;
              $scope.expanded = true;
          });
        };
        $scope.condenseDisplay = function() {
          $timeout(function() {
              $scope.displayHeight = parseInt($attrs["initialHeight"], 10);
              $scope.expanded = false;
          });
        };
        $scope.toggleDisplay = function() {
            if($scope.expanded) {
              $scope.condenseDisplay();
            } else {
              $scope.expandDisplay();
            }
        };
    }

    function controller($scope, $element, $attrs) {
        $scope.displayHeight = parseInt($attrs["initialHeight"], 10);
        $scope.expanded = false;
    }

    return {
        link: link,
        controller: controller
    };
}]);
