neonmobApp.directive('artBadge', function () {
    var directiveDefinitionObject = {};
    directiveDefinitionObject.scope = {
        badge: "=artBadge",
        showBrag: "=showBrag"
    };
    directiveDefinitionObject.controller = [
        "$scope",
        function ($scope) {
            $scope.hasAchieved = function () {
                return $scope.badge.achieved;
            };
            $scope.isVisible = function () {
                return $scope.badge.achieved || !$scope.badge.secret;
            };
        }];
    directiveDefinitionObject.templateUrl = "partials/badges/badge.partial.html";
    return directiveDefinitionObject;
});
