artModule.directive("artConfirm", [
    "artConfirm",
    function (
        artConfirm
    ) {
        var directiveDefinitionObject;

        directiveDefinitionObject = {
            scope: {},
            controller: ["$scope", function ($scope) {
                $scope.caratsEarned = artConfirm.caratsEarned.bind(artConfirm);
                $scope.caratsBalance = artConfirm.caratsBalance.bind(artConfirm);
                $scope.getMessage = artConfirm.getMessage.bind(artConfirm);
                $scope.getSubtext = artConfirm.getSubtext.bind(artConfirm);
                $scope.getDesctext = artConfirm.getDesctext.bind(artConfirm);
                $scope.isVisible = artConfirm.isVisible.bind(artConfirm);
                $scope.getIconClass = artConfirm.getIconClass.bind(artConfirm);
                $scope.getParentClass = artConfirm.getParentClass.bind(artConfirm);
                $scope.getOkText = artConfirm.getOkText.bind(artConfirm);
                $scope.hasCloseBtn = artConfirm.hasCloseBtn.bind(artConfirm);
                $scope.confirm = artConfirm.close.bind(artConfirm);
                $scope.getTemplateData = artConfirm.getTemplateData.bind(artConfirm);
                $scope.getTemplateUrl = artConfirm.getTemplateUrl.bind(artConfirm);
                $scope.getLevel =  artConfirm.getLevel.bind(artConfirm);
                $scope.cancel = function () {
                    return artConfirm.close(true);
                };
            }],
            templateUrl: "partials/art/confirm.partial.html"
        };
        return directiveDefinitionObject;
    }]);
