creatorModule.directive("ctPieceEditor", function(){

    var ctPieceEditor = {};

    ctPieceEditor.scope = {
        piece: "=ctPieceEditor"
    };

    ctPieceEditor.controller = [
        "$scope",
        '$window',
        'artOverlay',
        'artSubscriptionService',
        'ctPieceRarities',
        "ctSettCreator",
    function(
        $scope,
        $window,
        artOverlay,
        artSubscriptionService,
        ctPieceRarities,
        ctSettCreator
    ){

        $scope.showHelpFor = function( fieldName ){

            var tpl = "partials/creator/help-messages/" + fieldName + ".partial.html";
            artOverlay.bindNameToTemplateUrl("collection-creator-help", tpl, "modal");
            artOverlay.show( 'collection-creator-help' );

        };

        $scope.deletePiece = function(){

            if($window.confirm("Delete this piece? You cannot get it back!")){
                ctSettCreator.deletePiece($scope.piece);
            }

        };

        $scope.isRarity = function(rarity){

            return $scope.piece.rarity.rarity === rarity;

        };


        $scope.getSettPieces = function(){

            return ctSettCreator.getSettPieces();

        };

        $scope.isSelectedPiece = function(piece){

            return $scope.piece.id === piece.id;

        };

        $scope.rarityChoices = ctPieceRarities.RARITIES;


        $scope.updateRarity = function(rarity, pieceForm){
            //do not update if this is the same class.
            if($scope.isRarity(rarity.rarity)){
                return;
            }

            $scope.piece.rarity = rarity;
            $scope.piece.variant_parent = null;
            $scope.piece.weighting = null;

            $scope.updatePiece(pieceForm);

        };

        $scope.updatePiece = function(formField){
            if(formField.name.$valid){

                artSubscriptionService.broadcast('show-global-message', 'Updating Artwork&hellip;');

                ctSettCreator.updateObject($scope.piece);

            }

        };


        $scope.getOriginalUrl = function(){

            if($scope.piece.asset_type === "video"){
                return $scope.piece.piece_assets.video.original.sources[0].url;
            } else {
                return $scope.piece.piece_assets.image.original.url;
            }

        };

        $scope.hasOriginalAsset = function(){

            return $scope.piece.piece_assets &&
                   $scope.piece.piece_assets[$scope.piece.asset_type] &&
                   $scope.piece.piece_assets[$scope.piece.asset_type].original;

        };

    }];

    ctPieceEditor.templateUrl = "partials/creator/editor/piece-editor.partial.html";

    return ctPieceEditor;

}).value("ctPieceRarities", {
    RARITIES: [
        {
            name: "Common",
            class: "common",
            rarity: 0
        },{
            name: "Uncommon",
            class: "uncommon",
            rarity: 1
        },{
            name: "Rare",
            class: "rare",
            rarity: 2
        },{
            name: "Very Rare",
            class: "veryRare",
            rarity: 3

        },{
            name: "Extra Rare",
            class: "extraRare",
            rarity: 4
        },{
            name: "Chase",
            class: "chase",
            rarity: 5
        },{
            name: "Variant",
            class: "variant",
            rarity: 6
        },{
            name: "Legendary",
            class: "legendary",
            rarity: 7,
        }
    ],

    SPECIAL_RARITY: [
        {
            name: "Chase",
            class: "chase",
            rarity: 5
        },{
            name: "Variant",
            class: "variant",
            rarity: 6
        },{
            name: "Legendary",
            class: "legendary",
            rarity: 7,
        }
    ],
    COMMON_RARITY:0,
    UNCOMMON_RARITY:1,
    RARE_RARITY:2,
    VERY_RARE_RARITY:3,
    EXTRA_RARE_RARITY:4,
    CHASE_RARITY:5,
    VARIANT_RARITY:6,
    LEGENDARY_RARITY:7,

}).directive('ctValidPieceName', ["ctSettCreator", function(ctSettCreator){

    var ctValidPieceName = {};

    ctValidPieceName.require = 'ngModel';

    ctValidPieceName.link = function(scope, elm, attrs, ctrl){

        ctrl.$parsers.unshift(function(viewValue){

            if(ctSettCreator.isExistingPieceName(viewValue)){

                ctrl.$setValidity('name', false);
                return undefined;

            } else {

                ctrl.$setValidity('name', true);
                return viewValue;

            }

        });

    };

    return ctValidPieceName;

}]);
