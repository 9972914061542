artModule.directive('artOverlay', function () {
    var directiveDefinitionObject;
    directiveDefinitionObject = {
        controller: [
            '$scope',
            'artOverlay',
            'artShowStreaks',
            function ($scope,
                      artOverlay,
                      artShowStreaks) {
                //HACK: to prevent from having to do a major rewrite of the overlay module, we
                //bind this scope to the overlay service so that we can bind the name and the data
                //to the scope.
                artOverlay.init($scope);

                $scope.getTemplateUrl = function () {
                    return artOverlay.getTemplateUrl();
                };

                $scope.getThemeClass = function () {
                    return artOverlay.getThemeClass();
                };

                $scope.getClass = function () {
                    var classes = [];
                    var name = artOverlay.getName();
                    if (name) {
                        classes.push(name);
                    }
                    var type = artOverlay.getType();
                    if (type) {
                        classes.push(artOverlay.getType());
                    }
                    return classes.join(" ");
                };

                $scope.isLoading = artOverlay.isLoading.bind(artOverlay);

                $scope.backdropClick = function(){

                    if( artOverlay.closeOnBackdropClick() ){
                      artOverlay.hide();
                    }
                };

                $scope.hide = function () {
                    artOverlay.hide();
                    artShowStreaks.showStreaks()
                };
            }],
        replace: true,
        templateUrl: "partials/art/overlay.partial.html"
    };
    return directiveDefinitionObject;
})
    .controller("artOverlayDismissController", [
        '$element',
        'artOverlay',
        'clickStreaksService',
        function ($element,
                  artOverlay,
                  clickStreaksService) {
            $element.on('click', function(){

                artOverlay.hide();

                if(artOverlay.getName() == "streaks" && clickStreaksService.getClickedBtn()) {

                    clickStreaksService.openClickedBtn();
                };
            });
        }
    ])
    .directive('artOverlayDismiss', function () {
        return {
            controller: "artOverlayDismissController"
        };
    }).directive('artOverlayShow', ['$timeout', 'artOverlay', function ($timeout, artOverlay) {

        var artShowOverlay = {};

        artShowOverlay.scope = {
            overlayName: "@artOverlayShow",
            onClose: "&artOnClose"
        };

        artShowOverlay.link = function (scope, element) {
            element.bind("click.artoverlayshow", function () {
                $timeout(function () {
                    artOverlay.show(scope.overlayName).then(scope.onClose);
                });
            });
            scope.$on("$destroy", function () {
                element.unbind("click.artoverlayshow");
            });
        };
        return artShowOverlay;
    }]);
