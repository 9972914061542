neonmobApp.directive('nmLikes', function() {

	var directiveDefinitionObject;

	directiveDefinitionObject = {

		scope: {

			likes: "=nmLikes",
            parentId: "=nmParentId",
            parentType: "@nmParentType"

		},

		controller: [
            '$scope',
            '$cookies',
            'artConfig',
            'artContentTypes',
            'artResource',
            'artUser',
            'artMessage',
		function(
            $scope,
            $cookies,
            artConfig,
            artContentTypes,
            artResource,
            artUser,
            artMessage
        ){


            function playYahoo(){

                var yahoo = $('<audio preload="auto"><source src="' + artConfig['yahoo'] + '"></source></audio>').get(0);
                yahoo.volume = 0.1;
                yahoo.play();

            }


            $scope.canLike = function(){

                return artUser.isAuthenticated() && artUser.isVerified();

            };

            $scope.toggleLike = function(){

                $scope.likes.liked = !$scope.likes.liked;

                if($scope.likes.liked) {
                    /*
                        Both likes(Submission) and Coming soon sett using same model(Likemodel).
                        We can differentiate by using Content_type_id.
                        Both has same Email and notification format.
                        Both are using same API URL.(http://testing.neonmob.com/api/likes/).
                    */
                    if (!artUser.isVerified()) {
                        return artMessage.showAlert("You have to verify your email before you can do that :(");
                    }
                    artResource.create(artConfig.api['api-likes-list'], {

                        content_type: artContentTypes[$scope.parentType],
                        object_id: $scope.parentId

                    }).then(function(likeData){

                        $scope.likes.total++;
                        if( $cookies.audio == 'yes' ){
                            playYahoo();
                        }
                        $scope.likes.url = likeData.url;

                    });
                } else {
                    artResource.destroy($scope.likes).then(function(data){

                        $scope.likes.total--;
                    
                    });
                    
                }

            };


		}],

		templateUrl: "partials/component/likes.partial.html"

	};

	return directiveDefinitionObject;

});