neonmobApp.directive('profileBioUpdate', function(){

    var directiveDefinitionObject = {

        controller: [
            '$scope',
            '$http',
            '$timeout',
            '$window',
            'artOverlay',
            'nmConfig',
        function(
            $scope,
            $http,
            $timeout,
            $window,
            artOverlay,
            nmConfig
        ){
            if($scope.isYou) {
                $scope.settingsUrl = nmConfig['api-accounts-detail'];
            }
            $scope.bioLimit = nmConfig.max_bio_length;
            $scope.triggerModal = function (type) {
                artOverlay.show(type,null,true).then(function (results) {
                    if (results.status === true) {
                        $scope.showMessage("Saved!");
                        $scope.hideMessage(1000);
                    }
                });
            };

            $scope.hideOverlay = function (status) {
                artOverlay.hide(status);
            };

            $scope.clearErrors = function () {
                $scope.fieldErrors = {};
                $scope.error = null;
            };

            //Get User Settings Data from API
            if ($scope.settingsUrl) {
                $http.get($scope.settingsUrl).then(function(response) {
                    $scope.user = response.data;
                    $scope.lastSaved = angular.copy($scope.user);
                }).catch(function() {
                    NM.error("I had some problems fetching your data - please refresh the page!");
                });
            }

            $scope.updateSettings = function() {
                $scope.showMessage("Saving your data...");
                $scope.errors = {};
                $http.put($scope.settingsUrl, $scope.user).success(function (data) {
                    $scope.user = data;
                    $scope.lastSaved = angular.copy($scope.user);
                    $scope.showMessage("Saved!");
                    $scope.hideMessage(1000);
                    $scope.hideOverlay(true);
                    // $state.go('collectionview', {}, {reload: true});
                    $window.location.reload();
                }).error(function(data, status) {
                    if (status === 400) {
                        $scope.showMessage(data.detail);
                        $scope.errors = data;
                    } else if (status === 503) {
                        $scope.showMessage("The site is experiencing high traffic - please refresh the page.");
                    } else {
                        $scope.showMessage("Please refresh the page, I made a mistake");
                    }
                    $timeout(function(){
                        $scope.user = angular.copy($scope.lastSaved);
                        $scope.errors = [];
                    }, 1000);
                });
            };

            $scope.showMessage = function(message) {
                $scope.message = message;
            };

            $scope.hideMessage = function(duration) {
                $timeout(function () {
                    delete $scope.message;
                }, duration);
            };

            $scope.editBio = function() {
                $scope.hideTextArea = false;
            }

        }],


        templateUrl: "partials/profile-new/profile-bio-update.partial.html"

    };

    return directiveDefinitionObject;

});
