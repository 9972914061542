artModule.factory("artUser", [
    "$http",
    "$timeout",
    "artJson",
    "artConstants",
function(
    $http,
    $timeout,
    artJson,
    artConstants
){

    var _user = null;

    if(_getUser() !== null){
        $http.get('/api/credit-pass/web/').success(_setCreditPassExpiry);
    } 
    
    function _getUser(){

        //FIXME: this is a legacy support since some parts of the site still need to use NM.you for
        //updates. This should be fixed in the story [DE58]
        if(window.NM && window.NM.you){

            _user = window.NM.you;

        } else if(_user === null){

            var userJson = artJson.parseFromInput("#user-json");

            if (userJson) {

                _user = userJson;


            }

        }

        return _user;

    }

    function _setUserAttr(attr, value){

        var user = _getUser();

        if(user && angular.isFunction(user.attr)){

            user.attr(attr, value);
            user.save();

        } else if(user){

            user[attr] = value;

        } else {

            throw new Error("Cannot set the property '"+attr+"' from a user that is not logged in.");

        }

    }

    function _updateUser(userData){

        for(var key in userData){

            if(userData.hasOwnProperty(key)){

                _setUserAttr(key, userData[key]);

            }

        }

    }


    function _getUserAttr(attr){

        var user = _getUser();

        if(user && angular.isFunction(user.attr)){

            return user.attr(attr);

        } else if(user){

            return user[attr];

        } else {

            throw new Error("Cannot get the property '"+attr+"' from a user that is not logged in.");

        }

    }

    function _clampCredits(){

        var credits = _getUserAttr('balance');

        if(credits < 0){

            _getUser().setCredits(0);

        }

    }

    function _getUserLink(link){

        return _getUserAttr("links")[link] || null;

    }

    function _setCreditPassExpiry(exp_date) {
        $timeout(function () {
            _setUserAttr("credit_pass_expiry", exp_date);
        });
        return exp_date;
    }


    var artUser = {

        toObject: function(){

            var user = _getUser();

            return (user && user.attributes) ? user.attributes : user;

        },

        update: function(userData){

            if(_getUser() === null){

                _createUser(userData);

            } else {

                _updateUser(userData);

            }

        },

        isYou: function(userData){

            if(!artUser.isAuthenticated()){

                return false;

            }

            return userData === artUser ||
                   userData.id === artUser.getId() ||
                   userData.username === artUser.getUsername();

        },

        getId: function(){

            return _getUserAttr("id");

        },

        getFacebookStatus: function() {

            return _getUserAttr("connected_accounts").facebook;

        },

        getDailyFreebieCount: function(){

            return _getUserAttr("num_daily_freebies");

        },

        getFreebiesRemaining: function() {

            return _getUserAttr("num_freebies_left");

        },

        getFreebiesLimit: function() {

            return _getUserAttr("get_freebie_limit");

        },

        getTimezoneOffset: function(){

            return _getUserAttr("timezone_offset");

        },

        decrementFreebiesRemaining: function(count) {

            $timeout(function(){

                var remaining = _getUserAttr("num_freebies_left");

                _setUserAttr("num_freebies_left", remaining - (count || 1));


            });

        },

        setFreebiesRemaining: function(amt) {
            $timeout(function(){
                _setUserAttr("num_freebies_left", amt);
            });
        },

        getTodaysSettFreebiesCount: function(settId){

            var todaysFreebies = artUser.getTodaysFreebieCount();

            if(todaysFreebies && todaysFreebies[settId]){

                return todaysFreebies[settId];

            } else {

                return 0;

            }

        },

        getTodaysFreebieCount: function() {

            return _getUserAttr("todays_freebies_count");

        },

        isAmbassador: function() {

            return _getUserAttr("is_ambassador") === true;

        },
        incrementSetFreebiesCollected: function(settId) {

            var todaysFreebies = _getUserAttr("todays_freebies_count");

            if(!todaysFreebies){

                todaysFreebies = {};
                _setUserAttr("todays_freebies_count", todaysFreebies);

            }

            var currentFreebies = todaysFreebies[settId] || 0;

            todaysFreebies[settId] = currentFreebies+1;

        },

        updateUserCarats: function(balance) {

            $timeout(function(){

                _setUserAttr("carats", balance);
            });
        },

        updateUserLevel: function(level) {

            $timeout(function() {

                _setUserAttr("level", level);
            });
        },

        getUserLevel: function() {

            return _getUserAttr("level");
        },

        isNewUser: function() {

            return _getUserAttr("points") === 0; // points '0' indicates a new user
        },

        getUserCarats: function() {

            return _getUserAttr("carats");
        },

        hasPermission: function(permission){
            var user = _getUser();
            if(_getUser()){
                return _getUserAttr("permissions")[permission] || false;
            } else {
                return false;
            }
        },

        isAuthenticated:function(){

            return _getUser() !== null;

        },

        isStaff: function(){

            return _getUser()!== null && _getUserAttr('is_staff') === true;

        },

        isCreatorAdmin: function(){
            return artUser.hasPermission("art.admin_sett");
        },

        isCreator: function(){

            return _getUser()!== null && _getUserAttr('is_creator') === true;

        },

        getReferralCode:function(){

            return _getUserAttr("referral_code");

        },

        isConnectedToFacebook:function(){

            return _getUserAttr("connected_accounts").facebook;

        },

        getCredits:function(){

            return _getUserAttr('balance');

        },

        removeCredits:function(amount){

            var user = _getUser();

            if(user){

                user.removeCredits(amount);
                _clampCredits();

            } else {

                throw new Error("Cannot remove credits from a user that is not logged in.");

            }

        },

        getAvatar: function(size){

            size = size || "large";

            return _getUserAttr('avatar')[size];

        },

        getProUserStatus: function() {
            try {
                return _getUserAttr('pro_status');
            } catch (err) {
                return null;
            }
        },

        checkUserProStatus: function(proStatus) {
            return proStatus !== artConstants['PRO_USER_CHOICES'].normal;
        },

        areFeaturesGated: function() {
            if (this.isAuthenticated()) {
                // Handle tests as they don't have accessible_features set on user attributes
                return Object.keys(_getUserAttr('accessible_features') || {}).length > 0;
            }
            return false;
        },

        canIDo: function(permission) {
            if (!this.isAuthenticated()) return false;
            if (!this.areFeaturesGated()) return true;
            var features = _getUserAttr('accessible_features');
            return features.includes(permission);
        },

        getUserEmail: function() {
            return _getUserAttr('email');
        },

        getUsername: function() {
            if(this.isAuthenticated()){
                return _getUserAttr("username");
            } else {
                return null;
            }
        },

        getUrl:function(name){

            return _getUserLink(name);

        },

        sync:function(){

            return $http.get(_getUserLink('self')).success(_updateUser);

        },

        isVerified: function() {
            if (this.isAuthenticated()){
                return _getUserAttr("is_verified");
            } else {
                return  false;
            }
        },


        getAvatarSource: function(size) {
            var avatar = _getUserAttr('avatar');
            return avatar[size];
        },

        tradingActive: function() {
            return !_getUserAttr('vacation_mode');
        },

        hasVariantNav: function () {
            if (this.isAuthenticated()){
                return _getUserAttr('new_user_nav_variant');
            } else {
                return  false;
            }
        },

        hasRewards: function() {

            return _getUserAttr("has_rewards");
        },

        updateCredits: function(balance) {

            $timeout(function() {

                _setUserAttr("balance", balance);
            });
        },
        
        getCreditPassExpiry: function () {
            return _getUserAttr("credit_pass_expiry");
        },

        isCreditPassActive: function () {
            isActive = false;
            
            if(_getUserAttr("credit_pass_expiry")){
                expireDate = _getUserAttr("credit_pass_expiry").date
                if(expireDate)
                    expireDate = new Date(Date.parse(expireDate)).getTime();                    
            }
                
            if(expireDate){            
                isActive = expireDate > new Date(Date.now()).getTime()       
            }
            return isActive;
        },        

    };

    return artUser;

}]).filter("hasPermission", ["artUser", function(artUser){
    return function(permission, value){
        value = value !== false;
        return value === artUser.hasPermission(permission);
    };
}]);
