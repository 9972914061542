creatorModule.controller("ctPreviewCtrl", [
    '$scope',
    'artUser',
    'ctSettCreator',
function(
    $scope,
    artUser,
    ctSettCreator
){

    $scope.pieces   = ctSettCreator.getSettPieces();
    $scope.sett     = ctSettCreator.getSett();
    $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);

    $scope.returnAndEdit = function(piece){
        $scope.$parent.goToEditor();
        ctSettCreator.setSelectedPiece(piece);

    };

    $scope.getPrintPerPiece = function(pieceRarity) {
        rarityClass = pieceRarity.class;
        var tempArr = ctSettCreator.getCoreCounts().concat(ctSettCreator.getSpecialCount());
        for (var i=0; i <= tempArr.length; i++) {
            if (tempArr[i].class == pieceRarity.class) {
                return tempArr[i].printPerPiece;
            }
        }
        return 0;
    };

}]);
