artModule.service("clickStreaksService", [
    'artUser',
	'poRoute',
	'poPackSelect',
	'artShowStreaks',
	'$timeout',
	function(
	artUser,
	poRoute,
	poPackSelect,
	artShowStreaks,
	$timeout){

		var clickStreaksService = {},
		    clickedBtnDetail,
            _lastPackTier = null,
            _you;

        function _updateCurrency(tier) {
            if(tier.currency ==='credit') {
                artUser.removeCredits(tier.price);
                _you.balance -= tier.price;
            }

            if(tier.currency === 'carat') {
                _you.carats -= tier.price;
                artUser.updateUserCarats(_you.carats);
            }

            if(tier.currency === 'freebie') {
                artUser.decrementFreebiesRemaining(tier.price);
            }
        };

        function _updateAvailability(tier) {
            if(tier.currency ==='credit') {
                tier.can_user_open = tier.price <= _you.balance;
            }

            if(tier.currency === 'carat') {
                tier.can_user_open = tier.price <= _you.carats;
            }

            if(tier.currency === 'freebie') {
                tier.can_user_open = tier.price <= artUser.getFreebiesRemaining();
            }
            if (tier.count > -1) {
                tier.available -= 1;
                tier.is_available = tier.available > 0;
            }
        };

		var displayOptions = {
			discoverMore: {
				click: function(){
					poRoute.launchSuggestedSetts();
				}
			},
			openAnother: {
				click: function(callback){
					callback();
        			// _resetPackOpenState();
				}
			},
			launchPackTier:{
				click: function(callback) {
					callback();
				}
			}
		};

		clickStreaksService.setClickedBtn = function(btnDetailObj){

			clickedBtnDetail = btnDetailObj;
		};

		clickStreaksService.getClickedBtn = function() {

			return clickedBtnDetail;
		};

		clickStreaksService.openClickedBtn = function() {

			displayOptions[clickedBtnDetail.name].click(clickedBtnDetail.value);
		};

        // FIXME: This and other pack opening change don't belong here.
        // It's done because of circular dependency between poPackSelect and poRoute.
        // Also, ideally this should be with packs and this service should not be here.
        // Awaiting the huge refactoring day!!! :duh:
        clickStreaksService.openPack = function(tier){
            if(tier.currency === 'freebie'){
                poPackSelect.startFreePack();
            } else {
                poPackSelect.startPurchasePack();
            }

            //route to the freebie pack open
            poRoute.fetchAndOpenPack(tier.open_pack_url).then(function(packs){
                // Fetch the streaks detail when open pack is triggered
                artShowStreaks.fetchStreaksDetail();
                _updateCurrency(tier);
                $timeout(function() {
                    _updateAvailability(tier);
                    _lastPackTier = tier;
                });
            });
        };

        clickStreaksService.getLastOpenedTier = function() {
            return _lastPackTier;
        },

        clickStreaksService.setLastOpenedTier = function(tier) {
            _lastPackTier = tier;
        }
        clickStreaksService.setYouObject = function(you) {
            _you = you;
        }


		return clickStreaksService;
	}]);