creatorModule.controller("ctValidateSettController", [
    '$http',
    '$scope',
    'artConstants',
    'artOverlay',
    'artSubscriptionService',
    'ctPieceRarities',
    'ctSettCreator',
function(
    $http,
    $scope,
    artConstants,
    artOverlay,
    artSubscriptionService,
    ctPieceRarities,
    ctSettCreator
){

    //exposed for easy testing since there's some funkyness that could happen.
    this.getDefaultPreviewPieces = function(pieces){
        var visiblePieces = pieces.filter(function(piece){
            return piece.rarity.rarity !== ctPieceRarities.CHASE_RARITY;
        });

        visiblePieces.sort(function(a, b){
            var comparison = a.preview_order - b.preview_order;
            return comparison ? comparison : a.ordering - b.ordering;
        });

        var previewPieces = visiblePieces.slice(0, artConstants.MAX_PREVIEW_PIECES);
        if(previewPieces.length % 2 === 1){
            previewPieces.pop();
        }
        return previewPieces;
    };

    //exposed for easy testing since there's some funkyness that could happen.
    this.shouldUpdatePreviewPieces = function(previewPieces){
        if(previewPieces.length < artConstants.MAX_PREVIEW_PIECES){
            return true;
        }

        for(var i=0; i<previewPieces.length; i++){
            if(previewPieces[i].preview_order >= artConstants.MAX_PREVIEW_PIECES){
                return true;
            }
        }

        return false;
    };

    var self = this;
    $scope.errors = null;

    $scope.getTemplate = function() {
        if (ctSettCreator.getPreviewStatus()) {
            ctSettCreator.gotoPreviewPackTemplate(false);
            return 'select-pack-template';
        }
        var template = ctSettCreator.isLimitedEdition() && !ctSettCreator.getDisabledStatus().disabled ?
            'select-pack-template' :
            'show-preview';
        return template;
    }

    $scope.validateSett = function() {
        var setTemplate = $scope.getTemplate();
        $http.post(ctSettCreator.settValidateUrl).success(function(data) {
            var previewPieces = self.getDefaultPreviewPieces(ctSettCreator.getSettPieces());
            if(self.shouldUpdatePreviewPieces(previewPieces)) {
                ctSettCreator.updatePreviewPieces(previewPieces).then(function(){
                    artOverlay.hide();
                    if(ctSettCreator.getTemplateToPreview()) {
                        artSubscriptionService.broadcast('show-preview');
                        ctSettCreator.fromPreviewStatus(false);
                    } else artSubscriptionService.broadcast(setTemplate);
                });
            } else {
                artOverlay.hide();
                if(ctSettCreator.getTemplateToPreview()) {
                    artSubscriptionService.broadcast('show-preview');
                    ctSettCreator.fromPreviewStatus(false);
                } else artSubscriptionService.broadcast(setTemplate);
            }

        }).error(function(data){
            $scope.errors = data;
        });

    };

    $scope.validateSett();


}]);