artModule.directive("artSettCompletionGraph", ["artUser", "artSett", function (artUser, artSett
) {
    var artSettCompletionGraph = {};
    artSettCompletionGraph.scope = {
        sett: '=artSettCompletionGraph',
        user: '=?user',
        size: '=?size',
        showCore: '=?showCore',
        showSpecial: '=?showSpecial',
        strokeWidth: '=?strokeWidth'
    };
    artSettCompletionGraph.link = function (scope, elm) {

        var size = 40;

        scope.size = size = scope.size || 40;
        scope.showCore = (scope.showCore !== false ? true : false);
        scope.showSpecial = (scope.showSpecial !== false ? true : false);
        scope.strokeWidth = scope.strokeWidth || 2;
        scope.specialRadius = size / 2 - scope.strokeWidth;
        scope.coreRadius = size / 2 - scope.strokeWidth * 2;
        elm.addClass('sett-completion-graph');

        scope.getCoreCompletionPercent = artSett.getCoreCompletionPercent.bind(artSett);
        scope.getSpecialCompletionPercent = artSett.getSpecialCompletionPercent.bind(artSett);
        scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);

        scope.getCompletionPercent = function () {
            if (scope.showSpecial && !scope.showCore) {
                return artSett.getSpecialCompletionPercent(scope.sett);
            } else {
                return artSett.getCoreCompletionPercent(scope.sett);
            }
        }

        scope.getCircumference = function (radius) {
            return 2 * Math.PI * radius;
        }

        scope.getSize = function () {
            return size;
        }

        function _getDashOffset(percent, radius) {

            return ((100 - percent) / 100) * scope.getCircumference(radius);

        }

        scope.getCoreDashOffset = function () {
            return _getDashOffset(artSett.getCoreCompletionPercent(scope.sett), scope.coreRadius);
        };

        scope.getSpecialDashOffset = function (percent) {
            return _getDashOffset(artSett.getSpecialCompletionPercent(scope.sett), scope.specialRadius);
        };

        scope.ready = true;

    };
    artSettCompletionGraph.templateUrl = "partials/art/sett/sett-completion-graph.partial.html";
    return artSettCompletionGraph;

}]).directive("artSettCompletionBreakdown", ["artUser", "artSett", "ProfileUser", function (artUser, artSett, ProfileUser
) {
    var artSettCompletionBreakdown = {};
    artSettCompletionBreakdown.scope = {
        sett: '=artSettCompletionBreakdown',
        user: '=?user',
    };
    var headingTitle = "Your"
    if (ProfileUser.userId) {
        if(ProfileUser.userId !== artUser.getId()){
            headingTitle = ProfileUser.userName + "'s";
        }
    }
    artSettCompletionBreakdown.link = function (scope) {
        scope.totals = artSett.getRarityTotals(scope.sett);
        scope.headingTitle = headingTitle
    };
    artSettCompletionBreakdown.templateUrl = "partials/art/sett/sett-completion-breakdown.partial.html";
    return artSettCompletionBreakdown;

}]);
