artModule.directive('artTips', [
    '$http',
    'artMessage',
    function ($http,
              artMessage) {
        var directiveDefinitionObject = {
            controller: [
                "$scope",
                "$http",
                '$location',
                '$timeout',
                'artConfig',
                '$sce',
                function ($scope,
                          $http,
                          $location,
                          $timeout,
                          artConfig,
                          $sce) {

                    $scope.tips = artConfig.userTips || [];
                    $scope.dismissURL = artConfig.tipsDismissUrl;

                    var _FADE_IN_TIME = 3000;

                    $scope.show = function () {
                        // TODO - UPDATE THIS WITH WHATEVER WE WANT WITH NEW ONBOARDING
                        $(".tip").tooltip({ container: 'body', trigger: 'hover' });
                        return true;
                    };

                    $scope.showTip = function (tip) {
                        if (tip.kind != "normal") {
                            return true;
                        }
                        if (tip.page_url.length > 0) {
                            if (tip.page_url.includes("/")) {
                                if ($location.path().includes(tip.page_url)) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    };

                    $scope.isSpecialTip = function (tip) {
                        return tip.kind === "special";
                    };

                    $scope.isNormalTip = function (tip) {
                        return tip.kind === "normal";
                    };

                    $scope.isGlobalTip = function (tip) {
                        return tip.kind === "global";
                    };

                    $scope.dismissTip = function (tip) {
                        //assume success
                        delete $scope.dismissableTip;
                        // SLEDGE HAMMER FOR JACKED UP BOOTSTRAP TOOOLTIPS: that leave dangling tips. sounds like a personal problem.
                        $('.tooltip').remove();
                        var postPayload = {
                            tip_id: tip.id,
                            read: true
                        };
                        $http.post($scope.dismissURL, postPayload).success(function (data) {
                            //assume success
                        }).error(function (data) {
                            //for now this is silent-ly failing to the user
                            $scope.dismissableTip = $scope.dismissableTips[0];
                        });
                    };

                    $scope.getContent = function (tip) {
                        return $sce.trustAsHtml(tip.content);
                    };

                    $timeout(function () {
                        $scope.activeTips = $scope.tips.filter($scope.showTip);
                        $scope.specialTips = $scope.activeTips.filter($scope.isSpecialTip).sort(function (a, b) {
                            return b.priority - a.priority;
                        });
                        $scope.normalTips = $scope.activeTips.filter($scope.isNormalTip).sort(function (a, b) {
                            return b.priority - a.priority;
                        });
                        $scope.globalTips = $scope.activeTips.filter($scope.isGlobalTip).sort(function (a, b) {
                            return b.priority - a.priority;
                        });
                        $scope.dismissableTips = $scope.globalTips.concat($scope.normalTips);
                        $scope.dismissableTip = $scope.dismissableTips[0];
                    }, _FADE_IN_TIME);
                }
            ],
            link: function (scope, element) {
                //todd told me to do this
                element.on("click", '.post-link', function (e) {
                    e.preventDefault();
                    link = $(e.target).data("link");
                    $http({
                        url: link,
                        method: "post"
                    }).success(function (data) {
                        if (data.cookies) {
                            $("#tip-" + data.id).remove();
                        } else {
                            artMessage.showInfo("Verification email sent!");
                        }
                    }).error(function (data) {
                        artMessage.showAlert("Uh oh :( Something went wrong - please reload the page");
                    });
                });
            },
            templateUrl: "partials/art/tips.partial.html"
        };
        return directiveDefinitionObject;
    }]);
