creatorModule.directive("ctName", function(){

    var ctName = {};

    ctName.restrict = 'A';
    ctName.priority = -1;
    ctName.require = 'ngModel';

    ctName.link = function(scope, element, attrs, ctrl){

        element.attr("name", attrs.ctName);
        ctrl.$name = attrs.ctName;

    };

    return ctName;

});
