neonmobApp.directive('nmMilestonesList', function(){
    
    var directiveDefinitionObject = {};

    directiveDefinitionObject.controller = "MilestonesListController";

    directiveDefinitionObject.templateUrl = "partials/badges/milestones-list.partial.html";

    return directiveDefinitionObject;

});
