artModule.provider("artUploader", function () {
	var _transloaditOptions = {};

	this.registerTransloaditOptions = function (name, transloaditOptions) {
		var options = angular.extend(transloaditOptions, {
			modal: false,
			autoSubmit: false
		});
		_transloaditOptions[name] = options;
	};

	this.$get = [
		"$q",
		"$window",
		"artResource",
		"artProgress",
        "artMessage",
		function ($q,
		          $window,
		          artResource,
		          artProgress,
                  artMessage) {

			var _uploadCount = 0;

			function _Uploader(form, options) {
				var self = this;

				this._form = form;
				this._transloaditUploader = null;
				this._deferred = $q.defer();
				this._progressId = null;

				this._options = angular.extend({
					onProgress: function (bytesRecieved, bytesExpected) {
						artProgress.update(self._progressId, Math.floor(100 * bytesRecieved / bytesExpected));
					},
					onError: function () {
						self._deferred.reject();
						artProgress.end(self._progressId);
						self = null;
                        artMessage.showAlert("Sorry, your file could not be uploaded. Please try again.");
					},
					onCancel: function () {
						self._deferred.reject();
						artProgress.end(self._progressId);
						self = null;
					}
				}, options);

				//local dev settings
				if (this._options.processUrl) {
					this._options.wait = true;
					this._options.onSuccess = function (assembly) {
						artResource.create(self._options.processUrl, {
							"transloadit": JSON.stringify(assembly)
						});
						self._deferred.resolve(assembly);
						artProgress.end(self._progressId);
						self = null;
					};

				} else { //prod/staging settings
					this._options.onSuccess = function (assembly) {
						self._deferred.resolve(assembly);
						artProgress.end(self._progressId);
						self = null;
					};
				}
				this.fileCount = $(this._form).children("input[type=file]").get(0).files.length;
			}

			_Uploader.prototype.upload = function (progressId) {
				this._progressId = progressId;
				artProgress.start(this._progressId);

				//for testing without using transloadit
				if (this._options.uploadUrl) {
					var self = this,
						formData = new FormData(this._form.get(0));

					artResource.create(this._options.uploadUrl, formData)
						.then(self._options.onSuccess.bind(self))
						.catch(self._options.onError.bind(self));
				} else {
					this._transloaditUploader = $(this._form).transloadit(this._options).submit().unbind("submit.transloadit").data("transloadit.uploader");
				}

				//Keep a count of how many uploads are going on so if the user tries to leave, we can warn them.
				_uploadCount += 1;
				this._deferred.promise['finally'](function () {
					_uploadCount--;
				});

				return this._deferred.promise;
			};

			_Uploader.prototype.cancel = function () {
				if (this._transloaditUploader) {
					this._transloaditUploader.cancel();
				}
			};

			_Uploader.prototype.isUploading = function () {
				return artProgress.isActive(this._progressId);
			};

			var artUploader = function (form, optionsName) {
				return new _Uploader(form, _transloaditOptions[optionsName]);
			};

			artUploader.NULL_UPLOADER = {
				start: function () {},
				cancel: function () {},
				isUploading: function () { return false; },
				fileCount: 0
			};

			angular.element($window).bind("beforeunload", function () {
				if (_uploadCount > 0) {
					return "You are currently uploading files. Are you sure you want to leave and cancel the upload?";
				}
			});
			return artUploader;
		}];

});