artModule.factory("artResource", [
    "$http",
    "$q",
    function ($http,
              $q) {

        var artResource = {};

        function _whenHttp(httpPromise) {
            var defer = $q.defer();

            httpPromise
                .success(defer.resolve)
                .error(function (data, status) {
                    var error;
                    switch (status) {
                        case 400:
                            error = {
                                type: artResource.INVALID,
                                fields: data,
                                detail: "Oops! Could not save!"
                            };
                            break;
                        case 403:
                            error = {
                                type: artResource.NOT_AUTHORIZED,
                                detail: "Sorry, you're not authorized. Make sure you are logged in to the right account!"
                            };
                            break;
                        case 404:
                            error = {
                                type: artResource.NOT_FOUND,
                                detail: "We couldn't find what you're looking for. Please refresh the page or contact support@neonmob.com"
                            };
                            break;
                        case 503:
                            error = {
                                type: artResource.TIMED_OUT,
                                detail: "Our servers are a little tuckered out. Please try again!"
                            };
                            break;
                        default :
                            error = {
                                type: artResource.UNKNOWN,
                                fields: data,
                                detail: "Oops! Something bad happened! Please refresh the page or contact support@neonmob.com"
                            };
                    }
                    defer.reject(error);
                });
            return defer.promise;
        }

        artResource.INVALID = 0;
        artResource.NOT_AUTHORIZED = 1;
        artResource.NOT_FOUND = 2;
        artResource.TIMED_OUT = 3;
        artResource.UNKNOWN = 4;
        artResource.PRECONDITION_FAILED = 5;

        function _uploadFormData(url, method, formData) {
            return _whenHttp($http({
                url: url,
                method: method,
                data: formData,
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }));
        }

        artResource.create = function (url, obj) {
            if (obj instanceof FormData) {
                return _uploadFormData(url, "post", obj);
            }

            var promise = _whenHttp($http.post(url, obj)).then(function (newObj) {
                return newObj;
            });
            return promise;
        };

        artResource.retrieve = function (url, config) {
            promise = _whenHttp($http.get(url, config || {}));
            return promise;
        };

        artResource.retrievePaginated = function (url) {
            return _whenHttp($http.get(url)).then(function (data) {
                var results = data.results;
                results.next = data.next;
                if (data && data.sett) {
                    results.sett = data.sett;
                    results.full_cta_flag = data.full_cta_flag;
                }
                results.retrieveNext = function () {
                    if (!results.next) {
                        return null;
                    }

                    return _whenHttp($http.get(results.next)).then(function (data) {
                        results.push.apply(results, data.results);
                        results.next = data.next;
                        return results;
                    });
                };

                results.count = data.count;
                return results;
            });
        };

         artResource.retrievePaginatedAllowCancel = function (config) {
            return _whenHttp($http(config)).then(function (data) {
                var results = data.results;
                results.next = data.next;

                results.retrieveNext = function () {
                    if (!results.next) {
                        return null;
                    }

                    return _whenHttp($http.get(results.next)).then(function (data) {
                        results.push.apply(results, data.results);
                        results.next = data.next;
                        return results;
                    });
                };

                results.count = data.count;
                return results;
            });
        };

        artResource.update = function (obj, url) {
            if (obj instanceof FormData) {
                if (!url) {
                    throw new TypeError("a url must be passed in when submitting formdata.");
                }
                return _uploadFormData(url, "put", obj);
            }

            var promise = _whenHttp($http.put(obj.url || url, obj));
            return promise;
        };

        artResource.destroy = function (obj) {
            return _whenHttp($http['delete'](obj.url));
        };

        return artResource;
    }]);
