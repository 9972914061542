artModule.service('artShowRewards', [
    'artAnalytics',
    'artOverlay',
function(
    artAnalytics,
    artOverlay
){
    return function( type ){
        artAnalytics.track("Viewed Rewards", type ? {type: type} : null);

        artOverlay.bindNameToTemplateUrl("rewards", "partials/rewards/overlay-rewards.partial.html", "modal");

        return artOverlay.show("rewards",null,true);
    };
}]);
