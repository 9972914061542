artModule.directive('artSetHeader', function () {

    var directiveDefinitionObject;
    directiveDefinitionObject = {
        scope: {
            sett: "=artSetHeader",
            user: "=?user"
        },
        controller: [
            '$scope',
            '$window',
            '$filter',
            'artUser',
            'artSett',
            'artOverlay',
            function ($scope,
                      $window,
                      $filter,
                      artUser,
                      artSett,
                      artOverlay) {

                $scope.toggleFavoriteSett = artSett.toggleFavoriteSett.bind(artSett);

                $scope.isLimitedRelease = artSett.isLimitedRelease.bind(artSett);

                $scope.isExclusive = artSett.isExclusive.bind(artSett);

                $scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);

                $scope.canFavorite = artUser.canIDo('favorite');

                if ($scope.isAuthenticated()) {
                    $scope.loggedInUserId = artUser.getId();
                }

                $scope.getPromoteText = function () {
                    if (!$scope.sett) return;
                    if ($scope.sett.creator.twitter_username) {
                        creatorName = "@" + $scope.sett.creator.twitter_username;
                    } else {
                        creatorName = $scope.sett.creator.name;
                    }
                    return "Check out this incredible digital art series by " + creatorName + " on @NeonMob";
                };

                $scope.gotoCollection = function () {
                    $window.location.href = "/" + $scope.user.username + "/collection";
                }

                $scope.getCollectionUserName = function () {
                    if ($scope.loggedInUserId === $scope.user.id) {
                        return 'Your';
                    }
                    return $filter('titleCase')($scope.user.first_name) + '\'s';
                }
                $scope.switchSeriesOverlay = function () {
                    artOverlay.bindNameToTemplateUrl("switch-series", "/static/common/component/partial/switch-series-overlay.partial.html", "modal");
                    artOverlay.updateTemplateContext("switch-series", {"user": $scope.user})
                    artOverlay.show("switch-series", null, true, 'theme-light', true);
                }

            }],
        templateUrl: "partials/art/set-header.partial.html"
    };

    return directiveDefinitionObject;
});
