artModule.controller('artRewardsController', [
    '$scope',
    '$timeout',
    'artUrl',
    'artUser',
    'artConfig',
    'artResource',
    'artMessage',
function (
    $scope,
    $timeout,
    artUrl,
    artUser,
    artConfig,
    artResource,
    artMessage
){

    $scope.invitedContactState = {
        email: null,
        buttonText: 'Send',
        formEnabled: true
    };

    $scope.twitterMessage = "Want to join me in collecting digital art? 👀 Sign up with my link to start for FREE and get 100,000 carats when you reach level 5! Let's collect on #NeonMob together! 👉 ";
    $scope.facebookMessage = "Want to join me in collecting digital art? 👀 Sign up with my link to start for FREE and get 100,000 carats when you reach level 5! Let's collect on #NeonMob together! 👉 ";
    $scope.copyLinkButtonText = 'Copy Link';
    $scope.referralUrl = artUrl.addDomain(artUser.getUrl("referral_url"));

    var usersToInvite = [];
    $scope.inviteTypedInContact = function () {
        $scope.invitedContactState.formEnabled = false;
        $scope.invitedContactState.buttonText = "Sending...";
        usersToInvite = [{
            email: $scope.invitedContactState.email,
            firstName: null
        }];

        cleanInvites();
        sendInvites().then(function () {
            $scope.invitedContactState = {
                email: null,
                buttonText: 'Sent!',
                formEnabled: false
            };
            $timeout(function() {
                $scope.invitedContactState = {
                    email: null,
                    buttonText: 'Send',
                    formEnabled: true
                };
            }, 2000);
        });
    };


    function cleanInvites() {
        var a = usersToInvite.concat();
        var emails = {}; // for de-dupe

        usersToInvite = a.reduce(function (accum, user) {
            if (!user.email) return accum;

            user.email = user.email.toLowerCase().trim();
            if (emails[user.email]) return accum; // found a duplicate

            accum.push(user);
            emails[user.email] = true;
            return accum;
        }, []);
    }

    function sendInvites() {
        if (usersToInvite.length === 0) return;

        return artResource.create(artConfig.api['api-invite-contacts'], {
            invites: usersToInvite
        }).then(function () {
            var amtUsersInvited = usersToInvite.length;
            usersToInvite = [];

            artMessage.showInfoAutoClose({
                message: "Yay! You've invited " + amtUsersInvited + " friend" + (amtUsersInvited>1? 's':'') + " to Neonmob.",
                closeMs: 3000
            });
        });
    }


    $scope.afterUrlCopy = function () {
        $scope.textToCopy = $scope.referralUrl;
        $scope.copyLinkButtonText = 'Copied!';
        $timeout(function () {
            $scope.copyLinkButtonText = 'Copy Link';
        }, 2000);
    };

}]);
