artModule.controller('artStreakController',['$scope',
    'artShowStreaks',
    'artUser',
    function(
    $scope,
    artShowStreaks,
    artUser){
    
    $scope.creditPassActive = artUser.isCreditPassActive();
    $scope.streakDetail = artShowStreaks.getStreaksDetail();

    // Update this in tandem with art/tasks.py
    // Day 1: one freebie
    // Day 2-6: two freebies
    // Day 7-29: three freebies
    // Day 30-89: four freebies
    // Day 90-364: five freebies
    // Day 365+: six freebies
    $scope.getStreakRewardsForDay = function(dayNumber) {
        if (dayNumber <= 1) {
            return '1';
        } else if (dayNumber < 7) {
            return '2';
        } else if (dayNumber < 30) {
            return '3';
        } else if (dayNumber < 90) {
            return '4';
        } else if (dayNumber < 365 ) {
            return '5';
        } else {
            return '6';
        }
    }

}]);
