creatorModule.directive("ctGeneratePackPromo", function(){
    var ctGeneratePackPromo = {};
    ctGeneratePackPromo.scope = {
        sett:"=ctGeneratePackPromo",
    };

    ctGeneratePackPromo.controller = [
        '$scope',
        'artOverlay',
        'ctSelectedSett',
    function(
        $scope,
        artOverlay,
        ctSelectedSett
    ){
        artOverlay.bindNameToTemplateUrl("newPromoCode", "partials/creator/promocodes/pack-promo-new.modal.html", "modal");

        $scope.openPackPromoModal = function(){
            artOverlay.show("newPromoCode");
            ctSelectedSett.setSelectedSett($scope.sett);

        };
    }];

    ctGeneratePackPromo.templateUrl = "partials/creator/promocodes/generate-pack-promo-button.partial.html";

    return ctGeneratePackPromo;
});
