neonmobApp.directive('packMessage', function(){
    var directiveDefinitionObject;

    directiveDefinitionObject = {
        scope: {
            pack  :"=packMessage"
        },
        
        controller: [
            '$scope',
        function(
            $scope
        ){

            if ($scope.pack.bonus_type == "bonus") {
                $scope.show_message = true;
                $scope.message = "a Super Pack!";
            } else if ($scope.pack.bonus_type == "remainder") {
                $scope.show_message = true;
                $scope.message = "the last pack!";
            } else {
                $scope.show_message = false;
            }

        }],
        
        templateUrl: "partials/pack-open/pack-message.partial.html"
    };

    return directiveDefinitionObject;

});