artModule.controller('artCollectionViewController', [
	'$scope',
	'artUser',
	'artUrl',
	'CollectionService',
	'artResource',
	'ProfileUser',
	function (
		$scope,
		artUser,
		artUrl,
		CollectionService,
		artResource,
		ProfileUser) {

		$scope.user = artUser.toObject();
		$scope.footerFlag = true;
		$scope.expanded = false;
		$scope.canFavorite = artUser.canIDo('favorite');

		var baseUrl;

		function getUrl() {

			return artUrl.updateParams(baseUrl, $scope.activeFilters);
		};

		ProfileUser.fetchUser().then(function(response) {
            $scope.targetUser = response.data;
        });

		$scope.load = function() {

			$scope.loading = true;
            $scope.loadingMore = false;
			$scope.activeFilters = CollectionService.activeCollectionViewFilters();

			// [FIXME] Seperate the API request for getting target id
			ProfileUser.fetchUser().then(function(response){

	            var profile = response.data;
	            baseUrl = '/api/user/collections/' + profile.id + '/';

				artResource.retrievePaginated(getUrl()).then(function(data){

					$scope.userCollectedSetts = data;
					$scope.loading = false;
				});
	        });
		};

		$scope.getNextPage = function() {

			if($scope.loading || $scope.loadingMore || !$scope.userCollectedSetts.next){
                return;
            }
            // $scope.loading = true;
            $scope.loadingMore = true;
			$scope.userCollectedSetts.retrieveNext().then(function(data){

	            // $scope.loading = false;
	            $scope.loadingMore = false;
	        });
		};

		$scope.selectSortOption = function(selectedSort, event) {

			if (selectedSort && selectedSort.clickCount) {
				return;
			}

			$scope.selectedSortObj = selectedSort;
			$scope.selectedSortName = selectedSort.name;

			CollectionService.updateActiveFilter(selectedSort);

			$scope.load();
		};

		$scope.toggleSort = function(event) {


			if(event) {

				event.stopPropagation();
			}

			if (!$scope.selectedSortObj) {

				return;
			}

			CollectionService.updateActiveFilter($scope.selectedSortObj);

			if (!$scope.selectedSortObj.clickCount) {

				$scope.selectSortOption($scope.collectionViewSort[0])
			}

			$scope.load();
		};

		$scope.handleClick = function($event) {
            $event.stopPropagation();
			$scope.expanded = !$scope.expanded;
		};

		$scope.getEmptyStateMessage = function() {

			var message,
				activeFilters = $scope.activeFilters,
				activeFiltersCount = 0;

			if(!$scope.userCollectedSetts) return;

			if(activeFilters.favorite) {
				message = "Looks like you don’t have any favorites. Click the <i>⭐</i> on any series to add it to your favorites!";
				activeFiltersCount++;
			}

			if (activeFilters.complete == 'Incomplete') {

				message = "Wow, you don’t have any incomplete series! Why don’t you start collecting a new one? <i>🤔</i>";
				activeFiltersCount++;
			}

			if(activeFilters.complete =='Completed') {

				message = " You haven’t completed any series <i>😢</i>.  Have you tried trading? You’ll get there soon!";
				activeFiltersCount++;
			}

			if(activeFilters.available == 'Available') {

				message = "You have no series with packs available to open <i>😢</i>. Go discover some new ones!";
				activeFiltersCount++;
			}

			if(activeFilters.available == 'Unavailable') {

				message = "Lucky you <i>🤩</i>, all of your series are still available! Go collect them while they last!";
				activeFiltersCount++;
			}

			if(activeFiltersCount > 1) {
				message = "No luck <i>😯</i>! None of these filters matches your criteria. Try changing the filters or collecting something new!";
			}

			return message;
		};

		init();

		function init() {
			// Construct filter object
			$scope.collectionViewFilters = CollectionService.createCollectionViewFilters();
			$scope.collectionViewSort = CollectionService.createCollectionViewSort();
			$scope.selectSortOption($scope.collectionViewSort[0])
			$scope.load();
		};
	}
]);