artModule.factory('artResponsive', [
    "$rootScope",
    "$window",
function(
    $rootScope,
    $window
){

    var artResponsive = {};

    artResponsive.BREAKPOINTS = {

        MOBILE_SMALL:320,
        MOBILE_LARGE:640

    };

    artResponsive.WINDOW_VISIBLE = false;

    // http://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
    (function() {
        var hidden = "hidden";

        // Standards:
        if (hidden in $window.document) {
            $window.document.addEventListener("visibilitychange", onVisibilityChange);
        }
        else if ((hidden = "mozHidden") in $window.document) {
            $window.document.addEventListener("mozvisibilitychange", onVisibilityChange);
        }
        else if ((hidden = "webkitHidden") in $window.document) {
            $window.document.addEventListener("webkitvisibilitychange", onVisibilityChange);
        }
        else if ((hidden = "msHidden") in $window.document) {
            $window.document.addEventListener("msvisibilitychange", onVisibilityChange);
        }
        // IE 9 and lower:
        else if ("onfocusin" in $window.document) {
            $window.document.onfocusin = $window.document.onfocusout = onVisibilityChange;
        }
        // All others:
        else {
            $window.onpageshow = $window.onpagehide = $window.onfocus = $window.onblur = onVisibilityChange;
        }

        function onVisibilityChange (evt) {
            var evtMap = {
                focus: true, focusin: true, pageshow: true,
                blur: false, focusout: false, pagehide: false
            };

            evt = evt || $window.event;
            if (evt.type in evtMap) {
                artResponsive.WINDOW_VISIBLE = evtMap[evt.type];
            }
            else {
                artResponsive.WINDOW_VISIBLE = !this[hidden];
            }
        }

        // set the initial state (but only if browser supports the Page Visibility API)
        if( $window.document[hidden] !== undefined ) {
            onVisibilityChange({type: $window.document[hidden] ? "blur" : "focus"});
        }
    })();

    artResponsive.isTouchDevice = function(){

        return !!('ontouchstart' in window);

    };

    artResponsive.inView =function(element){

        element = element.get(0);

        if( element.getClientRects().length > 0 ){

            var clientRect = element.getClientRects()[0],
                height = clientRect.bottom - clientRect.top,
                width = clientRect.right - clientRect.left;

            return clientRect.bottom > height && clientRect.top < artResponsive.getWindowHeight() - height &&
                   clientRect.right > width && clientRect.left < artResponsive.getWindowWidth() - width;

        } else {

            return false;

        }

    };

    artResponsive.isMobileLarge = function(){

        return artResponsive.getWindowWidth() <= artResponsive.BREAKPOINTS.MOBILE_LARGE;

    };

    artResponsive.isMobileSmall = function(){

        return artResponsive.getWindowWidth() <= artResponsive.BREAKPOINTS.MOBILE_SMALL;

    };

    artResponsive.onResize = function(callback){

        return $rootScope.$on("artResponsive:resize", callback);

    };

    artResponsive.onScroll = function(callback){

        return $rootScope.$on("artResponsive:scroll", callback);

    };

    artResponsive.getWindowWidth = function(){

        return $window.innerWidth;

    };

    artResponsive.getWindowHeight = function(){

        return $window.innerHeight;

    };

    function _cloneAndSortNumerically(array){

        array = array.slice(0);
        array.sort();
        return array;

    }

    artResponsive.getCurrentBreakPointMinMax = function(breakpoints){

        var index = _getCurrentBreakpointIndex(_cloneAndSortNumerically(breakpoints));

        var min = index === -1 ? 0:breakpoints[index];
        var max = index === breakpoints.length-1 ? Number.POSITIVE_INFINITY : breakpoints[index+1];

        return [min, max];

    };

    artResponsive.applyAtBreakpoints = function(scope, breakpoints){

        breakpoints = _cloneAndSortNumerically(breakpoints);

        var _currentBreakPointIndex = _getCurrentBreakpointIndex(breakpoints);

        var _unregisterWidthChange = artResponsive.onResize(function(){

            var newBreakPointIndex = _getCurrentBreakpointIndex(breakpoints);

            if(newBreakPointIndex !== _currentBreakPointIndex){

                scope.$apply();

            }

            _currentBreakPointIndex = newBreakPointIndex;

        });

        scope.$on("$destroy", function(){

            _unregisterWidthChange();

        });

    };

    artResponsive.invokeAtBreakpoints = function( method, breakpoints ) {

        if (!angular.isFunction(method)) {

            throw new TypeError("the first parameter needs to be a function");

        }

        breakpoints = _cloneAndSortNumerically(breakpoints);

        var _currentBreakPointIndex = _getCurrentBreakpointIndex(breakpoints);

        var _unregisterWidthChange = artResponsive.onResize(function(){

            var newBreakPointIndex = _getCurrentBreakpointIndex(breakpoints);

            if(newBreakPointIndex !== _currentBreakPointIndex){

                if (method) {
                    method();
                }

            }

            _currentBreakPointIndex = newBreakPointIndex;

        });

        return _unregisterWidthChange();

    };

    function _getCurrentBreakpointIndex(breakpoints){

        var currentWidth = artResponsive.getWindowWidth();

        if(currentWidth <= breakpoints[0]){

            return -1;

        }

        var i;

        for(i=0; i < breakpoints.length-1; i++){

            if(currentWidth > breakpoints[i] && currentWidth <= breakpoints[i+1]){

                return i;

            }

        }

        return i;

    }

    function _resize(ev){

        $rootScope.$broadcast("artResponsive:resize", $window.innerWidth, $window.innerHeight);

    }

    function _scroll(ev){

        $rootScope.$broadcast("artResponsive:scroll");

    }

    //HACK - Tests do not work with $window binding to the resize event.
    //I'm not sure why, so we should inviestigate this later.
    $(window).resize(_resize);
    $(window).scroll(_scroll);

    return artResponsive;

}]);
