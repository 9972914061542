/**
 * This is a general purpose resource grabber. We should work on this to get resources and to resolve either
 * a url or to see if the url is actually a hidden input in the page. We can also add caching to this.
 */
artModule.factory("artResourceService", [
    "$http",
    "$timeout",
    "$q",
    "artJson",
function(
    $http,
    $timeout,
    $q,
    artJson
){

	var _cacheData = {},
        _cachePromise = {},
        _CACHE_TIME = 60*1000;

    function _cache(id, data){

        if(_cachePromise[id]){

            $timeout.cancel(_cachePromise[id]);

        }

        _cachePromise[id] = $timeout(function(){

            delete _cacheData[id];
            delete _cachePromise[id];

        }, _CACHE_TIME);

        _cacheData[id] = data;

    }

    function _getCache(id){

        return _cacheData[id] || null;

    }

    function _HttpPromise(promise){

        this.success = function(callback){

            return new _HttpPromise(promise.then(callback));

        };

        this.error = function(callback){

            return new _HttpPromise(promise['catch'](callback));

        };

        this['catch'] = function(callback){

            return new _HttpPromise(promise['catch'](callback));

        };

        this.then = function(success, error, notify){

            return new _HttpPromise(promise.then(success, error, notify));

        };

        this['finally'] = function(callback){

            return new _HttpPromise(promise['finally'](callback));

        };

    }

	return {

		clear_cache:function(){

            _cacheData = {};
            _cachePromise = {};

        },

		/**
		 * Deprecated. This is not a good way to go.
		 */
		get_url:function(url_id){
			return $("#"+url_id).val();
		},

        /**
         * This will replace get once I get to that refactor!
         * @param {String} resourceId
         * @param {Boolean} [cache=false]
         */
        get:function(resourceId, cache){

            var defer = $q.defer(),
                data = null;

            if(cache){

                data = _getCache(resourceId);

            }


            if(data === null){

                try {
                    input = $("#"+resourceId).val();
                }
                catch(e) {

                    input = null;

                }

                if(input && input.length > 0){ //local

                    try{

                        data = artJson.parse(input);

                        if(cache){

                            _cache(resourceId, data, _CACHE_TIME);

                        }

                        defer.resolve(data);

                    } catch(e){

                        defer.reject({type: 'parse-error', error: e});

                    }


                } else { //http

                    ajax = $http.get(resourceId).success(function(data){

                        if(cache){

                            _cache(resourceId, data, _CACHE_TIME);

                        }

                        defer.resolve(data);

                    });

                    ajax.error(function(data, status, headers, config ) {

                        defer.reject({
                            type: 'http-error',
                            error: status,
                            data: data,
                            headers: headers,
                            config: config
                        });

                    });

                }

            } else {

                defer.resolve(data);

            }

            return new _HttpPromise(defer.promise);

        }

	};

}]);