artModule.factory("artActivityFeedFactory", [
    "$location",
    "artUser",
    "artPieceService",
    function ($location,
              artUser,
              artPieceService) {

        var noop = function () {};

        var currentUrl = $location.protocol() + "://" + $location.host();
        var currentPort = $location.port();
        if(currentPort && !~[80, 443].indexOf(currentPort))
            currentUrl += ":" + currentPort;

        var storyOwnershipProcessors = {
            'submission-created': noop,
            'badge-earned': noop,
            'pack-opened': packOpenedOwnership,
            'trade-completed': tradeCompletedOwnership,
            'sett-published': noop
        };

        function packOpenedOwnership(story) {
            var piece = story.rarest_piece;

            if (artPieceService.hasPiece(artUser, piece.id))
                piece.$imageUrl = piece.owned_image_url;
            else
                piece.$imageUrl = piece.not_owned_image_url;
        }

        function tradeCompletedOwnership(story) {
            var bidderPiece = story.bidder.rarest_piece;
            var responderPiece = story.responder.rarest_piece;

            // piece_id's are null if this is a pack
            if (bidderPiece.piece_id && artPieceService.hasPiece(artUser, bidderPiece.piece_id))
                bidderPiece.$imageUrl = bidderPiece.owned_image_url;
            else
                bidderPiece.$imageUrl = bidderPiece.not_owned_image_url;

            if (responderPiece.piece_id && artPieceService.hasPiece(artUser, responderPiece.piece_id))
                responderPiece.$imageUrl = responderPiece.owned_image_url;
            else
                responderPiece.$imageUrl = responderPiece.not_owned_image_url;
        }

        function setMasonryFeedOwnership(arr) {
            var e = {};
            for (var i = 0, c = arr.length; i < c; i++) {
                for (var j = 0, d = arr[i].length; j < d; j++) {
                    e = arr[i][j];
                    storyOwnershipProcessors[e.type](e);
                }
            }
        }

        var compactViewImageWidth = 300;

        var compactViewImageHeights = {
            "submission-created": function () {
                return 300;
            },
            "badge-earned": function () {
                return Math.round(480 * (compactViewImageWidth / 480));
            },
            "pack-opened": function (item) {
                var piece = item.rarest_piece;
                return Math.round(piece.height * (compactViewImageWidth / piece.width));
            },
            "sett-published": function (item) {
                return Math.round(item.height * (compactViewImageWidth / item.width));
            },
            "trade-completed": function (item) {
                var piece = item.bidder.rarest_piece || item.responder.rarest_piece;
                if (!piece) return 350;

                return Math.round(piece.height * (compactViewImageWidth / piece.width));
            }
        };

        function processStories(arr) {
            artPieceService.syncOwnership(artUser);

            var e = {};
            for (var i = 0, c = arr.length; i < c; i++) {
                e = arr[i];
                storyOwnershipProcessors[e.type](e);
                if (e.num_prints) e.num_prints--; // -1 for the hero image

                e.$imageWidth = compactViewImageWidth;
                e.$imageHeight = compactViewImageHeights[e.type](e);
                e.$height = e.$imageWidth + 54;

                // make sure detail urls are fully qualified
                if(!e.detail_url || ~e.detail_url.indexOf('http')) continue;
                e.detail_url = currentUrl + e.detail_url;
            }
        }

        var fullViewImageWidth = 480;
        var fullViewSmallerImageWidth = 160;

        function processPackOpenDetails(data, selectedPackId) {
            data.user.avatar = {
                small: data.user.avatar_url
            };

            var pack, piece, rarest_piece, height, width;
            for (var i = 0, c = data.packs.length; i < c; i++) {
                pack = data.packs[i];

                pack.pieceIds = [];
                pack.focused = pack.pack_id == selectedPackId;

                if (pack.rarest_piece) {
                    rarest_piece = pack.rarest_piece;
                    rarest_piece.id = rarest_piece.piece_id;
                    pack.pieceIds.push({id: rarest_piece.piece_id});

                    rarest_piece.$imageWidth = fullViewImageWidth;
                    height = rarest_piece.large_image.height;
                    width = rarest_piece.large_image.width;

                    if (rarest_piece.asset_type === 'video') {
                        height = rarest_piece.large_video.height;
                        width = rarest_piece.large_video.width;
                    }

                    rarest_piece.$imageHeight = Math.round(height * (fullViewImageWidth / width));
                }

                for (var j = 0, d = pack.pieces.length; j < d; j++) {
                    piece = pack.pieces[j];
                    piece.id = piece.piece_id;
                    pack.pieceIds.push({id: piece.piece_id});

                    piece.$imageWidth = fullViewSmallerImageWidth;
                    height = piece.small_image.height;
                    width = piece.small_image.width;

                    if (piece.asset_type === 'video') {
                        height = piece.small_video.height;
                        width = piece.small_video.width;
                    }
                    piece.$imageHeight = Math.round(height * (fullViewSmallerImageWidth / width));
                }
            }
        }

        function processTradeDetails(data) {
            var setupRole = function (role) {

                var item, rarest_piece, height, width;
                role.user.avatar = {
                    small: role.user.avatar_url
                };

                if (!role.pieceIds) {
                    role.pieceIds = [];

                    if (role.rarest_piece) {
                        rarest_piece = role.rarest_piece;
                        rarest_piece.id = rarest_piece.piece_id;

                        if (role.rarest_piece.piece_id)
                            role.pieceIds.push({id: rarest_piece.piece_id});

                        rarest_piece.$imageWidth = fullViewImageWidth;
                        height = rarest_piece.large_image.height;
                        width = rarest_piece.large_image.width;

                        if (rarest_piece.asset_type === 'video') {
                            height = rarest_piece.large_video.height;
                            width = rarest_piece.large_video.width;
                        }

                        rarest_piece.$imageHeight = Math.round(height * (fullViewImageWidth / width));
                    }
                }

                for (var i = 0, c = role.items.length; i < c; i++) {
                    item = role.items[i];

                    item.$imageWidth = fullViewSmallerImageWidth;
                    height = item.small_image.height;
                    width = item.small_image.width;

                    if (item.asset_type === 'video') {
                        height = item.small_video.height;
                        width = item.small_video.width;
                    }
                    item.$imageHeight = Math.round(height * (fullViewSmallerImageWidth / width));

                    if (!item.piece_id) continue;

                    item.id = item.piece_id;
                    role.pieceIds.push({id: item.piece_id});
                }
            };
            setupRole(data.bidder);
            setupRole(data.responder);
        }

        return {
            setMasonryFeedOwnership: setMasonryFeedOwnership,
            processStories: processStories,
            processPackOpenDetails: processPackOpenDetails,
            processTradeDetails: processTradeDetails
        };
    }]);
