neonmobApp.directive('nmMilestonesSeries', function() {

    var directiveDefinitionObject = {};

    directiveDefinitionObject.controller = "MilestonesListController";

    directiveDefinitionObject.templateUrl = "partials/badges/milestones-series.partial.html";

    return directiveDefinitionObject;

});
