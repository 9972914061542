artModule.directive('artShowCarats', function() {

	var directiveDefinitionObject;

	directiveDefinitionObject = {

		controller: [
			'$scope',
			'artShowCarats',
		function(
			$scope, 
			artShowCarats
		){
			$scope.showCarats = artShowCarats.bind(artShowCarats);
		}],

		link: function(scope, element){

			angular.element(element).click(function(){
				scope.showCarats();
				scope.$apply();
			})
		}
	};

	return directiveDefinitionObject;
});