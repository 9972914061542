artModule.directive('artSet', [function () {
    var directiveDefinitionObject;

    directiveDefinitionObject = {
        scope: {
            set: "=artSet",
            size: "=?artSetSize"
        },
        controller: [
            '$scope',
            function ($scope) {
                $scope.pack_count = 1;
                $scope.show_count = false;
                $scope.type = "set";
                $scope.size = $scope.size || "small";
            }],
        templateUrl: "partials/art/set.partial.html",
        replace: true
    };
    return directiveDefinitionObject;
}]);
