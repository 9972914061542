neonmobApp.controller('nmPublicPieceDetailController', [
    "$scope",
    "nmPieceDetailService",
    "artJson",
    "artUser",
function(
    $scope,
    nmPieceDetailService,
    artJson,
    artUser
){

    var _piece = artJson.parseFromInput('#piece-detail-piece'),
        _targetUser = artJson.parseFromInput('#piece-detail-target-user');

    $scope.piece = _piece;

    nmPieceDetailService.startPieceDetail(_targetUser, [_piece], _piece.id, false);

    if(!artUser.isAuthenticated()){ //always make our pieces public for unauthenticated users.
        nmPieceDetailService.makePublic();
    }

}]);