artModule.directive('artMessageNotification', function() {

    var artMessageNotification = {};

    artMessageNotification.scope = {

        message: '=artMessageNotification'

    };

    artMessageNotification.controller = [
        '$scope',
        'artConversationUtils',
        'artNotificationCenter',
        'artUser',
    function(
        $scope,
        artConversationUtils,
        artNotificationCenter,
        artUser
    ) {

        $scope.getRecipient = function(){

            return artConversationUtils.getRecipient($scope.message.object.users);

        };

        $scope.getMessage = function(){

            return $scope.message.actor.action_data;

        };

        $scope.getTimeStamp = function(){
            return $scope.message.actor.time;
        };

        $scope.isYourMessage = function(){

            return artUser.isYou( $scope.message.actor );

        };

        $scope.loadConversation = function(){
            var conversation = $scope.message.object;
            artNotificationCenter.show("conversation", {
                conversation:conversation,
                conversationNavState:"messages"
            });
            if(!conversation.read){
                artNotificationCenter.markNotificationsAsRead([$scope.message.id], "messages");
            }
        };

    }];

    artMessageNotification.templateUrl = 'partials/art/notifications/message-notification.partial.html';

    return artMessageNotification;

});
