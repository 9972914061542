creatorModule.controller("ctPackTemplateSelectionCtrl", [
    '$scope',
    '$filter',
    '$rootScope',
    'artUser',
    'ctSettCreator',
    'artConstants',
function(
    $scope,
    $filter,
    $rootScope,
    artUser,
    ctSettCreator,
    artConstants
){

    $scope.coreRarity = ctSettCreator.getCoreCounts();
    $scope.specialRarity = ctSettCreator.getSpecialCount();

    $scope.isPublished = ctSettCreator.getDisabledStatus();
    $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);

    $scope.showPackTemplatesDropDown = false;
    $scope.disableAddTemplateButton = false;
    $scope.packTierTemp = [];
    $scope.packTierTemp = ctSettCreator.getPackTemplates();
    $scope.sett = ctSettCreator.sett;

    // Pieces count
    $scope.corePiecesCount =  ctSettCreator.getNonChaseCount();
    $scope.specialPiecesCount =  ctSettCreator.getNonCoreCount();
    $scope.totalPiecesCount = $scope.corePiecesCount + $scope.specialPiecesCount;
    // Prints count
    $scope.totalCoreRarityPrints = 0;
    $scope.totalSpecialRarityPrints = 0;
    $scope.totalCoreAndSpecialPrints = 0;

    // One pack is 2 prints and .14 is super pack probability
    var PACK_TO_PRINTS_MULTIPLIER = artConstants.BONUS_PACK_FREQUENCY;
    var CREDIT_TO_CENTS = 7;
    var temp = 0;
    var _persistedPackTier = [];

    $scope.tempArr = $scope.coreRarity.concat($scope.specialRarity);

    $scope.returnAndEdit = function(piece) {
        $scope.$parent.goToEditor();
        ctSettCreator.setSelectedPiece(piece);
    };

    /*
    * Enable, disable Add template drop down.
    */
    $scope.getPackTierTemplate = function() {
        $scope.showPackTemplatesDropDown = !$scope.showPackTemplatesDropDown;
    }

    // todo: make it a utility. used in milestone service as well
    function isSpecialRarity(rarityName) {
        return ['chase', 'variant', 'legendary'].indexOf(rarityName) > -1;
    }

    /*
    * Calculate expected Print for each rarity, for each tier.
    */
    function _calculateExpectedPrint (totalPack, rarityOdd, count) {
        var expectedPrints = totalPack * rarityOdd * ((count + PACK_TO_PRINTS_MULTIPLIER) / 100);
        if(expectedPrints > 0)  {
            return Math.round(expectedPrints);
        }
        return 0;
    }
    /*
    * Round prints per card up or down.
    */
    function _round(value, roundUpFlag) {
        if (value < 5) {
            return Math.round(value);
        } else {
            // Rounding pushes 99.5 as 100 and not 95 as output from this function.
            value = Math.floor(value);
        }
        var factor = 1000,
            lengthOfValue = value.toString().length,
            quotient;

        switch(lengthOfValue) {
            case 1:
                factor = 5;
                break;
            case 2:
                factor = 5;
                break;
            case 3:
                factor = 10;
                break;
            case 4:
                factor = 100;
                break;
        }
        quotient = Math.floor(value / factor);
        if (roundUpFlag) {
            return (quotient + 1) * factor;
        } else {
            return (quotient) * factor;
        }
    }

    /*
    * Reset summery data when delete, add.
    */
    function _resetSummaryData() {
        angular.forEach($scope.tempArr, function(rarityData) {
            rarityData.totalPrints = null;
            rarityData.printPerPiece = 0;
            rarityData.actualPrints = 0;
            rarityData.imbalancedClass = "";
        });
        $scope.totalCoreRarityPrints = 0;
        $scope.totalSpecialRarityPrints = 0;
        $scope.totalCoreAndSpecialPrints = 0;
    }
    /*
    * To check rarities are balanced or imbalanced when clicking `Preview` button.
    */
    function _checkRarityImbalance() {
        var filteredRarity = $scope.coreRarity.filter(function(rarity) {
            return rarity.count !== 0 && rarity.printPerPiece > 0;
        });

        if(filteredRarity.length) {
            ctSettCreator.isPackTemplateSelected(true);
            ctSettCreator.setPackTemplateToPersist(filteredRarity, $scope.packTierTemp);
        }
        else {
            ctSettCreator.isPackTemplateSelected(false);
        }

    }

    /*
    * To update print counts in summary data.
    */
    function _adjustTotalPrintCountInSummaryData() {
        _resetSummaryData();
        var rarityNameTotalMap = {};
        angular.forEach($scope.packTierTemp, function(packTemplate, index) {
            if (!packTemplate.selected || !packTemplate.total_packs) {
                $scope.packTierTemp[index].allPrintCounts = null;
                return;
            }
            $scope.packTierTemp[index].allPrintCounts = 0;
            angular.forEach(packTemplate.partials, function(odd) {
                if (!rarityNameTotalMap[odd.name]) {
                    rarityNameTotalMap[odd.name] = 0;
                }
                rarityNameTotalMap[odd.name] += odd.actualPrint;
                $scope.packTierTemp[index].allPrintCounts += odd.actualPrint;
            });
        });
        // Calculate total prints per core and special rarity also total of both prints.
        angular.forEach($scope.tempArr, function(rarityData) {
            if (!rarityData.count){
                return;
            }
            rarityData.totalPrints = rarityNameTotalMap[rarityData.name];
            rarityData.printPerPiece = rarityData.totalPrints / rarityData.count;
            rarityData.actualPrints = rarityData.printPerPiece * rarityData.count;
            if (isSpecialRarity(rarityData.name)) {
                $scope.totalSpecialRarityPrints += rarityData.actualPrints;
            } else {
                $scope.totalCoreRarityPrints += rarityData.actualPrints;
            }
            $scope.totalCoreAndSpecialPrints += rarityData.actualPrints;
        });
        _checkRarityImbalance();
    };

    /*
    * To restrict fractions for Legendary `expected` or `actual` odds.
    * if value is less than 1 for Legendary, we should restrict fractions for 3 digits.
    * odd {object}
    * val {expected or actual percent}
    */
    $scope.restrictFractions = function (odd, val) {
        if(odd.name === 'legendary' && val < 1 && !Number.isInteger(val)) {
            return $filter('number')(val, 3);
        } else if(!Number.isInteger(val)) {
            return $filter('number')(val, 0);
        }
        return val;
    }

    /*
    * If pack is `Credit`, calculate earnings.
    */
    function _addEarningData (packTemplate) {
        if( packTemplate.currency === 'credit') {
            packTemplate.credits = Math.round(packTemplate.total_packs * packTemplate.price);
            packTemplate.earnings = Math.round(packTemplate.credits * CREDIT_TO_CENTS / 100);
        }
    }

    /*
    * Send added pack tiers to backend when Publish sett.
    */
    function _getPublishMeta() {
        var printsPerPiece = {}, tiers = [];
        angular.forEach($filter('orderBy')($scope.packTierTemp, 'order'), function(packTemplate) {
            if (packTemplate.selected && packTemplate.total_packs) {
                tiers.push({
                'id': packTemplate.id,
                'total_packs': packTemplate.total_packs,
                'total_tier_prints': packTemplate.allPrintCounts,
                'odds': packTemplate.partials
                });
            }
        });
        angular.forEach($scope.tempArr, function(rarityData) {
            printsPerPiece[rarityData.name] = rarityData.printPerPiece;
        });
        return {
            total_print_count: $scope.totalCoreAndSpecialPrints,
            prints_per_piece: printsPerPiece,
            tiers: tiers
        };
    }

    /*
    * Calculate rounded prints for each rarity, for eack pack tier as we can't produce percentage of a print.
    * This also helps in rarity and pack figures (ending with 0<00>s) look more clean.
    */
    function _calculateRoundedPrintPerTier(odd) {
        var filteredArr = $scope.tempArr.filter(function(arr) {
            return odd.name === arr.name && arr.count > 0;
        });
        if(!filteredArr.length) {
            return 0;
        }
        return filteredArr[0].count * _round(Math.ceil(odd.expectedPrint / filteredArr[0].count));
    }

    /*
    * This function returns the adjusted count of prints for the rarities in the template. This adjustment is necessary
    * to compensate fewer prints generated after iteration 1 of rounding down the prints/piece for each rarity in a
    * template, which resulted in too many packs left empty and series prematurely selling out.
    */
    function _getActualPrintsPerRarity(packTemplate) {
        var actualPrintsPerPiece,
            detailsSortedByOdds,
            i,
            missingPrintsDetails = [],
            piecesPerRarity = {},
            result = {},
            rollingDifference,
            tempDetail,
            unadjustedPrints = 0;

        $scope.tempArr.forEach(function(rarity) {
            piecesPerRarity[rarity.class] = rarity.count;
        });

        // Calculate missing print for each rarity
        packTemplate.partials.forEach(function(rarityOdd) {
            tempDetail = {
                "id": rarityOdd.id,
                "missing": rarityOdd.expectedPrint - rarityOdd.roundedPrint,
                "pieces": piecesPerRarity[rarityOdd.css_class],
                "percent": rarityOdd.percent
            };
            tempDetail.roundedPrintsPerPiece = rarityOdd.roundedPrint/tempDetail.pieces;
            missingPrintsDetails.push(tempDetail);
        });

        // Sort by odds to ensure difficulty order preserved
        detailsSortedByOdds = $filter('orderBy')(missingPrintsDetails, 'percent');

        // Calculate rolling disparity
        // Note: We don't need to handle rarity not available in the series. It's not sent itself as part of the
        // odds to begin with.
        for (i = 0; i < detailsSortedByOdds.length; i++) {
            tempDetail = detailsSortedByOdds[i];
            rollingDifference = tempDetail.missing + unadjustedPrints;
            actualPrintsPerPiece = _round(
                // Rounding should follow previous rounded value
                tempDetail.roundedPrintsPerPiece + rollingDifference / tempDetail.pieces,
                // Round up the least difficult rarity
                i == (detailsSortedByOdds.length - 1) && (rollingDifference > 0));
            // Skip over-optimisation
            if (actualPrintsPerPiece <= 0) {
                actualPrintsPerPiece = tempDetail.roundedPrintsPerPiece;
            }
            result[tempDetail.id] = tempDetail.actualPrintsPerPiece = actualPrintsPerPiece;
            result[tempDetail.id] *= tempDetail.pieces;
            unadjustedPrints = rollingDifference - (
                tempDetail.actualPrintsPerPiece - tempDetail.roundedPrintsPerPiece) * tempDetail.pieces;
        }

        // Ensure there is no missing prints
        if (unadjustedPrints > 0) {
            result[tempDetail.id] = tempDetail.actualPrintsPerPiece = (
            actualPrintsPerPiece >= 25 ? _round(actualPrintsPerPiece, true) : actualPrintsPerPiece + 1);
            result[tempDetail.id] *= tempDetail.pieces;
        }
        return result;
    }

    /*
    * To calculate actual odds for each rarity, for eack pack tier.
    */
    function _calculateAcutalOddsPerTier(odd, count, packCount) {
        return (odd.actualPrint / ((count + PACK_TO_PRINTS_MULTIPLIER) * packCount)) * 100;
    }

    /*
    * To validate input value between 1 to 500000
    */
    function _validateRange(input) {
        if(!input) {
            return "Range between 1 to 500000";
        }
        return '';
    }

    /**
     * Based on total number of packs, calculate the print count for
     * each rarity. The print counts need to be rounded as per the rules.
     * The count is updated in the pack template and the summary table data.
     */
    $scope.updateTotalPrints = function(packTemplate) {
        packTemplate.error = "";
        var packCount = packTemplate.total_packs,
            actualPrintsPerRarity;
        packTemplate.error = _validateRange(packCount);

        packTemplate.partials.forEach(function(odd) {
            // Based on total packs calculate total print for each rarity
            odd.expectedPrint = _calculateExpectedPrint(packCount, odd.percent, packTemplate.card_count);
            // Calculate Actual print per tier level.
            odd.roundedPrint = _calculateRoundedPrintPerTier(odd);
        });
        // After the above adjustment the pack to print ratio gets disturbed. We need to adjust the prints
        // so that this problem doesn't happen. In this process we would start compensating the fewer prints
        // from most difficult rarity (based on odds) to the least difficult rarity. We would end up generating
        // excess prints for the least difficulty rarity (because of rounding up rather than rounding down) and
        // making the odds more difficult. This hack handles the disturbed ratio while maintaining the rounded
        // count prints per card and packs ending in 0(00)s.
        // Note: This is done at pack template level than all combined because it ensures the odds per template is
        // deterministic and pack print calculation is idempotent. Though it results in further marginally excess
        // prints.
        actualPrintsPerRarity = _getActualPrintsPerRarity(packTemplate);
        // Calculate Actual odds
        packTemplate.partials.forEach(function(odd) {
            odd.actualPrint = actualPrintsPerRarity[odd.id];
            odd.actualOdds = _calculateAcutalOddsPerTier(odd, packTemplate.card_count, packCount);
        });
        // Update rounded print counts for each rarity in the summary table
        _adjustTotalPrintCountInSummaryData();
        // If credit pack, calculate, total credits and earnings
        _addEarningData(packTemplate);
        // Broadcast value to publish method.
        $rootScope.$broadcast("Pack-tier-template", _getPublishMeta());
    }

    /*
    * To calculate, pack tier's selected order.
    */
    function _calculatePackTemplateOrder() {
        var selectedTemplates = $scope.packTierTemp.filter(function(packTemplate){
            return packTemplate.selected;
        });
        return selectedTemplates.length;
    }

    /*
    * To show pack tiers in selected order.
    * order {Integer}.
    */
    function _updatePackTemplateOrder(order) {
        angular.forEach($scope.packTierTemp, function(packTemplate) {
            if (packTemplate.selected && (packTemplate.order > order)) {
                packTemplate.order -= 1;
            }
        });
    }

    /*
    * function get trigger when we add template.
    * `selectedPackTemplate` {Object}
    */
    $scope.chooseTemplate = function(selectedPackTemplate) {
        $scope.showPackTemplatesDropDown = false;
        // save pack template to persist.
        ctSettCreator.setPackTemplateToPersist([], $scope.packTierTemp);
        selectedPackTemplate.selected = true;
        // To disable add template button when all pack tier's are selected through drop down.
        if($scope.packTierTemp.length === ++temp) {
           $scope.disableAddTemplateButton = true;
        }
        // Calculate rarity percentage and total prints.
        selectedPackTemplate.order = _calculatePackTemplateOrder();
    };

    // Reset odds value when delete template.
    function _resetOdds(template) {
        template.partials.forEach(function(tier) {
            tier.actualPrint = 0;
            tier.actualOdds = 0;
        });
    }

    /*
    * Delete template.
    * We have an option to delete template.
    */
    $scope.deleteTemplate = function(index) {
        var deselectedPackTemplate = $filter('orderBy')($scope.packTierTemp, 'order')[index],
            order = deselectedPackTemplate.order;
        // reset values.
        deselectedPackTemplate.selected = false;
        deselectedPackTemplate.total_packs = null;
        deselectedPackTemplate.order = undefined;
        deselectedPackTemplate.error = "";
        // Reset odds data while deleting.
        _resetOdds(deselectedPackTemplate);
        _updatePackTemplateOrder(order);
        // Update total prints.
        _adjustTotalPrintCountInSummaryData();
        // Add template button enable or disable status.
        --temp;
        // disable `preview` button when template deleted(when `No` template was there)
        var isValid  = $scope.packTierTemp.some(function(pack) {
            return pack.total_packs > 0;
        });
        ctSettCreator.isPackTemplateSelected(isValid);
        $scope.disableAddTemplateButton = false;
        $rootScope.$broadcast("Pack-tier-template", _getPublishMeta());
    };

    /*
    * Persist added pack tier template when you click `back to admin` or `back from preview`.
    */
    function _displayPersistPackTierTemplate() {
        // Get persisted data from backend through service.
        _persistedPackTier = ctSettCreator.getSelectedTemplates();
        // _persistedPackTier is empty return.
        if(!_persistedPackTier.length) {
            return;
        }
        // disable `Add template` button when all packtier has been selected from pack tier dropdown.
        if(_persistedPackTier.length === $scope.packTierTemp.length) {
            $scope.disableAddTemplateButton = true;
        }
        _persistedPackTier.forEach(function(tier) {
            $scope.packTierTemp.forEach(function(pack) {
                if(tier.id === pack.id) {
                    // `temp` variable to enable and disable `Add template` button.
                    ++temp;
                    pack.total_packs = tier.total_packs;
                    // `true` to remove from add template dropdown.
                    pack.selected = true;
                    pack.order = _calculatePackTemplateOrder();
                    $scope.updateTotalPrints(pack);
                }
            });
        });

    }

    /*
    * Preview button validation.
    * When you land on pack tier template selection page, without adding template `preview` shouldn't possible.
    */
    ctSettCreator.isPackTemplateSelected(false);

    // Reset all data in pack tier template selection page.
    _resetSummaryData();

    /*
    * After published sett, we should `preview` added pack tier template for Limited edition.
    * `$scope.packTierTemp` variable is filled with added pack tier template for that sett, which is done by
    * `ctSettCreator.getPackTemplates()` service method.
    */
    function _afterPublishedSettGetPackTemplate() {
        $scope.packTierTemp.forEach(function(pack) {
            $scope.updateTotalPrints(pack);
        });
    }

    /*
    * Check sett is published with `$scope.isPublished = ctSettCreator.getDisabledStatus()` service method;
    * Value of `disabled` is a boolean.
    * if `false`- sett is not published.
    * if `true`- sett is published.
    */
    if(!$scope.isPublished.disabled) {
        // To persist selected pack template, when you click `back to admin` or `back from Preview`.
        _displayPersistPackTierTemplate();
    } else _afterPublishedSettGetPackTemplate();

}]);
