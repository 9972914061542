artModule.directive("artGlobalMessage", function(){

    var globalMessageDirective = {};

    globalMessageDirective.controller = [
        '$scope',
        '$timeout',
        'artSubscriptionService',
    function(
        $scope,
        $timeout,
        artSubscriptionService
    ){


        artSubscriptionService.subscribe('show-global-message', function(message, time){
            var displayTime = time ;
            $scope.message = message;
            
            if (displayTime) {

                $timeout(function(){
                    delete $scope.message;
                }, displayTime);
            
            }
        });

    }];

    globalMessageDirective.templateUrl = "partials/art/notifications/global-message.partial.html";

    return globalMessageDirective;

});