neonmobApp.controller("nmActivityFeedController", [
    '$scope',
    'artAnalytics',
    'artConfig',
    'artConstants',
    'artPromiseHelpers',
    'artResourceService',
    'artSubscriptionService',
    'artUser',
    'ProfileUser',
    function ($scope,
              artAnalytics,
              artConfig,
              artConstants,
              artPromiseHelpers,
              artResourceService,
              artSubscriptionService,
              artUser,
              ProfileUser
              ) {

        var MAX_SETT_TILES = 3;
        var MAX_RECENT_SETT_FILTERS = 3;
        var MAX_RECENT_SETT_FILTERS_OPTGROUP = 10;
        var settMetricsUrl = artConfig.api['api-collected-sets'];
        
        $scope.recentlyCollectedSetts = [];
        $scope.recentlyCollectedSettFilters = [];
        $scope.settFilterOptions = [];
        $scope.showFilterDropdown = true;

        $scope.filters = {
            sett: null,
            user: null
        };

        function init() {
            $scope.recentlyCollectedSetts = [];
            $scope.alphabetizedCollectedSetts = [];

            $scope.filters = {
                sett: null,
                user: {id: artConfig.targetId}
            };

            artPromiseHelpers.allObject({
                fetchUser: ProfileUser.fetchUser(),
                metrics: ProfileUser.fetchCollectedSets()
            }).then(function (result) {
                $scope.user = result.fetchUser.data;
                prepareSettsForDisplay(result.metrics);
            });
        }

        var stopWordRegex = /^the\-/;

        function prepareSettsForDisplay(metrics) {
            if (!metrics) return metrics;

            var data = metrics.concat(); // concat() so we don't change the source
            for (var i = 0, c = data.length; i < c; i++) {
                data[i].$name_slug = data[i].name_slug.replace(stopWordRegex, '');
            }

            var fullSettList = data.concat()
                .sort(function compare(a, b) { // desc sort
                    if (a.pack_last_acquired > b.pack_last_acquired)
                        return -1;
                    if (a.pack_last_acquired < b.pack_last_acquired)
                        return 1;
                    return 0;
                });

            // remove welcome to neonmob
            fullSettList = fullSettList.filter(function(el){
                return el.id !== artConstants['WELCOME_SETT_ID'];
            });


            $scope.recentlyCollectedSetts = fullSettList.slice(0, MAX_SETT_TILES);
            $scope.recentlyCollectedSettFilters = fullSettList.slice(0, MAX_RECENT_SETT_FILTERS);

            var settFilterOptions = fullSettList.slice(MAX_RECENT_SETT_FILTERS, MAX_RECENT_SETT_FILTERS_OPTGROUP);
            var mobileSettFilterOptions = fullSettList.slice(0, MAX_RECENT_SETT_FILTERS_OPTGROUP);
            var alphabetizedCollectedSetts = fullSettList.slice(MAX_RECENT_SETT_FILTERS_OPTGROUP).sort(function (a, b) {
                return a.$name_slug.localeCompare(b.$name_slug);
            });

            $scope.settFilterOptions        = settFilterOptions.concat(alphabetizedCollectedSetts);
            $scope.mobileSettFilterOptions  = mobileSettFilterOptions.concat(alphabetizedCollectedSetts);

            if(fullSettList.length < MAX_RECENT_SETT_FILTERS) {
                $scope.showFilterDropdown = false;
            }

            // re apply the old filters
            if(!$scope.filters.sett) return;

            for(var i = 0, c = $scope.settFilterOptions.length; i < c; i++) {
                if($scope.filters.sett.id == $scope.settFilterOptions[i].id) {
                    $scope.filters.sett = $scope.settFilterOptions[i];
                    return;
                }
            }
        }

        artSubscriptionService.subscribe('pack-consumed', onPackConsumed);

        function onPackConsumed() {
            artResourceService.get(settMetricsUrl, true)
                .then(prepareSettsForDisplay);
        }

        $scope.$on('$destroy', function () {
            artSubscriptionService.unsubscribe('pack-consumed', onPackConsumed);
        });

        $scope.isYou = function () {
            if (!$scope.user || !artUser.isAuthenticated()) return false;
            return $scope.user.username === artUser.getUsername();
        };

        $scope.isAuthenticated = artUser.isAuthenticated.bind(artUser);

        $scope.dropdownFilterSelected = function(){
            return $scope.filters.user == null &&
                   $scope.recentlyCollectedSettFilters.indexOf($scope.filters.sett) < 0;
        };

        $scope.requestActivityFeed = function (type, source, obj) {
            // reset filters
            if (!obj)
                type = "user";

            var id;
            if (type === "user") {
                $scope.filters = {
                    sett: null,
                    user: {id: artConfig.targetId}
                };
                id = artConfig.targetId;
            } else {
                $scope.filters = {
                    sett: obj,
                    user: null
                };
                id = obj.id;
            }
            artAnalytics.track('Changed Profile Feed',{
                settId: (type == 'sett'? obj.id : null),
                settName: (type == 'sett'? obj.name : null),
                type: type,
                source: source
            });

            artSubscriptionService.broadcast('request-activity-feed-filter', {
                type: type,
                id: id
            });
        };

        $scope.getOptGroupName = function (sett) {
            var idx = $scope.settFilterOptions.indexOf(sett);

            if (idx < MAX_RECENT_SETT_FILTERS_OPTGROUP)
                return "Recently Collected Series";
            else
                return "Other Series You've Collected";
        };

        $scope.showDetail = function() {
            return $scope.isYou() || $scope.recentlyCollectedSetts.length > 0;
        };


        init();
    }]);
