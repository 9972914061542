neonmobApp.factory("nmPromoCodes", [
    "$cookies",
    "artMessage",
    "artOverlay",
    "artSetCookie",
    "artUser",
    "poRoute",
function(
    $cookies,
    artMessage,
    artOverlay,
    artSetCookie,
    artUser,
    poRoute
){

    var nmPromoCodes = {};
    artOverlay.bindNameToTemplateUrl("promocodes-redeeming", "partials/application/promo-codes/redeeming.partial.html");

    nmPromoCodes.redeemCookiePromoCode = function(){
        if(!artUser.isAuthenticated() || !$cookies.promo_code){
            return;
        }

        nmPromoCodes.redeemPromoCode($cookies.promo_code).finally(function(){
            //Not sure why $cookies does not update all the time, but it wasn't, so I'm using artSetCookie!
            artSetCookie("promo_code", "", -1);
            artSetCookie("promo_sett_url", "", -1);
        });
    };

    nmPromoCodes.redeemPromoCode = function(promocode){
        var promise = poRoute.launchOpenPromoPack(
            // FIXME: The promo sett url has extra quote wrapper
            $cookies.promo_sett_url.replace(/"/g, ""),
            {
                code: promocode,
                store_signup_sett: $cookies.show_rewards || false
            }
        );

        promise.catch(function(response){
            artOverlay.hide();
            if(response.status === 400){
                artMessage.showAlert(response.data.detail);
            } else {
                artMessage.showAlert("Sorry, we were not able to redeem your promo code. Please try again by going to the url or contact via the feedback button.");
            }
        });

        artOverlay.show("promocodes-redeeming");
        return promise;
    };

    return nmPromoCodes;

}]);