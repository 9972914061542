artModule.service('artCreditRewardService',[
    "artOverlay",
    "artGlobalAlertService",
    "artUser",
    "artResource",
    function(
        artOverlay,
        artGlobalAlertService,
        artUser,
        artResource
    ) {
        artOverlay.bindNameToTemplateUrl("claim-credits", "partials/art/credits/claim-credits.partial.html", "modal");
        var artCreditRewardService = {};
        if(artUser.isAuthenticated()) {
            var creditRewardUrl = "/data/rewards.json",
            claimCreditUrl = "/data/claim_credits.json/",
            _alertData = {
                classes: '',
                message: ''
            },
            _currentCreditBalance = artUser.getCredits(),
            _claimedAmount,
            _creditRewardData;


            function setupAlert() {
                _alertData = {
                    classes: "show-rewards celebration hide",
                    message: "partials/art/credit-reward.partial.html"
                }
                if (_creditRewardData) {
                    _alertData = {
                        classes: "show-rewards celebration show",
                        message: "partials/art/credit-reward.partial.html"
                    }
                }
                artGlobalAlertService.setupGlobalAlert(_alertData);
            };

            function updateCreditBalance() {

                var newCreditBalance = _currentCreditBalance + _claimedAmount;
                artUser.updateCredits(newCreditBalance);
            };

            artCreditRewardService.showCreditReward = function() {
                artResource.retrieve(creditRewardUrl).then(function(response) {
                    _creditRewardData = response.credits[0];
                    setupAlert();
                });
            };

            artCreditRewardService.getCreditRewardData = function() {

                return _creditRewardData;
            };

            artCreditRewardService.getCreditRewardData = function() {

                return _creditRewardData;
            };

            artCreditRewardService.claimCredit = function() {

                var request = {
                    url: claimCreditUrl,
                    data: {
                        credit_bonus_id: _creditRewardData.id
                    }
                }

                artResource.create(request.url, request.data).then(function(response) {
                    if( response.success) {
                        _claimedAmount = response.credits;
                        artCreditRewardService.showClaimedModal();
                    }

                });
            };

            artCreditRewardService.showClaimedModal = function() {
                artCreditRewardService.showCreditReward();
                artOverlay.show("claim-credits");
            };

            artCreditRewardService.getClaimedCredit = function() {

                return _claimedAmount;
            };

            artCreditRewardService.closeRewardModal = function() {
                updateCreditBalance();
                artOverlay.hide();
            };
        }


        return artCreditRewardService;

}]);
