creatorModule.provider('ctSettCreator', function(){
    var collectionAttrs = {};

    this.$get = [
        "$rootScope",
        "$http",
        "$q",
        "$timeout",
        "artCapitalizeFilter",
        "artConstants",
        "artPieceService",
        "artResource",
        "artSett",
        "artUser",
        "artSubscriptionService",
        "ctPieceRarities",
        "ImageService",
        "artObjectHelpers",
        "artMessage",
    function(
        $rootScope,
        $http,
        $q,
        $timeout,
        artCapitalizeFilter,
        artConstants,
        artPieceService,
        artResource,
        artSett,
        artUser,
        artSubscriptionService,
        ctPieceRarities,
        ImageService,
        artObjectHelpers,
        artMessage
    ){

        var ctSettCreator = {},
            _packTemplates = [],
            _selectedPiece = null,
            _pieces = [],
            _genres = [],
            _coreRarity = [],
            _packTierTemplate = [],
            _selectedTemplates = [],
            _changeTemplatePreview = false;
            _isSelected = false;
            _canPreviewPackTemplate = false;
            _loadingPieces = true;

        for (var attr in collectionAttrs) {

            ctSettCreator[attr] = collectionAttrs[attr];

        }

        artResource.retrieve(ctSettCreator.settDetailUrl).then(function(data) {
            data.discontinue_date && (data.discontinue_date = new Date(data.discontinue_date));
            data.released && (data.released = new Date(data.released));

            ctSettCreator.sett = data;

            artResource.retrieve(ctSettCreator.sett.status_url).then(function(data){
                if(data.status === "publishing") {
                    artSubscriptionService.broadcast('show-publishing-status', ctSettCreator.sett.status_url);
                }
            });

            ctSettCreator.sett.url = ctSettCreator.settDetailUrl;
            if (ctSettCreator.sett.version === artConstants.VERSION_TYPES.unlimited) {
                ctSettCreator.sett.isUnlimited = true;
            } else {
                ctSettCreator.sett.isUnlimited = false;
            }


            if (ctSettCreator.sett.sett_type === artConstants.SETT_TYPE_VALUES['promo-only']) {
                ctSettCreator.freePackSizes[0].showOption = false;
            }

            artResource.retrieve(ctSettCreator.genreListUrl).then(function(data){
                _genres = data;
                for (var i = 0; i < _genres.length; i++) {
                    if (_genres[i].id === ctSettCreator.sett.genre) {
                        ctSettCreator.sett.genre = _genres[i].id;
                        return data;
                    }
                }
            });

            artResource.retrieve(ctSettCreator.creatorListUrl).then(function(data){
                ctSettCreator.creators = data;
                for (var i = 0; i < ctSettCreator.creators.length; i++) {
                    if (ctSettCreator.creators[i].id === ctSettCreator.sett.creator.id) {
                        ctSettCreator.sett.creator = ctSettCreator.creators[i];
                        return data;
                    }
                }
            });

        });

        artResource.retrieve(ctSettCreator.settPieceListUrl).then(function(pieces){
            _pieces.push.apply(_pieces, pieces);
            _updateRequiredCounts();
            _loadingPieces = false;

            var _sortPieces = function(a, b){
                return a.preview_order - b.preview_order;
            };

            pieces.sort(_sortPieces);

            ctSettCreator.previewPieces = pieces.slice(0, artConstants.MAX_PREVIEW_PIECES);
        });

        ctSettCreator.getGenres = function() {
            return _genres;
        };

        ctSettCreator.refreshPieces = function(){
            artResource.retrieve(ctSettCreator.settPieceListUrl).then(function(pieces){
                _pieces = pieces;
                _updateRequiredCounts();
            });
        };

        ctSettCreator.fetchLEPackTemplates = function() {
            return artResource.retrieve(ctSettCreator.packTemplateListUrl);
        };

        ctSettCreator.setLEPackTemplates = function(packTemplates) {
            _packTemplates = packTemplates.templates;
            _selectedTemplates = packTemplates.selected_templates;
        };

        ctSettCreator.getPackTemplates = function() {
            return _packTemplates;
        };

        ctSettCreator.getSelectedTemplates = function() {
            return _selectedTemplates;
        };

        ctSettCreator.getSett = artObjectHelpers.makePropertyGetter(ctSettCreator, "sett");

        ctSettCreator.isLoadingPieces = function(){

            return _loadingPieces;

        };

        ctSettCreator.getSettPieces = function(){
            return _pieces;
        };

        ctSettCreator.isLimitedEdition = function() {
            return ctSettCreator.getSett().version === artConstants.VERSION_TYPES.limited;
        }

        ctSettCreator.isAmateur = function() {
            return ctSettCreator.getSett().sett_type === artConstants.SETT_TYPE_VALUES['amateur']
        }

        // Purpose of Replicate series - starts here
        ctSettCreator.getSettId = function() {
            return ctSettCreator.sett.id;
        }

        ctSettCreator.canReplicate = function() {
            var sett = ctSettCreator.sett;
            return artSett.isReleased(sett);
        }
        // Purpose of Replicate series - ends here

        ctSettCreator.getCoreSettPieces = function () {
            var corePieces = [];

            ctSettCreator.getSettPieces().forEach(function (piece, index) {
                if ((!piece.rarity || !piece.rarity.rarity) ||
                    (piece.rarity.rarity !== ctPieceRarities.CHASE_RARITY)) {
                        corePieces.push(piece);
                    }
            });

            return corePieces;

        };

        ctSettCreator.getChaseSettPieces = function(){
            var chasePieces = [];
            ctSettCreator.getSettPieces().forEach(function (piece, index) {
                if (piece.rarity.rarity === ctPieceRarities.CHASE_RARITY) {
                        chasePieces.push(piece);
                    }
            });
            return chasePieces;
        };

        ctSettCreator.getSelectedPiece = function(){

            return _selectedPiece;

        };

        ctSettCreator.getPreviewPieces = function () {
            return ctSettCreator.previewPieces;
        };

        ctSettCreator.setSelectedPiece = function(piece){

            _selectedPiece = piece;

        };

        ctSettCreator.clearSelectedPiece = function(){
            _selectedPiece = null;
        };

        ctSettCreator.gotoPreviewPackTemplate = function(value) {
            _canPreviewPackTemplate = value;
            return true;
        };
        ctSettCreator.getPreviewStatus = function() {
            return _canPreviewPackTemplate;
        };

        ctSettCreator.fromPreviewStatus = function(val){
            _changeTemplatePreview = val;
        };

        ctSettCreator.getTemplateToPreview = function(){
            return _changeTemplatePreview;
        };

        ctSettCreator.deletePiece = function(piece){

            return artResource.destroy(piece).then(function(){

                var pieces = ctSettCreator.getSettPieces(),
                    index = pieces.indexOf(piece);
                pieces.splice(index, 1);

                if(_selectedPiece && _selectedPiece.id === piece.id){

                    _selectedPiece = null;

                }

                for(var i=0; i<pieces.length; i++){

                    var settPiece = pieces[i];

                    if(settPiece.variant_parent === piece.id){

                        settPiece.variant_parent = null;

                    }

                }
                _updateRequiredCounts();

            });

        };

        function _handleErrors(promise){
            promise.catch(function(error){

                artSubscriptionService.broadcast('show-global-message', error.detail);

            });
            return promise;
        }

        function _orderAlphabetically(pieces, orderOffset){

            //sort pieces by name!
            pieces.sort(function(a,b){
                return a.name.localeCompare(b.name);
            });

            for(var i=0; i<pieces.length; i++){
                pieces[i].ordering = i + orderOffset;
            }

        }

        function formatName(name){

            name = name.replace(/\.(png|jpg|jpeg|bmp|mp4|mpeg|avi)$/, "") //remove file extension
                       .replace(/[-_+.]/g, " ") //add spaces!
                       .replace(/ \(\d+\)$/, "") //remove any existing number ending
                       .trim();
            return artCapitalizeFilter(name);

        }

        ctSettCreator.bulkCreatePieces = function(transloaditResults){

            var pieces = [], existingNames = {}, settPieces = ctSettCreator.getSettPieces(), i;

            for(i=0; i<settPieces.length; i++){

                existingNames[settPieces[i].name.toLowerCase()] = true;

            }

            for(i=0; i<transloaditResults.uploads.length; i++){

                var upload = transloaditResults.uploads[i],
                    baseName = upload.basename.substr(0, 94),
                    baseName = formatName(baseName),
                    baseIndex = 1,
                    name = baseName;

                while(existingNames[name.toLowerCase()]){

                    name = baseName + " (" + baseIndex + ")";
                    baseIndex++;

                }

                existingNames[name.toLowerCase()] = true;

                pieces.push({
                    name: name,
                    asset_id: upload.original_id,
                    sett: ctSettCreator.sett.id
                });

            }

            _orderAlphabetically(pieces, settPieces.length);

            return _handleErrors(artResource.create(ctSettCreator.pieceListUrl, pieces)).then(function(newPieces){

                for(var i=0; i<newPieces.length; i++){

                    var piece = newPieces[i];
                    settPieces.push(piece);

                    ctSettCreator.pollPieceAsset(piece);

                }
                _updateRequiredCounts();

            });

        };

        ctSettCreator.hasPieceAssets = function(piece){

            return piece.piece_assets.video || piece.piece_assets.image;

        };

        var _pollingTriggered = false,
            _pollingPieceIds = {};

        ctSettCreator.POLL_PIECE_ASSET_TIME = 2000;

        function _updatePieceAsset(piece, updatedPiece){

            ImageService.preload(artPieceService.getImageData(null, updatedPiece, "medium", true).url)['finally'](function(){

                piece.asset_type = updatedPiece.asset_type;
                piece.piece_assets = updatedPiece.piece_assets;
                $rootScope.$broadcast("artRedrawAsset", updatedPiece.id);

            });

        }

        ctSettCreator.pollPieceAsset = function(piece){
            if(piece){
                piece.piece_assets = {};
                _pollingPieceIds[piece.id] = true;
            }

            if(!_pollingTriggered){

                _pollingTriggered = true;

                $timeout(function(){
                    var pieceIds = Object.keys(_pollingPieceIds);

                    if(pieceIds.length > 0){

                        for(var i=0; i<pieceIds.length; i++){

                            pieceIds[i] = parseInt(pieceIds[i]);

                        }

                        _handleErrors(artResource.create(
                            ctSettCreator.pieceAssetsUrl,
                            pieceIds
                        )).then(function(updatedPieces){

                            _pollingTriggered = false;

                            var updatedPiecesMap = {},
                                pieces = ctSettCreator.getSettPieces(),
                                piece, i;

                            for(i = 0; i < updatedPieces.length; i++){

                                piece = updatedPieces[i];
                                updatedPiecesMap[piece.id] = piece;

                                delete _pollingPieceIds[piece.id];

                            }

                            //update our original pieces.
                            for(i = 0; i < pieces.length; i++){

                                piece = pieces[i];
                                var updatedPiece = updatedPiecesMap[piece.id];

                                if(updatedPiece){
                                    if ('oldImageUrl' in piece && updatedPiece.piece_assets.image.medium.url === piece.oldImageUrl) {
                                        // the data hasn't yet been updated so keep polling
                                        _pollingPieceIds[piece.id] = true;

                                    } else {
                                        _updatePieceAsset(piece, updatedPiece);
                                    }

                                }

                            }

                            if(Object.keys(_pollingPieceIds).length > 0){

                                ctSettCreator.pollPieceAsset();

                            }

                        });

                    }

                }, ctSettCreator.POLL_PIECE_ASSET_TIME);

            }

        };

        ctSettCreator.appendPiece = function(piece){

            var defer = $q.defer();

            ctSettCreator.getSettPieces().push(piece);

            defer.resolve(piece);

            return defer.promise;

        };

        ctSettCreator.showUpdating = function(itemName){
            itemName = itemName || "Collection";
            artSubscriptionService.broadcast('show-global-message', 'Updating '+itemName+'&hellip;');
        };

        ctSettCreator.hideUpdating = function(){

            artSubscriptionService.broadcast('show-global-message', 'Updated', 1000);

        };

        ctSettCreator.cancelUpdating = function(){

            artSubscriptionService.broadcast('show-global-message', 'Cancelled', 1000);

        };

        ctSettCreator.updateObject = function(object){
            ctSettCreator.showUpdating(object.name);
            return _handleErrors(artResource.update(object)).then(function(){
                _updateRequiredCounts();
                ctSettCreator.hideUpdating();
            });

        };

        ctSettCreator.deleteObject = function(object){

            return _handleErrors(artResource.destroy(object));

        };

        ctSettCreator.isExistingPieceName = function(name){

            var pieces = ctSettCreator.getSettPieces();

            for(var i=0; i<pieces.length; i++){

                if(pieces[i].name === name){

                    return true;

                }

            }

            return false;

        };

        ctSettCreator.isValidPieceName = function(piece){

            var pieces = ctSettCreator.getSettPieces();

            for(var i=0; i<pieces.length; i++){

                if(piece.id !== pieces[i].id && pieces[i].name === name){

                    return false;

                }

                if(!piece.name) return false;

            }

            return true;

        };


        ctSettCreator.isValidPiece = function(piece){
            return ctSettCreator.isValidPieceName(piece);
        };

        ctSettCreator.allPiecesValid = function(){
            var pieces = ctSettCreator.getSettPieces();
            var counts = [0, 0, 0, 0, 0, 0];
            for(var i=0; i<pieces.length; i++){
                if (!ctSettCreator.isValidPiece(pieces[i])) return false;
                counts[pieces[i].rarity.rarity]++;
            }
            for (var j=0; k=0, j<counts.length; j++) {
                if (j >= ctPieceRarities.CHASE_RARITY) {
                    if (counts[j] < _specialCounts[k].required){
                        k++;
                        return false;
                    }
                } else {
                    if (counts[j] < _coreCounts[j].required) return false;
                }
            }
            return true;
        };

        ctSettCreator.canPreviewSett = function() {
            return ctSettCreator.allPiecesValid();
        };

        /*
        * Preview button validation.
        * If template selected and value filled with # of packs we should enable `Preview` button.
        * If template selected and value filled with albha number or empty value` shouldn't enable preview button.
        */
        ctSettCreator.isPackTemplateSelected = function(isSelected) {
            _isSelected = isSelected;
        };

        /*
        * Set value to persist pack template data.
        */
        ctSettCreator.setPackTemplateToPersist = function(coreRarity, allPacTierTemplate) {
            _coreRarity = coreRarity;
            _packTierTemplate = allPacTierTemplate;
        }

        /*
        * Get value to save template data in backend.
        */
        ctSettCreator.getSavedTemplateData = function() {
            return _packTierTemplate;
        }
        /*
        * To check core rarity balanced or imbalanced.
        */
        function _toCheckRarityImbalance() {
            _tempArr = _coreCounts.concat(_specialCounts);
            var selectedRarity = _tempArr.filter(function(rarity) {
                return rarity.count !== 0;
            });
            var printPerRarity = _tempArr.filter(function(rarity) {
                return rarity.printPerPiece > 0;
            });

        }

        /*
        * To validate input box.
        */
        ctSettCreator.canPreviewSettFromPackTemplateSelectionPage = function () {
            for(var i = 0; i < _packTierTemplate.length; i++) {
                var totalPacks = Number(_packTierTemplate[i].total_packs);
                if(_packTierTemplate[i].selected && _packTierTemplate[i].allPrintCounts === 0) {
                    return 'Improper Template - '+ _packTierTemplate[i].name + '.' + '<br>Series and Pack Template should have atleast one rarity in common.';
                }
                if( _packTierTemplate[i].selected && !totalPacks) {
                    return 'Complete all fields in pack templates.';
                }
            }

            if(!_coreRarity.length || !_coreRarity[0].totalPrints) {
                _isSelected = false;
                return 'Add at least one pack template before proceeding.';
            }
            return _toCheckRarityImbalance();
        }

        /*
        * Preview button status.
        */
        ctSettCreator.previewButtonStatus = function() {
            return _isSelected;
        };

        /*
        * Send data to backend to persist data.
        */
        ctSettCreator.savePackTierTemplateToPersist = function(packTiers) {
            $http.post(ctSettCreator.saveTemplateUrl, {'tiers': packTiers})
            .success(function(data){

            }).error(function(data){
                artMessage.showAlert('Please refresh and try again or contact support@neonmob.com');
            });
        }

        ctSettCreator.updateOrdering = function(){

            var ids = [],
                pieces = ctSettCreator.getSettPieces();

            for(var i=0; i<pieces.length; i++){
                ids.push(pieces[i].id);
            }

            return _handleErrors(artResource.update({ordered_ids:ids}, ctSettCreator.pieceReorderUrl).then(function(data){
                ctSettCreator.hideUpdating();
            }));

        };

        ctSettCreator.updatePreviewPieces = function(previewPieces){
            var i;

            var ids = [];
            for(i = 0; i < previewPieces.length; i++){
                ids.push(previewPieces[i].id);

                ctSettCreator.previewPieces[i] = previewPieces[i];
            }

            return _handleErrors(artResource.update({piece_ids:ids}, ctSettCreator.sett.preview_pieces_url).then(function (data) {
                //update local instances!
                for(i = 0; i < artConstants.MAX_PREVIEW_PIECES; i++) {
                    ctSettCreator.sett["preview_"+i] = data["preview_"+i];
                }

                var pieces = ctSettCreator.getSettPieces();

                for (i = 0; i < pieces.length; i++) {
                    var isPreviewPiece = false, order;

                    var piece = pieces[i];

                    for (var j = 0; j < ctSettCreator.previewPieces.length; j++){
                        if(piece.id === ctSettCreator.previewPieces[j].id){
                            isPreviewPiece = true;
                            order = j;
                            break;
                        }
                    }

                    if (isPreviewPiece) {
                        piece.preview_order = order;
                    }
                    else {
                        piece.preview_order = artConstants.MAX_PREVIEW_PIECES;
                    }
                }
            }));
        };

        ctSettCreator.updateCover = function(transloaditData) {

            return _handleErrors(artResource.create(ctSettCreator.settTransloaditUrl, {
                "transloadit": JSON.stringify(transloaditData)
            }).then(function(settAssetsData){

                ctSettCreator.sett.sett_assets = settAssetsData;

            }));

        };

        ctSettCreator.getDisabledStatus = function(){
            var sett = ctSettCreator.sett,
                status = {
                    'type': null,
                    'disabled': null
                };
            if (artUser.isCreatorAdmin()) {
                status.type = 'staff';
                status.disabled = artSett.isReleased(sett);

            } else {
                status.type = 'creator';
                status.disabled = !artSett.isEditing(sett);
            }
            return status;
        };

    // ---------------------
    // Strict Rarity Counts
    // ---------------------
    _baseRequirements = [3, 3, 3, 2, 2];
    _coreCounts = [
        {required:1, count: 0, tip: null, totalPrints: 0, name: 'common', class: 'common'},
        {required:1, count: 0, tip: null, totalPrints: 0, name: 'uncommon', class: 'uncommon'},
        {required:1, count: 0, tip: null, totalPrints: 0, name: 'rare', class: 'rare'},
        {required:1, count: 0, tip: null, totalPrints: 0, name: 'very rare', class: 'veryRare'},
        {required:1, count: 0, tip: null, totalPrints: 0, name: 'extra rare', class: 'extraRare'},
    ];
    _specialCounts = [
        {required:0, count: 0, tip: null, totalPrints: 0, name: 'chase', class: 'chase'},
        {required:0, count: 0, tip: null, totalPrints: 0, name: 'variant', class: 'variant'},
        {required:0, count: 0, tip: null, totalPrints: 0, name: 'legendary', class: 'legendary'},
    ];
    //_chaseCount = {required:1, count: 0, tip: null};
    _incrementalRarity = [0, 1, 2, 3, 4, 0, 1, 2, 0, 1, 2, 3, 4];

    ctSettCreator.getCoreCounts = function(){
        return _coreCounts;
    };

    ctSettCreator.getSpecialCount = function() {
        return _specialCounts;
    };

    ctSettCreator.getMinCorePieces = function(){
      return 15 // Short cut for the min number of cards we currently want

      var count = 0;
      for (var i=0; i <_baseRequirements.length; i++) {
        count += _baseRequirements[i];
      }
      return count;
    };

    ctSettCreator.getPieceCount = function(){
      return _pieces.length;
    };

    ctSettCreator.getNonChaseCount = function(){
        var count = 0;
        for (var i=0; i<_pieces.length; i++) {
            if (_pieces[i].rarity.rarity < ctPieceRarities.CHASE_RARITY) count++;
        }
        return count;
    };

    ctSettCreator.getNonCoreCount = function(){
        var count = 0;
        for (var i=0; i<_pieces.length; i++) {
            if (_pieces[i].rarity.rarity >= ctPieceRarities.CHASE_RARITY) count++;
        }
        return count;
    };

    function _getRarityCount(rarity) {
        var count = 0;
        for (var i=0; i<_pieces.length; i++) {
            if (_pieces[i].rarity.rarity === rarity) count++;
        }
        return count;
    }
    function _getRarityTip(rarity, need){
      var title = '',
          cardString = " cards.";


      if (need === 1) cardString = " card.";

      return title;
    }


    function _updateRequiredCounts(){
        for (var k = 0; k < _coreCounts.length; k++) {
            _coreCounts[k].required = _baseRequirements[k] 
            _coreCounts[k].count = _getRarityCount(k);
            need = _coreCounts[k].required - _coreCounts[k].count;
            _coreCounts[k].tip = _getRarityTip(k, need);
        }
        for (var k = 0, j = 5; k < _specialCounts.length; k++, j++) {
            _specialCounts[k].count = _getRarityCount(j);
            need = _baseRequirements[k] - _specialCounts[k].count;
            _specialCounts[k].tip = _getRarityTip(j, need);
        }
        return _coreCounts;
    }

    return ctSettCreator;

    }];

    this.setAttr = function(attr, value) {
        collectionAttrs[attr] = value;
    };

});
