artModule.directive('artPiece', function() {

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope: {

            piece: "=artPiece",
            imageSize: "=?artSize",
            fluid: "=?artFluid",
            isPublic:"=?artIsPublic",
            flipped:"=?artFlipped",
            stack:"=?artStack",
            isNew: "=?artIsNew",
            width: "=?artWidth",
            height: "=?artHeight"

        },

        controller: [
            "$scope",
            "$element",
        function(
            $scope,
            $element
        ){

            //set a default image size!
            $scope.imageSize = $scope.imageSize || "large";
            $scope.fluid = $scope.fluid || false;
            $scope.isPublic = $scope.isPublic || false;
            $scope.flipped = $scope.flipped || false;
            $scope.isNew = $scope.isNew || false;

            $scope.getClass = function(){

                var classList = [$scope.imageSize];

                if($scope.flipped){
                    classList.push("flipped");
                }

                if($scope.fluid){
                    classList.push("fluid");
                }

                var classText = classList.join(" ");

                return classText;

            };

            $scope.hasPrintNumber = function(){
                return $scope.piece.print_num && angular.isNumber($scope.piece.print_num);

            };

            $scope.$on('$destroy', function() {

                $element.find("img.asset").unbind('load');
                $element.find("video.asset").unbind('loadstart');

            });

            $scope.$emit("show-stack-plane");

        }],

        templateUrl: "partials/art/piece/piece.partial.html"

    };

    return directiveDefinitionObject;

});
