artModule.directive('artConversation', [
    'artBlockUserService',
    'artConversationUtils',
    'artFriendsService',
    'artMessage',
    'artResponsive',
    'artUser',
    'artUserConnections',
function(
    artBlockUserService,
    artConversationUtils,
    artFriendsService,
    artMessage,
    artResponsive,
    artUser,
    artUserConnections
){

    var artConversation = {};

    //Isolate the scope.
    artConversation.scope = {
        conversation: "=artConversation",
        messages: "=artMessages",
        navState: "=?artNavState"
    };

    artConversation.link = function(scope){
        scope.canTrade = artUser.canIDo('trade');
        scope.showFriends = artUser.canIDo('friends');
        scope.getNavState = function(){

            if(scope.navState === "trade" && artResponsive.isMobileLarge()){
                return "mobileTrade";
            } else {
                return scope.navState;
            }

        };

        scope.isEmpty = function(){

            return scope.messages && scope.messages.results.length === 0;

        };

        function _setupRecipient(){
            scope.recipient = artConversationUtils.getRecipient(scope.conversation.users);
            scope.disconnect = artUserConnections.connectToUser(scope.recipient.id);
            scope.isRecipientOnline = artUserConnections.isUserOnline.bind(artUserConnections, scope.recipient.id);
            scope.isLoading = true;
            scope.isVerified = artUser.isVerified();

            artFriendsService.isFriend(scope.recipient).then(function(data){
                scope.recipient.isFriend = data.is_friend;
            }).finally(function(){
                scope.isLoading = false;
            });

            artBlockUserService.isBlocked(scope.recipient).then(function (data) {
                scope.userBlocked = data.is_blocked;
                scope.userInitiatedBlock = data.user_initiated;
            });
        }

        scope.blockUser = function () {
            if (!artResponsive.isTouchDevice()) {
                artMessage.showConfirm("This user will be unable to send you trades or messages. " +
                  "All pending trades will be withdrawn or declined.", function (cancelled) {
                    if (!cancelled) {
                        artBlockUserService.blockUser(scope.recipient);
                    }
                });
            }
            else {
                artBlockUserService.blockUser(scope.recipient);
            }
        }

        scope.unblockUser = function () {
            artBlockUserService.unblockUser(scope.recipient);
        }

        _setupRecipient();

        scope.$on("$destroy", function(){
            scope.disconnect();
        });

        scope.$on("updatedBlockedUsers", function(event, blockedUserObj){
            var userId = blockedUserObj.userId,
                isBlocked = blockedUserObj.isBlocked;

            if (scope.recipient.id === userId || artUser.getId() === userId){
                scope.userBlocked = isBlocked;
                scope.userInitiatedBlock = scope.recipient.id === userId;
            }
        });

        scope.$watch("conversation.id", function(newValue, oldValue){
            if(newValue !== oldValue){
                scope.disconnect();
                _setupRecipient();
            }
        });

    };

    artConversation.templateUrl = "partials/art/notifications/conversation.partial.html";

    return artConversation;


}]).directive('artStartConversation', [
    "artNotificationCenter",
function(
    artNotificationCenter
){

    var artStartConversation = {};

    artStartConversation.scope = {
        "recipient": "=artStartConversation",
        "navState": "=?artNavState"
    };

    artStartConversation.link = function(scope, element){

        element.click(function(){

            artNotificationCenter.show("conversation", {
                recipient: scope.recipient,
                conversationNavState: scope.navState || 'closable'
            });

        });

    };

    return artStartConversation;

}]).factory("artConversationUtils", [
    'artUser',
function(
    artUser
){

    var artConversationUtils = {};

    artConversationUtils.getRecipient = function(userList){

        for(var i = 0; i < userList.length; i++){
            if(!artUser.isYou(userList[i])){
                return userList[i];
            }
        }
    };

    return artConversationUtils;

}]);
