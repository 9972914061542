artModule.factory("artUserConnections", [
    "$rootScope",
    "$timeout",
    "artWebSocket",
function(
    $rootScope,
    $timeout,
    artWebSocket
){

    var _onlineUsers = {};
    var _disconnectingUserTimeoutIds = {};

    //Setup the user connection.
    var _userSocket = artWebSocket.connect("/user");

    _userSocket.on("updateStatus", function(status){

        if(status.connected){

            //cancel pending disconneting user.
            $timeout.cancel(_disconnectingUserTimeoutIds[status.id]);
            delete _disconnectingUserTimeoutIds[status.id];

            if(!_onlineUsers[status.id]){
                //trigger ui update only if the status has actually changed!
                _onlineUsers[status.id] = true;
                $rootScope.$apply(function(){
                    $rootScope.$broadcast("artUserConnections.changed", status);
                });

            }


        } else if(_onlineUsers[status.id] && !_disconnectingUserTimeoutIds[status.id]){ //only trigger this once.

            _disconnectingUserTimeoutIds[status.id] = $timeout(function(){

                delete _disconnectingUserTimeoutIds[status.id];

                if(_onlineUsers[status.id]){

                    delete _onlineUsers[status.id];
                    $rootScope.$apply(function(){
                        $rootScope.$broadcast("artUserConnections.changed", status);
                    });

                }

                //let's wait for the user to be offline for a few seconds before updating the ui.
                //since this can occur if an active user is browsing the site, we'll control the apply above.
            }, artUserConnections.DISCONNECT_WAIT_TIME, false);

        }

    });

    _userSocket.on("requestStatus", function(){

        _userSocket.emit("sendStatusConnected");

    });

    var artUserConnections = {};
    var _connectedUsers = {};

    artUserConnections.DISCONNECT_WAIT_TIME = 10*1000;

    artUserConnections.connectToUser = function(userId){

        var connectedUser = _connectedUsers[userId];

        if(!connectedUser){

            connectedUser = {
                count: 0,
                disconnect: function(){
                    connectedUser.count--;
                    if(connectedUser.count <= 0){
                        _userSocket.emit("leave", userId);
                        delete _connectedUsers[userId];
                    }
                }
            };

            _connectedUsers[userId] = connectedUser;
            _userSocket.emit("join", userId);

        }

        connectedUser.count++;

        return connectedUser.disconnect;

    };

    artUserConnections.isUserOnline = function(userId){

        return _onlineUsers[userId] || false;

    };

    //Mainly for testing!
    artUserConnections.setUserOnline = function(userId, online){
        _onlineUsers[userId] = online;
    };

    return artUserConnections;

}]);