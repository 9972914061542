artModule.directive('artPieceTraderList', function() {

    var artPieceTraderList = {};

    artPieceTraderList.scope = {

        "piece": "=artPieceTraderList",
        "need": "=artNeed",
        "sett": "=?artPieceSett"

    };

    artPieceTraderList.controller = [
        '$scope',
        '$timeout',
        '$q',
        'artOverlay',
        'artResource',
        'artUser',
        'nmTrades',
        'artUrl',
        'pieceTraderClickTracker',
        function(
            $scope,
            $timeout,
            $q,
            artOverlay,
            artResource,
            artUser,
            nmTrades,
            artUrl,
            pieceTraderClickTracker
        ) {

            $scope.search = {
                show: false,
                text: null
            };

            $scope.userClicked = pieceTraderClickTracker.userClicked.bind(pieceTraderClickTracker);
            pieceTraderClickTracker.setPieceData($scope.piece, $scope.sett, $scope.need);

            $scope.sortOptions = nmTrades.tradeSortOptions;

            var paramObj = {};

            var updateSortOption = function(option) {

                if (!option.selected) {
                    option.selected = true;
                    return;
                }
                if (option.selected && option.sortOrder === 'desc') {
                    option.sortOrder = 'asc';
                    return;
                }

                if (option.selected && option.sortOrder === 'asc') {
                    option.sortOrder = 'desc';
                    option.selected = false;
                    return;
                }
            };
            var updateParamObj = function(selectedSort) {

                if (!$scope.sortOptions) return;

                paramObj = {};
                $scope.sortOptions.map(function(option) {
                    if (option.selected) {
                        paramObj[option.id] = option.sortOrder;
                    }
                });
            };

            $scope.toggleTradersList = function() {
                if (artOverlay.getName() == "show-owners") {
                    artOverlay.show("show-needers", { piece: $scope.piece, sett: $scope.sett }, true, 'theme-light');
                } else if (artOverlay.getName() == "show-needers") {
                    artOverlay.show("show-owners", { piece: $scope.piece, sett: $scope.sett }, true, 'theme-light');
                }
            };
            $scope.user = artUser.toObject();

            $scope.getSettId = function() {
                // Sett detail-cards section will have sett object but not piece.set/piece.sett_id.
                if ($scope.sett) {
                    return $scope.sett.id;

                    // Piece detail will have piece.set object only.
                } else if ($scope.piece.set) {
                    return $scope.piece.set.id;

                    // In your collection cards section sett/piece.set object will not be there.
                } else if ($scope.piece.sett_id) {
                    return $scope.piece.sett_id;
                }
            };

            $scope.getInfiniteScrollApiUrl = function() {
                if (artOverlay.getName() !== "show-owners" && artOverlay.getName() !== "show-needers") {
                    return;
                }

                if ($scope.need === true && $scope.piece) {

                    //from the "api-piece-needers" endpoint
                    return '/api/pieces/' + $scope.piece.id + '/needers/';

                } else {

                    //from the "api-piece-owners" endpoint
                    return '/api/pieces/' + $scope.piece.id + '/owners/';

                }

            };

            $scope.getUrl = function() {

                return artUrl.updateParams($scope.getInfiniteScrollApiUrl(), paramObj);
            };
            $scope.sortBy = function(field) {
                updateSortOption(field);
                updateParamObj(field);
                $scope.bodyLoader = true;
                $scope.load(function() {
                    $scope.bodyLoader = false;
                });
            };

            $scope.markTraded = function(collector) {
                if (collector.vacation_mode) {
                    return;
                }
                artOverlay.hide(true);
                pieceTraderClickTracker.addUser(collector.id);
                var initialPieceData;
                $scope.piece.print_num = collector.print_num;
                if ($scope.need) {
                    initialPieceData = {
                        offerType: "bidder_offer",
                        pieceId: $scope.piece.id,
                        ownerId: artUser.getId(),
                        settId: $scope.getSettId()
                    };
                } else {
                    initialPieceData = {
                        offerType: "responder_offer",
                        pieceId: $scope.piece.id,
                        ownerId: collector.id,
                        settId: $scope.getSettId()
                    };
                }
                nmTrades.createNewTrade(artUser.toObject(), collector, initialPieceData);
            };

            $scope.tradingActive = artUser.tradingActive.bind(artUser);
            $scope.initSearch = function() {
                $scope.search.show = true;
                $timeout(function() {
                    $('#collector-search').focus();
                });
            };

            $scope.resetSearch = function() {
                $scope.search.show = false;
                $scope.search.text = null;
                $scope.load();
            };

            $scope.loading = false;
            $scope.loadingMore = false;
            // Used to control size of the overlay while searching or sorting.
            // And to position loader at such times.
            $scope.bodyLoader = false;

            var _typingTimeout = null;
            $scope.updateSearchFilter = function() {
                $scope.bodyLoader = true;
                $timeout.cancel(_typingTimeout);
                _typingTimeout = $timeout(function() {
                    paramObj.search = $scope.search.text;
                    $scope.load(function() {
                        $scope.bodyLoader = false;
                    });
                    // TODO: Use ng-model param object.
                    paramObj.search = null;
                }, 500);
            };

            $scope.showLoading = function() {
                return $scope.bodyLoader || $scope.loading || $scope.loadingMore;
            };

            $scope.itemData = [];
            var canceler;
            $scope.load = function(callback) {

                if (canceler) canceler.resolve();
                canceler = $q.defer();

                $scope.loading = true;
                $scope.loadingMore = false;
                var config = {
                    method: "GET",
                    url: $scope.getUrl(),
                    timeout: canceler.promise
                };
                artResource.retrievePaginatedAllowCancel(config).then(function(data) {
                    if (angular.isFunction(callback)) callback();
                    $scope.itemData = data;
                    $scope.loading = false;
                });
            };

            $scope.getNextPage = function() {

                if ($scope.showLoading() || !$scope.itemData.next) {
                    return;
                }
                $scope.loading = true;
                $scope.loadingMore = true;
                $scope.itemData.retrieveNext().then(function(data) {
                    $scope.loading = false;
                    $scope.loadingMore = false;
                });
            };
            updateParamObj();
            $scope.load();
        }
    ];

    artPieceTraderList.templateUrl = 'partials/trade/piece-trader-list.partial.html';

    return artPieceTraderList;

}).directive('artShowTraders', function() {

    var artShowTraders = {};

    artShowTraders.link = function(scope, elem, attr) {

        elem.click(scope.openShowOwners);

    };

    artShowTraders.scope = {
        "piece": "=artShowTraders",
        "sett": "=?artPieceSett"
    };

    artShowTraders.controller = [
        '$scope',
        'artOverlay',
        'artUser',
        'artMessage',
        'artPieceService',
        function(
            $scope,
            artOverlay,
            artUser,
            artMessage,
            artPieceService
        ) {
            var getRequestUserOwnsPrint = function() {
                return artPieceService.getPrintCount(artUser, $scope.piece.id) > 0;
            };

            // Popup the trade screen
            var showTradePopup = function() {
                if (getRequestUserOwnsPrint()) {
                    if (artPieceService.getPrintCount(artUser, $scope.piece.id) === 1){
                        // Popup the Confirmation popup to ensure the trade
                        artMessage.showAlertWithCancel({
                            message: "Watch out!",
                            subtext: "Are you sure to trade your last copy of this card ?"
                    }, function(canceled) {
                        // If ready to trade last card owned,
                        if (!canceled) {
                            artOverlay.show("show-needers", { piece: $scope.piece, sett: $scope.sett }, true, 'theme-light');
                        }
                    });
                 }else{
                    artOverlay.show("show-needers", { piece: $scope.piece, sett: $scope.sett }, true, 'theme-light');
                 }
                } else {
                    artOverlay.show("show-owners", { piece: $scope.piece, sett: $scope.sett }, true, 'theme-light');
                }
            }

            artOverlay.bindNameToTemplateUrl("show-owners", "partials/trade/show-owners.partial.html", "modal");
            artOverlay.bindNameToTemplateUrl("show-needers", "partials/trade/show-needers.partial.html", "modal");
            $scope.openShowOwners = function(event) {
                if (event)
                    event.stopPropagation();

                if (artUser.isVerified()) {
                    // Popup trade screen
                    showTradePopup();
                } else {
                    artMessage.showAlert("You have to verify your email before you can do that :(");
                }

            };

        }
    ];

    return artShowTraders;

}).factory("pieceTraderClickTracker", function() {

    var pieceTraderClickTracker = {},
        _tradersList = [],
        _pieceData = {
            piece: null,
            need: null
        };

    pieceTraderClickTracker.getList = function() {
        return _tradersList;
    };

    pieceTraderClickTracker.resetData = function() {
        _pieceData.piece = null;
        _pieceData.need = null;
        _pieceData.sett = null;
        _tradersList = [];
    };

    pieceTraderClickTracker.setPieceData = function(piece, sett, need) {
        _pieceData.piece = piece;
        _pieceData.need = need;
        _pieceData.sett = sett;
    };

    pieceTraderClickTracker.getPieceData = function() {
        return _pieceData;
    };

    pieceTraderClickTracker.addUser = function(userId) {
        _tradersList.push(userId);
    };

    pieceTraderClickTracker.userClicked = function(userId) {
        return _tradersList.indexOf(userId) >= 0;
    };

    return pieceTraderClickTracker;

});

artModule.filter('traderCountText', function() {
    return function(count) {
        if (count > 100) return '100+';
        if (count == 0) return 'No';
        return count
    };
});