neonmobApp.directive('nmComments', function(){

    var nmComments = {};

    nmComments.scope = {

        parentId: "=nmParentId",
        parentType: "@nmParentType",
        targetUser: "=nmCommentsUser"

    };

    nmComments.controller = [
        '$element',
        '$rootScope',
        '$scope',
        '$timeout',
        '$window',
        'artAnalytics',
        'artBlockUserService',
        'artContentTypes',
        'artNotificationCenter',
        'artResponsive',
        'artTitleNotify',
        'artUser',
        'artWebSocket',
    function(
        $element,
        $rootScope,
        $scope,
        $timeout,
        $window,
        artAnalytics,
        artBlockUserService,
        artContentTypes,
        artNotificationCenter,
        artResponsive,
        artTitleNotify,
        artUser,
        artWebSocket
    ){

        var _REMAINING_CHAR_COUNT_THRESHOLD = 20,
            _COMMENTS_LIST_SELECTOR = '.comments--list--container',
            _COMMENT_LINK_MAX_MS = 5 * 60 * 1000;

        var _maxCharacters = 1000;
        var _userIsActive = true;

        $scope.initialLoaded = false;
        $scope.unreadMessageCount = 0;
        $scope.typing = false;

        $scope.userBlocked = false;
        artBlockUserService.isBlocked($scope.targetUser).then(function (data) {
            $scope.userBlocked = data.is_blocked;

            _changeCommentFocus($scope.userBlocked);
        });

        $scope.$on("updatedBlockedUsers", function(event, blockedUserObj){
            var userId = blockedUserObj.userId,
                isBlocked = blockedUserObj.isBlocked;

            if ($scope.targetUser.id === userId || artUser.getId() === userId){
                $scope.userBlocked = isBlocked;
            }

            _changeCommentFocus($scope.userBlocked);
        });

        function _connect(){
            $scope.comments = artWebSocket.connectSortedList("/conversation", {
                id: $scope.parentId,
                sort: function(a, b){
                    return (new Date(a.created)) - (new Date(b.created));
                },
                onAddItem: function(item){

                    _setTyping(false);

                    if(!_userIsActive){
                        $scope.unreadMessageCount++;
                    } else {
                        _markNotificationRead();
                    }
                },
                onLoadInitial: function(){
                    $scope.initialLoaded = true;
                    _setIsActive(true);
                },
                onLoad: function(){
                    _setIsActive(false);
                }
            });

            $scope.comments.socket.on("serverError", function (err) {
                if (err.code === "USER_BLOCKED") {
                    $rootScope.$broadcast("updatedBlockedUsers", {userId: artUser.getId(), isBlocked: true});
                }
            });
        }

        _connect();

        function _changeCommentFocus(userBlocked) {
            if (userBlocked) {
                $element.find("textarea.comments--field--entry").blur();
            }
            else {
                $element.find("textarea.comments--field--entry").focus();
            }
        }


        $scope.$watch("parentId", function(newValue, oldValue){

            if(newValue !== oldValue){
                $scope.comments.leave(); //leave the old comments!
                _connect(); //connect to the new comments.
            }

        });

        function _markNotificationRead(){
            //HACK: manual construction of the notification id.
            artNotificationCenter.markNotificationsAsRead([$scope.parentId + "-comment-conversation"], "messages");
        }

        function _setIsActive(isActive){
            if(_userIsActive === isActive){
                return; //don't do anything!
            }
            _userIsActive = isActive;
            if(isActive){
                artTitleNotify.stop();
                _markNotificationRead();
                $scope.unreadMessageCount = 0;
            }
        }

        $scope.getUser = function(comment){
            if($scope.targetUser.id === comment.user_id){
                return $scope.targetUser;
            } else {
                return artUser.toObject();
            }
        };


        $scope.getUserById = function(userId) {
            return userId === $scope.targetUser.id ? $scope.targetUser : artUser.toObject();
        };

        $scope.canComment = function(){

            return artUser.isAuthenticated();

        };

        $scope.getRemainingCommentCount = function(){

            return $scope.comments.totalCount - $scope.comments.list.length;

        };

        $scope.hasRemainingComments = function(){

            return $scope.getRemainingCommentCount() > 0;

        };

        $scope.showMoreComments = function(){

            $scope.comments.fetchMore();

        };

        function advanceToBottom() {
            if(_userIsActive && $scope.comments.list.length > 0){
                var commentsList = $element.find(_COMMENTS_LIST_SELECTOR);

                if($element.length > 0){
                    commentsList.scrollTop(commentsList.get(0).scrollHeight);
                }
            }
        };

        $scope.$watch(function () {
            var commentsList = $element.find(".comments--list");

            return commentsList.height();
        }, function (newValue, oldValue) {
            if (newValue !== null) {
                advanceToBottom();
            }
        });

        $scope.handleUnreadClick = function(){

            _setIsActive(true);
            advanceToBottom();

        };

        $scope.addComment = function(){

            var commentInput = _getCommentInput();

            artAnalytics.track("Posted Comment", {
                parentId: $scope.parentId,
                parentType: $scope.parentType,
                userId: $scope.targetUser.id
            });

            //always make the user active after adding a comment.
            _setIsActive(true);

            $scope.comments.addItem({
                comment: commentInput.val().trim(),
                content_type_id: artContentTypes[$scope.parentType],
                object_id: $scope.parentId,
                user_id: artUser.getId(),
                created: moment().format()
            });

            commentInput.val("");

        };


        $scope.showRemainingCharCount = function(){

            return $scope.remainingCharCount() <= _REMAINING_CHAR_COUNT_THRESHOLD;

        };

        $scope.showErrorCharCount = function(){

            return $scope.remainingCharCount() < 0;

        };

        $scope.remainingCharCount = function(){

            var commentLength = _getCommentInput().length;
            return _maxCharacters - commentLength;

        };

        function _getCommentInput(){
            return $element.find("textarea.comments--field--entry");
        }

        function _isSameAuthor(c1, c2){

            return c1.user_id === c2.user_id;

        }

        function _isCondensedComment(currentComment, previousComment){

            return previousComment &&
                !currentComment.attachment &&
                _isSameAuthor(currentComment, previousComment) &&
                new Date(currentComment.created) - new Date(previousComment.created) < _COMMENT_LINK_MAX_MS;
        }

        $scope.getCommentClass = function(currentComment, previousComment){
            var classes = [];
            if(_isCondensedComment(currentComment, previousComment)){
                classes.push('condensed');
            } else {
                classes.push('not-condensed');
            }
            if(currentComment.attachment){
                classes.push('with-attachment');
            }
            return classes.join(' ');
        };

        $scope.getTradeAttachmentClass = function(comment){
            if(comment.attachment.active){
                return comment.attachment.state + ' active';
            } else {
                return comment.attachment.state;
            }
        };

        $scope.handleKeypress = function(ev){

            if(!_canKeySubmit(ev)){
                return;
            }

            ev.preventDefault();
            ev.stopPropagation();

            $scope.addComment();

        };

        $scope.handleKeydown = function(ev){

            if(!_canKeySubmit(ev)){
                _sendTyping(true);
            }

        };

        $scope.updateScroll = function(){
            var listContainer = angular.element("#comment-list-container").get(0);
            // Handle container not found scenario
            if (!listContainer) {
                return;
            }
            var scrollBottom = listContainer.offsetHeight + listContainer.scrollTop;
            var scrollHeight = listContainer.scrollHeight;
            var padding = 5; //lets snap if there's a little movement.

            //user is active if the scroll position is at the bottom
            _setIsActive(scrollHeight - scrollBottom <= padding);

        };

        function _canKeySubmit(ev){
            return !artResponsive.isTouchDevice() &&
                ev.which === 13 &&
                ev.shiftKey === false &&
                /^\s*$/.test(ev.target.value) === false;
        }


        function _sendTyping(active){

            $scope.comments.socket.emit("typing", {
                id: $scope.parentId,
                active: active
            });

        }

        var _typingTimeoutId = null;

        function _setTyping(active){

            if($scope.typing !== active){

                $scope.$evalAsync(function(){

                    $scope.typing = active;

                });

            }

            $timeout.cancel(_typingTimeoutId);

            if($scope.typing){

                _typingTimeoutId = $timeout(function(){

                    $scope.typing = false;

                }, 3000);

            }

        }

        $scope.comments.socket.on("typing", _setTyping);

        $scope.hasUnreadMessages = function(){
            return !_userIsActive && $scope.unreadMessageCount > 0;
        };

        angular.element($window).bind("blur.comment", function(ev){
            _setIsActive(false);
        });

        $scope.$on("$destroy", function(){

            $scope.comments.leave();
            angular.element($window).unbind("blur.comment");

        });

    }];

    nmComments.templateUrl = "partials/component/comments.partial.html";

    return nmComments;

});
