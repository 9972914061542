neonmobApp.directive('nmMarketingBanner', function(){

    var nmMarketingBanner = {};

    nmMarketingBanner.scope = {

        piece:          "=?nmPiece",
        sett:           "=?nmSett",
        useProfile:     "=?nmUseProfile",
        submission:     "=?nmSubmissionData",
        submissions:    "=?nmSubmissions",
        generic:        "=?nmGeneric",
        recent:         "=?nmRecent"

    };

    nmMarketingBanner.controller = [
        '$scope',
        '$window',
        'artAnalytics',
        'artConstants',
        'artUser',
        'ProfileUser',
    function(
        $scope,
        $window,
        artAnalytics,
        artConstants,
        artUser,
        ProfileUser
    ){

        if( $scope.useProfile === true ){

            ProfileUser.fetchUser().then(function(response){

                $scope.profile = response.data;

            });
        }

        $scope.getBannerType = function(){

            var types = ['sett','piece','profile','submission','submissions','generic', 'recent'],
                type  = null;

            for( var i=0; type = types[i]; ++i ){

                if( $scope[type] ){

                    return type;

                }

            }

            return type;

        };

        $scope.showBanner = function(){

            //return !artUser.isAuthenticated() || $scope.recent;
            // Removing banner for archive
            return false;

        };

        $scope.isLoaded = function(){

            return $scope.getBannerType() !== null;

        };

        $scope.trackClick = function(){

            var data = {};

            if( $scope.getBannerType() == 'profile' ){

                data.page       = 'profile';
                data.userId     = $scope.profile.id;
                data.username   = $scope.profile.username;

            } else if( $scope.getBannerType() == 'submission' ){

                data.page           = 'vote-detail';
                data.submissionId   = $scope.submission.id;
                data.creatorName    = $scope.submission.user.name;
                data.creatorId      = $scope.submission.user.id;

            } else if( $scope.getBannerType() == 'submissions' ){

                data.page           = 'submissions-page';

            } else if( $scope.getBannerType() == 'piece' ){

                data.page       = 'piece-detail';
                data.pieceId   = $scope.piece.id;
                data.pieceName = $scope.piece.name;

            } else if( $scope.getBannerType() == 'sett' ){

                data.page       = 'collection-detail';
                data.settId    = $scope.sett.id;
                data.settName  = $scope.sett.name;

            } else {

                data.page       = $scope.getBannerType();

            }

            artAnalytics.track( 'Clicked Marketing Banner', data );

        };

        $scope.triggerSignUp = function() {
            $window.location.href = '/signup';
        };

    }];

    nmMarketingBanner.templateUrl= "partials/application/marketing-banner/marketing-banner.partial.html";

    return nmMarketingBanner;

});