artModule.directive("artVideo", [
function() {

    var artVideo = {};

    artVideo.scope = {

        sources: "=artVideo",
        poster: "@artPoster",
        width: "=?artWidth",
        height: "=?artHeight"

    };

    artVideo.controller = [
        "$scope",
        "$element",
        "artResponsive",
    function(
        $scope,
        $element,
        artResponsive
    ){

        var _unregisterOnScroll, _unregisterOnResize, _dimension = {};

        $scope.video = null;
        $scope.playing = false;


        $scope.render = function(){

            var template = [], i;

            if($scope.video){

                $scope.video.unbind("touchend");

            }

            template.push("<video loop muted playsinline class='asset'");

            if($scope.poster && $scope.poster.length > 0){

                template.push(" poster='");
                template.push($scope.poster);
                template.push("'");

            }

            if($scope.width){

                template.push(" width='");
                template.push($scope.width);
                template.push("'");
                _dimension.width = $scope.width+"px";

            }

            if($scope.height){

                template.push(" height='");
                template.push($scope.height);
                template.push("'");
                _dimension.height = $scope.height+"px";

            }

            template.push(">");

            $scope.sources.sort(function(a, b){
                return a.mime_type.localeCompare(b.mime_type);
            });

            for(i=0; i<$scope.sources.length; i++){

                var source = $scope.sources[i];

                template.push("<source src='");
                template.push(source.url);
                template.push("' type='");
                template.push(source.mime_type);
                template.push("'>");

            }

            template.push("</video>");

            $element.html(template.join(""));

            $scope.video = $element.children("video");

            $scope.video.css(_dimension);

            $scope.video.bind("loadstart", function(){

                $scope.playIfInView();

            });

        };


        $scope.playIfInView = function(){
            try {
                var playPromise = $scope.video.get(0).play();
                if (playPromise !== undefined){
                    playPromise.then(function(){
                        //successfully played video
                        $scope.playing = true;
                    }).catch(function(error){
                        //auto play didn't work. no need to log anything
                    })
                }                
            }catch(error){
                //auto play didn't work. no need to log anything   
            }            
        };

        $scope.render();
        var artVideoTriggerPlayListener;

        if(!artResponsive.isTouchDevice()){

            _unregisterOnResize = artResponsive.onResize($scope.playIfInView);
            _unregisterOnScroll = artResponsive.onScroll($scope.playIfInView);

            artVideoTriggerPlayListener = $scope.$on("artVideoTriggerPlay", function(){

                $scope.playIfInView();

            });

        }

        $scope.$on("$destroy", function(){

            $scope.video.unbind("loadstart");

            if(!artResponsive.isTouchDevice()){
                artVideoTriggerPlayListener();
                _unregisterOnScroll();
                _unregisterOnResize();
            }

        });

        $scope.$watch("sources", function(newValue, oldValue){

            if(newValue[0].url !== oldValue[0].url){

                $scope.render();

            }

        });

        $scope.$watch("poster", function(newValue, oldValue){

            if(newValue !== oldValue){

                $scope.video.attr("poster", newValue);

            }

        });

        $scope.$watch("width", function(newValue, oldValue){

            if(newValue !== oldValue){

                $scope.video.attr("width", newValue);

                if(newValue){

                   _dimension.width = newValue + "px";

                } else {

                    delete _dimension.width;

                }

                $scope.video.css(_dimension);

            }

        });

        $scope.$watch("height", function(newValue, oldValue){

            if(newValue !== oldValue){

                $scope.video.attr("height", newValue);

                if(newValue){

                   _dimension.height = newValue + "px";

                } else {

                    delete _dimension.height;

                }

                $scope.video.css(_dimension);

            }

        });

    }];

    return artVideo;

}]).directive("artPlayVideoOnScroll", function(){

    var artVideoView = {};

    artVideoView.link = function(scope, element){

        element.bind("scroll.artvideoview", function(){

            scope.$broadcast("artVideoTriggerPlay");

        });

        scope.$on("$destroy", function(){

            element.unbind("scroll.artvideoview");

        });

    };

    return artVideoView;

});
