/**
 * A service for handling piece ownership. Uses Resource for caching.
 */
artModule.provider("artPieceService", function() {

    var _PUBLIC = 0,
        _PRIVATE = 1,
        _permissions = _PRIVATE;

    this.setPermissions = function(permissions) {

        _permissions = permissions;

    };

    this.PUBLIC = _PUBLIC;
    this.PRIVATE = _PRIVATE;

    this.$get = [
        "$http",
        "$q",
        "artConfig",
        "artMessage",
        "artResourceService",
        "artSubscriptionService",
        'artUser',
        "ImageService",
        'wsLumberjack',
    function(
        $http,
        $q,
        artConfig,
        artMessage,
        artResourceService,
        artSubscriptionService,
        artUser,
        ImageService,
        wsLumberjack
    ){

        //Private stuff.

        var _ownership = {},
            _syncing={};

        var _DEFAULT_IMAGE_DATA = {
            width:220,
            height:220,
            url:artConfig.defaultImageUrl
        };

        function _processData(user, data) {
            var ownership, item;
            ownership = _getOwnerships(user);

            for (var i = 0, c = data.length; i < c; i++) {
                item = data[i];
                ownership[item[0]] = item[1];
            }
        }

        function _getId(objectOrId){

            var id;

            if(typeof(objectOrId) === "number"){

                id = objectOrId;

            } else if(typeof(objectOrId) === "string"){

                id = parseInt(objectOrId, 10);

                if(isNaN(id) || id.toString() !== objectOrId){

                    throw new Error("The id is not a parsible number and we all know that a user id is a number.");

                }


            } else if(objectOrId && typeof(objectOrId.id) === "number") {

                id = objectOrId.id;

            } else if(objectOrId && typeof(objectOrId.attr) === "function"){

                id = objectOrId.attr("id");

            } else {

                throw new Error("An item or item id must be passed in.");

            }

            return id;

        }


        function _getOwnerships(user){

            if(!user){

                return {};

            }

            var url=_getUrl(user),
                ownership;

            if(!url){

                return {};

            }

            ownership = _ownership[url];

            if(!ownership){

                ownership = {};
                _ownership[url] = ownership;

            }

            return ownership;

        }

        function _setPrintCount(user, pieceOrId, count){

            var ownership = _getOwnerships(user),
                pieceId = _getId(pieceOrId);

            ownership[pieceId] = count;

        }

        function _getUrl(user){
            var url = null;

            if(user === artUser){

                if(user.isAuthenticated()){

                    url = artUser.getUrl('print_counts');

                }

            } else if(typeof(user.attr) === "function"){

                url = user.attr('links').print_counts;

            } else {

                url = user.links.print_counts;

            }

            return url;

        }

        //Public stuff
        var PieceService = {

            NO_USER_ERROR:  "NO_USER_ERROR",
            SERVER_ERROR:   "SERVER_ERROR",

            /**
             * Pull data from the server. This does not handle errors by default.
             *
             * @param {Mixed} [userOrId=artUser] The user to get.
             * @returns {Object} A Promise object.
             */
            syncOwnership:function(user){

                var promise,
                    url,
                    deferred;

                if(!user && artUser.isAuthenticated()){

                    user = artUser;

                } else if(!user){

                    deferred = $q.defer();
                    deferred.resolve(); //ownership data obtained
                    return deferred.promise;

                }

                url = _getUrl(user);

                promise = _syncing[url];

                if(!promise){

                    deferred = $q.defer();

                    promise = artResourceService.get(url).success(function(data){

                        _processData(user, data);
                        deferred.resolve();
                        artSubscriptionService.broadcast("user-piece-ownership-refreshed");


                    }).error(function(){

                        deferred.reject(this.SERVER_ERROR);

                    });

                    _syncing[url] = deferred.promise;

                }

                return promise;
            },

            /**
             * Front end syncing manipulation for an array of pieces
             */
            addPrintOwnerships:function(user, pieces){

                var i;

                for(i = 0; i < pieces.length; i++){

                    this.addPrintOwnership(user, pieces[i]);

                }

            },

            filterPieces:function(user, collection) {

                var array = [];
                var i, len = collection.length;

                for (i = 0; i < len; i++) {

                    if (this.hasPiece(user, collection[i])) {
                        collection[i].owner = user;
                        array.push(collection[i]);
                    }

                }

                return array;

            },

            /**
             * Front end syncing manipulation for an array of pieces
             */
            removePrintOwnerships:function(user, pieces){

                var i;

                for(i=0; i<pieces.length; i++){

                    this.removePrintOwnership(user, pieces[i]);

                }

            },

            /**
             * Front end syncing manipulation
             */
            addPrintOwnership:function(user, pieceOrId){

                var count = this.getPrintCount(user, pieceOrId);
                _setPrintCount(user, pieceOrId, count+1);

            },

            /**
             * Front end syncing manipulation
             */
            removePrintOwnership:function(user, pieceOrId){

                var count = this.getPrintCount(user, pieceOrId);

                if(count > 0){

                    _setPrintCount(user, pieceOrId, count-1);

                }
            },

            /**
             * Front end syncing manipulation after discarding
             * [TODO] Merge the removePrintOwnerships func with this
             */
            removePrintOwnershipsDiscard:function(user, pieces){
                var i;

                for(i=0; i<pieces.length; i++){

                    this.removePrintOwnershipDiscard(user, pieces[i]);

                }
            },

            /**
             * Front end syncing manipulation
             */
            removePrintOwnershipDiscard:function(user, piece){

                var count = this.getPrintCount(user, piece.piece_id);

                if(count > 0 && piece.count<=count){

                    _setPrintCount(user, piece.piece_id, count-piece.count);
                }
            },

            getPieceCount:function(user, pieces){

                var count = 0;
                for(var i=0; i<pieces.length; i++){

                    if(PieceService.hasPiece(user, pieces[i])){

                        count++;

                    }

                }
                return count;

            },

            hasPiece:function(user, pieceOrId){

                if (_permissions === _PUBLIC) {

                    return true;

                } else {

                    return this.getPrintCount(user, pieceOrId) > 0;

                }

            },

            getPrintCount:function(user, pieceOrId){

                var ownership = _getOwnerships(user);

                return ownership[_getId(pieceOrId)] || 0;

            },

            isNewForYou:function(pieceOrId){
                if (this.getPrintCount(artUser, pieceOrId) === 1) {
                    return true;
                }
                else {
                   return false;
                }
            },

            getImageRatio:function(user, piece, size){

                var imageData = this.getImageData(user, piece, size);

                if(!imageData){
                    if(piece && piece.id){
                        wsLumberjack.error('Missing image data for piece: ' + piece.id);
                    } else {
                        wsLumberjack.error('Missing image data and piece data');
                    }
                    return 1;
                }
                return imageData.width/imageData.height;

            },

            getPromoImageUrl:function(piece){
                try{
                    var imageData = piece.piece_assets.image["large-promo"] ||
                                    piece.piece_assets.image.large;
                    return imageData.url;
                } catch(e){
                    wsLumberjack.exception(e);
                }
                return _DEFAULT_IMAGE_DATA.url;
            },

            getImageData:function(user, piece, size, isPublic){
                try{
                    var hasPiece = isPublic || this.hasPiece(user, piece);
                    var type = hasPiece ? size : size + "-gray";
                    return piece.piece_assets.image[type];
                } catch(e){
                    wsLumberjack.exception(e);
                }
                return _DEFAULT_IMAGE_DATA;
            },

            preloadImagesSeries:function(user, pieces, size, isPublic){

                var imageUrls = this.getImageUrls(user, pieces, size, isPublic);

                function preloadImage(){
                    if(imageUrls.length === 0){
                        return $q.when(); //return an empty promise!
                    }
                    var promise = ImageService.preload(imageUrls.shift());
                    promise.finally(preloadImage);
                    return promise;
                }

                return preloadImage();

            },

            preloadImages:function(user, pieces, size, isPublic){

                return ImageService.preloadAll(this.getImageUrls(user, pieces, size, isPublic));

            },

            getImageUrls:function(user, pieces, size, isPublic){

                var urls = [];

                for(var i=0; i < pieces.length; i++){

                    var imageData = this.getImageData(user, pieces[i], size, isPublic);

                    if(imageData){

                        urls.push(imageData.url);

                    }

                }

                return urls;

            },

            toggleFavorite:function(piece, failure){

                //Reverse url for 'api-piece-favorite-update' (we are constructing it since appending this
                // data involves dealing with cache, so for now this is a quicker win:
                var url = "/api/pieces/" + piece.id + "/favorite/";

                piece.favorite = !piece.favorite; //toggle the status of this piece.
                $http.post(url) //update the server!
                .success(function(){
                    angular.element('.tip').tooltip('hide');
                }).error(function() {
                    piece.favorite = !piece.favorite; //toggle the status of this piece.
                    artMessage.showAlert(
                        "Sorry, we could not " + (piece.favorite ? "unfavorite" : "favorite") +
                        " the card as there was an error. Please contact a dev.");
                });

            }

        };

        //syncing
        if (artUser.isAuthenticated()) {

            artSubscriptionService.subscribe('trade-accepted', function(data){

                //update ownerships!
                if(artUser.getUsername() === data.trader.username){

                    PieceService.addPrintOwnerships(artUser, data.receive.pieces);
                    PieceService.removePrintOwnerships(artUser, data.give.pieces);

                } else {

                    PieceService.addPrintOwnerships(artUser, data.give.pieces);
                    PieceService.removePrintOwnerships(artUser, data.receive.pieces);

                }

            });

        }

        return PieceService;

    }];

});
