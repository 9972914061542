packOpenModule
.directive('poMilestones', function() {

	var poMilestones = {};

	poMilestones.controller = 'poMilestonesCtrl';
	poMilestones.templateUrl = 'partials/pack-open/pack-open-milestones.partial.html';
	
	return poMilestones;
})
.controller('poMilestonesCtrl', [
	'$scope',
    'artSett',
    'poMilestones',
function(
    $scope,
    artSett,
    poMilestones
) {
    $scope.hasSpecialRarities = artSett.hasSpecialRarities.bind(artSett);
    $scope.getRarityStats = poMilestones.getMilestones.bind(poMilestones);
    $scope.isAnimating = poMilestones.isAnimating.bind(poMilestones);

}]);
