creatorModule.factory("ctSelectedSett", function(){
    var ctSelectedSett = {};
    ctSelectedSett.selectedSett = null;
    ctSelectedSett.setSelectedSett = function(sett){
        ctSelectedSett.selectedSett = sett;
    };
    ctSelectedSett.getSelectedSett = function(){
        return ctSelectedSett.selectedSett;
    };

    return ctSelectedSett;
});
