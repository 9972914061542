artModule.directive('artImageLoad', function(){

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        link: function(scope, element){

            element.bind("load", function(){
                element
                    .closest('.loadTarget')
                    .removeClass('loading')
                    .addClass('loaded');
            });

        }
    };

    return directiveDefinitionObject;

});