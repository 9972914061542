artModule.directive('nmUserSettSelect', function() {

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope: {

            displayMinimum: '=',
            user: "=?nmUser",
            slug: "=settSlug"
        },

        controller : [
            '$scope',
            '$timeout',
            '$window',
            'CollectionService',
            'ProfileUser',
            'SetChooserOverlay',
            'nmDiscard',
        function(
            $scope,
            $timeout,
            $window,
            CollectionService,
            ProfileUser,
            SetChooserOverlay,
            nmDiscard
        ){

            var skip_nodes = {
                "A": true,
                "INPUT":true,
                "TEXTAREA":true
            };

            var stopWordRegex = /^the\-/;

            function createSortableNameSlugs(data) {
                for(var i = 0, c = data.length; i < c; i++) {
                    data[i].$name_slug = data[i].name_slug.replace(stopWordRegex, '');
                }
            }

            ProfileUser.fetchCollectedSets().then(onMetricsfetched);

            function onMetricsfetched(data) {
                data = data.concat(); // concat() so we don't harm the source
                $scope.set_choices = data;

                //pass the object to discard factory to manipulate it
                // when a print is discarded
                nmDiscard.passSelectedSet($scope.set_choices);

                createSortableNameSlugs(data);

                if( typeof $scope.displayMinimum === 'number'){
                    $scope.showMenu = $scope.set_choices.length > $scope.displayMinimum;
                }

                $scope.sort();
                selectSettBySlug($scope.slug);
            }

            var selectSettBySlug = function(slugName) {
              for (var i = 0; i < $scope.set_choices.length; i++) {
                if ($scope.set_choices[i].name_slug === slugName) {
                  $scope.selected_set = $scope.set_choices[i];
                  return;
                }
              }

              return null;
            }

            // =======================================================

            $scope.expanded       = false;
            $scope.set_choices    = [];
            $scope.selected_set   = null;
            $scope.cservice       = CollectionService;
            $scope.overlay        = SetChooserOverlay;
            $scope.overlay_active = false;
            $scope.showMenu       = true;

            $scope.filter = function(sett){
                return sett !== $scope.selected_set;
            };

            $scope.sort = function() {
                $scope.set_choices.sort(function(a, b){
                    return a.$name_slug.localeCompare(b.$name_slug);
                });
            };

            $scope.handleClick = function($event) {

                if (!skip_nodes[$event.target.nodeName]) {

                    $scope.toggleExpand();

                    if ($scope.expanded) {
                        $scope.overlay.show();
                    }
                    else {
                        $scope.overlay.hide();
                    }

                    $event.stopPropagation();

                }

            };

            $scope.toggleExpand = function(){

                $scope.expanded = !$scope.expanded;

            };


            $scope.$on('$destroy', function() {

                angular.element($window).unbind('click.set-select');

            });

            angular.element($window).bind('click.set-select', function(){

                $timeout(function(){
                    $scope.expanded = false;
                    $scope.overlay.hide();
                });

            });

        }],

        templateUrl : "partials/collection/user-sett-select.partial.html"

    };

    return directiveDefinitionObject;

});
