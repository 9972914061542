artModule.directive("artMessage", [
    "artMessage",
    function (artMessage) {
        var directiveDefinitionObject;

        directiveDefinitionObject = {
            scope: {},

            controller: ["$scope", function ($scope) {
                $scope.getMessage = artMessage.getMessage.bind(artMessage);
                $scope.getSubtext = artMessage.getSubtext.bind(artMessage);
                $scope.isVisible = artMessage.isVisible.bind(artMessage);
                $scope.getStyleClass = artMessage.getStyleClass.bind(artMessage);
                $scope.getIconClass = artMessage.getIconClass.bind(artMessage);
                $scope.getBlurClass = artMessage.getBlurClass.bind(artMessage);
                $scope.getOkText = artMessage.getOkText.bind(artMessage);
                $scope.confirm = artMessage.close.bind(artMessage);
                $scope.hasCancelButton = artMessage.hasCancelButton.bind(artMessage);
                $scope.hasOkButton = artMessage.hasOkButton.bind(artMessage);

                $scope.cancel = function () {
                    return artMessage.close(true);
                };
            }],

            templateUrl: "partials/art/message.partial.html"
        };
        return directiveDefinitionObject;
    }]);