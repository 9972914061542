creatorModule.controller("ctRoyaltiesController", [
        '$scope',
        '$location',
        'artResource',
        'artUser',
        'ctConfig',
        'ctRoyaltiesService',
        '$filter',
    function(
        $scope,
        $location,
        artResource,
        artUser,
        ctConfig,
        ctRoyaltiesService,
        $filter
    ){

    $scope.filters = {
        year:       null,
        sett:       undefined
    };
    // orderColumn and reverse are used for table sorting
    $scope.orderColumn = 'dateIndex';
    $scope.reverse = false;

    var royaltiesData    = undefined;
    var newCreatorPayoutThreshold = ctConfig.paymentVariables.NEW_CREATOR_PAYOUT_THRESHOLD;
    var fetchPaymentHistory   = ctRoyaltiesService.fetchPaymentHistory.bind(ctRoyaltiesService);
    var fetchLiabilityHistory = ctRoyaltiesService.fetchLiabilityHistory.bind(ctRoyaltiesService);

    $scope.yearChoices      = [];
    $scope.settChoices      = [];
    $scope.paymentMessage = '';
    $scope.dueRoyaltiesCents = ctConfig.dueRoyaltiesCents;
    $scope.showLiabilityInfo = ctConfig.showLiabilityInfo;
    $scope.showPaymentInfo = ctConfig.showPaymentInfo;
    $scope.hasPaidPacks = ctConfig.hasPaidPacks;
    $scope.isKnownCreator = ctConfig.isKnownCreator;
    $scope.canGetPayout = ctConfig.canGetPayout;
    $scope.creditConversionBouns = ctConfig.paymentVariables.CREDIT_CONVERSION_BONUS_PERCENT;
    $scope.cashoutDuration = ctConfig.paymentVariables.MINIMUM_PAYOUT_DURATION;
    $scope.paymentsData          = ctRoyaltiesService.getPaymentsData.bind(ctRoyaltiesService);
    $scope.getLiabilitiesData    = ctRoyaltiesService.getLiabilitiesData.bind(ctRoyaltiesService);
    $scope.launchSelfPay         = ctRoyaltiesService.launchSelfPay.bind(ctRoyaltiesService);
    $scope.disableRoyaltyConversionButtons  = ctRoyaltiesService.sendPaymentButtonStatus.bind(ctRoyaltiesService);
    $scope.updatePaymentAfterConversion  = ctRoyaltiesService.sendPaymentTextAfterConversion.bind(ctRoyaltiesService);

    var _TODAY = new Date();
    var _CURRENT_MONTH = _TODAY.getMonth();
    var _CURRENT_YEAR = _TODAY.getFullYear();

    $scope.canCashout = function() {
        return $scope.dueRoyaltiesCents > 0;
        return $scope.canGetPayout && $scope.dueRoyaltiesCents >= ctConfig.paypalThreshold;
    };

    $scope.canRedeemAsCredit = function() {
        return $scope.dueRoyaltiesCents > 0;
        return $scope.isKnownCreator && $scope.dueRoyaltiesCents >= ctConfig.creditThreshold;
    };

    $scope.canSelfPay = function() {
        if($scope.dueRoyaltiesCents < 0) {
            $scope.paymentMessage = (
                "You currently must earn <strong>" + $filter('centsToDollars')(Math.abs($scope.dueRoyaltiesCents)) +
                "</strong> against your advance payment before you will be able to begin collecting earnings.");
            return true;
        }
        if($scope.dueRoyaltiesCents == 0) {
            $scope.paymentMessage = "You have no earnings. Sell some packs to earn royalties.";
            return true;
        }
        // For shutdown.
        $scope.paymentMessage = "You currently have <strong>" + $filter('centsToDollars')($scope.dueRoyaltiesCents) + "</strong> in earnings available to redeem as cash or credits.";
        return true;
        if($scope.dueRoyaltiesCents >= ctConfig.creditThreshold && $scope.dueRoyaltiesCents < ctConfig.paypalThreshold) {
            $scope.paymentMessage = "You have <strong>" + $filter('centsToDollars')($scope.dueRoyaltiesCents) + "</strong> in earnings.";
            if($scope.isKnownCreator) {
                $scope.paymentMessage += "You can redeem credits or wait to reach the minimum threshold of <strong>" + $filter('centsToDollars')(ctConfig.paypalThreshold) + "</strong> to redeem as cash.";
            } else if(!$scope.isKnownCreator && newCreatorPayoutThreshold){
                $scope.paymentMessage += " Please wait " + newCreatorPayoutThreshold + $filter('pluralize')(" day", newCreatorPayoutThreshold) +" after your series is published before attempting your first self-pay.";
            }
            return true;
        }
        if($scope.dueRoyaltiesCents <= ctConfig.creditThreshold && $scope.dueRoyaltiesCents > 0) {
            $scope.paymentMessage = "You have <strong>" + $filter('centsToDollars')($scope.dueRoyaltiesCents) + "</strong> in earnings. You must reach the minimum threshold of <strong>" + $filter('centsToDollars')(ctConfig.paypalThreshold) + "</strong> to cash out or <strong>" + $filter('centsToDollars')(ctConfig.creditThreshold) + "</strong> to convert your royalties to credits.";
            return true;
        }
        if($scope.dueRoyaltiesCents >= ctConfig.paypalThreshold && $scope.dueRoyaltiesCents > 0) {
            $scope.paymentMessage = "You currently have <strong>" + $filter('centsToDollars')($scope.dueRoyaltiesCents) + "</strong> in earnings available to redeem as cash or credits.";
            if(!$scope.canGetPayout && $scope.cashoutDuration && $scope.isKnownCreator) {
                $scope.paymentMessage += " You must wait " + $scope.cashoutDuration + $filter('pluralize')(" day", $scope.cashoutDuration) +" after your last <br/>self-pay to process another cash payment.";
            } else if (!$scope.canGetPayout && newCreatorPayoutThreshold && !$scope.isKnownCreator){
                $scope.paymentMessage += " Please wait " + newCreatorPayoutThreshold + $filter('pluralize')(" day", newCreatorPayoutThreshold) +" after your series is published before attempting your first self-pay.";
            }
            return true;
        }
    };

    $scope.setOrderColumn = function(orderColumn) {

        $scope.orderColumn = orderColumn;
        $scope.reverse = !$scope.reverse;

    };

    $scope.isCreatorAdmin = artUser.isCreatorAdmin.bind(artUser);

    function includeDataInChart(month, year){

        return !('id' in $scope.filters.year) || (year in $scope.indexData && month in $scope.indexData[year]);

    }

    function noPackData( sett ){

        return (sett.free_packs.length === 0 && sett.purchased_packs.length === 0);

    }

    function filteredOutSett( sett ){

        return ('id' in $scope.filters.sett) && ($scope.filters.sett.id != sett.id);

    }

    function buildRoyaltyDataSkeleton(){

        $scope.indexData = {};
        $scope.salesTotals = {
            paid_packs: 0,
            paid_credits: 0,
            free_packs: 0,
            royalty_cents: 0,
            credits: 0
        };
        $scope.monthlySales     = [];

        var yearIterator, monthIterator, iterator=0, firstYear, maxYear;

        if ($scope.filters.year.id === 0) {
            maxYear = _CURRENT_YEAR;
            firstYear = _CURRENT_YEAR - 1;
            if (_CURRENT_MONTH === 11) {
                firstYear = _CURRENT_YEAR;
            }
        } else if ($scope.filters.year.id) {
            maxYear = $scope.filters.year.id;
            firstYear = $scope.filters.year.id;
        } else {
            maxYear = _CURRENT_YEAR;
            firstYear = royaltiesData.firstYear;
        }

        for (yearIterator = maxYear; yearIterator >= firstYear; yearIterator--) {
            var maxMonth, minMonth;
            if ($scope.filters.year.id !== 0) {
                minMonth = 0;
                maxMonth = 11;
                if(yearIterator === _CURRENT_YEAR){
                    maxMonth = _CURRENT_MONTH;
                }
            } else {
                if(yearIterator === _CURRENT_YEAR){
                    maxMonth = _CURRENT_MONTH;
                    minMonth = 0;
                } else {
                    maxMonth = 11;
                    minMonth = Math.min(_CURRENT_MONTH + 1 % 12);
                }
            }
            $scope.indexData[yearIterator] = {};
            for (monthIterator = maxMonth; monthIterator >= minMonth; monthIterator--) {
                $scope.indexData[yearIterator][monthIterator] = iterator;
                $scope.monthlySales.push({
                    dateIndex: iterator,
                    month: monthIterator,
                    year: yearIterator,
                    paid_packs: 0,
                    royalty_cents: 0,
                    total_cents: 0,
                    free_packs: 0
                });
                iterator ++;
            }
        }
    }

    function buildRoyaltyData(){
        var monthData, dataIndex;
        buildRoyaltyDataSkeleton();
        if (royaltiesData) {

            for( var i=0; i < royaltiesData.length ; ++i ){
                var sett = royaltiesData[i];
                if (filteredOutSett(sett) === false ){

                    for( var j=0; j < sett.purchased_packs.length ; ++j ){
                        monthData = sett.purchased_packs[j];

                        if( includeDataInChart(monthData.month-1, monthData.year)){
                            dataIndex = $scope.indexData[monthData.year][monthData.month-1];

                            $scope.monthlySales[dataIndex].paid_packs += monthData.items;
                            $scope.monthlySales[dataIndex].royalty_cents += monthData.royalty_cents;
                            $scope.monthlySales[dataIndex].total_cents += monthData.sale_price_cents;

                            $scope.salesTotals.paid_packs       += monthData.items;
                            $scope.salesTotals.royalty_cents    += monthData.royalty_cents;

                        }
                    }

                    for( var k=0; k < sett.free_packs.length ; ++k ){
                        monthData = sett.free_packs[k];
                        if( includeDataInChart(monthData.month-1, monthData.year)){
                            dataIndex = $scope.indexData[monthData.year][monthData.month-1];
                            $scope.salesTotals.free_packs += monthData.items;
                            $scope.monthlySales[dataIndex].free_packs += monthData.items;


                        }
                    }

                }

            }

        }

    }


    function buildYearChoices(firstYear){

        $scope.yearChoices.splice(0);

        for (var yearIterator = _CURRENT_YEAR; yearIterator >= firstYear; yearIterator--) {
            var isThisYear = false;
            if(yearIterator === _CURRENT_YEAR){
                isThisYear = true;
            }
            $scope.yearChoices.push({

                label:   yearIterator.toString() + (isThisYear?' (This Year)': ''),
                id:      yearIterator

            });

        }

        if ($scope.yearChoices.length > 1) {
            $scope.yearChoices.unshift({label: 'All Years'});
        }
        $scope.yearChoices.unshift({label: 'Last 12 Months', id:0});
        $scope.filters.year = $scope.yearChoices[0];

    }

    function buildSettChoices(){

        var sett;
        $scope.settChoices.splice(0);
        $scope.settChoices.push({name: 'All Series', sales_url: ctConfig['creator-sales-url'] });
        $scope.filters.sett = $scope.settChoices[0];

        if (royaltiesData) {

            for( var i=0; i < royaltiesData.length ; ++i ){
                sett = royaltiesData[i];
                if( noPackData(sett) === false ){

                    $scope.settChoices.push( sett );

                    if( sett.id == ctConfig['settId'] ){
                        $scope.filters.sett = sett;
                    }

                }

            }

            if ($scope.settChoices.length === 2) {
                // remove "all collections" from options
                $scope.filters.sett = $scope.settChoices[1];
                $scope.settChoices.splice(0,1);

            }

        }

    }


    $scope.settFilterChanged = function(){

        var sett = $scope.filters.sett;

        $location.path(sett.sales_url).replace();

        $scope.updateData();

    };

    fetchPaymentHistory();
    fetchLiabilityHistory();

    $scope.updateData = function(){
        var defaultFirstYear, royaltiesUrl, releaseYear;
        if (!royaltiesData) {

            if (ctConfig['api-royalties-detail']) {
                royaltiesUrl = ctConfig['api-royalties-detail'];
                defaultFirstYear = _CURRENT_YEAR;
            } else {
                // FIXME: Separate controller for admin earnings. Or else this block would be required.
                // Have removed the api-royalties-list API that was getting invoked here.
                return
            }

            artResource.retrieve(royaltiesUrl).then(function(data) {
                royaltiesData = data;

                // get first year of data to avoid empty data in chart
                royaltiesData.firstYear = defaultFirstYear;
                for (var i = 0; i < data.length; i++) {

                    if (royaltiesData[i].released) {
                        releaseYear = new Date(royaltiesData[i].released).getFullYear();
                        if ( releaseYear < royaltiesData.firstYear) {
                            royaltiesData.firstYear = releaseYear;
                        }
                    }

                }

                buildSettChoices();
                buildYearChoices(royaltiesData.firstYear);
                buildRoyaltyData();
                $scope.ready = true;
            });

        } else {
            buildRoyaltyData();
        }
    };

    $scope.updateData();
}]);

creatorModule.service("ctRoyaltiesService", [
    "artOverlay",
    "artResource",
    "ctConfig",
    function(
        artOverlay,
        artResource,
        ctConfig
){
    var SELF_PAY_PREFIX = 'self-pay--',
        _paymentsData = [],
        _liabilitiesData = [],
        _disablePaymentButton = false,
        _paymentTextAfterConversion = false;

    artOverlay.bindNameToTemplateUrl(
        SELF_PAY_PREFIX + "cashout", "partials/creator/overlay/cashout.partial.html", "modal");
    artOverlay.bindNameToTemplateUrl(
        SELF_PAY_PREFIX + "convert-credits", "partials/creator/overlay/convert-credits.partial.html", "modal");

    function markLiabilitiesSettled() {
        if (_liabilitiesData.length) {
            angular.forEach(_liabilitiesData, function(data) {
                data.status = 'Settled';
            });
        }
    };

    var ctRoyaltiesService = {
        addPayment: function(paymentData) {
            markLiabilitiesSettled();
            return _paymentsData.unshift(paymentData);
        },
        getPaymentsData: function() {
            return _paymentsData;
        },
        getLiabilitiesData: function() {
            return _liabilitiesData;
        },
        sendPaymentButtonStatus: function() {
            return _disablePaymentButton;
        },
        sendPaymentTextAfterConversion: function() {
            return _paymentTextAfterConversion;
        },
        disableRoyaltyConversionButtons: function(val) {
            _disablePaymentButton = val;
        },
        updatePaymentAfterConversion: function(val) {
            _paymentTextAfterConversion = val;
        },
        fetchPaymentHistory: function() {
            if (ctConfig['api-royalties-payments']) {
                artResource.retrieve(ctConfig['api-royalties-payments']).then(function(data){
                    _paymentsData = data;
                });
            }
        },
        fetchLiabilityHistory: function() {
            if (ctConfig['api-liabilities-payments']) {
                artResource.retrieve(ctConfig['api-liabilities-payments']).then(function(data){
                    _liabilitiesData = data;
                });
            }
        },
        launchSelfPay: function(selfPayType) {
            artOverlay.show(SELF_PAY_PREFIX + selfPayType, null, false);
        }
    };
    return ctRoyaltiesService;
}]);

creatorModule.controller("selfPayController", [
    "$scope",
    "artMessage",
    "artOverlay",
    "artResource",
    "ctConfig",
    "ctRoyaltiesService",
    "artUser",
    function(
        $scope,
        artMessage,
        artOverlay,
        artResource,
        ctConfig,
        ctRoyaltiesService,
        artUser
){
    $scope.formSubmitted = false;
    $scope.enableEmailBox = false;

    $scope.enableEmailBoxWhenEdit = function() {
        $scope.enableEmailBox = true;

    };

    $scope.paypalEmail = $scope.refConfigPaypalEmail = ctConfig.paypalEmail;
    $scope.dueRoyaltiesCents = ctConfig.dueRoyaltiesCents;
    $scope.paypalFeePercent = ctConfig.paymentVariables.PAYPAL_PAYOUT_FEE;
    $scope.paypalFeeCents = parseInt(Math.max($scope.dueRoyaltiesCents * $scope.paypalFeePercent / 100, 25));
    $scope.actualRoylatiesCents = $scope.dueRoyaltiesCents - $scope.paypalFeeCents;
    $scope.totalCredit = ctConfig.totalCredit;
    $scope.royaltyUsername = ctConfig.royaltyUsername;
    $scope.bonusCredit = ctConfig.bonusCredit;
    $scope.actualCredit = ctConfig.totalCredit - ctConfig.bonusCredit;

    $scope.postPayment = function(){
        artMessage.showAlertWithCancel({
            message: "Your earnings will be cashed out to PayPal account <br><i>" + $scope.paypalEmail + "</i>",
            subtext: "Please confirm your information is correct. This payment is irreversible."
        }, function(canceled){
            if (canceled) {
                $scope.formSubmitted = false;
                return
            };
            $scope.formSubmitted = true;
            $scope.cashoutData = {
                paypal_email: $scope.paypalEmail
            };
            artResource.update($scope.cashoutData, ctConfig['api-royalties-detail'])
                .then(function(data){
                    $scope.formSubmitted = false;
                    artOverlay.hide();
                    if (data.created) {
                        ctRoyaltiesService.addPayment(data.payout);
                        ctRoyaltiesService.disableRoyaltyConversionButtons(true);
                        ctRoyaltiesService.updatePaymentAfterConversion(true);
                        message = "Your cash out has been sent to PayPal account <br><i>" + $scope.paypalEmail + "</i>";
                        subtext = "PayPal payments normally take few minutes to a couple of days to reflect in account statement"
                        artMessage.showAlert({"message": message, "subtext": subtext}, null);
                    } else {
                        artOverlay.hide();
                        message = ctConfig['royalty-user-name'] +  " had no outstanding payments due. No payment was posted.";
                        artMessage.showAlert(message, null);
                    }
                }, function(error) {
                    artOverlay.hide();
                    var error_message = (error && error.fields) === undefined ? error.detail : error.fields.error_message;
                    artMessage.showAlert(error_message);
                });
        }, "Confirm");
    };

    $scope.postCreditConversion = function() {
        artMessage.showAlertWithCancel({
            message: "Your earnings will be converted to <b>" + $scope.totalCredit + "</b> NeonMob credits.",
            subtext: "Would you like to continue? This action is irreversible."
        }, function(canceled){
            if (canceled) {
                $scope.formSubmitted = false;
                return
            };
            $scope.formSubmitted = true;
            $scope.cashoutData = {};
            artResource.update($scope.cashoutData, ctConfig['api-credit-conversion-detail'])
                .then(function(data) {
                    $scope.formSubmitted = false;
                    artOverlay.hide();
                    if (data.created) {
                        ctRoyaltiesService.addPayment(data.payout);
                        ctRoyaltiesService.disableRoyaltyConversionButtons(true);
                        ctRoyaltiesService.updatePaymentAfterConversion(true);
                        var isSelfPay = artUser.isYou({username:$scope.royaltyUsername});
                        if(isSelfPay && window.NM && window.NM.you){
                            window.NM.you.addCredits($scope.totalCredit);
                        }
                        message = "Sweet action! You successfully redeemed <b>" + $scope.totalCredit + "</b> shiny credits against your outstanding royalties!";
                        // FIXME: The arguments should be sent as an object to avoid positional null values
                        artMessage.showInfo(message, null, null, 'credit larger');
                    } else {
                        artOverlay.hide();
                        message = ctConfig['royalty-user-name'] +  " had no outstanding payments due.";
                        artMessage.showAlert(message, null);
                    }
                }, function(error) {
                    artOverlay.hide();
                    var error_message = (error && error.fields) === undefined ? error.detail : error.fields.error_message;
                    artMessage.showAlert(error_message);
            });
        });
    };
}]);
