neonmobApp.directive("collectionTabs", function() {
    var collectionTabs = {};

    collectionTabs.scope = {
        username: "@collectionTabsUsername",
    };

    collectionTabs.controller = "collectionTabController";
    collectionTabs.templateUrl = "partials/collection/collection-tabs.partial.html";

    return collectionTabs;
})
.controller("collectionTabController", [
    "$scope",
    "$location",

    function (
        $scope,
        $location
    ) {

    /*
    * Your collection tab options.
    */
    $scope.tabOptions = [
        {
            name: 'Series',
            tab: 'series'
        },
        {
            name: 'Cards',
            tab: 'cards'
        },
        {
            name: 'Activity',
            tab: 'activity'
        }
    ];

    $scope.selectedTab = 'series';
    if($location.search().cards) {
        $scope.selectedTab = 'cards';
    }

    $scope.onTabClick = function(selectedTab) {
        $scope.selectedTab = selectedTab;

        switch($scope.selectedTab) {
            case 'cards':
                $location.path("/" + $scope.username + "/cards/");
                break;
            case 'series':
                $location.path("/" + $scope.username + "/collection/");
                break;
            case 'activity':
                $location.path("/" + $scope.username + "/activity/");
                break;
        }
    };

    $scope.onTabClick($scope.selectedTab);

}]);
