artModule.factory("artCsrfInterceptor", [
    "$injector",
function(
    $injector
){

    //Where's $cookies? The issue with $cookies is that it can take till the next digest loop to update. thus
    //you run a risk of grabbing an old cookie value if you grab it too early. yay... angular...
    function _getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var artCsrfInterceptor = {};

    artCsrfInterceptor.response = function(response){

        //This is done because adding $http to the factory definition will cause a dependency loop. yay...
        $injector.invoke(["$http", function($http){

            var csrfToken = _getCookie('csrftoken');

            $http.defaults.headers.post['X-CSRFToken'] = csrfToken;
            $http.defaults.headers.put['X-CSRFToken'] = csrfToken;
            $http.defaults.headers.patch['X-CSRFToken'] = csrfToken;
            $http.defaults.headers['delete'] = {
                'X-CSRFToken' : csrfToken
            };

        }]);

        return response;

    };

    return artCsrfInterceptor;

}]);