neonmobApp.controller('ProfileController', [
    '$scope',
    '$q',
    'artConfig',
    'artMessage',
    'artResource',
    'artSett',
    'artUrl',
    'artUser',
    'ProfileUser',
    function ($scope,
              $q,
              artConfig,
              artMessage,
              artResource,
              artSett,
              artUrl,
              artUser,
              ProfileUser) {

    
        var recentSettsUrl = artUrl.updateParams(artConfig.api['api-setts-legacy-list'], {
            category:'recent',
            page_size: 6,
            user_id: ProfileUser.userId,
            metrics_id: ProfileUser.userId
        });

        var MIN_SETT_TILES = 3;

        $scope.ready = false;
        $scope.padSetts = false;

        $q.all({
            fetchUser: ProfileUser.fetchUser(),
            recentlyCollected: artResource.retrievePaginated(recentSettsUrl)
        }).then(function (result) {
            $scope.ready = true;
            $scope.user = result.fetchUser.data;
            $scope.recentSetts = result.recentlyCollected;
            $scope.recentNext = result.recentlyCollected.retrieveNext;

            // pad recently collected
            if ($scope.recentSetts.length < MIN_SETT_TILES && $scope.isYou()) {
                $scope.padSetts = true;
                var discoverSettsUrl = artUrl.updateParams(artConfig.api['api-setts-legacy-list'], {
                    category:'newest',
                    page_size: MIN_SETT_TILES
                });
                artResource.retrieve(discoverSettsUrl).then(function(data){
                    var ids = $.map($scope.recentSetts, function(e){return e['id'];});
                    var paddedSetts = data.results;
                    // deduplicate results
                    paddedSetts = paddedSetts.filter(function(e, i){return ids.indexOf(e.id) < 0;});
                    $scope.recentSetts = $scope.recentSetts.concat(paddedSetts).slice(0, MIN_SETT_TILES);
                });
            }
        }).catch(function(result){
            artMessage.showAlert({
                message: "Unable to load your profile.",
                subtext: "Try refreshing the page or contact us at support@neonmob.com"
            });
        });

        function viewCollection(event, sett) {
            ProfileUser.fetchUser().then(function (response) {
                document.location.href = artSett.getUserSettLink(sett, response.data);
            });
        }

        $scope.isYou = function () {
            if (!$scope.user || !artUser.isAuthenticated()) return false;
            return $scope.user.username === artUser.getUsername();
        };

        $scope.$on('updateCollection', viewCollection);

    }]);
