neonmobApp.directive('nmMilestonesCollection', function() {

    var directiveDefinitionObject = {};

    directiveDefinitionObject.controller = "MilestonesListController";

    directiveDefinitionObject.templateUrl = "partials/badges/milestones-collection.partial.html";

    return directiveDefinitionObject;

});
