artModule.factory("artShare", [
    "$window",
    'artAnalytics',
    "artUrl",
    "artUser",
    "artConfig",
    function ($window,
              artAnalytics,
              artUrl,
              artUser,
              artConfig) {

        var artShare = {},
            _channelAdaptors = {};

        //These are the same name as the backend constants: accounts.constants
        artShare.REFERRAL_CODE_PARAM = "rc";
        artShare.REFERRAL_CHANNEL_PARAM = "rh";
        artShare.REFERRAL_SOURCE_PARAM = "rs";

        artShare.registerChannelAdaptor = function (channel, handler) {
            _channelAdaptors[channel] = handler;
        };

        artShare.getChannels = function () {
            return Object.keys(_channelAdaptors).sort();
        };

        artShare.createShareUrl = function (url, source, channel) {
            var params = {};
            if (channel) {
                params[artShare.REFERRAL_CHANNEL_PARAM] = channel;
            }
            if (source) {
                params[artShare.REFERRAL_SOURCE_PARAM] = source;
            }
            if (artUser.isAuthenticated() && url.indexOf(artUser.getUrl('referral_url')) < 0) {
                params[artShare.REFERRAL_CODE_PARAM] = artUser.getReferralCode();
            }

            min = 1000;
            max = 9999;
            cacheBuster= Math.floor(Math.random() * (max - min) + min);

            params["nmr"] = cacheBuster;
            return artUrl.updateParams(url, params);
        };

        artShare.shareOn = function (channel, source, url, message, imageUrl) {
            if (!(channel in _channelAdaptors)) {
                throw new Error("The share channel '" + channel + "' is not registered.");
            }
            if (!artConfig.canBrag) {
                return;
            }

            url = artUrl.addProtocol(url);
            imageUrl = artUrl.addProtocol(imageUrl);

            //call the share handler!
            _channelAdaptors[channel](artShare.createShareUrl(url, source, channel), message, imageUrl, function () {
                if (artUser.isAuthenticated()) {
                    artAnalytics.track("Posted Referral Url", {
                        channel: channel,
                        source: source,
                        referrerId: artUser.getId(),
                        referrerUsername: artUser.getUsername()
                    });
                    return;
                }

                //Not sure how important this data is
                artAnalytics.track("Posted Referral Url Anonymously", {
                    channel: channel,
                    source: source
                });
            });
        };

        //Default handlers!
        artShare.registerChannelAdaptor("twitter", function (url, message, imageUrl, onSuccess) {
            var postUrl = "https://twitter.com/share?url=" + encodeURIComponent(url) +
                "&text=" + encodeURIComponent(message);

            $window.open(postUrl, "_tweetIt", "width=600,height=520");
            onSuccess();
        });

        artShare.registerChannelAdaptor("facebook", function (url, message, imageUrl, onSuccess) {
            $window.FB.ui({
                method: 'share',
                href: url
            }, function (response) {
                if (response) {
                    onSuccess();
                }
            });
        });

        artShare.registerChannelAdaptor("pinterest", function (url, message, imageUrl, onSuccess) {
            var postUrl = "http://pinterest.com/pin/create/button/?url=" + encodeURIComponent(url) +
                "&description=" + encodeURIComponent(message) + "&media=" + encodeURIComponent(imageUrl);

            $window.open(postUrl, "_pinIt", "width=632,height=270");
            onSuccess();
        });

        return artShare;

    }]).directive("artShareOn", ["artShare", function (artShare) {

    var artShareOn = {};

    artShareOn.scope = {
        type: "@artShareOn",
        url: "=artShareUrl",
        source: "=artShareSource",
        message: "=artShareMessage",
        imageUrl: "=artShareImageUrl"
    };

    artShareOn.link = function (scope, element) {
        element.click(function () {
            artShare.shareOn(scope.type, scope.source, scope.url, scope.message, scope.imageUrl);
        });
    };

    return artShareOn;

}]).filter("artCreateShareUrl", [
    'artShare',
    function (artShare) {
        return artShare.createShareUrl;
    }]);
