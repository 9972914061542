/*%%. `@%%    ;@@%;        
  ;@%. :@%%  %@@%;       
    %@bd%%%bd%%:;             
      #@%%%%%:;;      ▄▄▌ ▐ ▄▌            ·▄▄▄▄  .▄▄ ·  ▄▄▄· ▄▄▌ ▐ ▄▌    ▐▄▄▄.▄▄ · 
      %@@%%%::;       ██· █▌▐█▪     ▪     ██▪ ██ ▐█ ▀. ▐█ ▀█ ██· █▌▐█     ·██▐█ ▀. 
      %@@@%(o);  . '  ██▪▐█▐▐▌ ▄█▀▄  ▄█▀▄ ▐█· ▐█▌▄▀▀▀█▄▄█▀▀█ ██▪▐█▐▐▌   ▪▄ ██▄▀▀▀█▄
      %@@@o%;:(.,'    ▐█▌██▐█▌▐█▌.▐▌▐█▌.▐▌██. ██ ▐█▄▪▐█▐█ ▪▐▌▐█▌██▐█▌   ▐▌▐█▌▐█▄▪▐█
  `.. %@@@o%::;        ▀▀▀▀ ▀▪ ▀█▄▀▪ ▀█▄▀▪▀▀▀▀▀•  ▀▀▀▀  ▀  ▀  ▀▀▀▀ ▀▪ ▀  ▀▀▀• ▀▀▀▀ 
     `)@@@o%::;         
      %@@(o)::;       AngularJS User Behavior & Error Catching Module
     .%@@@@%::;       @NeonMob @meanJim 2014
     ;%@@@@%::;.      Feedback is welcome because I'm not very smart.       */

/*

    Woodsaw is currently under heavy revision for version 0.0.2

    Changelog 0.0.2

    + Convert comments into angular standard.
    + Global exception handling outside of AngularJS option
    + Debug Mode / Production Mode

*/
var woodsawModule = angular.module('Woodsaw', []);

woodsawModule.constant('MODULE_VERSION', '0.0.1');

woodsawModule.config([
    '$provide',
function(
    $provide
){

    $provide.decorator('$exceptionHandler', [
        '$delegate',
        'wsLumberjack',
        function($delegate, wsLumberjack){

            return function(exception, cause){

                $delegate(exception, cause);
                wsLumberjack.exception(exception, cause);

            };

        }
    ]);

}]);

woodsawModule.provider('wsLumberjack', function(){

    var _logger = window.console;
    var _exceptionHandler;

    if(window.Raven){

        _exceptionHandler = function(exception, cause){

            window.Raven.captureException(exception, {extra: {cause: cause}});
            _log("error", exception, cause);

        }

    } else {

        _exceptionHandler = console.error;

    }

    this.setLogger = function(logger){

        _logger = logger;

    };

    this.setExceptionHandler = function(exceptionHandler){

        _exceptionHandler = exceptionHandler;

    };

    function _log(level, args){

        //Use logentries as our logger!
        _logger[level||"log"].apply(_logger, args);

    }

    this.$get = function(){

        var serviceDefinition = {};

        serviceDefinition.warn = function() {

            _log("warn", arguments);

        };

        serviceDefinition.error = function() {

            _log("error", arguments);

        };

        serviceDefinition.exception = function(error, message){

            _exceptionHandler(error, message);

        };

        serviceDefinition.info = function() {

            _log("info", arguments);

        };

        serviceDefinition.log = function( data ) {

            _log("log", arguments);

        };

        return serviceDefinition;

    }

});