artModule.factory("artBlockUserService", [
    "$http",
    "$q",
    '$rootScope',
    'artConfig',
    'artFriendsService',
    'artResource',
    'artUrl',
    'artUser',
function(
    $http,
    $q,
    $rootScope,
    artConfig,
    artFriendsService,
    artResource,
    artUrl,
    artUser
){

    var artBlockUserService = {};
    var _blockUserPromise = null;
    var _blockedUsers = [];

    if (artUser.isAuthenticated()) {
        _blockUserPromise = artResource.retrieve(artConfig.api["api-block-user-list"]).then(function (data) {
            _blockedUsers = data;
        });
    }

    function _isUserInBlockList(user) {
        _blockedUsers.forEach(function (u, i) {
            if (u.id === user.id) {
                return true;
            }
        });

        return false;
    }

    artBlockUserService.isBlocked = function (user) {
        if( artUser.isYou(user) ) {
            return $q.when({
                is_blocked: false,
                user_initiated: false
            });
        }

        if(_isUserInBlockList(user)){
            return $q.when({
                is_blocked: true,
                user_initiated: true
            });
        } else {
            // DJANGO ENDPOINT NAME: api-block-user-detail
            var url = artUrl.join(artConfig.api['api-block-user-list'], user.id, true);
            return $q.when($http.get(url)).then(function(response){
                return response.data;
            });
        }
    };

    artBlockUserService.blockUser = function (user) {
        return _blockUserPromise.then(function(){
            artResource.create(artConfig.api['api-block-user-list'], {
                id: user.id
            }).then(function(blockedUser){
                _blockedUsers.push(blockedUser);

                if (artFriendsService.isFriend(user)) {
                    artFriendsService.removeFriend(user.id);
                }

                $rootScope.$broadcast("updatedBlockedUsers", {userId: blockedUser.id, isBlocked: true});
            });
        });
    };

    artBlockUserService.unblockUser = function (user) {
        return _blockUserPromise.then(function(){
            var index, blockedUser;
            _blockedUsers.forEach(function(u, i){
                if(u.id === user.id){
                    blockedUser = u;
                    index = i;
                }
            });

            if(blockedUser){
                return artResource.destroy(blockedUser).then(function(){
                    _blockedUsers.splice(index, 1);

                    $rootScope.$broadcast("updatedBlockedUsers", {userId: blockedUser.id, isBlocked: false});
                });
            } else {
                return artResource.destroy({
                    // api-block-user-detail url.
                    url: "/api/block_user/" + user.id
                });
            }
        });
    }

    return artBlockUserService;
}]);
