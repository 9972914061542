creatorModule.controller("CreatorAdminSeriesCtrl", [
    '$scope',
    'artConstants',
    'artSett',
    function(
        $scope,
        artConstants,
        artSett
    ) {
    $scope.releasedOptions = [
        { name: 'All States', value: -1 },
        { name: 'Created', value: artConstants['SETT_FILTERS'].created },
        { name: 'In-Progress', value: artConstants['SETT_FILTERS']['in-progress'] },
        { name: 'Submitted', value: artConstants['SETT_FILTERS']['submitted'] },
        { name: 'Published', value: artConstants['SETT_FILTERS']['published'] }
    ];
    $scope.settTypeOptions = [
        { name: 'All Types', value: -1 },
        {name: artSett.getSettTypeDisplay(artConstants.SETT_TYPE_VALUES['normal']), value: artConstants.SETT_TYPE_VALUES['normal']},
        {name: artSett.getSettTypeDisplay(artConstants.SETT_TYPE_VALUES['promo-only']), value: artConstants.SETT_TYPE_VALUES['promo-only']},
        {name: artSett.getSettTypeDisplay(artConstants.SETT_TYPE_VALUES['amateur']), value: artConstants.SETT_TYPE_VALUES['amateur']}
    ];
    $scope.filters = {
        releasedOption: $scope.releasedOptions[0],
        settType: $scope.settTypeOptions[0],
        searchString: ""
    };
}]);
