// o(((([====================>
//
//		my tool for passing data around.
//
// o(((([====================>

neonmobApp.factory("Passer", ['$rootScope', function($rootScope) {

	var PasserService = {};

    function generateTag(id, context){

        return context+"-"+id;

    }

	PasserService.send = function(id, context, data) {

		this.data = data;
		$rootScope.$broadcast(generateTag(id, context));

	};

    PasserService.subscribe = function(id, context, callback){

        var self = this;

        $rootScope.$on(generateTag(id, context), function(){

            callback(self.data);

        });
    };

    PasserService.unsubscribe = function(id, context) {

        var name = generateTag(id, context);

        $rootScope.purgeListeners(name);

    };

	return PasserService;

}]);