artModule.controller("artDisplayCaseController", [
    "$scope",
    "$http",
    "artResource",
    "artConfig",
    "artUser",
    "artNodeHttpService",
    "artSubscriptionService",
    "artResponsive",
    "TileMasonry",
    "nmPieceDetailService",
    "artDisplayChooseService",
    function ($scope,
              $http,
              artResource,
              artConfig,
              artUser,
              artNodeHttpService,
              artSubscriptionService,
              artResponsive,
              TileMasonry,
              nmPieceDetailService,
              artDisplayChooseService
              ) {

        var fetchUrl = "";
        var saveUrl = "";
        var currentUserId = 0;
        var selectedPieceId = 0;
        var pieces = [];

        $scope.displayCasePieces = [];
        $scope.viewState = "loading";
        $scope.isYou = false;
        $scope.editing = false;

        function init() {
            fetchUrl = artConfig["node-api-endpoint"] + "/user/" + artConfig.targetId + "/display-case/";

            currentUserId = artUser.isAuthenticated() ? artUser.getId() : 0;
            if(currentUserId) {
                saveUrl = artUser.getUrl("display_case_save");

                if(currentUserId === artConfig.targetId) {
                  $scope.isYou = true;
                }
            }

            selectedPieceId = 0;

            pieces = [];
            $scope.displayCasePieces = [];
            $scope.viewState = "loading";

            getPieces();
        }

        function getPieces() {
            $scope.viewState = "loading";
            artNodeHttpService.get(fetchUrl).then(onResults, onError);
        }

        function onResults(data) {
            pieces = data;
            setupPieces();
            artDisplayChooseService.setDisplayCasePieces(pieces);
            $scope.viewState = "loaded";
        }

        function setupPieces() {
            var layout = getViewLayout();
            $scope.imageWidth = layout.imageWidth;
            $scope.columnWidth = layout.columnWidth;

            $scope.displayCasePieces = TileMasonry(pieces, layout.columns, function(piece) {
                return piece.piece_assets.image.large.height;
            });
        }

        function onError() {
            $scope.viewState = "error";
        }

        function getViewLayout() {
            var width = artResponsive.getWindowWidth(),
                padding = 10,
                columns = 4;

            width = width > 960 ? 960 : width;
            columns = width <= 480 ? 2 : columns;
            padding = columns < 4 ? 5 : padding;

            return {
                columnWidth: Math.floor(width/columns),
                imageWidth: Math.floor(width/columns - padding*2),
                columns: columns
            };
        }

        function getIdxByPieceId(id) {
            for(var i = 0, c = pieces.length; i < c; i++) {
              if(pieces[i].id === id) {
                return i;
              }
            }
            return -1;
        }

        $scope.selectPieceId = function(pieceId) {
            // event.stopPropagation(); // se we don't trigger the overlay
            selectedPieceId = pieceId;

        };

        var resizeListener = artResponsive.onResize(setupPieces);
        var currentPieceIds = [];

        function onPieceSelected(newPieceId) {
            if(!saveUrl) {
              return;
            }

            currentPieceIds = [];
            for(var i = 0, c = pieces.length; i < c; i++) {
              currentPieceIds[i] = pieces[i].id;
            }

            var postBody = {
                replace_piece_id: selectedPieceId,
                current_ids: currentPieceIds
            };

            if (newPieceId !== 'random') {
              postBody["piece_id"] = newPieceId;
            }

            artResource.create(saveUrl, postBody).then(function (data) {
                var newPiece = data.piece_added;

                var layout = getViewLayout();
                newPiece.imageWidth = layout.imageWidth;
                newPiece.selection_mode = postBody["piece_id"] ? "user-selected" : "randomly-chosen";

                var replaceIdx = getIdxByPieceId(selectedPieceId);
                if (replaceIdx === -1) {
                  return init();
                }

                pieces[replaceIdx] = newPiece;
                setupPieces();
            });
        }

        $scope.toggleEditing = function() {
          $scope.editing = !$scope.editing;
          if($scope.editing) {
            $scope.expandDisplay();
          } else {
            dc_apiurl = "/api/accounts/" + artUser.getId() + "/process/displaycase/"
            $http.get(dc_apiurl).success(function (data) {
            }).error(function (data, status) {
              if (error) {
                console.error("data: " + data + " status: " + status);
              }

            });
          }

        };

        $scope.triggerOverlay = function(item) {
            if($scope.editing) {
              return;
            }
            nmPieceDetailService.startPieceDetailOverlay(
                {id: item.owner_id}, // owner
                pieces, // collection
                item.id // print id
            );
        };

        artSubscriptionService.subscribe("display-case-add-piece", onPieceSelected);
        artSubscriptionService.subscribe("trade-accepted", init);

        $scope.$on("$destroy", function() {
            resizeListener();
            artSubscriptionService.unsubscribe("display-case-add-piece", onPieceSelected);
            artSubscriptionService.unsubscribe("trade-accepted", init);
        });

        init();
    }]);
