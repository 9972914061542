window.packOpenModule = angular.module('PackOpen', [
    'Art',
    'Woodsaw'
]);

packOpenModule.run(['$rootScope', function($rootScope){

    // Someone can tell me where to put this better.
    $rootScope.purgeListeners = function (name) {

        if ($rootScope.$$listeners[name]) {
            delete $rootScope.$$listeners[name];
            return true;
        }
        else {
            return false;
        }

    };

}]);