neonmobApp.factory("SetSort", function() {

    var SetSort = {};

    var _filterConfig = {
        general: {
            favorite:           false,
            duplicate:          false,
            rareSelected:       false
        },
        ownership: {
            owned:              true,
            unowned:            true
        },
        rarity: {
            common:             false,
            uncommon:           false,
            rare:               false,
            veryRare:           false,
            extraRare:          false,
            variant:            false,
            chase:              false
        }
    };

    SetSort.options = {};
    SetSort.defaultOptions = {};

    angular.copy(_filterConfig, SetSort.options);
    angular.copy(_filterConfig, SetSort.defaultOptions);

    SetSort.setRareSelected = function(truth) {

        SetSort.options.rareSelected = truth;

    };

    SetSort.filterPiece = function(piece) {

        var options = SetSort.options;

        // [Condition 1]: Sorting by whether you own a piece, you
        // don't own a piece, and the all filter.
        if (options.ownership.unowned === true && options.ownership.owned === false) {
            if (piece.own_count > 0) {
                return false;
            }
        }
        else if (options.ownership.owned === true && options.ownership.unowned === false) {
            if (piece.own_count <= 0) {
                return false;
            }
        } else if (options.ownership.unowned === false && options.ownership.owned === false){
            return false;
        }

        // [Condition 2]: Sorting by Rarity
        // Note that if all is selected, rarities will not
        // be sorted specially.
        if (options.general.rareSelected === true) {
            if (options.rarity.common === false) {
                if (piece.rarity.name === "Common") {
                    return false;
                }
            }

            // Uncommons
            if (options.rarity.uncommon === false) {
                if (piece.rarity.name === "Uncommon") {
                    return false;
                }
            }

            // Rare
            if (options.rarity.rare === false) {
                if (piece.rarity.name === "Rare") {
                    return false;
                }
            }

            // Very Rare
            if (options.rarity.veryRare === false) {
                if (piece.rarity.name === "Very Rare") {
                    return false;
                }
            }

            // Extra Rare
            if (options.rarity.extraRare === false) {
                if (piece.rarity.name === "Extra Rare") {
                    return false;
                }
            }

            // Variant
            if (options.rarity.variant === false) {
                if (piece.rarity.name === "Variant") {
                    return false;
                }
            }

            // Chase
            if (options.rarity.chase === false) {
                if (piece.rarity.name === "Chase") {
                    return false;
                }
            }
        }

        // [Condition 3]: Favorites
        if (options.general.favorite === true) {
            if (piece.favorite === false) {
                return false;
            }
        }

        // [Condition 4]: Duplicates
        if (options.general.duplicate === true) {
            if (piece.own_count < 2) {
                return false;
            }
        }

        // [Condition 5]: If you don't have a chase or if you
        // Don't have a variant, it won't appear here.
        if (piece.rarity.name === "Chase" || piece.rarity.name === "Variant") {
            if (piece.own_count < 1) {
                return false;
            }
        }

        return true;

    };

    return SetSort;

});
