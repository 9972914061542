artModule.factory("artFreebiesPollService", [
    '$interval',
    '$timeout',
    'artConfig',
    'artResource',
    'artUser',
    function ($interval,
              $timeout,
              artConfig,
              artResource,
              artUser) {

        var artFreebiesPollService = {};
        var countdownInterval;
        var freebiePollTimeout;

        if(artUser.isAuthenticated()) {
            artFreebiesPollService.freebiesRemaining = artUser.getFreebiesRemaining();
        }

        artFreebiesPollService.viewState = 'startup';
        artFreebiesPollService.labelText = null;
        artFreebiesPollService.freebieCount = null;
        artFreebiesPollService.tipText = null;

        window.addEventListener('load', function() {
            artUser.isAuthenticated() && artFreebiesPollService.freebiePollState();
        });

        function stopFreebiePoll() {
            if (!freebiePollTimeout) {
             return;
            }
            $timeout.cancel(freebiePollTimeout);
            freebiePollTimeout = undefined;
        }


        function startCountdown(data) {

            var secondsRemaining = data.seconds;
            if (secondsRemaining < 0) {
                artFreebiesPollService.startFreebiePoll();
            }
            var decrementSecondsRemaining = function() {
                secondsRemaining--;

//                var hours = parseInt(secondsRemaining / 3600, 10) % 24;
                var minutes = parseInt(secondsRemaining / 60, 10) % 60;
                var seconds = parseInt(secondsRemaining % 60, 10);

                // timer's up, start polling for freebies
                if (secondsRemaining < 0) {
                    return artFreebiesPollService.freebiePollState();
                }
                artFreebiesPollService.setLabelText((minutes < 10 ? "0" + minutes : minutes)
                + ":" + (seconds < 10 ? "0" + seconds : seconds));
            };

            // This conditional avoids repetitive erroneous polling request.
            if (!countdownInterval) {
                countdownInterval = $interval(decrementSecondsRemaining, 1000);
            }
            decrementSecondsRemaining();
        }

        artFreebiesPollService.getLabelText = function() {
            return artFreebiesPollService.labelText;
        }

        artFreebiesPollService.setLabelText = function(text) {
            return artFreebiesPollService.labelText = text;
        }
        artFreebiesPollService.getTipText = function() {
            return artFreebiesPollService.tipText;
        }

        artFreebiesPollService.setTipText = function(text) {
            return artFreebiesPollService.tipText = text;
        }

        artFreebiesPollService.getViewState = function() {
            return artFreebiesPollService.viewState;
        }

        artFreebiesPollService.setViewState = function(state) {
            return artFreebiesPollService.viewState = state;
        }

        artFreebiesPollService.getFreebieCount = function() {
            return artFreebiesPollService.freebieCount;
        }

        artFreebiesPollService.setFreebieCount = function(count) {
            return artFreebiesPollService.freebieCount = count;
        }

        artFreebiesPollService.getFreebiesRemaining = function() {
            return artFreebiesPollService.freebiesRemaining ;
        }

        artFreebiesPollService.setFreebiesRemaining = function(count) {
            return artFreebiesPollService.freebiesRemaining = count;
        }

        artFreebiesPollService.freebiesState = function() {
            artFreebiesPollService.stopCountdown();
            stopFreebiePoll();
            artFreebiesPollService.setViewState('freebies');
            artFreebiesPollService.setLabelText(artFreebiesPollService.freebiesRemaining);
            artFreebiesPollService.setFreebieCount(artFreebiesPollService.freebiesRemaining);
            artFreebiesPollService.setTipText('Free pack limit is reached!');
        }

        artFreebiesPollService.countdownState = function() {
            artFreebiesPollService.stopCountdown();
            stopFreebiePoll();

            artResource.create(artConfig.api['seconds-until-freebies-ready'], {
                timezone_offset: (new Date()).getTimezoneOffset()
            }).then(function (data) {
                artFreebiesPollService.setViewState('countdown');
                artFreebiesPollService.setTipText('Until next free pack');
                artFreebiesPollService.setFreebieCount(artFreebiesPollService.freebiesRemaining);
                startCountdown(data);
            });
        }

        artFreebiesPollService.freebiePollState = function() {
            artFreebiesPollService.stopCountdown();
            artFreebiesPollService.startFreebiePoll();
            artFreebiesPollService.setViewState('poll');
            artFreebiesPollService.setLabelText(null);
            artFreebiesPollService.setTipText(null);
        }


        artFreebiesPollService.stopCountdown = function() {
            if (!countdownInterval){
                return;
            }
            $interval.cancel(countdownInterval);
            countdownInterval = undefined;
        }

        artFreebiesPollService.startFreebiePoll = function() {
            var freebiePoll = function() {
                artResource.retrieve(artConfig.api['num-freebies-left'])
                    .then(function(data) {
                        artUser.setFreebiesRemaining(data.freebies);
                        artFreebiesPollService.setFreebiesRemaining(data.freebies);
                        artFreebiesPollService.setFreebieCount(data.freebies);
                        if (data.freebies < artUser.getFreebiesLimit()) {
                            artFreebiesPollService.countdownState();
                        } else {
                            artFreebiesPollService.freebiesState();
                        }
                    });
            };
            freebiePoll();
        }

        artFreebiesPollService.startupState = function() {
            artFreebiesPollService.setViewState('startup');
            artFreebiesPollService.setLabelText(null);
            artFreebiesPollService.setTipText(null);
        }

        return artFreebiesPollService;
    }]);
