artModule.directive("artActivityFeedCompactFixScroll", [
    "$window",
    "$document",
    "$timeout",
    function ($window,
              $document,
              $timeout) {

        function link($scope, $element, $attrs) {
            var scrollTop, windowHeight, documentHeight;
            var win = angular.element($window);
            var doc = angular.element($document);
            var _body = $('body');

            function calcState() {
                scrollTop = _body.scrollTop();
                windowHeight = win.height();
                documentHeight = doc.height();
            }

            function onPagesLoaded() {
                var heightOffset = Math.min(doc.height(), windowHeight);

                if (heightOffset < documentHeight) {
                    _body.css('min-height', heightOffset + scrollTop);
                    _body.scrollTop(scrollTop);
                } else {
                    _body.css('min-height', '');
                }
                calcState();
            }

            var viewStateWatcher = $scope.$watch(function () {
                return $attrs.artActivityFeedCompactFixScroll;
            }, function (newValue) {
                if (newValue === "loadingPage") return calcState();
                $timeout(onPagesLoaded, 0);
            });

            $scope.$on('$destroy', function () {
                viewStateWatcher();
            });

            calcState();
        }

        return {
            link: link
        };
    }]);
