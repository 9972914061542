artModule.factory("artBindingHelpers", function () {

    function getProp(obj, prop) {
        return function () {
            return obj[prop];
        }
    }

    function bindObjectPropToScope(params) {
        var scope = params.scope;
        var scopeProp = params.scopeProp;
        var obj = params.obj;
        var prop = params.prop;
        var cb = params.cb;

        return scope.$watch(getProp(obj, prop), function (newValue, oldValue) {
            scope[scopeProp] = newValue;
            if (cb) cb(newValue, oldValue)
        });
    }

    return {
        getProp: getProp,
        bindObjectPropToScope: bindObjectPropToScope
    }
});
