artModule.factory("artSetCookie", function(){


    return function(key, value, daysUntilExpires){

        var cookieValueParts = [key,"=",value,";"];

        if(daysUntilExpires){

            var expireDate = new Date();

            expireDate.setDate(expireDate.getDate()+daysUntilExpires);
            cookieValueParts = cookieValueParts.concat(["expires=", expireDate.toUTCString(), ";"]);

        }

        cookieValueParts.push('path=/');

        document.cookie = cookieValueParts.join("");

    };

});