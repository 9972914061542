artModule.factory("artObjectHelpers", function () {
    function makePropertyGetter(obj, prop) {
        if (!prop) throw new Error("Error: makePropertyGetter(), prop is required");
        return function () {
            if (!obj) return;
            return obj[prop];
        }
    }

    function getProperty(prop) {
        if (!prop) throw new Error("Error: getProperty(), prop is required");
        return function (obj) {
            if (!obj) return;
            return obj[prop];
        }
    }

    return {
        makePropertyGetter: makePropertyGetter,
        getProperty: getProperty
    }
});
