neonmobApp.directive('nmCollectItButton', function(){
    var collectItButton = {};

    collectItButton.scope = {
        sett: '=nmCollectItButton'
    };

    collectItButton.controller = [
        '$scope',
        '$window',
        '$http',
        '$filter',
        '$interval',
        'artAnalytics',
        'artSett',
        'artUser',
        'artUrl',
        'poRoute',
        'artResource',
        'artConfig',
        'artConstants',
        'artContentTypes',
        'artMessage',
        'artFreebiesPollService',
    function(
        $scope,
        $window,
        $http,
        $filter,
        $interval,
        artAnalytics,
        artSett,
        artUser,
        artUrl,
        poRoute,
        artResource,
        artConfig,
        artConstants,
        artContentTypes,
        artMessage,
        artFreebiesPollService
    ){
        $scope.daysLeftToDiscontinue = false;
        $scope.percentLeftToDiscontinue = false;
        $scope.getHourlyTimer = artFreebiesPollService.getLabelText.bind(artFreebiesPollService);
        $scope.settFreebiesRemaining = artSett.settFreebiesRemaining.bind(artSett);
        $scope.timerText = '...';
        // Nitpick: Change this variable name to areFeaturesGated for consistency
        $scope.beginner = artUser.areFeaturesGated();
        var countdownInterval;

        function _getTimer() {
            $scope.timerText = $filter('getTimerCountDown')();
        }

        // FIXME: Move me to service like hourly freebie timer
        $scope.getTimerToMidnight = function() {
            if (!countdownInterval) {
                countdownInterval = $interval(_getTimer, 1000);
            }
            return $scope.timerText;
        }

        $scope.checkFreebiesDiscontinued = function(sett, className) {
            if(!sett.freebies_discontinued && className === 'collect-it') return true;
            return false;
        }

        $scope.getCTAText = function(sett, className, ctaText) {
            if((artUser.isAuthenticated() && $scope.checkFreebiesDiscontinued(sett, className))) {
                if($scope.settFreebiesRemaining(sett)) {
                    if($scope.getHourlyTimer()) {
                        return 'New free packs in ' + $scope.getHourlyTimer();
                    }
                    return '...';
                }
                return 'New free packs in ' + $scope.getTimerToMidnight();
            }
            return ctaText;
        }

        var displayOptions = {
            free: {
                text: "Open Pack",
                className: "reward primary",
                click: function() {
                    poRoute.launchPackTiers($scope.sett.links.self);
                }
            },
            nearlyGone: {
                text: $scope.beginner ? "Open Pack" : "Nearly Gone",
                className: "reward primary",
                click: function() {
                    poRoute.launchPackTiers($scope.sett.links.self);
                }
            },
            paid: {
                text: "Open Pack",
                className: "collect-it",
                click: function() {
                    poRoute.launchPackTiers($scope.sett.links.self);
                }
            },
            nearlyGonePaid: {
                text: $scope.beginner ? "Open Pack" : "Nearly Gone",
                className: "collect-it",
                click: function() {
                    poRoute.launchPackTiers($scope.sett.links.self);
                }
            },
            /*
                Both likes(Submission) and Coming soon sett using same model(Likemodel).
                We can differentiate by using Content_type_id.
                Both has same Email and notification format.
                Both are using same API URL.(http://testing.neonmob.com/api/likes/).
            */
            comingSoon: {
                text: "Coming soon",
                className: "collect-it",
                click: function() {
                    if (!artUser.isVerified()) {
                        return artMessage.showAlert("You have to verify your email before you can do that :(");
                    }
                    $scope.sett.notify = !$scope.sett.notify;
                    if($scope.sett.notify) {
                        artResource.create(artConfig.api['api-likes-list'], {
                            content_type: artContentTypes['art.sett'],
                            object_id: $scope.sett.id,
                            notify: true
                        }).then(function() {
                            $scope.sett.notify = true;
                        });
                    } else { // ToDO: Move it to common service, bcoz homepage and collect page using the same functionality.
                        var obj = {
                            url: artConfig.api['api-un-like'],
                            content_type: artContentTypes['art.sett'],
                            object_id: $scope.sett.id,
                        }
                        try {
                            $http.post(obj.url, obj).then(function () {});
                        } catch (e) {
                            NM.error("Something went wrong :( Please reach NeonMob.");
                        }
                    }
                }
            },
            discontinued: {
                text: "Sold Out",
                className: "disabled",
                click: function() {
                    //VOID
                }
            },
            promo: {
                text: "Promo Series",
                className: "disabled",
                click: function() {
                    //VOID
                }
            },
            anonymous: {
                text: "Collect",
                className: "collect-it",
                click: function() {
                    var data = {},
                        signupUrlParams = {url: '/series/' + $scope.sett.id};

                    data.page = 'sett-header';
                    data.settId = $scope.sett.id;

                    if($scope.sett) {
                      data.settName = $scope.sett.name;
                      signupUrlParams.url = $scope.sett.links.permalink;
                      signupUrlParams.settID = $scope.sett.id;
                      signupUrlParams.collection = $scope.sett.name;
                    }

                    artAnalytics.track('Clicked Collect When Signed Out', data );
                    var signupUrl = artUrl.updateParams("/signup", signupUrlParams);

                    $window.location.href = signupUrl;
                }
            },
            loading: {
                text: "Loading...",
                className: "disabled",
                click: function(){
                    //VOID
                }
            }
        };

        function _formatDiscontinueDate() {
            return new Date($scope.sett.discontinue_date);
        }

        $scope.isNearlySoldOut = function () {
            if($scope.sett.version === artConstants.VERSION_TYPES.unlimited) {
                $scope.daysLeftToDiscontinue = _formatDiscontinueDate();
                var diff = $filter('findDiffBWDate')($scope.daysLeftToDiscontinue);
                return diff;
            }
            $scope.percentLeftToDiscontinue = $scope.sett.percent_sold_out >= 80 ? 100 - $scope.sett.percent_sold_out : 0;
            return $scope.percentLeftToDiscontinue;
        }

        $scope.releaseDate = new Date($scope.sett.released)
        $scope.isComingSoonSett = artSett.isComingSoonSett.bind(artSett);

        function _getDisplayType() {
            if(artSett.isSoldOut($scope.sett)) {
                return 'discontinued';
            } else if (!artUser.isAuthenticated()) {
                return 'anonymous';
            } else if(artSett.isPromoOnly($scope.sett)) {
                return 'promo';
            } else if (!$scope.sett) {
                return 'loading';
            } else if ($scope.isComingSoonSett($scope.sett)) {
                return 'comingSoon';
            } else if (artSett.hasDailyFreebiesForSett($scope.sett) && !$scope.isNearlySoldOut()) {
                return 'free';
            } else if (!artSett.hasDailyFreebiesForSett($scope.sett) && $scope.isNearlySoldOut()) {
                return 'nearlyGonePaid';
            } else if ($scope.isNearlySoldOut()) {
                return 'nearlyGone';
            }
            return 'paid';
        }

        $scope.getClass = function(){
            return displayOptions[_getDisplayType()].className;
        };

        $scope.getText = function(){
            return displayOptions[_getDisplayType()].text;
        };

        $scope.handleClick = function(){
            return displayOptions[_getDisplayType()].click();
        };

        $scope.$on('$destroy', function () {
            if (!countdownInterval) {
                return;
            }
            $interval.cancel(countdownInterval);
            countdownInterval = undefined;
        });

        $scope.isUnlimitedSett = artSett.isUnlimitedSett.bind(artSett);
        $scope.isSoldOut = artSett.isSoldOut.bind(artSett);
    }];

    collectItButton.templateUrl = "partials/collect-it/collect-it-button.partial.html";

    return collectItButton;

});
