neonmobApp.provider('ProfileUser', function() {
    var userUrl, settingsUrl, emailSubscriptionsUrl, passwordUrl, userId,
        isFriend, settSlug, userName, userRecentProgressUrl;

    this.$get = ['$http', '$q', 'artNodeHttpService',
        function($http, $q, artNodeHttpService){

        if (userUrl) {
            var userPromise, collectedSetsPromise;

            userPromise = $q.when($http.get(userUrl));

            collectedSetsPromise = userPromise.then(function(user) {
                return artNodeHttpService.get(user.data.links.collected_setts_names_only);
            });
        }
        if (settingsUrl) {
            var fetchSettingsUserPromise = $q.when($http.get(settingsUrl));
        }

        if (emailSubscriptionsUrl) {
            var fetchemailSubscriptionsPromise = $q.when($http.get(emailSubscriptionsUrl));
        }

        return {
            fetchUser: function() {
                return userPromise;
            },
            fetchSettingsUserObject: function() {
                return fetchSettingsUserPromise;
            },
            fetchCollectedSets: function() {
                return collectedSetsPromise;
            },
            fetchEmailSubscriptions: function() {
                return fetchemailSubscriptionsPromise;
            },

            userUrl: userUrl,
            userRecentProgressUrl: userRecentProgressUrl,
            settingsUrl: settingsUrl,
            emailSubscriptionsUrl: emailSubscriptionsUrl,
            passwordUrl: passwordUrl,
            userId: userId,
            isFriend: isFriend,
            settSlug: settSlug,
            userName: userName
        };
    }];

    this.setUserUrl = function(url) {
        userUrl = url;
    };

    this.setUserRecentProgressUrl = function(url) {
        userRecentProgressUrl = url;
    };

    this.setSettingsUrl = function(url) {
        settingsUrl = url;
    };

    this.setEmailSubscriptionsUrl = function(url) {
        emailSubscriptionsUrl = url;
    };

    this.setPasswordUrl = function(url) {
        passwordUrl = url;
    };

    this.setUserId = function(id) {
        userId = id;
    }
    this.setTargetUsername = function(username) {
        userName = username;
    }

    this.setIsFriend = function(friend) {
        isFriend = friend;
    }

    this.setSlug = function(slug) {
        settSlug = slug;
    }

});
