/**
 * Description:
 *     puts &nbsp between last two words to prevent single word danglies
 * Usage:
 *   {{some_text | nowidows:3}}
 * Options:
 *   - minWords (int) - optional  (default is 3) word-count to apply this filter
 * Notes:
 *     \u00A0 is used to create &nbsp to get around angular sanitizing
 *     see: http://stackoverflow.com/a/12431145/337192
 */
artModule.filter('nowidows', function () {
    return function (value, minWords) {
        if (!value || typeof value !== 'string') {
            return value;
        }

        if (!minWords || minWords < 2) {
            minWords = 3;
        }

        var wordArray = value.trim().split(' ');

        if (wordArray.length > minWords) {
            wordArray[wordArray.length - 2] += '\u00A0' + wordArray[wordArray.length - 1];
            wordArray.pop();
            return wordArray.join(' ');
        } else {
            return value;
        }
    };
});