/**
 * This factory creates a simple scroller object that automatically connects to django rest framework
 * list apis.
 */
artModule.factory("artInfiniteScroller", [
    "artResource",
function (
    artResource
) {

    function _extendScroller(source, elementSelector, apiUrl){
        source.infiniteScrollLoading = false;
        source.infiniteScrollHasMore = true;
        source.infiniteScrollList = [];

        function getRegion() {
            var element = angular.element(elementSelector);
            if( !element.length ) return;

            var region = element.scrollParent();

            if (region[0].documentElement) {
                region = $(region[0].documentElement);
            }

            return region;
        }

        source.getNextPage = function(){
            if(source.infiniteScrollLoading || !source.infiniteScrollHasMore){
                return;
            }
            
            source.infiniteScrollLoading = true;

            var promise;

            if(!source.infiniteScrollList.retrieveNext){
                promise = artResource.retrievePaginated(apiUrl).then(function(data){
                    source.infiniteScrollList = data;
                });
            } else {
                promise = source.infiniteScrollList.retrieveNext();
            }

            promise.finally(function(){
                var region = getRegion();
                var scrollHeight = region[0].scrollHeight;
                var notOverflowed = !!source.infiniteScrollHasMore && scrollHeight <= region.height();
                source.infiniteScrollLoading = false;
                source.infiniteScrollHasMore = !!source.infiniteScrollList.next;

                if (notOverflowed) {
                    source.getNextPage();
                }
            });

            return promise;
        };

        return source;
    }

    var artInfiniteScroller = {};

    artInfiniteScroller.create = function(elementSelector, apiUrl){
        return _extendScroller({}, elementSelector, apiUrl);
    };

    /**
     * Backwards compatibility with the old artInfiniteScroll directive.
     * @param scope
     * @param apiUrl
     */
    artInfiniteScroller.bindToScope = function(scope, elementSelector, apiUrl){

        _extendScroller(scope, elementSelector, apiUrl);

    };

    return artInfiniteScroller;

}]);
