artModule.directive('artAlertMessage',[
	"artGlobalAlertService",
	function(
		artGlobalAlertService
	){
		var artAlertMessage = {};

		artAlertMessage.scope = {};
		artAlertMessage.controller = function($scope) {

			$scope.message = artGlobalAlertService.getAlertMessage.bind(artGlobalAlertService);
			$scope.getClasses = artGlobalAlertService.getClasses.bind(artGlobalAlertService);
		};

		artAlertMessage.templateUrl = "partials/art/global-alert.partial.html";

		return artAlertMessage;
}]);
