artModule.directive("artBrowse", ['artMessage', 'artUser', '$filter', '$timeout', function(artMessage, artUser, $filter, $timeout){

    var artBrowse = {};

    artBrowse.scope = {

        onPick:"&artBrowse",
        name:"@artBrowseName",
        accept:"@artBrowseAccept",
        minImgWidth: "@imgMinWidth",
        minAspectRatio: "@imgMinAspectRatio",
        maxAspectRatio: "@imgMaxAspectRatio",
        maxFileCount: "@artMaxFileCount",
        maxFileSize:"@artMaxFileSize"
    };

    artBrowse.link = function(scope, element, attrs){
        var maxFileSize = attrs.artMaxFileSize,
            maxFileCount = attrs.artMaxFileCount,
            isFileValid = true;

        var formBuilder = [],
            _URL = window.URL || window.webkitURL;

        function convertToAspectRatioFormat(float) {
            // We calculate aspect ratio as height/width but convention to display is width:height
            // So taking reverse
            return $filter('floatToFraction')(parseFloat(float), ':', true);
        };
        function validateAspectRatio(img) {
            if( artUser.isCreatorAdmin()){
                return;
            }

            var aspectRatio,
                suffixMessage;

            aspectRatio = img.height / img.width;
            if (!(scope.minAspectRatio <= aspectRatio && aspectRatio <= scope.maxAspectRatio)) {
                isFileValid = false;
                if (scope.minAspectRatio == scope.maxAspectRatio) {
                    suffixMessage = convertToAspectRatioFormat(scope.minAspectRatio);
                } else {
                    suffixMessage = (
                        "between " + convertToAspectRatioFormat(scope.minAspectRatio) + " and " +
                        convertToAspectRatioFormat(scope.maxAspectRatio)
                    );
                }
                artMessage.showAlert(
                    "We're sorry, aspect ratio of the uploaded file(s) should be " + suffixMessage
                );
            }
        };

        function validateMinWidth(img) {
            if (img.width < scope.minImgWidth) {
                isFileValid = false;
                artMessage.showAlert(
                    "We're sorry, width of the uploaded file(s) should be a minimum of " + scope.minImgWidth + "px"
                );
            }
        };

        function validateImage(uploadedFile) {
            var count = 0,
                tempImage = new Image(),
                validations = [validateAspectRatio];

            if (scope.minImgWidth) {
                validations.push(validateMinWidth);
            }

            tempImage.onload = function () {
                while (isFileValid && (count < validations.length)) {
                    validations[count](this);
                    count++;
                }
            };
            tempImage.src = _URL.createObjectURL(uploadedFile);
        };

        formBuilder.push("<form id='");
        formBuilder.push(element.attr('id'));
        formBuilder.push("-form''><input id='");
        formBuilder.push(element.attr('id'));
        formBuilder.push("-input' type='file'");

        if(scope.name){

            formBuilder.push(" name='");
            formBuilder.push(scope.name);
            formBuilder.push("'");

        }

        if(scope.accept){

            formBuilder.push(" accept='");
            formBuilder.push(scope.accept);
            formBuilder.push("'");

        }

        if(attrs.artBrowseMultiple !== undefined){
            formBuilder.push(" multiple");
        }

        formBuilder.push("></form>");

        var form = angular.element(formBuilder.join(''));

        if (!nmFeatureEnabled('functional_tests')){

            form.hide();
        }

        angular.element("body").append(form);

        element.click(function(){

            var children = form.children().unbind('change.ctsettcreator').bind("change.ctsettcreator",function(ev){
                var fileList = ev.currentTarget.files,
                    numFiles = fileList.length;

                if(numFiles > 0) {
                    maxFileCount = parseInt(maxFileCount, 10) || Number.POSITIVE_INFINITY;
                    if (fileList.length > maxFileCount){
                        var listLen = fileList.length; // Following line wipes out fileList
                        angular.element(ev.currentTarget).val("");
                        return artMessage.showAlert("We're sorry, you can only upload " + maxFileCount + " files at a time. You included " + listLen + " files.");
                    }

                    if(!maxFileSize) {
                        return scope.onPick({event: ev, pickEvent: {form: form}});
                    }

                    maxFileSize = parseInt(maxFileSize, 10);
                    var maxFileSizeInMb = Math.ceil(maxFileSize / (1024 * 1024)),
                        fileSize,
                        uploadedFile;
                    for (i=0; i < numFiles; i++) {
                        uploadedFile = fileList[i];
                        fileSize = uploadedFile.size;
                        if(fileSize > maxFileSize) {
                            angular.element(ev.currentTarget).val("");
                            if (numFiles === 1) {
                                return artMessage.showAlert("We're sorry, the file you uploaded is too large. Files must be smaller than " + maxFileSizeInMb + " MB");
                            }
                            return artMessage.showAlert("We're sorry, one or more of the files you've uploaded is too large. All files must be smaller than " + maxFileSizeInMb + " MB");
                        }
                        if (uploadedFile.type.startsWith('image')) {
                            validateImage(uploadedFile);
                        }
                    }
                }
                // FIXME: The timeout is required because the onload event triggers late.
                $timeout(function() {
                    if(isFileValid) {
                        scope.onPick({event:ev, pickEvent:{form:form}});
                    } else {
                        isFileValid = true;
                        angular.element(ev.currentTarget).val("");
                    }
                }, 500 * numFiles);

            });

            // disable click for functional tests
            if (!nmFeatureEnabled('functional_tests')) {
                children.click();
            }

        });

        scope.$on("$destroy", function(){

            form.remove();

        });

    };

    return artBrowse;

}]);
