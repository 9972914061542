artModule.directive('artNotification', function() {

    var artNotification = {};

    artNotification.scope = {

        notification: '=artNotification',
        notificationType: "@artNotificationType"

    };

    artNotification.templateUrl = 'partials/art/notifications/notification.partial.html';

    artNotification.controller = [
        'artConfirm',
        'artConstants',
        'artUser',
        'artNotificationCenter',
        'artResponsive',
        'first_nameFilter',
        'poMilestones',
        'userFirstNamePossessiveFilter',
        '$scope',
        '$window',
        '$q',
    function(
        artConfirm,
        artConstants,
        artUser,
        artNotificationCenter,
        artResponsive,
        first_nameFilter,
        poMilestones,
        userFirstNamePossessiveFilter,
        $scope,
        $window,
        $q
    ) {
        var _milestoneModalData = [],
            _milestoneRewardsArray = [],
            modalHeaderText = {
                 'series-complete': 'Series Completed',
                 'series-complete-beginner': 'Awesome Job!',
                 'series-complete-reward': "<div class='reward-carat-header'><span class='reward-header-msg'>Core Series Completed!</span></div>"
                 };
        function _resetMilestoneData() {
             _milestoneModalData = [],
             _milestoneRewardsArray = [];
         };

        function _prepareRewards(rewards, data) {
             rewards.forEach(function (reward, index) {
                 if(!artUser.areFeaturesGated()) {
                     var completionStat = {
                         rewardType: 'series-complete',
                         message: modalHeaderText['series-complete'],
                         okText: 'Claim Reward',
                         data: reward
                     };
                     var completionBonus = {
                         rewardType: 'series-complete-reward',
                         message: modalHeaderText['series-complete-reward'],
                         data: {
                             baseReward: reward.carats,
                             proBonus: reward.pro_bonus,
                             difficultyBonus: reward.difficulty_bonus,
                             totalReward: reward.total,
                             suggestedSett: data[index].data.results
                         }
                     };
                     _milestoneRewardsArray.push(completionStat);
                     _milestoneRewardsArray.push(completionBonus);
                 } else {
                     var completionStat = {
                         rewardType: 'series-complete-beginner',
                         message: modalHeaderText['series-complete-beginner'],
                         okText: 'GREAT!',
                         data: reward,
                     };
                     _milestoneRewardsArray.push(completionStat);
                 }
             });
         };

        function _processSuggestionSet(data) {
             _prepareRewards(_milestoneModalData, data);
             if (_milestoneRewardsArray.length) {
                 var firstReward = _milestoneRewardsArray.shift();
                 firstReward.messageQueue = _milestoneRewardsArray;
                 firstReward.parentClass = 'series-complete-reward';
                 _milestoneModalData = [];
                 artConfirm.showEarnedStatus(firstReward);
                 firstReward = [];
             }
         }

         function _showMilestoneRewardModal(modalData) {
             if (modalData) {
                 _milestoneModalData = [modalData];
             }
             if (_milestoneModalData.length) {
                 var promiseCollection = [];
                 _milestoneModalData.forEach(function (reward) {
                     promiseCollection.push(poMilestones.fetchSuggestedSetts(reward.sett.id));
                 });

                 $q.all(promiseCollection).then(function (data) {
                     _processSuggestionSet(data);
                     _resetMilestoneData();
                 });
             } else {
                 _resetMilestoneData();
             }
         };

        $scope.viewNotification = function($event) {

            _markRead();

            if(artResponsive.isMobileLarge()){
                artNotificationCenter.hide();
            }

            if ($scope.notification.verb === "leveled-up") {
                var levelUpData = {};
                if($scope.notification.object.new_features.length) {
                    levelUpData = {
                        rewardType: 'gated-level-up',
                        message: 'Congratulations',
                        okText: 'GREAT',
                        data: $scope.notification.object,
                        parentClass: 'series-complete-reward',
                        hasCloseBtn: true
                    }
                }
                else {
                    levelUpData = {
                        rewardType: 'level-up',
                        message: 'Level Up!',
                        okText: 'Great!',
                        data: $scope.notification.object,
                        parentClass: 'series-complete-reward'
                    };
                }
                artConfirm.showEarnedStatus(levelUpData, function() {
                    $window.location.reload();
                });
            }

            if ($scope.notification.verb === "series-completed") {
                _showMilestoneRewardModal($scope.notification.object);
            }

            if(!_isSinglePageAppRoute()){
                $window.location.href = $scope.notification.object.url;
                $event.preventDefault();
                $event.stopPropagation();
            }

        };

        $scope.notificationCanBeMarkedRead = function(){
            return $scope.notificationType !== artConstants.TRADES_KEY;
        };

        function _markRead(){
            return artNotificationCenter.markNotificationsAsRead(
                [$scope.notification.id],
                $scope.notificationType
            );
        }

        function _isSinglePageAppRoute(){
            return ["traded", "leveled-up", "series-completed"].includes($scope.notification.verb);
        }

        $scope.markReadAndPreventDefault = function($event){

            $event.preventDefault();
            $event.stopPropagation();
            return _markRead();

        };

        $scope.getVerbPhrase = function () {

            var verb_phrase = $scope.notification.verb_phrase,
                noun = $scope.notification.object.noun,
                ending = $scope.notification.actor.action_data ? ":" : ".";

            if (~["proposed", "withdrew", "modified"].indexOf(verb_phrase)) {
                return verb_phrase + " a " + noun + " to " + first_nameFilter($scope.notification.object.users[0]) + ending;
            }

            if (verb_phrase === "expired") {
                return "let " + userFirstNamePossessiveFilter($scope.notification.object.users[0]) + " " + noun + " expire.";
            }

            if ($scope.notification.verb === "friend"){
                return verb_phrase + " " + first_nameFilter($scope.notification.object.users[0]) + " as a friend.";
            }

            if ($scope.notification.verb === "submission-series" || $scope.notification.verb === "coming-soon"
             || $scope.notification.verb === "leveled-up" || $scope.notification.verb === "series-completed" 
             || $scope.notification.verb === "bonus-awarded" || $scope.notification.verb === "credit-pass-credits-awarded") {
                return false; // We will have action_data coming from backend
            }

            if ($scope.notification.object.users.length > 0) {
                var names = [],
                    users = $scope.notification.object.users;

                for (var i = 0; i < users.length - 1; i++) {

                    names.push(first_nameFilter(users[i]));

                }

                names.push(userFirstNamePossessiveFilter(users[users.length - 1]));

                return verb_phrase + " " + _joinList(names) + " " + noun + ending;

            }

            return verb_phrase + " the " + noun + ending;

        };

        function _joinList(list){

            var separator = list.length > 2 ? ", " : " ";

            if(list.length > 1){

                list.push(["and",list.pop()].join(" "));

            }

            return list.join(separator);

        }

    }];

    return artNotification;

});