creatorModule.controller("ctCollectionBuilderCtrl", [
    "$scope",
    "$timeout",
    "$window",
    "artConstants",
    "artSubscriptionService",
    "artUploader",
    "ctPieceRarities",
    "ctSettCreator",
    "artUser",
    "artMessage",
    "artResource",


function(
    $scope,
    $timeout,
    $window,
    artConstants,
    artSubscriptionService,
    artUploader,
    ctPieceRarities,
    ctSettCreator,
    artUser,
    artMessage,
    artResource
){

    $scope.rarityChoices = ctPieceRarities.RARITIES;
    $scope.specialRarity = ctPieceRarities.SPECIAL_RARITY;
    $scope.chaseRarity = ctPieceRarities.CHASE_RARITY;
    $scope.variantRarity = ctPieceRarities.VARIANT_RARITY;
    $scope.specialCounts = ctSettCreator.getSpecialCount();
    $scope.coreCounts = ctSettCreator.getCoreCounts();
    $scope.getNonChaseCount = ctSettCreator.getNonChaseCount.bind(ctSettCreator);
    $scope.minCorePieces = ctSettCreator.getMinCorePieces();
    $scope.editOrder = false;
    $scope.isAdmin = artUser.isCreatorAdmin.bind(artUser);

    if(!$scope.isAdmin()) {
        $scope.rarityChoices.pop();
    }

    var _originalOrder = null,
        _dirty = false;

    $scope.canEditOrder = function(){

        return $scope.pieces && $scope.pieces.length > 1;

    };

    $scope.editPiece = function(piece, $event){
        if($scope.editOrder){
            return; //Disable editing a piece while in edit order mode.
        }
        $event.stopPropagation();

        //HACK - force blur and update in the next cycle so the blur event will properly trigger.
        angular.element("#piece-editor :focus").blur();
        $timeout(function(){
            ctSettCreator.setSelectedPiece(piece);
        });

    };

    //TODO: we should update this to pull so that the service has the control and we don't have
    //update issues.
    $scope.pieces = ctSettCreator.getSettPieces();

    $scope.isSettEmpty = function(){

        return $scope.pieces.length === 0;

    };

    $scope.isLoadingPieces = function(){

        return ctSettCreator.isLoadingPieces();

    };

    $scope.disableUpload = function(){
        $(".tip").tooltip("hide"); // this is for the functional test
        return $scope.isLoadingPieces() || ctSettCreator.getDisabledStatus().disabled;
    };

    $scope.disableUpdate = function(){
        return ctSettCreator.getDisabledStatus().disabled && ctSettCreator.getDisabledStatus().type !== "staff";
    };

    $scope.pieceUploader = artUploader.NULL_UPLOADER;

    $scope.uploadPieceAsset = function(pickEvent){

        var selectedPiece = ctSettCreator.getSelectedPiece();
        artUploader(pickEvent.form, "piece").upload(selectedPiece.id.toString()).then(function(transloaditResponse){

            selectedPiece.asset_id = transloaditResponse.uploads[0].original_id;
            ctSettCreator.updateObject(selectedPiece);
            selectedPiece.oldImageUrl = selectedPiece.piece_assets.image.medium.url;
            ctSettCreator.pollPieceAsset(selectedPiece);

        });

    };

    $scope.uploadPieceAssets = function(pickEvent){

        $scope.pieceUploader = artUploader(pickEvent.form, "piece");
        $scope.pieceUploader.upload("piece-asset").then(ctSettCreator.bulkCreatePieces);

    };

    $scope.enableEditOrder = function(){

        $scope.editOrder = true;
        ctSettCreator.setSelectedPiece(null); //Deselect any selected piece.
        _originalOrder = $scope.pieces.slice();

    };

    $scope.saveEditOrder = function(){

        $scope.editOrder = false;
        _originalOrder = null;

        if(_dirty){
            artSubscriptionService.broadcast('show-global-message', 'Updating Order&hellip;');
            ctSettCreator.updateOrdering();
        }

    };

    $scope.cancelEditOrder = function(){

        if(_dirty){ //not pretty, but other parts of this code uses the same piece array.
            $scope.pieces.length = 0;
            $scope.pieces.push.apply($scope.pieces, _originalOrder);
        }

        _originalOrder = null;
        $scope.editOrder = false;

    };

    $scope.dragAndDropOptions = {
        placeholder:    'create--sett-pieces-editor--piece-placeholder',
        cursor:         '-webkit-grabbing',
        handle:         '.drag-handle',
        cancel:         '.edit-order-inactive',
        tolerance: "pointer",
        update:         function(e, ui){
            _dirty = true;
            $timeout(function(){

                $scope.$broadcast("artVideoTriggerPlay");

            });

        }

    };

    $scope.clearSelectedPiece = function(){
        //HACK - force blur and update in the next cycle so the blur event will properly trigger.
        angular.element("#piece-editor :focus").blur();
        $timeout(ctSettCreator.clearSelectedPiece);
    };

    /*
    * Repilca series functionality goes here.
    */

    $scope.formSubmitted = false;
    // If `True` only admin have an option `Replicate`.
    $scope.canReplicate = function() {
       return artUser.isCreatorAdmin() && ctSettCreator.canReplicate();
    }

    function _triggerReplicateApi() {
        var Url = '/api/creator/collection/' + parseInt(ctSettCreator.getSettId()) + '/replicate/';
        artResource.create(Url, null)
            .then(function(data) {
                if( data && data.status_url ) {
                    $window.location.href = data.status_url;
                }
            }, function(error) {
                $scope.formSubmitted = false;
                var error_message = (error && error.fields) === undefined ? error.detail : error.fields.error_message;
                artMessage.showAlert(error_message);
            });
    }

    // ShowAlertWithCancel callback.
    function _handleAlertCallback(canceled) {
        if (canceled) {
            return;
        };
        $scope.formSubmitted = true;
        _triggerReplicateApi();
    }

    $scope.confirmReplicate = function() {
        artMessage.showAlertWithCancel(
            { message: "Are you sure you wish to create a replica of this series?" },
            _handleAlertCallback,
            "COPY",
            true
        );
    }
    // Replica set end's here.
    /*
    * Check sett is unlimited edition.
    */
    $scope.isUnlimited = function(sett) {
        return sett.version === artConstants.VERSION_TYPES.unlimited;
    }

    $scope.canPreview = function() {
        if(!$scope.disableUpload() && $scope.isUnlimited($scope.getSett())) {
            return true;
        }
        if($scope.disableUpload() && $scope.isUnlimited($scope.getSett())) {
            return true;
        }
        if($scope.disableUpload() && !$scope.isUnlimited($scope.getSett())) {
            return true;
        }
    }

    $scope.canAddCards = function() {
        if(!$scope.disableUpload() && $scope.isUnlimited($scope.getSett())) {
            return true;
        }
        if($scope.disableUpload() && $scope.isUnlimited($scope.getSett())) {
            return true;
        }
        if(!$scope.disableUpload() && !$scope.isUnlimited($scope.getSett())) {
            return true;
        }
    }

    $scope.gotoPreviewPackTemplate = ctSettCreator.gotoPreviewPackTemplate.bind(ctSettCreator);
    // Limited Edition series functionality ends.

}]);
