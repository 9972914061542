packOpenModule.directive('poOpenMore', function(){

    var directiveDefinitionObject;

    directiveDefinitionObject =  {
        scope: {
            pack :'=poOpenMore',
            sett :'=poOpenMoreSett'
        },

        controller: "poOpenMoreController",
        templateUrl: "partials/pack-open/open-more.partial.html"
    };

    return  directiveDefinitionObject;

}).controller('poOpenMoreController',[
    '$scope',
    'poPackSelect',
    'poRoute',
    'artShowStreaks',
    'artResponsive',
    'clickStreaksService',
    'poMilestones',
    function(
        $scope,
        poPackSelect,
        poRoute,
        artShowStreaks,
        artResponsive,
        clickStreaksService,
        poMilestones
    ){

    function _resetPackOpenState(){
        $scope.$emit("pack-open-completed");
    }

    $scope.openPack = clickStreaksService.openPack.bind(clickStreaksService);

    $scope.getOpenAnotherCTA = function() {
        if (artResponsive.getWindowWidth() < 480) {
            return 'Open pack';
        } else {
            return 'Open another pack';
        }
    }

    $scope.getSelectAPackCTA = function() {
        if (artResponsive.getWindowWidth() < 480) {
            return 'Select';
        } else {
            return 'Select a pack';
        }
    }

    $scope.showDefaultActions = function(){
        return !poPackSelect.isSpecialPack();
    };

    $scope.openAnother = function() {
        var tier = clickStreaksService.getLastOpenedTier();
        if(!artShowStreaks.streaksClaimedStatus()){

            var openAnother = {
                name: 'openAnother',
                value: $scope.openAnother
            };
            clickStreaksService.setClickedBtn(openAnother);
            artShowStreaks.showStreaks();
            _resetPackOpenState();
        }
        else if (tier && tier.is_available) {
            _resetPackOpenState();

            if (!tier.can_user_open) {
                clickStreaksService.setLastOpenedTier(null);
                poRoute.launchPackTiers($scope.sett);
            } else {
                $scope.openPack(tier);
                poMilestones.setProgressImage();
            }
        } else {
            poRoute.launchPackTiers($scope.sett);
            _resetPackOpenState();
        }
    };

    $scope.close = function() {
        poRoute.finish();
        artShowStreaks.showStreaks();
    };

      $scope.launchPackTier = function() {

        if(!artShowStreaks.streaksClaimedStatus()){

            var launchPackTier = {
                name: 'launchPackTier',
                value: $scope.launchPackTier
            };
            clickStreaksService.setClickedBtn(launchPackTier);
            artShowStreaks.showStreaks();
            // _resetPackOpenState();
        }
        else{

            poRoute.launchPackTiers($scope.sett);
            // _resetPackOpenState();
        }
    };

    $scope.launchSuggestedSetts = function(){

        if(!artShowStreaks.streaksClaimedStatus()){

            var suggestedSett = {
                name: 'discoverMore',
                value: null
            };

            clickStreaksService.setClickedBtn(suggestedSett);
            artShowStreaks.showStreaks();
        }
        else{

            poRoute.launchSuggestedSetts();
        }
    };

}])
.filter('kFormatter', function() {
    return function(number, precision) {
        if (typeof precision === 'undefined') {
            precision = 0;
        }
        if(!number) return 0;
        // toFixed returns a number to the specified number of decimal points (default 0)
        // parseFloat removes any unneeded trailing zeros. Examples:
        // number = 12500 precision = 1 => 12.5K
        // number = 13000 precision = 1 => 13K (without parseFloat it would return 13.0K)
        return number > 999 ? parseFloat((number/1000).toFixed(precision)).toString() + 'K' : number;
    }
});
