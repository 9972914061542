artModule.directive('artFriendsList', function(){

    var artMessageNotification = {};

    artMessageNotification.controller = [
        '$element',
        '$http',
        '$scope',
        '$timeout',
        'artEmailValidator',
        'artFriendsService',
        'artUser',
    function(
        $element,
        $http,
        $scope,
        $timeout,
        artEmailValidator,
        artFriendsService,
        artUser
    ){
        $scope.MAX_SEARCH_RESULTS = 10;

        $scope.isVerified = artFriendsService.isVerified;

        $scope.allFriends = artFriendsService.getFriendsList();
        $scope.friends = $scope.allFriends;

        $scope.allSearchResults = [];
        $scope.searchResults = [];
        $scope.showFriends = artUser.canIDo('friends');

        $scope.searchLoading = false;

        $scope.getSearchResultsTitle = function () {
            var title = "";

            if ($scope.searchResults.length === 0) {
                title += "No";
            }
            else {
                title += "Top";
            }

            if ($scope.searchResults.length > 1) {
                if ($scope.searchResults.length > $scope.MAX_SEARCH_RESULTS) {
                    title += " " + $scope.MAX_SEARCH_RESULTS
                } else {
                    title += " " + $scope.searchResults.length;
                }
            }

            title += " Search Result";

            if ($scope.searchResults.length !== 1) {
                title += "s";
            }

            return title;
        }

        $scope.orderFriends = function(friend){
            return friend.isOnline() ? "0-" + friend.first_name : "1-" + friend.first_name;
        };

        $scope.getNext = function(){
            artFriendsService.loadMoreFriends();
        };

        $scope.isSearching = function(){
          return $scope.searchTerm;
        };

        $scope.$watch("searchTerm", function () {
            $scope.filterFriends();
            $element.find(".tip").tooltip("hide");
        });

        $scope.filterFriends = function () {
            $scope.friends = [];
            _filterExistingFriends();

            $scope.searchResults = [];
            _filterSearchResults();
        };

        // Remove entry from search list and add to friends list
        $scope.addResultToFriendsList = function (index) {
            $element.find(".tip").tooltip("hide");
            var searchResult = $scope.searchResults.splice(index, 1)[0];
            artFriendsService.connectToFriend(searchResult);

            for (var i = 0; i < $scope.allSearchResults.length; i++) {
                if ($scope.allSearchResults[i].username === searchResult.username) {
                    $scope.allSearchResults.splice(i, 1);
                    $scope.friends.push(searchResult);
                    break;
                }
            }
        }

        var _typingTimeoutId = null;

        $scope.updateTyping = function (){
            $timeout.cancel(_typingTimeoutId);

            _typingTimeoutId = $timeout(function(){

                _updateSearchResults();

            }, 500);
        }

        function _filterExistingFriends() {
            if ($scope.searchTerm) {
                if (artEmailValidator.isValidEmail($scope.searchTerm)) {
                    _filterByEmail();
                }
                else {
                    $scope.friends = $scope.allFriends.filter(function (entry) {
                        return _filterByName(entry) || _filterByUsername(entry);
                    });
                }
            }
            else {
                $scope.friends = $scope.allFriends;
            }
        }

        function _updateSearchResults() {
            if ($scope.searchTerm && $scope.searchTerm.length > 2 && $scope.searchResults.length < $scope.MAX_SEARCH_RESULTS) {
                if (artEmailValidator.isValidEmail($scope.searchTerm)) {
                    _filterByEmail();
                }
                else {
                    $scope.searchLoading = true;
                    if (!$scope.userTyping) {
                        $http.post("/api/friend/search/", {"search": $scope.searchTerm}).success(function (data) {
                            $scope.searchResults = [];
                            $scope.allSearchResults = data.results;
                            _filterSearchResults();
                            $scope.searchLoading = false;
                        });
                    }
                }
            }
        }

        function _filterSearchResults() {
            if ($scope.searchTerm && $scope.searchTerm.length > 2) {
                if (artEmailValidator.isValidEmail($scope.searchTerm)) {
                    $scope.searchLoading = true;
                    _filterByEmail();
                }
                else {
                    $scope.searchResults = $scope.allSearchResults.filter(function (entry) {
                        return _filterByName(entry) || _filterByUsername(entry);
                    });
                }
            }
            else {
                $scope.searchResults = [];
                $scope.allSearchResults = [];
            }
        }

        function _filterByEmail() {
            $http.post("/api/friend/search/", {"search": $scope.searchTerm}).success(function (data) {
                var results = data.results;

                if (results.length > 0) {
                    var user = results[0];
                    var foundFriend = false;

                    for (var i = 0; i < $scope.allFriends.length; i++) {
                        if (user.id === $scope.allFriends[i].id){
                            $scope.friends = results;
                            foundFriend = true;
                            break;
                        }
                    }

                    if (!foundFriend) {
                        $scope.searchResults = data.results;
                        $scope.allSearchResults = data.results;
                    }
                }

                $scope.searchLoading = false;
            });
        }

        function _filterByName(entry) {
            var searchRegex = new RegExp($scope.searchTerm.replace("*", ".*"), 'i');
            return searchRegex.test(entry.name);
        }

        function _filterByUsername(entry) {
            var searchRegex = new RegExp($scope.searchTerm.replace("*", ".*"), 'i');
            return searchRegex.test(entry.username);
        }
    }];

    artMessageNotification.templateUrl = 'partials/art/notifications/friends-list.partial.html';

    return artMessageNotification;

}).directive("artToggleFriend", [
    "artBlockUserService",
    "artMessage",
    "artFriendsService",
function(
    artBlockUserService,
    artMessage,
    artFriendsService
){

    var artToggleFriend = {};

    artToggleFriend.scope = {
        user: "=artToggleFriend",
        isFriend: "=artIsFriend",
        showAlert: "=?artShowAlert"
    };

    artToggleFriend.link = function(scope, element){
        // Listen for updates to friend status from other places
        scope.$on("updatedFriends", function(event, friendObj){
            var userId = friendObj.userId,
                isFriend = friendObj.isFriend;

            if(scope.user.id === userId){
                scope.isFriend = isFriend;
            }
        });

        scope.isFriend = scope.isFriend || false;
        scope.showAlert = scope.showAlert !== false;

        var _canClick = true;

        element.click(function(){

            if(!_canClick){
                return;
            }

            _canClick = false;

            artBlockUserService.isBlocked(scope.user).then(function (data) {
                if (data.is_blocked) {
                    artMessage.showInfoAutoClose({ message: "This user is unavailable.", closeMs: 2000 });
                    _canClick = true;
                }
                else {
                    if(scope.isFriend){
                        artFriendsService.removeFriend(scope.user.id).finally(function(){
                            _canClick = true;
                        });
                        artMessage.showInfoAutoClose({ message: "You've removed " + scope.user.first_name + " from your Friends List 😭", closeMs: 2000 });
                    } else {
                        artFriendsService.addFriend(scope.user.id).finally(function(){
                            _canClick = true;
                        });
                        if(scope.showAlert){
                            artMessage.showInfoAutoClose({ message: "You've added " + scope.user.first_name + ' to your Friends List', closeMs: 2000 });
                        }
                    }
                }
            });
        });

    };

    return artToggleFriend;

}]);
