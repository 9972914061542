creatorModule.filter("centsToDollars", ['numberFilter','$sce', function(numberFilter, $sce){

    return function(cents, excludeCents, isSuperScript){

        var negative = cents < 0, dollars;

        if (negative) {
            cents = Math.abs(cents);
        }

        dollars = Math.floor(cents / 100);
        cents = cents % 100;

        if (cents < 10){

            cents = "0"+cents;

        }

        if(isSuperScript && !excludeCents) {
            return $sce.trustAsHtml((negative && '<sup class="currency">$</sup>-' || '<sup class="currency">$</sup>') +  numberFilter(dollars) + "." + cents);
        }

        if(isSuperScript && excludeCents && (cents == 0)) {
            return $sce.trustAsHtml((negative && '<sup class="currency">$</sup>-' || '<sup class="currency">$</sup>') +  numberFilter(dollars));
        }

        if (excludeCents && (cents == 0)) {
            return (negative && "-$" || "$") + numberFilter(dollars);
        }
        return (negative && "-$" || "$") + numberFilter(dollars) + "." + cents;

    };

}]);

