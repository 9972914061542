packOpenModule.controller("poSuggestedSettsController", [
    "$scope",
    "$window",
    "artConfig",
    "artResource",
    "artUser",
    "poRoute",
    "poPackSelect",
function(
    $scope,
    $window,
    artConfig,
    artResource,
    artUser,
    poRoute,
    poPackSelect
){

    $scope.setts = [];

    $scope.user = artUser.toObject();

    poPackSelect.fetchSettList().then(function(setts){

        $scope.setts = setts;

    });


    poPackSelect.fetchSett().then(function(data){
        $scope.referringSett = data;
    });

    $scope.close = function(){
        poRoute.finish();
    };

    $scope.collect = function(){
        if ($window.location.href != '/collect/') {
            $window.location.href = '/collect/';
        } else {
            return $scope.close();
        }
    };

    $scope.selectSett = function(sett) {
        artAnalytics.track("Picked Suggested Sett", {
            settId:sett.id,
            suggestedType:sett.suggested_type
        });
        var suggestionLedger = {
            'sett_opened': sett.id,
            'referring_sett': $scope.referringSett.id,
            'suggestion_type': sett.suggested_type
        };
        artResource.create(artConfig['api']['api-suggestion-ledger-list'], suggestionLedger);
    };

}]);
