artModule.factory('MomentService', [
    "$moment",
    function ($moment) {
        var condensedTimeFormat = {
            future: "now",
            past: "%s",
            s: "now",
            m: "now",
            mm: "%d mins",
            h: "1 hr",
            hh: "%d hrs",
            d: "1 day",
            dd: "%d days",
            M: "1 mon",
            MM: "%d mos",
            y: "1 yr",
            yy: "%d yrs"
        };

        var mediumTimeFormat = {
            future: "just now",
            past: "%s",
            s: "just now",
            m: "just now",
            mm: "%d min ago",
            h: "1 hour ago",
            hh: "%d hours ago",
            d: "1 day ago",
            dd: "%d days ago",
            M: "1 month ago",
            MM: "%d months ago",
            y: "1 year ago",
            yy: "%d years ago"
        };

        var timeFormat = {
            future: "just now",
            past: "%s",
            s: "just now",
            m: "just now",
            mm: "%d minutes ago",
            h: "1 hour ago",
            hh: "%d hours ago",
            d: "1 day ago",
            dd: "%d days ago",
            M: "1 month ago",
            MM: "%d months ago",
            y: "1 year ago",
            yy: "%d years ago"
        };

        var timeTilFormat = {
            future: "%s",
            past: "0 minutes left",
            s: "less than 1 minute left",
            m: "less than 1 minute left",
            mm: "%d minutes left",
            h: "1 hour left",
            hh: "%d hours left",
            d: "1 day left",
            dd: "%d days left",
            M: "1 month left",
            MM: "%d months left",
            y: "1 year left",
            yy: "%d years left"
        };

        var toPluralize = function(val, str) {
            return (val<=1 ? str : str+'s')
        }

        var formDiscontStr = function(inDays, inHours, inMins, inMonths) {
            if(inMins >= 1  && inMins < 60) {
                return inMins + ' ' + toPluralize(inMins, 'minute');
            }
            else if(inHours >= 1 && inHours < 24) {
                return inHours + ' ' + toPluralize(inHours, 'hour');
            }
            else if(inDays >= 1 && inDays < 11) {
                return inDays + ' ' + toPluralize(inDays, 'day');
            }
            return false;
        }

        var findDiff = function(startDate, endDate) {
            var inDays = Math.round(endDate.diff(startDate, 'days', true));
            var inHours = Math.round(endDate.diff(startDate, 'hours', true));
            var inMins = Math.round(endDate.diff(startDate, 'minutes', true));
            var inMonths = Math.round(endDate.diff(startDate, 'months', true));
            return formDiscontStr(inDays, inHours, inMins, inMonths);
        }

        $moment.locale('en', {relativeTime: timeFormat});

        var MomentService = {
            timeFormat: timeFormat,
            condensedTimeFormat: condensedTimeFormat,
            mediumTimeFormat: mediumTimeFormat,
            timeTilFormat: timeTilFormat,
            toPluralize: toPluralize,
            formDiscontStr: formDiscontStr,
            findDiff: findDiff,

            timeago: function (time, baseTime, isCondensed) {
                var format = timeFormat;
                if (isCondensed)
                    format = condensedTimeFormat;
                var currentTimeZone = moment.utc(time).toDate();
                var time = $moment(currentTimeZone);
                $moment.locale('en', {relativeTime: format});
                return $moment(time).from(baseTime || Date.now());
            },

            customFormatTimeAgo: function (time, format) {
                $moment.locale('en', {relativeTime: format});
                return $moment(time).from(Date.now());
            },

            timetil: function (time, isCondensed, hourToDate) {
                var format = timeTilFormat;
                var timeThreshold = 72;
                if (isCondensed)
                    format = condensedTimeFormat;
                if(hourToDate) {
                    timeThreshold = hourToDate;
                }

                $moment.locale('en', {relativeTime: format});
                $moment.relativeTimeThreshold('h', timeThreshold);
                return $moment(time).from(Date.now());
            },
            findDiffBWDate: function(date) {
                var startDate = $moment($moment().format());
                var currentTimeZone = moment.utc(date).toDate();
                var endDate = $moment(currentTimeZone);
                return findDiff(startDate, endDate);
            },
            // Format: May 24(yearless date)
            yearless: function (date) {
                return $moment(date).format('MMM DD');
            },
            // Format: 06/28/2019 DD/MM/YYYY
            minDateFormat: function(date) {
                return $moment(date).format('YYYY-MM-DD');
            },
            // get upcoming years
            getYears: function() {
                var year = new Date().getFullYear();
                var range = [];
                range.push(year);
                for (var i = 1; i < 20; i++) {
                    range.push((year + i).toString());
                }
                return range;
            },
            getMonthAsNumber: function(month) {
                return $moment().month(month).format("M");
            },
            getMonths: function() {
                var months = {}, key, val;
                for (var i = 1; i <= 12; i++) {
                    key = ('0' + i).slice(-2);
                    val = key + ' - ' + moment.monthsShort(i - 1);
                    months[key] = val;
                }
                return months;
            },

            // Replenishing timer. Format: 00:00:00
            getTimerCountDown: function(date) {
                const endOfDay = moment().endOf('day');
                const timeDifference = moment(endOfDay.diff(date || moment()));
                return moment.utc(timeDifference).format('HH:mm:ss');
            }
        };

        return MomentService;

    }])
    .filter('timeago', ['MomentService', function (MomentService) {
        return MomentService.timeago;
    }])
    .filter('yearless', ['MomentService', function (MomentService) {
        return MomentService.yearless;
    }])
    .filter('timetil', ['MomentService', function (MomentService) {
        return MomentService.timetil;
    }])
    .filter('findDiffBWDate', ['MomentService', function (MomentService) {
        return MomentService.findDiffBWDate;
    }])
    .filter('minDateFormat', ['MomentService', function (MomentService) {
        return MomentService.minDateFormat;
    }])
    .filter('getYears', ['MomentService', function (MomentService) {
        return MomentService.getYears;
    }])
    .filter('getMonths', ['MomentService', function (MomentService) {
        return MomentService.getMonths;
    }])
    .filter('getTimerCountDown', ['MomentService', function (MomentService) {
        return MomentService.getTimerCountDown;
    }]);
