artModule.factory("artOverlay", [
    '$document',
    '$location',
    '$q',
    '$timeout',
    'artSubscriptionService',
    'pieceTraderClickTracker',
function(
    $document,
    $location,
    $q,
    $timeout,
    artSubscriptionService,
    pieceTraderClickTracker
) {
    var _scope = null,
        _templateContext = null,
        _templateUrl = null,
        _lastState = null,
        _loading = false,
        _name = null,
        _themeClass = null,
        _templateUrls = {},
        _hideDeferred = null,
        _ESCAPE_KEY_CODE = 27,
        _SHOW_CLASS = "nm-showing-overlay",
        _baseUrl = null,
        _clickBackDropToClose = false;
        _escapeToClose = true;

	var artOverlay = {};

    function _closeKeyEvent(ev){

        if (ev.keyCode === _ESCAPE_KEY_CODE) {

            $timeout(function(){

                artOverlay.hide();

            });

        }

    }

    function _getFullTemplateUrl(overlayName){

        var templateUrl = _templateUrls[overlayName];

        if(!templateUrl){

            templateUrl = "partials/overlay-modules/overlay-" + overlayName + '.template.html';

        } else {
            templateUrl = templateUrl.templateUrl;
        }

        return templateUrl;

    }

    function _getTemplateContext(overlayName){
        var templateUrl = _templateUrls[overlayName];

        if (!templateUrl) {
            return null;
        } else {
            return templateUrl.context;
        }
    }

    function _getType(overlayName) {
        var templateUrl = _templateUrls[overlayName];

        if (!templateUrl) {
            return null;
        } else {
            return templateUrl.type;
        }
    }

    //For backwards compatibility with partials that used the old overlay method.
    function _updateScope(data){

        if(_scope){

            _scope.name = _name;
            _scope.data = data || {};

        }

    }

    function _hide(){
        $document.unbind("keyup", _closeKeyEvent);
        $location.path(_baseUrl);
        _templateUrl = null;
        _baseUrl = null;
        // Delay to match _ADD_CLASS
        $timeout(function(){
            angular.element('html').removeClass(_SHOW_CLASS);
        }, 350);
    }

    artOverlay.closeOnBackdropClick = function(){

      return _clickBackDropToClose;

    };

    //HACK: this allows us to set the scope data from the service (instead of needing to broadcast a change).
    artOverlay.init = function(scope){

        _scope = scope;

    };

    //This will override the default template behavior.
    artOverlay.bindNameToTemplateUrl = function(name, templateUrl, type){
        _templateUrls[name] = {
            templateUrl:templateUrl,
            type:type
        };

    };

    artOverlay.updateTemplateContext = function(name, context){
        var templateUrl = _templateUrls[name];

        if (!templateUrl) {
            return null;
        } else {
            templateUrl.context = context;
        }
    };

    artOverlay.getType = function() {
        return _getType(_name);
    };

    artOverlay.getThemeClass = function() {
        return _themeClass;
    };

    // By default artOverlay modal takes `theme-dark` as a background color.
    // but we have an option to change theme color by passing class name.
    // call artOverlay.show function with `theme-white` to bring light theme.
    artOverlay.show = function(name, promise, clickBackdropToClose, themeClass, escapeToClose){

        if (_lastState != null) {
            artSubscriptionService.broadcast('overlay-changing', {
                'name': _lastState
            })
        }

        _lastState = name;

        var htmlElement = angular.element('html');

        _clickBackDropToClose = clickBackdropToClose === true;

        _escapeToClose = escapeToClose === false ? false : true;

        _baseUrl = _baseUrl || $location.path();

        $document.unbind("keyup.nm-overlay");
        // You can disable escape key action by passing false.
        if(_escapeToClose) {
            $document.bind("keyup.nm-overlay", _closeKeyEvent);
        }

        //$timeout is being used since addClass will resolve immediately and not in a digest loop.
        //$apply could work too, but then I have to check if I'm in a digest loop.
        $timeout(function(){

            htmlElement.addClass(_SHOW_CLASS);

        }, 350);

        _name = name;

        _themeClass = themeClass;

        _templateUrl = _getFullTemplateUrl(name);

        _templateContext = _getTemplateContext(name);

        if (_templateContext) {
            angular.extend(_scope, _templateContext);
        }

        if(promise){

            artOverlay.showLoading(promise);

        } else {

            artOverlay.hideLoading();

        }

        _hideDeferred = $q.defer();
        return _hideDeferred.promise;

    };

    artOverlay.hide = function(status){
        if ((artOverlay.getName() === "show-needers" || artOverlay.getName() === "show-owners") && !status) {
            pieceTraderClickTracker.resetData();
        }

        if(artOverlay.getName() == "streaks") {

            _scope.$broadcast('streak-refresh-freebie');
        }

        _hide();

        var results = {
            "name": artOverlay.getName(),
            "status": status
        };

        artSubscriptionService.broadcast('overlay-closing', results);

        if(_hideDeferred){

            _hideDeferred.resolve(results);
            _hideDeferred = null; //remove the deferred from being called a second time.

        }

        _lastState = null;

    };

    artOverlay.showLoading = function(promise){

        _loading = true;

        if(promise){

            $q.when(promise).then(function(data){

                artOverlay.hideLoading();
                _updateScope(data);

            })
            .catch(function(err) {
                if(err.data) {
                    NM.error(err.data.detail);
                }
                else NM.error("Something went wrong :( Please reach NeonMob.");
            });

        }

    };

    artOverlay.hideLoading = function(){

        _updateScope();
        _loading = false;

    };

    artOverlay.getTemplateUrl = function(){
        return _templateUrl;

    };

    artOverlay.getName = function(){

        return _name;

    };

    artOverlay.isLoading = function(){

        return _loading;

    };

    return artOverlay;

}]);
