artModule.directive('artAllure', function(){

    var artAllure = {},
        _DEFAULT_ALLURE_CLASS = 'allure';

    artAllure.link = function(scope, elm, attr){

        var allureClass = attr.artAllureClass || _DEFAULT_ALLURE_CLASS;

        attr.$observe('artAllure', function(){

            var dur =   $(elm).css('-webkit-animation-duration') ||
                        $(elm).css('-moz-animation-duration') ||
                        $(elm).css('-ms-animation-duration') ||
                        $(elm).css('-o-animation-duration') ||
                        $(elm).css('animation-duration');

            $(elm).removeClass( allureClass );
            setTimeout(function(){
                $(elm).addClass( allureClass );
            },1);

        });

    };

    return artAllure;

});