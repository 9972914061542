neonmobApp.provider('RestMethods', function(){

    function getCollectionUrl(collection){
        if(collection){

            return collection.metadata.link.collection;

        } else {

            return null;

        }

    }

    function getSingleUrl(object){
        if(object){

            return object.link.self;

        } else {

            return null;

        }

    }

    this.$get = ["$http", function($http){

        return {

            create_data_in_collection: function(collection, data, callback, error){

                $http.post(getCollectionUrl(collection), data).success(function(data){

                    $http.get(getCollectionUrl(collection)).success(function(data){

                        collection.results = data.results;

                        if(callback){
                            callback(data);
                        }

                    }).error(function(data, status){

                        if(error){
                            error();
                        }

                    });

                });

            },

            add_comment_into_collection: function(collection, data, callback, error){

                $http.post(getCollectionUrl(collection), data).success(function(data){

                    $http.get(getCollectionUrl(collection)).success(function(data){

                        if(callback){
                            callback(data);
                        }

                    }).error(function(data, status){

                        if(error){
                            error();
                        }

                    });

                });

            },

            repost_specific_data: function(single, data, callback, error){

                var url = getSingleUrl(single);

                $http.post(url, data).success(function(data){

                    if(callback){

                        callback();

                    }

                }).error(function(data, status){

                    if(error){

                        error();

                    }

                });

            },

            read_data_in_collection: function(single, callback){

                $http.get(getSingleUrl(single)).success(callback);

            },

            read_collection: function(collection, callback, error){

                $http.get(getCollectionUrl(collection)).success(callback).error(function(data, status){

                    if(error){

                        error();

                    }

                });

            },

            update: function(single){

                $http.put(getSingleUrl(single), single).success(function(result){

                    $.extend(single, result);

                });

            },

            remove_specific_data: function(single, callback){

                var url = getSingleUrl(single);

                $http['delete'](url).success(function(){

                    if(callback){

                        callback();

                    }

                });

            },

            remove_data_from_collection: function(collection, data){

                $http['delete'](data.url).success(function(){

                    var index = collection.results.indexOf(data);

                    if(index !== -1){

                        collection.results.splice(index, 1);
                        collection.results = collection.results.slice(0); //ROGIE: hack to make sure scope.$watch functions notice a change

                    }

                });

            }

        };

    }];

});
