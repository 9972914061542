artModule.factory("artSubscriptionService", function () {

    var SubscriptionService,
        _subscriptions = {};

    SubscriptionService = {
        subscribe: function (event, callback) {
            var subscription = _subscriptions[event];
            if (!subscription) {
                subscription = [];
                _subscriptions[event] = subscription;
            }
            subscription.push(callback);
        },

        unsubscribe: function (event, callback) {
            var subscription = _subscriptions[event], i;
            if (!subscription) {
                return;
            }

            i = subscription.indexOf(callback);
            if (i > -1) {
                subscription.splice(i, 1);
            }
        },

        unsubscribeAll: function (event) {
            if (!_subscriptions[event]) {
                return;
            }

            delete _subscriptions[event];
        },

        broadcast: function (event, data) {
            var subscription = _subscriptions[event], i;
            if (!subscription) {
                return;
            }
            var args = Array.prototype.slice.call(arguments, 1);
            for (i = 0; i < subscription.length; i++) {
                subscription[i].apply(window, args);
            }
        }
    };

    return SubscriptionService;
});
