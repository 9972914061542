//wayback.archive-it.org/22769/20240513212157/https://julie oh so hacky but since we want sorting we need to get wacky
creatorModule.controller("ctCreatorPayments", [
    '$scope',
    'artConfig',
    'artResource',
    function(
        $scope,
        artConfig,
        artResource
    ){

    var _FIRST_YEAR = 2012;
    var _TODAY = new Date();
    var _CURRENT_MONTH = _TODAY.getMonth();
    var _CURRENT_YEAR = _TODAY.getFullYear();
    $scope.monthsList = [];
    $scope.loading = true;
    var year, month, payrollUrl;

    for (year = _CURRENT_YEAR; year >= _FIRST_YEAR; year--) {
        var maxMonth = 11;
        if(year === _CURRENT_YEAR){
          maxMonth = _CURRENT_MONTH;
        }
        for (month = maxMonth; month >= 0; month--) {
          $scope.monthsList.push(new Date(year, month));
        }
    }


    $scope.creatorList  = [];
    $scope.creatorEarnings = [];
    $scope.filters      = {
        // By default load earning detail for previous month
        month: $scope.monthsList[1],
        report_type: 'earnings-only'
    };
    $scope.earningsFilterOptions = [
        {'val': 'all-creators', 'label': 'All Creators (Active or with Net Earning)'},
        {'val': 'active-creators', 'label': 'Active Creators (At least one stat besides Net Earning)'},
        {'val': 'earnings-only', 'label': 'Earnings Only (Creators whose series earned)'}
    ];

    // orderColumn and reverse are used for table sorting
    $scope.orderColumn = 'user.name';
    $scope.reverse = false;

    $scope.setOrderColumn = function(orderColumn) {

        $scope.orderColumn = orderColumn;
        $scope.reverse = !$scope.reverse;

    };

    $scope.canExport = function(){
        $scope.fileName = (
            $scope.filters.month.getFullYear() + "-" + ($scope.filters.month.getMonth() + 1) + "-" +
            $scope.filters.report_type + ".csv");
        return $scope.filtered && $scope.filtered.length > 0;
    };

    $scope.selectedColumns = function () {
        var rows = [
            {
                "Creator"                   : "TOTAL",
                "Paypal"                    : "--------",
                "freebie"                   : $scope.payrollTotals.free_packs_items,
                "Packs"                     : $scope.payrollTotals.purchased_packs_items,
                "sales"                     : $scope.payrollTotals.purchased_packs_sale_price_cents / 100,
                "royalties"                 : $scope.payrollTotals.purchased_packs_royalty_cents / 100,
                "paypal_payout"             : $scope.payrollTotals.paypal_payout / 100,
                "neonmob_fee"               : $scope.payrollTotals.neonmob_fee / 100,
                "paypal_fee"                : $scope.payrollTotals.paypal_fee / 100,
                "credit_payout"             : $scope.payrollTotals.credit_payout / 100,
                "monthly_liability_cents"   : $scope.payrollTotals.monthly_liability_cents / 100,
                "outstanding_liability_cents": $scope.payrollTotals.outstanding_liability_cents / 100,
                "net_unpaid_royalty_cents"  : $scope.payrollTotals.net_unpaid_royalty_cents / 100
            }
        ];
        angular.forEach($scope.filtered, function(value, key){
            rows.push({
                "Creator"                   : value.user.name,
                "Paypal"                    : value.user.paypal_email || "Paypal email missing",
                "freebie"                   : value.free_packs.items,
                "Packs"                     : value.purchased_packs.items,
                "sales"                     : value.purchased_packs.sale_price_cents / 100,
                "royalties"                 : value.purchased_packs.royalty_cents / 100,
                "paypal_payout"             : value.payouts.paypal / 100,
                "neonmob_fee"               : value.payouts.neonmob_fee / 100,
                "paypal_fee"                : value.payouts.paypal_fee / 100,
                "credit_payout"             : value.payouts.credit / 100,
                "monthly_liability_cents"   : value.monthly_liability_cents / 100,
                "outstanding_liability_cents": value.outstanding_liability_cents / 100,
                "net_unpaid_royalty_cents"  : value.net_unpaid_royalty_cents / 100
            });
        });
        return rows
    };

    $scope.selectedHeaders = function () {
        return [
            "User Name",
            "PayPal Email",
            "Free Packs",
            "Paid Packs",
            "Paid Pack Revenue($)",
            "Creator Royalties($)",
            "Creator Payouts($)",
            "PayPal Fee($)",
            "NeonMob Fee($)",
            "Converted To Credits($)",
            "Advance Payments($)",
            "Unsettled Advance($)",
            "Unclaimed Royalties($)"
          ];
    };

    $scope.getPayrollData = function() {
        _resetTotals();
        $scope.loading = true;
        // have to hardcode api-payroll url
        payrollUrl = "/api/payroll/" + $scope.filters.month.getFullYear() + "/" + ($scope.filters.month.getMonth() + 1) + "/";
        artResource.retrieve( payrollUrl ).then(function(data){
            $scope.creatorEarnings = data;
            $scope.ready = true;
            $scope.loading = false;

        });
    };

    $scope.addToTotal = function(earning) {

        $scope.payrollTotals.free_packs_items += earning.free_packs.items;

        $scope.payrollTotals.purchased_packs_items += earning.purchased_packs.items;
        $scope.payrollTotals.purchased_packs_sale_price_cents += earning.purchased_packs.sale_price_cents;
        $scope.payrollTotals.purchased_packs_royalty_cents += earning.purchased_packs.royalty_cents;

        $scope.payrollTotals.paypal_payout += earning.payouts.paypal;
        $scope.payrollTotals.credit_payout += earning.payouts.credit;
        $scope.payrollTotals.neonmob_fee += earning.payouts.neonmob_fee;
        $scope.payrollTotals.paypal_fee += earning.payouts.paypal_fee;

        $scope.payrollTotals.monthly_liability_cents += earning.monthly_liability_cents;
        $scope.payrollTotals.outstanding_liability_cents += earning.outstanding_liability_cents;
        $scope.payrollTotals.net_unpaid_royalty_cents += earning.net_unpaid_royalty_cents;

    };

    var _resetTotals = function() {
        $scope.payrollTotals = {
            free_packs_items: 0,
            purchased_packs_items: 0,
            purchased_packs_sale_price_cents: 0,
            purchased_packs_royalty_cents: 0,
            paypal_payout: 0,
            credit_payout: 0,
            neonmob_fee: 0,
            paypal_fee: 0,
            monthly_liability_cents: 0,
            outstanding_liability_cents: 0,
            net_unpaid_royalty_cents: 0
        };
    };

    $scope.showEmptyState = function() {

        return $scope.creatorEarnings.length < 1 & !$scope.loading;

    };

    $scope.filterEarnings = function(data) {
        if ($scope.filters.report_type == 'earnings-only') {
            return data.purchased_packs.royalty_cents > 0;
        } else if ($scope.filters.report_type == 'active-creators') {
            return (
                data.purchased_packs.royalty_cents || data.free_packs.items || data.payouts.paypal ||
                data.payouts.credit || data.monthly_liability_cents);
        } else {
            return true;
        }

    };

    $scope.calculateNewTotals = function(filteredData) {
        _resetTotals();
        for (var i=0; i<filteredData.length; i++) {
            $scope.addToTotal(filteredData[i]);
        }
        return filteredData;
    };


    artResource.retrieve( artConfig.api['api-creators-list'] ).then(function(data){

        $scope.creatorList = [{name: 'All Creators',id:undefined}].concat(data);
        $scope.filters.creator = $scope.creatorList[0];

    });

    $scope.getPayrollData();

}]);
