neonmobApp.factory('trader_grade', function() {

    var grades = ['F', 'F+', 'D-', 'D', 'D+', 'C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-','A','A+'];

    return {

        letter_grade: function(numeric_grade){

            return grades[parseInt(numeric_grade, 10)];

        },
        letter_grade_class: function(numeric_grade){

            return grades[parseInt(numeric_grade, 10)]
                    .toLowerCase()
                    .replace('-', '-minus')
                    .replace('+', '-plus');

        }

    };

}).filter('letter_grade', ['trader_grade', function(trader_grade) {

    return function(numeric_grade) {

        return trader_grade.letter_grade(numeric_grade);

    };

}]).filter('letter_grade_class', ['trader_grade', function(trader_grade) {

    return function(numeric_grade) {

        return trader_grade.letter_grade_class(numeric_grade);

    };

}]);
