/**
 * A service for interfacing with the invite endpoint and inviting your friends.
 */

neonmobApp.provider("Inviter", function() {


    // Global Inviter member variables
    var _inviteEndpoint = '/data/invite-by-email.json/',
        _delimiter = ',';

    this.setEndpoint = function( endpoint ) {

        _inviteEndpoint = endpoint;

    };

    this.setDelimiter = function( delim ){

        _delimiter = delim;

    };

    this.$get = ["$http", "$q", function( $http, $q){

        //Private stuff

        function _isEmail( emailAddress ){
            return (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/ig).test( emailAddress );
        }

        function _toEmailArray( emails ){

            var aEmails = [];

            if( typeof emails == 'object' && emails.constructor && emails.constructor == Array ){

                for( var i = 0, len = emails.length; i < len; ++i ){
                    if( _isEmail( emails[i] ) ){
                        aEmails.push( emails[i] );
                    }
                }

            } else if( typeof emails == 'string' ){

                var e = _toEmailArray( emails.split( _delimiter ) );
                aEmails = aEmails.concat( e );

            }

            return aEmails;

        }

        //Public stuff
        var Inviter = {


            /**
             * Send a neonmob invitation to a friend. This does not handle errors by default.
             *
             * @param {mixed}   The email {String} of people to invite (comma separated) / {Array} of emails to invite
             * @returns {Object} A Promise object.
             */
            sendInvitation: function( emailAddresses, message ){

                var aEmails = [],
                    deferred = $q.defer();

                aEmails = _toEmailArray ( emailAddresses );

                deferred.notify( 'sending' );

                $http.post(
                    _inviteEndpoint,
                    {
                        invite_message: message,
                        invite_email:   aEmails
                    },
                    {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        transformRequest: function(data){
                            return $.param(data);
                        }
                    }
                ).success( function( data ){

                    if( data.success ){
                        deferred.resolve( data );
                    } else {
                        deferred.reject( data );
                    }

                })
                .error( function( data, status ){

                    if( status !== 0 && status !== 200 ){
                        NM.error( 'error', 'Could not invite.' );
                    } else {
                        NM.error( 'error', status );
                    }
                    deferred.reject( data, status );

                });

                return deferred.promise;

            }

        };

        return Inviter;

    }];

});
