neonmobApp.filter('pluralize', function() {

	return function(input, count) {

		if (count == 0 || count > 1) {

			return input + 's';
		}

		return input;
	};
});