artModule.factory("ImageService", [
    "$exceptionHandler",
    "$q",
    "$timeout",
    "$window",
function (
    $exceptionHandler,
    $q,
    $timeout,
    $window
) {

    var ImageService = {};

    ImageService.preload = function (url) {
        var image = new Image(),
            imageDefer = $q.defer();

        if(!url){
            $exceptionHandler(new Error("Invalid image url passed in: "+url));
            $q.reject(image);
            return imageDefer.promise;
        }

        var onLoad = function () {
            cleanUp();
            imageDefer.resolve(this);
        };

        var onError = function() {
            cleanUp();
            imageDefer.reject(this);
        };

        var failsafe = $timeout(onError, 2000);

        var cleanUp = function() {
            $timeout.cancel(failsafe);
            image.removeEventListener('load', onLoad);
            image.removeEventListener('error', onError);
        };

        image.addEventListener('load', onLoad);
        image.addEventListener('error', onError);

        image.src = url;

        //There's no easy way to trigger an image load for this.
        if ($window.NM_TEST) {
            imageDefer.resolve(image);
        }
        return imageDefer.promise;
    };

    ImageService.preloadAll = function (urls) {
        var promises = [];
        for (var i = 0; i < urls.length; i++) {
            promises.push(ImageService.preload(urls[i]));
        }
        return $q.all(promises);
    };

    return ImageService;
}]);