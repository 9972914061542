artModule.directive("artProgressBar", function(){

    var artProgressBar = {};

    artProgressBar.scope = {

        progressId: "@artProgressBar"

    };

    artProgressBar.controller = [
        "$scope",
        "artProgress",
    function(
        $scope,
        artProgress
    ){

        $scope.getStyle = function(){

            return {

                width: artProgress.getProgress($scope.progressId)+"%"

            };

        };

        $scope.isActive = function(){

            return artProgress.isActive($scope.progressId);

        };

    }];

    artProgressBar.templateUrl = "partials/creator/widgets/progress-bar.partial.html";

    return artProgressBar;

}).factory("artProgress", [
    "$rootScope",
    "$timeout",
function(
    $rootScope,
    $timeout
){

    var artProgress = {},
        _active = {};

    artProgress.getProgress = function(id){

        return _active[id] || 0;

    };

    artProgress.isActive = function(id){

        return id in _active;

    };

    artProgress.start = function(id){

        artProgress.update(id, 0);

    };

    artProgress.update = function(id, percent){


        $rootScope.$evalAsync(function(){

            _active[id] = percent;

        });

    };

    artProgress.cancel = function(id){

        delete _active[id];

    };

    artProgress.end = function(id){

        artProgress.update(id, 100);

        $timeout(function(){

            delete _active[id];

        }, 667);

    };

    return artProgress;

}]).filter("artProgressActive", ['artProgress', function(artProgress){

    return function(progressName){

        return artProgress.isActive(progressName);

    };

}]).filter("artProgressInactive", ['artProgress', function(artProgress){

    return function(progressName){

        return !artProgress.isActive(progressName);

    };

}]);
