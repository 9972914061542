artModule.factory("artJson", function() {
    var artJson = {};

    artJson.parse = function(json){

        return window.resolveReferences(JSON.parse(json));

    };

    artJson.parseFromInput = function(selector){

        var value = angular.element(selector).val();

        if (value) {
            return artJson.parse(value);
        } else {
            return null;
        }

    };

    return artJson;
});