artModule.controller("artCollectionCardsController", [
    "$scope",
    "artConstants",
    "artResource",
    "artUser",
    "artUrl",
    "CollectionService",
    "artPieceService",
    "artSubscriptionService",
    "artResponsive",
    "nmPieceDetailService",
    "ArrayTo2dColumnArray",
    "artConfig",
    "nmConfig",
    function ($scope,
              artConstants,
              artResource,
              artUser,
              artUrl,
              CollectionService,
              artPieceService,
              artSubscriptionService,
              artResponsive,
              nmPieceDetailService,
              ArrayTo2dColumnArray,
              artConfig,
              nmConfig
              ) {

        $scope.profileLinks = artConfig.profileLinks;

        // exposed for tests
        $scope.pieces = [];
        $scope.expanded = false;

        $scope.targetUser = null;
        $scope.artWidth = 220;
        $scope.columns = [];

        $scope.viewState = "loading";

        $scope.isOwner = false;
        $scope.isAuthenticated = artUser.isAuthenticated();
        $scope.settVersion = artConstants.VERSION_TYPES.limited;
        $scope.canTrade = artUser.canIDo('trade');
        $scope.canFavorite = artUser.canIDo('favorite');

        $scope.selectedFilters = {};
        $scope.cardsSortOptions = CollectionService.createCollectionCardsSort();
        $scope.selectedSortObject = $scope.cardsSortOptions[0];
        $scope.filters = {
            user: artConfig.targetId,
            search: null,
            favorite: false,
            duplicates: false,
            common: false,
            uncommon: false,
            rare: false,
            veryRare: false,
            extraRare: false,
            variant: false,
            chase: false,
            legendary: false,
            orderBy : "acquired",
            order : "desc"
        };

        $scope.baseUrl = nmConfig['api-piece-base-url'];

        $scope.getUrl = function(){
            return artUrl.updateParams($scope.baseUrl, $scope.filters);
        };

        $scope.init = function(){
            $scope.pieces = [];
            CollectionService.fetchCollectionUser().then(function(user){
                $scope.targetUser = user;
                $scope.isOwner = artUser.isYou(user);
            });

            function _fetchCards() {
                return artResource.retrievePaginated($scope.getUrl());
            }
            $scope.loading = true;
            _fetchCards().then(function (results) {
                $scope.loading = false;
                $scope.pieces = results;
                $scope.viewState = "loaded";
                setupArtWidthColumns();

            });
        }

        $scope.getNextPage = function () {
            if($scope.loading || $scope.loadingMore || !$scope.pieces.next) {
                return;
            }
            if ($scope.pieces.length > 0) {
                $scope.loadingMore = true;
                $scope.pieces.retrieveNext().then(function (data) {
                    setupArtWidthColumns();
                    $scope.loadingMore = false;
                });
            }
        };

        $scope.selectSortOption = function(selectedSort) {
            if (selectedSort && $scope.selectedSortObject.id !== selectedSort.id){
                $scope.selectedSortObject  = selectedSort;
                $scope.filters.orderBy = selectedSort.key;
                $scope.filters.order = selectedSort.options;
                $scope.init();
            }
        };

        $scope.handleClick = function($event) {
            $event.stopPropagation();
			$scope.expanded = !$scope.expanded;
		};

        function setupArtWidthColumns() {
            var layout = getViewLayout(),
                piece;

            for (var i = 0, c = $scope.pieces.length; i < c; ++i) {
                piece = $scope.pieces[i];

                if (piece.asset_type === "image")
                    piece.$height = Math.ceil((layout.columnWidth / piece.piece_assets.image.medium.width) *
                        piece.piece_assets.image.medium.height);
                else
                    piece.$height = Math.ceil((layout.columnWidth / piece.piece_assets.video.medium.width) *
                        piece.piece_assets.video.medium.height);
            }

            $scope.artWidth = layout.columnWidth;

            $scope.columns = ArrayTo2dColumnArray($scope.pieces, layout.columns);
        };

        function getViewLayout() {
            var width = artResponsive.getWindowWidth(),
                padding = 10,
                columns = 4;

            width = width > 960 ? 960 : width;
            columns = width <= 480 ? 2 : columns;
            padding = columns < 4 ? 5 : padding;

            return {
                columnWidth: Math.ceil(width / columns - padding * 2),
                columns: columns
            };
        }

        $scope.toggleFavorite = function (piece, event) {

            if (event) event.stopPropagation();
            artPieceService.toggleFavorite(piece);
        };
        $scope.hasDuplicate = function (piece) {
            return piece.own_count > 1;
        };

        $scope.showPieceDetail = function (piece) {
            nmPieceDetailService.startPieceDetailOverlay(
                $scope.targetUser,
                $scope.pieces,
                piece.id
            );
        };

        $scope.getPrintCount = function(piece){

            return piece.own_count;
        };

        var resizeListener = artResponsive.onResize(setupArtWidthColumns);
        var updateCollectionListener = $scope.$on("updateCollection", $scope.init);
        artSubscriptionService.subscribe("pack-consumed", $scope.init);
        artSubscriptionService.subscribe("pack-open-finished", $scope.init);
        artSubscriptionService.subscribe("trade-accepted", $scope.init);

        // TODO - also listen for trades and badge earned

        $scope.$on("$destroy", function () {
            resizeListener();
            updateCollectionListener();
            artSubscriptionService.unsubscribe("pack-consumed", $scope.init);
            artSubscriptionService.unsubscribe("pack-open-finished", $scope.init);
            artSubscriptionService.unsubscribe("trade-accepted", $scope.init);
        });

        $scope.init();
    }
]);
