artModule.service('artGlobalAlertService',[
	function(){

		var artGlobalAlertService = {};

		var 
		_classes = '',
		_message;

		artGlobalAlertService.getAlertMessage = function() {

			return _message;
		};

		artGlobalAlertService.setupGlobalAlert = function(params) {

			_classes = params.classes;
			_message = params.message;
		};

		artGlobalAlertService.getClasses = function(classes) {

			return _classes;

		};

		return artGlobalAlertService;
}]);
