artModule.factory("artUtils", function(){

    var artUtils = {}

    artUtils.splitArrayByColumn = function(array, columns){

        var i = 0,
            results = [];

        while (i < array.length) {

            var size = Math.ceil((array.length - i) / columns--);
            results.push(array.slice(i, i + size));
            i += size;

        }

        return results;

    };

    artUtils.detectswipe = function (el, func) {
        swipe_det = new Object();
        swipe_det.sX = 0;
        swipe_det.sY = 0;
        swipe_det.eX = 0;
        swipe_det.eY = 0;
        var min_x = 20;  //min x swipe for horizontal swipe
        var max_x = 40;  //max x difference for vertical swipe
        var min_y = 40;  //min y swipe for vertical swipe
        var max_y = 50;  //max y difference for horizontal swipe
        var direc = "";
        var ele = document.getElementsByClassName(el)[0];
        ele.addEventListener('touchstart', function (e) {
            var t = e.touches[0];
            swipe_det.sX = t.screenX;
            swipe_det.sY = t.screenY;
        }, false);
        ele.addEventListener('touchmove', function (e) {
            e.preventDefault();
            var t = e.touches[0];
            swipe_det.eX = t.screenX;
            swipe_det.eY = t.screenY;
        }, false);
        ele.addEventListener('touchend', function (e) {
            //horizontal detection
            if ((((swipe_det.eX - min_x > swipe_det.sX) || (swipe_det.eX + min_x < swipe_det.sX)) && ((swipe_det.eY < swipe_det.sY + max_y) && (swipe_det.sY > swipe_det.eY - max_y)))) {
                if (swipe_det.eX > swipe_det.sX) direc = "r";
                else direc = "l";
            }
            //vertical detection
            if ((((swipe_det.eY - min_y > swipe_det.sY) || (swipe_det.eY + min_y < swipe_det.sY)) && ((swipe_det.eX < swipe_det.sX + max_x) && (swipe_det.sX > swipe_det.eX - max_x)))) {
                if (swipe_det.eY > swipe_det.sY) direc = "d";
                else direc = "u";
            }

            if (direc != "") {
                if (typeof func == 'function') func(el, direc);
            }
            direc = "";
        }, false);
    }

    return artUtils;

});
