artModule.directive('artCardBanner', [function () {
    var directiveDefinitionObject;

    directiveDefinitionObject = {
        scope: {
            text: "=artCardBannerText",
            color: "=artCardBannerColor",
            textColor: "=artCardBannerTextColor",
            backgroundColor: "=artCardBannerBackgroundColor"
        },
        controller: function($scope) {
            /* Dynamically set pre-configured banner CSS (using colors like red) for out of print, amateur etc. */
            $scope.getNamedColorCSS = function() {
                if ($scope.color) {
                    return "card--banner--" + $scope.color;
                }
                return "";
            };
            /* A better way to dynamically set CSS for pack-tier banners */
            $scope.getDynamicCSS = function() {
                if ($scope.backgroundColor) {
                    return {"background-color": $scope.backgroundColor, "color": $scope.textColor || 'black'};
                }
                return "";
            };
        },
        templateUrl: "partials/art/card.banner.partial.html"
    };
    return directiveDefinitionObject;
}]);
