neonmobApp.directive('nmScrollingSetts', function(){

    var nmScrollingSetts = {};

    nmScrollingSetts.scope = {
        paginatedList: "=nmScrollingSetts",
        user: "=user",
        displayType: "=displayType"
    };

    nmScrollingSetts.controller = [
        '$scope',
    function(
        $scope
    ){

        var currentIndex = 0,
            loadingActive = false,
            MAX_SETTS = 3,
            LOADING_BUFFER = 4;


        $scope.decrementIndex = function() {
            currentIndex = Math.max(currentIndex-1, 0);
        };
        $scope.incrementIndex = function () {
            if (loadingActive) return;
            if (!$scope.paginatedList) return;
            currentIndex = Math.min(currentIndex + 1, $scope.paginatedList.count - MAX_SETTS);
            if(currentIndex >= $scope.paginatedList.length - LOADING_BUFFER && $scope.paginatedList.length < $scope.paginatedList.count){
                loadingActive = true;
                $scope.paginatedList.retrieveNext().then(function(){
                    loadingActive = false;
                });
            }
        };
        $scope.showBackArrow = function() {
            return currentIndex > 0;
        };
        $scope.showForwardArrow = function(){
            if (!$scope.paginatedList) return;
            return currentIndex + MAX_SETTS < $scope.paginatedList.count;
        };
        $scope.calculateStyle = function() {
            leftValue = -320 * currentIndex;
            return {left: leftValue + "px"};
        };


    }];

    nmScrollingSetts.templateUrl = "partials/profile-new/scrolling-setts.partial.html";
    return nmScrollingSetts;
});