artModule.factory("artPromiseHelpers", ['$q', function ($q) {

    function allObject(promiseMap) {
        var promiseNames = Object.keys(promiseMap);
        var promises = [];

        promiseNames.forEach(function (key) {
            promises.push(promiseMap[key]);
        });

        return $q.all(promises).then(function (results) {
            return results.reduce(function (accum, result, idx) {
                accum[promiseNames[idx]] = result;
                return accum;
            }, {});
        });
    }

    function seriesArray(promises) {
        var results = [];
        return promises.reduce(function (accumPromise, promise) {
            // .then() adds promise to our overall chain
            return accumPromise.then(function () {
                return promise;
            }).then(function (result) {
                results.push(result);
                return results;
            });
        }, $q.when());
    }

    function seriesObject(promiseMap) {
        var promiseNames = Object.keys(promiseMap);
        var promises = [];

        promiseNames.forEach(function (key) {
            promises.push(promiseMap[key]);
        });

        var results = {};

        return promises.reduce(function (accumPromise, promise, idx) {
            // .then() adds promise to our overall chain
            return accumPromise.then(function () {
                return promise;
            }).then(function (result) {
                results[promiseNames[idx]] = result;
                return results;
            });
        }, $q.when());
    }

    return {
        allObject: allObject,
        seriesArray: seriesArray,
        seriesObject: seriesObject
    }
}]);
