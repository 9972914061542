artModule.directive('artPack', [function () {
    var directiveDefinitionObject;

    function _simpleDate(dateString) {
        if (typeof dateString != 'string') {
            return null;
        }
        dateString = dateString.replace(/^([0-9]{4}-[0-9]{2}-[0-9]{2})\s([0-9]{2}:[0-9]{2}:[0-9]{2}.*)/, "$1T$2");
        return new Date(dateString);
    }

    directiveDefinitionObject = {
        scope: {
            set: "=artPack",
            count: "=artPackCount",
            size: "=?artPackSize",
            className: "=?artPackClass",
            seriesCompletionClass: "=?artPackSeriesCompletionClass",
        },
        controller: [
            '$scope',
            '$attrs',
            'artSett',
            'artUser',
            function ($scope,
                $attrs,
                artSett,
                artUser) {

                $scope.pack_count = isNaN($scope.count) ? 1 : $scope.count;
                $scope.type = "pack";
                $scope.size = $scope.size || "small";
                $scope.showBanner = $attrs.hasOwnProperty("artPackShowBanner") || artSett.isLimitedSett($scope.set);
                $scope.bannerText = "";
                $scope.bannerColor = "";
                $scope.areFeaturesGated = artUser.areFeaturesGated();
                if (typeof $scope.set.discontinued == 'string') {
                    $scope.set.sold_out_date = _simpleDate($scope.set.discontinued);
                }

                $scope._getSettStatus = function () {
                    if (!artSett.isUnlimitedSett($scope.set)) return "limited";
                    if (artSett.isEditing($scope.set)) return "editing";
                    if (artSett.isSubmitted($scope.set)) return "submitted";
                    if (artSett.isPromoOnly($scope.set)) return "promo-only";
                    if (artSett.isAmateurSett($scope.set)) return "amateur";
                    if (artSett.isReplicaSett($scope.set)) return "replica";
                    if (artSett.isSoldOut($scope.set)) return "sold-out";
                };

                $scope.$watch('count', function () {
                    $scope.pack_count = isNaN($scope.count) ? 1 : $scope.count;
                });

                function _initBanner() {

                    if (artSett.isAIAssisted($scope.set)) {
                        $scope.bannerText = "AI";
                        $scope.bannerColor = "red";
                        return;
                    }
                    
                    if (!$scope.showBanner) {
                        return;
                    }

                    switch ($scope._getSettStatus()) {
                        case "editing":
                            $scope.bannerText = "In Progress";
                            $scope.bannerColor = "yellow";
                            break;

                        case "submitted":
                            $scope.bannerText = "Submitted";
                            $scope.bannerColor = "yellow";
                            break;

                        case "promo-only":
                            $scope.bannerText = "Promo Only";
                            $scope.bannerColor = "blue";
                            break;

                        case "reward":
                            $scope.bannerText = "Reward";
                            $scope.bannerColor = "green";
                            break;
                        case "amateur":
                            $scope.bannerText = "Amateur";
                            $scope.bannerColor = "purple";
                            break;
                        case "limited":
                            $scope.bannerText = "Limited";
                            $scope.bannerColor = "violet";
                            break;
                        case "replica":
                            $scope.bannerText = "Replica";
                            $scope.bannerColor = "purple-dark";
                            break;
                        default:
                            $scope.showBanner = false;
                            break;
                    }
                }

                _initBanner();
            }],
        templateUrl: "partials/art/pack.partial.html"
    };
    return directiveDefinitionObject;
}]);
