artModule.controller('collectionShareController', [
    '$scope',
    '$timeout',
    'artShare',

function(
    $scope,
    $timeout,
    artShare
){
 
    $scope.copyLinkButtonText = 'Copy';
    $scope.copyButtonDisabled = false;
    $scope.afterUrlCopy = function () {        
        min = 1000;
        max = 9999;
        cacheBuster= Math.floor(Math.random() * (max - min) + min);
        $scope.textToCopy = $scope.getSharePreviewUrl();
        $scope.text = $scope.getSharePreviewUrl();
        $scope.copyLinkButtonText = 'Copied!';
        $scope.copyButtonDisabled = true;
        $timeout(function () {
            $scope.copyLinkButtonText = 'Copy';
            $scope.copyButtonDisabled = false;
        }, 1500);
    };
    
    $scope.getSharePreviewUrl = function(){
        return artShare.createShareUrl($scope.getPopoverData().getPublicUrl(), channel="direct", source="showcase-share");
    };
}]);
