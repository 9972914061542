artModule.provider("artUrl", function(){

    var _staticRoot = null;

    this.setStaticRoot = function(staticRoot){
        _staticRoot = staticRoot;
    };

    this.$get = ["$window", function($window){
        var artUrl = {};

        artUrl.updateParams = function(url, params){

            params = $.extend({}, artUrl.parseParams(url), params);

            var encodedParams = [];

            for(var key in params){

                // single != checks both null and undefined - todd dont add an angry equal sign
                if(params.hasOwnProperty(key) && params[key] != null){

                    encodedParams.push(key+"="+encodeURIComponent(params[key]));

                }
            }

            encodedParams.sort();

            if(encodedParams.length > 0){

                return artUrl.removeParams(url) + "?" + encodedParams.join("&");

            } else {

                return artUrl.removeParams(url);

            }
        };

        artUrl.parseParams = function(url){

            var paramString = url.split("?")[1],
                results = {};

            if(paramString){

                var items = paramString.split("&");

                for(var i=0; i<items.length; i++){

                    var keyValue = items[i].split("=");
                    results[keyValue[0]] = decodeURIComponent(keyValue[1]);

                }

            }

            return results;

        };

        artUrl.removeParams = function(url){
            return url.split("?")[0];
        };

        artUrl.staticUrl = function(url){

            if(_staticRoot === null){

                throw new Error("No static url was configured and it is required.");

            }

            return _staticRoot + url;

        };

        artUrl.removeDomain = function(url){

            return "/"+url.replace(/^https?\:\/\/[a-z\-_.\d]+(\:\d+)?\//, '');

        };

        artUrl.addDomain = function(url){

            return artUrl.join($window.location.origin, url)

        };

        artUrl.join = function(root, path, trailingSlash){

            var url = root.toString().replace(/\/$/,"") + "/" + path.toString().replace(/^\//,"");
            if(trailingSlash) {
                return url.replace(/\/$/,"") + "/";
            } else {
                return url;
            }

        };

        artUrl.addProtocol = function(url){

            if(/^https?:\/\//.test(url)){

                return url;

            }

            if(/^\/\//.test(url)){
                return $window.location.protocol + url;
            }

            return url;

        };

        artUrl.formatExternalUrl = function(url){

            if(/^https?:\/\//.test(url)){

                return url;

            }

            if(/^\w+\.\w+(\.\w+)?\/?/.test(url)) {
                return "http://" + url;
            }

            return this.addProtocol(url);

        };

        return artUrl;

    }];

}).filter("artStaticUrl", ["artUrl", function(artUrl){

    return artUrl.staticUrl;

}]).filter("artDomainUrl", ["artUrl", function(artUrl){

    return artUrl.addDomain;

}]);
