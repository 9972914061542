/*
 * Tab directive
 * Used pages: Milestone page, Favourite page.
 * Used files: favorites-list.partial.html, Milestones-list.partial.html
*/

neonmobApp.directive('tab', [function () {
    var tabObj = {};

    tabObj.scope = {
        tabItem: "=tabItem",
        tabSelected: "=tabSelected",
        tabOnClick: "=tabOnClick",
        tabResponsiveText: "=tabResponsiveText",
        settNameSlug: "=?tabSettNameSlug",
        targetUserFlow: "=?targetUserFlow",
        loggedInUserName: "=?loggedInUserName",
    };
    tabObj.templateUrl = "partials/component/tab.partial.html";
    return tabObj;
}]);
