artModule.filter('settImageUrl', [
    'artSett',
    function (artSett) {
        return function (sett, size, imageType) {
            return artSett.getImageUrl(sett, size, imageType);
        };
    }])
    .filter('settPermalink', [
        'artSett',
        function (artSett) {
            return function (sett) {
                return artSett.getPermalink(sett);
            };
        }])
    .filter('rarityOdds', function () {
        return function (rarityRatio, compact) {
            var MIN_RATIO = 0.5,
                compact = compact || false,
                oddsText = compact ? 'most' : 'almost all';
            if (rarityRatio <= MIN_RATIO) {
                oddsText = compact ? '' : '1 in ';
                oddsText += Math.round(1 / rarityRatio);
            }
            return oddsText;
        };
    })
    .filter('freebieRarityOdds', function (rarityOddsFilter) {
        return function (rarity, sett) {
            rarityRatio = rarity.total_prints / sett.total_print_count * sett.prints_per_free_pack;
            return rarityOddsFilter(rarityRatio);
        };
    })
    .filter('purchaseRarityOdds', function (rarityOddsFilter) {
        return function (rarity, sett) {
            if(sett.prints_per_paid_pack === -1)
                sett.prints_per_paid_pack = 1;

            rarityRatio = rarity.total_prints / sett.total_print_count * sett.prints_per_paid_pack;
            return rarityOddsFilter(rarityRatio);
        };
    })
    .filter('settBackgroundUrl', [
        'artSett',
        function (artSett) {
            return function (sett) {
                return artSett.getBackgroundUrl(sett);
            };
        }]);
