artModule.directive('artRarityStats', function () {
    return {
        scope: {
            sett: "=artRarityStats"
        },
        controller: [
            '$scope', function($scope) {
                
                $scope.showingFreebie = $scope.sett.prints_per_free_pack === -1;

                $scope.showPurchasedOdds = function () {
                    $scope.showingFreebie = false;
                };
                $scope.showFreebieOdds = function () {
                    $scope.showingFreebie = true;
                };
                if ($scope.sett.rarities) {
                    $scope.rarities = $scope.sett.rarities;
                } else {
                    $scope.rarities = $scope.sett.core_stats.concat($scope.sett.special_stats);
                }
            }
        ],
        templateUrl: "partials/art/rarity-stats.partial.html"
    };
});
