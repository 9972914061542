window.neonmobApp = angular.module('neonmobApp', [
    'ngAnimate',
    'ngCookies',
    'ngSanitize',
    'Woodsaw',
    'Once',
    'monospaced.elastic',
    'Art',
    'PackOpen',
    'Public',
    'infinite-scroll',
    'ui.router',
    'angular-clipboard'
]);

neonmobApp.config([
    '$anchorScrollProvider',
    '$httpProvider',
function(
    $anchorScrollProvider,
    $httpProvider
){

    if(window.resolveReferences){
        $httpProvider.defaults.transformResponse.push(window.resolveReferences);
    } else if(window.console) {
        window.console.error("resolveReferences is not defined and required.");
    }


    $httpProvider.interceptors.push("artCsrfInterceptor");

    $anchorScrollProvider.disableAutoScrolling();

}]);

neonmobApp.run([
    '$rootScope',
    'artAnalytics',
    'artShowRewards',
    'artSetCookie',
    "nmPromoCodes",
    "poOnboardingPackOpen",
    "$timeout",
    "$cookies",
    "artConfirm",
    "$window",
function(
    $rootScope,
    artAnalytics,
    artShowRewards,
    artSetCookie,
    nmPromoCodes,
    poOnboardingPackOpen,
    $timeout,
    $cookies,
    artConfirm,
    $window
){
    artAnalytics.autoTrack();

    // Someone can tell me where to put this better.
    $rootScope.purgeListeners = function (name) {

        if ($rootScope.$$listeners[name]) {
            delete $rootScope.$$listeners[name];
            return true;
        }
        else {
            return false;
        }

    };

    function _getModelMockData() {
        return {
            name: "Beginner",
            title: 1,
            level: 1,
            new_features: [
                {
                    title: "Beginner Series",
                    "icon-class": "i difficulty-0",
                    "bg-class": "difficulty-1",
                    description:
                        "Start with some of our most popular artists in easy-to-complete Beginner series. Look for this icon!",
                },
                {
                    title: "Discovery",
                    "icon-class": "colored-packs",
                    "bg-class": "series-finder",
                    description:
                        "Discover amazing art by indie artists from all over the world creating digital trading card series for you to collect and share.",
                },
                {
                    title: "Create Series",
                    "icon-class": "colored-brush",
                    "bg-class": "series-finder",
                    description:
                        "Want to share your own original creations with our community of art collectors? Visit our CREATE page to get started on your own digital trading card series!",
                },
            ],
            levelup_tip: {
                tip: "Open packs to collect digital art trading cards, earn milestones and rewards.",
            },
        };
    }

    function _getLevelData() {
        return {
            rewardType: "welcome-model",
            message: "Welcome to NeonMob",
            okText: "Get Started",
            data: _getModelMockData(),
            parentClass: "welcome-model",
            hasCloseBtn: true
        };
    }

    //Angular awesomeness - run time setup doesn't have a priority, so this run
    //method can execute before the run setup for the crsf token interceptor.
    $timeout(function(){
        angular.element("#page-loading-overlay").remove(); //remove the temp overlay loader.
        if ($cookies.promo_code) {
            nmPromoCodes.redeemCookiePromoCode();
        } else if ($cookies.referral_sett_id) {
            poOnboardingPackOpen.openPack();
        } else if ($cookies.show_rewards) {
            artShowRewards("onboarding");
            artSetCookie("show_rewards", "", -1);
        } else if ($cookies.welcome_model) {
            artSetCookie("welcome_model", "false", -1);
            artConfirm.showEarnedStatus(_getLevelData());
        }
    });
}]);
