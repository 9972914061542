artModule.service('artDisplayChooseService', [
    "ProfileUser",
    "artResource",
    "artUrl",
    "artUser",
    function(
        ProfileUser,
        artResource,
        artUrl,
        artUser) {

        var artDisplayChooseService = {};

        var userRandomPiecesUrl,
            userSetPiecesUrl,
            viewState = "loading",
            queryParams = {
                offset: 0,
                num_results: 10
            },
            _userPieces = [],
            _userSets = [],
            _displayCasePieces,
            nextPageUrl,
            piecesMaxCount,
            _selectedSett,
            _selectedRarity,
            _searchedString;

        function getUrl() {

            var url = userRandomPiecesUrl = '/api/users/' + artUser.getId() + '/pieces/';

            if(_selectedSett && _selectedSett.id != 'random') {

                userSetPiecesUrl 	= '/api/users/' + artUser.getId() +'/sets/' + _selectedSett.id;
                url = userSetPiecesUrl;
            }

            updateParams();

            return artUrl.updateParams(url, queryParams);
        };

        function updateParams() {

            // Setup rarity param
            if(_selectedRarity) {

                queryParams['rarity'] = _selectedRarity.value;

                if(_selectedRarity.id == 'any') {

                    delete queryParams['rarity'];
                }
            }

            // Setup search param
            if(_searchedString) {

                queryParams['search'] = _searchedString;
            }

            if(!_searchedString && queryParams['search']) {

                delete queryParams['search'];
            }
        };

        function setPiecesMetadata(metadata) {
            if(metadata) {
                nextPageUrl = metadata.resultset.link.next;
                prevPageUrl = metadata.resultset.link.prev;
                piecesMaxCount = metadata.resultset.count;
            }
        };

        function fetchPaginatedPieces() {

            if (!nextPageUrl) return;

            viewState = "loadingMore";

            artResource.retrieve(nextPageUrl).then(function(response) {

                var results = response.results;
                
                setPiecesMetadata(response.results.metadata);
                artDisplayChooseService.setUserPieces(results);

                viewState = "loaded";
            });
        };

        function inDisplayCase(pieces) {

            for(var i=0; i < pieces.length; i++) {

                for(var j=0; j < _displayCasePieces.length; j++) {

                    if (pieces[i].id == _displayCasePieces[j].id) {

                        pieces[i].inDisplayCase = true;
                        break;
                    }
                    else {
                        pieces[i].inDisplayCase = false;
                    }
                }
            }

        };

        artDisplayChooseService.resetDefaultData = function() {

            viewState = "loading",
            queryParams = {
                offset: 0,
                num_results: 10
            },
            _userPieces = [],
            _userRandomPieces = [],
            nextPageUrl = null,
            prevPageurl = null,
            piecesMaxCount = null
        };

        artDisplayChooseService.getDisplayCasePieces = function(pieces) {

            return _displayCasePieces;
        };

        artDisplayChooseService.getUserPieces = function() {
            return _userPieces;
        };

        artDisplayChooseService.getUserSets = function() {
            return _userSets;
        };

        artDisplayChooseService.getSelectedRarity = function() {

            return _selectedRarity;
        };

        artDisplayChooseService.getSearchString = function(searchString) {

            return _searchedString;
        };

        artDisplayChooseService.getSelectedSett = function() {

            return _selectedSett
        };

        artDisplayChooseService.setDisplayCasePieces = function(pieces) {

            _displayCasePieces = pieces;
        };

        artDisplayChooseService.setSelectedSett = function(sett) {

            _selectedSett = sett;
        };

        artDisplayChooseService.setUserPieces = function(items) {

            inDisplayCase(items);
            _userPieces = _userPieces.concat(items);
        };

        artDisplayChooseService.setUserSetts = function(setts) {
            var defaultSett = {
                id: 'random',
                name: 'Show all Series'
            };

            setts.unshift(defaultSett);
            _userSets = setts;
        };

        artDisplayChooseService.setSelectedRarity = function(rarity) {

            _selectedRarity = rarity;
        };

        artDisplayChooseService.setSearchString = function(searchString) {

            _searchedString = searchString;
        };

        artDisplayChooseService.fetchUserPieces = function() {

            artResource.retrieve(getUrl()).then(function(response) {

                var results = response.results;
                
                setPiecesMetadata(response.metadata);
                artDisplayChooseService.setUserPieces(results);

                viewState = "loaded";
            });
        };

        artDisplayChooseService.fetchUserSets = function() {

            ProfileUser.fetchCollectedSets().then(function(data) {
                artDisplayChooseService.setUserSetts(data);
            });
        };

        artDisplayChooseService.getNextPage = function() {
            // [TODO] Remove this condition after inspection
            if (piecesMaxCount > _userPieces.length) {

                fetchPaginatedPieces();
            }
        };

        artDisplayChooseService.isLoading = function() {

            return viewState;
        };

        artDisplayChooseService.setUpRarityFilter = function() {

            var raritiesTemplate = [{

                    id: "any",
                    name: "Any Rarity",
                    value: -1,
            }, {
                    id: "common",
                    name: "Common",
                    value: 0,
            }, {
                    id: "uncommon",
                    name: "Uncommon",
                    value: 1,
            }, {
                    id: "rare",
                    name: "Rare",
                    value: 2,
            }, {
                    id: "veryRare",
                    name: "Very Rare",
                    value: 3,
            }, {
                    id: "extraRare",
                    name: "Extra Rare",
                    value: 4,
            }, {
                    id: "chase",
                    name: "Chase",
                    value: 5,
            }, {
                    id: "variant",
                    name: "Variant",
                    value: 6,
            }, {
                id: "legendary",
                name: "Legendary",
                value: 7,
            }];

            return raritiesTemplate;
        };


        return artDisplayChooseService;
}]);
