artModule.directive('artDisplayChoose', [
    "artDisplayChooseService",
    "artSubscriptionService",
    "$timeout",
    function(
    artDisplayChooseService,
    artSubscriptionService,
    $timeout){

    var artDisplayChoose = {};

    var searchPiecesTimeout;

    artDisplayChoose.scope = {

        data: '=artDisplayChoose'
    };

    artDisplayChoose.link = function(scope, element) {

        scope.selectPiece = function(args) {

            artSubscriptionService.broadcast('display-case-add-piece', args);
        };

        scope.getNextPage = function() {

            artDisplayChooseService.getNextPage();
        };

        scope.fetchSetPieces = function(sett) {

            artDisplayChooseService.resetDefaultData();
            artDisplayChooseService.setSelectedSett(sett);
            artDisplayChooseService.fetchUserPieces();
        };

        scope.selectRarity = function(rarity) {

            artDisplayChooseService.resetDefaultData();
            artDisplayChooseService.setSelectedRarity(rarity);
            artDisplayChooseService.fetchUserPieces();
        };

        scope.searchPieces = function(searchString) {

            artDisplayChooseService.setSearchString(searchString);
            if (searchPiecesTimeout) {

                $timeout.cancel(searchPiecesTimeout);
            }
            searchPiecesTimeout = $timeout(function() {

                artDisplayChooseService.resetDefaultData();
                artDisplayChooseService.fetchUserPieces();
            }, 300);
        };
    };

    artDisplayChoose.controller = function($scope) {

        $scope.userPieces = artDisplayChooseService.getUserPieces.bind(artDisplayChooseService);
        $scope.userSets = artDisplayChooseService.getUserSets.bind(artDisplayChooseService);
        $scope.viewState = artDisplayChooseService.isLoading.bind(artDisplayChooseService);
        $scope.selectedSett = artDisplayChooseService.getSelectedSett();
        $scope.selectedRarity = artDisplayChooseService.getSelectedRarity.bind(artDisplayChooseService);
        $scope.rarityList = artDisplayChooseService.setUpRarityFilter();

        function init () {

            // Fetch user selected sett pieces
            if(!artDisplayChooseService.getUserPieces().length) {

                artDisplayChooseService.fetchUserPieces();
            };

            // Fetch user setts
            if(!artDisplayChooseService.getUserSets().length) {

                artDisplayChooseService.fetchUserSets();
            };

            if (artDisplayChooseService.getSelectedSett()) {

                $scope.selectedSett = artDisplayChooseService.getSelectedSett();
            }

            if (artDisplayChooseService.getSearchString()) {

                $scope.searchString = artDisplayChooseService.getSearchString();
            }
        };

        init();
    };
    artDisplayChoose.templateUrl = "partials/profile-new/display-case-choose.partial.html";

    return artDisplayChoose;
}]);
