artModule.directive('artPrint', [function () {
    var directiveDefinitionObject = {};

    directiveDefinitionObject.scope = {
        print: "=artPrint",
        collection: "=artPrintCollection",
        owner: "=artPrintOwner",
        count: "=artPrintCount",
        size: "=?artPrintSize",
        showDetails: "=artPrintOverlay",
        isPublic: "=?artPrintPublic"
    };

    directiveDefinitionObject.controller = [
        '$scope',
        'nmPieceDetailService',
        'artConstants',
        function ($scope,
                  nmPieceDetailService,
                  artConstants) {

            $scope.isPublic = $scope.isPublic || false;
            $scope.size = $scope.size || "small";
            $scope.settVersion = artConstants.VERSION_TYPES.limited;

            $scope.getUsername = function () {
                if ($scope.print.hasOwnProperty('owner')) {
                    return $scope.owner.username || $scope.print.owner.username;
                } else {
                    return null;
                }
            };

            $scope.getPiece = function () {
                if ($scope.print.hasOwnProperty('piece')) {
                    return $scope.print.piece;
                } else {
                    return $scope.print;
                }
            };

            $scope.checkIfPrintNumExists = function () {
                return ($scope.getPiece().print_num > 0);
            };

            $scope.getCountOfPieces = function () {
                return isNaN($scope.count) ? 1 : $scope.count;
            };

            $scope.showCountOfPieces = function () {
                return ($scope.getCountOfPieces() > 1);
            };

            $scope.getSet = function () {
                return $scope.getPiece().set;
            };

            $scope.hasNumberOfPrints = function () {
                return $scope.getPiece().num_prints_total > 0;
            };

            $scope.hasUnlimitedOfPrints = function () {
                return $scope.getPiece().num_prints_total == 'unlimited';
            };

            $scope.triggerOverlay = function () {
                if ($scope.showDetails === true) {
                    nmPieceDetailService.startPieceDetailOverlay($scope.owner, $scope.collection, $scope.print.id);
                }
            };
        }];
    directiveDefinitionObject.templateUrl = "partials/art/print.partial.html";
    return directiveDefinitionObject;
}]);