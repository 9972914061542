artModule.factory("poRoute", [
    "$http",
    "$q",
    "artConfig",
    "artOverlay",
    "artSett",
    "artUrl",
    "artUser",
    "poPackSelect",
    "poMilestones",
function(
    $http,
    $q,
    artConfig,
    artOverlay,
    artSett,
    artUrl,
    artUser,
    poPackSelect,
    poMilestones
) {

    var _OPEN_PARTIAL_STATE           = "pack-open-overlay",
        _PACK_SELECT_PARTIAL_STATE    = "pack-select-overlay",
        _SUGGESTED_SETTS_PARTIAL_STATE    = "suggested-setts-overlay",
        _PROMO_PACK_FINISH_STATE      = "promo-pack-finish-overlay",
        _ONBOARDING_SETT_FINISH_STATE = "onboarding-pack-finish-overlay";

    var _STATE = {

        'pack-open-overlay':'partials/pack-open/pack-open.partial.html',
        'pack-select-overlay':'partials/pack-open/pack-select-and-purchase.partial.html',
        'suggested-setts-overlay':'partials/pack-open/suggested-setts.partial.html',
        'promo-pack-finish-overlay':'partials/pack-open/promo-pack-finish.partial.html',
        'onboarding-pack-finish-overlay':'partials/pack-open/onboarding-pack-finish.partial.html'

    };

    var _currentState =   null;

    angular.forEach(_STATE, function(url, name){

        artOverlay.bindNameToTemplateUrl(name, url);

    });

    var poRoute = {

        //public api for invoking the pack open experience
        launchPackTiers:function(settOrUrl){

            poPackSelect.startPackSelect(settOrUrl);
            poMilestones.initPoMilestones();
            poRoute.setState(_PACK_SELECT_PARTIAL_STATE);
            artOverlay.show(poRoute.getState(), poPackSelect.fetchSett()).then(function(){
                poPackSelect.finish();
            });

        },

        launchSuggestedSetts: function(){
            poPackSelect.setSelectType('suggested');
            poPackSelect.setPackType(poPackSelect.FREEBIE_PACK);
            poPackSelect.fetchSett().then(function(sett){
                var suggestedSettsUrl = artUrl.updateParams(artConfig.api['api-suggested-sett-list'], {
                    'sett_id': sett.id
                });
                poPackSelect.startSettSelect(suggestedSettsUrl);
                poRoute.setState(_SUGGESTED_SETTS_PARTIAL_STATE);
                artOverlay.show(poRoute.getState(), poPackSelect.fetchSettList());
            });
        },

        //public api for invoking the pack open experience
        launchOpenPack:function(settOrUrl){
            poPackSelect.setPackType(poPackSelect.PURCHASED_PACK);
            poPackSelect.startPackSelect(settOrUrl).then(function(sett){
                poRoute.fetchAndOpenPack(artSett.getOpenPackUrl(sett));
            });
        },

        launchOpenPromoPack: function(settUrl, promoPackPayload){
            poPackSelect.setPackType(poPackSelect.PROMO_PACK);
            poPackSelect.startPackSelect(settUrl);
            var defer = $q.defer();

            poMilestones.initPoMilestones()
            .then(function() {

                poPackSelect.fetchSett().then(function(sett) {
                    $http.post(artConfig.api["api-promo-codes-redeem"], promoPackPayload).then(function(response){
                        poRoute.openPack(response.data.pack);
                    });
                    defer.resolve();
                });
            })

            return defer.promise;
        },

        launchOpenOnboardingPack: function(settOrUrl) {
            poPackSelect.setPackType(poPackSelect.ONBOARDING_PACK);
            poPackSelect.startPackSelect(settOrUrl).then(function(sett){
                poMilestones.initPoMilestones()
                .then(function() {
                    poRoute.fetchAndOpenPack(artUser.getUrl('signup_sett_pack_url'));
                })
            });
        },

        fetchAndOpenPack:function(url){

            poRoute.setState(_OPEN_PARTIAL_STATE);
            var promise = poPackSelect.startPackOpen(url);
            artOverlay.show(poRoute.getState(), poPackSelect.fetchPack()).then(function(){
                poPackSelect.finish();
            });

            return promise;

        },

        gotoPackOpenPage: function(id) {
            poRoute.launchPackTiers('/api/setts/' + id);
        },

        openPack:function(pack){

            poRoute.setState(_OPEN_PARTIAL_STATE);
            poPackSelect.startPackOpen(pack);
            artOverlay.show(poRoute.getState(), poPackSelect.fetchPack()).then(function(){
                poPackSelect.finish();
            });

        },

        finish: function() {

            if(poPackSelect.isPromoPack() && poRoute.getState() !== _PROMO_PACK_FINISH_STATE){
                poRoute.setState(_PROMO_PACK_FINISH_STATE);
                artOverlay.show(poRoute.getState()).then(function(){
                    poPackSelect.finish();
                });
            } else if (poPackSelect.isOnboardingPack() && poRoute.getState() !== _ONBOARDING_SETT_FINISH_STATE){
                poRoute.setState(_ONBOARDING_SETT_FINISH_STATE);
                artOverlay.show(poRoute.getState()).then(function(){
                    poPackSelect.finish();
                });
            } else {
                artOverlay.hide();//this will fire poPackSelect.finish via the overlay hide promise from above.
                _currentState = null;
            }

        },

        getView:function(){

            return _STATE[_currentState];

        },

        getState:function(){

            return _currentState;

        },

        setState:function(state){

            _currentState = state;

        }

    };

    return poRoute;

}]).directive("poRoute", ['poRoute', 'wsLumberjack', function(poRoute, wsLumberjack){

    var poRouteDirective = {};

    poRouteDirective.scope = {

        route:"@poRoute",
        sett:"=?poRouteSett"

    };

    poRouteDirective.link = function(scope, element){

        if(!/^launch/.test(scope.route) || !poRoute[scope.route]){

             wsLumberjack.error("Improper use of the poRoute directive. Only launch routes can be called, you passed in '"+scope.route+"'.");

        } else {

            element.click(function(){

                scope.$apply(function(){

                    poRoute[scope.route](scope.sett||null);

                });

            });

        }

    };

    return poRouteDirective;

}]);
