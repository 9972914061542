packOpenModule.directive('poFooterPurchase', function() {
	var poFooterPurchase = {};

	poFooterPurchase.controller = 'poFooterPurchaseCtrl';
	poFooterPurchase.templateUrl = 'partials/pack-open/pack-open-footer-purchase.partial.html';

	return poFooterPurchase;
})
.controller('poFooterPurchaseCtrl', [
    '$scope',
    'artUser',
    'artFreebiesPollService',
function(
    $scope,
    artUser,
    artFreebiesPollService
) {

    $scope.caratBalance         = artUser.getUserCarats.bind(artUser);
    $scope.getCredits           = artUser.getCredits.bind(artUser);
    $scope.getFreebiesRemaining = artUser.getFreebiesRemaining.bind(artUser);
    $scope.labelText            = artFreebiesPollService.getLabelText.bind(artFreebiesPollService);
    // Nitpick: Change this variable name to areFeaturesGated for consistency
    $scope.beginner             = artUser.areFeaturesGated();

    var freebiesRemaining = artFreebiesPollService.getFreebiesRemaining();

    var freebiesWatch = $scope.$watch(artUser.getFreebiesRemaining, function (newValue) {
        if (freebiesRemaining === newValue) {
            return;
        }

        freebiesRemaining = newValue;
        if (freebiesRemaining) {
                return artFreebiesPollService.freebiesState();
            }
            artFreebiesPollService.countdownState();
    });

    $scope.$on('$destroy', function () {
        artFreebiesPollService.stopCountdown();
        artFreebiesPollService.startFreebiePoll();
        if (freebiesWatch) freebiesWatch();
    });

    $scope.$on("streak-refresh-freebie", function() {
        return artFreebiesPollService.freebiePollState();
    });

    artFreebiesPollService.startupState();

}]);
