neonmobApp.directive('profileBio', function(){

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope: {
            showBio:            "=showBio",
            pageName:           "@pageName"
        },

        controller: [
            '$element',
            '$scope',
            '$timeout',
            '$window',
            'artConfig',
            'artBlockUserService',
            'artOverlay',
            'artUser',
            'ProfileUser',
            'artResourceService',
        function(
            $element,
            $scope,
            $timeout,
            $window,
            artConfig,
            artBlockUserService,
            artOverlay,
            artUser,
            ProfileUser,
            artResourceService
        ){

            $scope.isFriend         = JSON.parse(ProfileUser.isFriend.toLowerCase());
            $scope.profileLinks     = artConfig.profileLinks;
            $scope.isBlocked        = false;
            $scope.milestonesCount  = 0;
            $scope.totalCards       = 0;
            $scope.canExpandBio     = false;
            $scope.favoritesCount   = 0;
            $scope.isVerified       = artUser.isVerified();
            $scope.canTrade            = artUser.canIDo('trade');
            $scope.showFriends     = artUser.canIDo('friends');
            function _fetchUserStats() {
                var userStatsURL = artConfig.api['api-user-stats-all'];
                artResourceService.get(userStatsURL, false).success(function(data) {
                    var coreMilestone = parseInt(data[1].stats[1].value);
                    var seriesMilestone = parseInt((data[1].stats[2].value).replace(/,/g, ""));

                    $scope.milestonesCount = coreMilestone + seriesMilestone;
                    $scope.totalCards = data[0].stats[0].value;
                    $scope.favoritesCount = data[4].fav_pieces_count + data[4].fav_setts_count;
                  }).error(function() {
                      NM.error("Sorry, I could not load user stats. Please try again.");
                  });
            }

            // Nitpick: Change this variable name to areFeaturesGated for consistency
            $scope.beginnerStatus = artUser.areFeaturesGated();

            $scope.triggerProgressBar = function(gradientColor, iconColor, progressPercent) {
                var canvas = document.getElementById('canvas-circular-bar');
                var circleProgressBar = new $window.CircleProgressBar(canvas, {
                    colors: [iconColor, gradientColor],
                    lineWidth: 10,
                    trackLineColor: "#e1e1e1"
                });
                /*
                * Scale down progressPercent to range between 0 to 92 because
                * the levelling icon overlaps with the circular progress otherwise.
                * CircleProgressBar library takes value in decimal(0.1, 0.2 ...1.0) so we are dividing by 100.
                */
                progressPercent = ((progressPercent / 100) * 92) / 100;
                circleProgressBar.setValue(progressPercent);
            }

            ProfileUser.fetchUser().then(function(response) {
                $scope.user = response.data;
                _fetchUserStats();
                if (artUser.isAuthenticated()) {
                    artBlockUserService.isBlocked($scope.user).then(function (data) {
                        $scope.showUnblockButton = data.user_initiated && data.is_blocked;
                        $scope.isBlocked = data.is_blocked;
                        $element.find(".tip").tooltip("hide");
                    });
                }

                $scope.$on("updatedBlockedUsers", function(event, blockedUserObj){

                    var userId = blockedUserObj.userId,
                        isBlocked = blockedUserObj.isBlocked;

                    if ($scope.user === userId || artUser.getId() === userId){
                        $scope.userBlocked = isBlocked;
                    }
                });
                // Show or hide expand full bio based on scroll height
                // 2 lines scroll height is 36. If its go above we are showing `Expand full bio`.
                $timeout(function() {
                    var ele = document.getElementById('bio-box');
                    if(ele) {
                        $scope.canExpandBio = ele.scrollHeight > 36;
                    }
                }, 500);
            });

            $scope.unblockUser = function(){
                artBlockUserService.unblockUser($scope.user).then(function(){
                    $scope.showUnblockButton = false;
                    $scope.isBlocked = false;
                });
            };

            $scope.triggerSignUp = function() {
                $window.location.href = '/signup';
            };

            $scope.isYou = function(){
                return artUser.isYou($scope.user);
            };

            $scope.hasReleasedSett = function(){
                if (($scope.isYou() && $scope.user.is_creator) || $scope.user.has_released_sett){
                    return true
                }
                return false
            };

            $scope.isAuthenticated = function(){
                return artUser.isAuthenticated();
            };

            $scope.getTradingState = function() {
                if ($scope.isAuthenticated() && $scope.user) {
                    if(!artUser.tradingActive()) return 'authenticated-user-inactive';
                    if($scope.user.vacation_mode) return 'target-user-inactive';
                    if($scope.isBlocked) return 'user-blocked';
                }
                return 'normal';
            };

            $scope.getFriendsListButtonTitle = function () {
                return $scope.isFriend ? "Remove from Friends List" : "Add to Friends List";
            };

            $scope.$on("updatedFriends", function(event, friendObj) {
                // You can't call functions in 'attr', so same logic as above just using the friendObj
                $element.find("#friends-list-button").attr("data-original-title", friendObj.isFriend ?
                    "Remove from Friends List" : "Add to Friends List");
            });

            $scope.openBioModal = function(hideTextArea) {
                artOverlay.bindNameToTemplateUrl("open-bio-modal", "partials/profile-new/profile-bio-modal.partial.html", "modal");
                artOverlay.updateTemplateContext("open-bio-modal", {"user": $scope.user, "hideTextArea": hideTextArea, "isYou": $scope.isYou()});
                artOverlay.show("open-bio-modal", null, true, 'theme-light', true);
            }

            $scope.gotoAllCards = function() {
                $window.location.href = '/' + $scope.user.username + '/collection?cards=true';
            }

        }],

        templateUrl: "partials/profile-new/profile-bio.partial.html"

    };

    return directiveDefinitionObject;

});
