artModule.directive('artPieceStack', function() {

    var directiveDefinitionObject;

    directiveDefinitionObject = {

        scope: {

            stack:             "=artPieceStack",
            width:              "=?artWidth",
            height:              "=?artHeight",
            fluid:              "=?artFluid",
            maxStackSize:       "=?",
            imageSize:          "=?",
            piecesFlipped:      "=?",
            label:              "=?",
            stackReversed:      "=?",
            labelPosition:      "=?",
            topFlipped:         "=?",
            renderFlippedImage: "=?",
            isPublic: "=?artIsPublic"

        },

        controller: [
            "$scope",
        function(
            $scope
        ){

            //set a default image size!
            $scope.imageSize            = $scope.imageSize || "large";
            $scope.maxStackSize         = $scope.maxStackSize || 10;
            $scope.renderFlippedImage   = $scope.renderFlippedImage == undefined? true : $scope.renderFlippedImage;
            $scope.piecesFlipped        = $scope.piecesFlipped == undefined? true : $scope.piecesFlipped;

            //defaults to true, thus this comparison will make sure that it is only false if it is set to false.
            $scope.fluid = $scope.fluid !== false;

            $scope.isPieceFlipped = function( piece ){

                var isFirstPiece = $scope.stack.indexOf(piece) == 0;

                if( isFirstPiece && $scope.topFlipped == false ){

                    return false;

                } else {

                    return $scope.piecesFlipped;

                }

            };

            $scope.renderPieceImage = function( piece ){

                var render = false;
                var isFirstPiece = $scope.stack.indexOf(piece) == 0;

                // always render unflipped pieces
                if ( $scope.isPieceFlipped(piece) == false ){

                    render = true;

                // only render flipped if the flag is on
                } else if( $scope.renderFlippedImage == true || isFirstPiece){

                    render = true;

                }

                return render;

            }

            $scope.getClasses = function(){

                return 'total-pieces-' + $scope.stack.length + ($scope.stackReversed?' reverse':'');

            };

        }],

        templateUrl: "partials/art/piece/piece-stack.partial.html"

    };

    return directiveDefinitionObject;

});