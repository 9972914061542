artModule.directive('artElementClick', function(){

    var artElementClicked = {};

    artElementClicked.scope = {
        onClick:"&artElementClick"
    };

    artElementClicked.link = function(scope, element){

        element.click(function(ev){

            if(ev.target === element[0]){

                scope.$apply(function(){

                    scope.onClick({$event:ev});

                });

            }

        });

    };

    return artElementClicked;

});