artModule.filter('name', ['artUser', function (artUser) {
    return function (input, capitalize, always_show_name) {
        if (!input) {
            console.error("user not passed in!");
            return "someone";
        } else if (artUser.isYou(input) && !always_show_name) {
            return capitalize ? "You" : "you";
        } else {
            return input.name || input.username || "someone";
        }
    };
}])
    .filter('first_name', ['artUser', function (artUser) {
        return function (input, capitalize) {
            if (!input) {
                console.error("user not passed in!");
                return "someone";
            } else if (artUser.isYou(input)) {
                return capitalize ? "You" : "you";
            } else {
                return input.first_name || input.username || "someone";
            }
        };
    }])
    .filter('if_you', ['artUser', function (artUser) {
        return function (input, you, not_you) {
            if (!input) {
                console.error("user not passed in!");
                return not_you;
            } else if (artUser.isYou(input)) {
                return you;
            } else {
                return not_you;
            }
        };
    }])
    .filter('is_you', ['artUser', function (artUser) {
        return function (input) {
            if (!input) {
                console.error("user not passed in!");
                return false;
            } else {
                return artUser.isYou(input);
            }
        };
    }])
    .filter('is_not_you', ['artUser', function (artUser) {
        return function (input) {
            if (!input) {
                return false;
            }
            else {
                return !artUser.isYou(input);
            }
        };
    }])
    .filter('pass_action', ['artUser', function (artUser) {
        var _you = {
                surprised: "were surprised by"
            },
            _it = {
                surprised: "was surprised by"
            };

        function _pass_tense(verb, lookup) {
            if (verb in lookup) {
                return lookup[verb];
            } else if (/e$/.test(verb)) {
                return verb + "d";
            } else if (/y$/.test(verb)) {
                return verb.replace(/y$/, "ied");
            } else {
                return verb + "ed";
            }
        }

        return function (input, user) {
            if (artUser.isYou(user)) {
                return _pass_tense(input, _you);
            } else {
                return _pass_tense(input, _it);
            }
        };
    }])
    .filter('hasPermissions', ['artUser', function (artUser) {
        return function (permission) {
            if (permission == "verified") {
                return artUser.isVerified();
            } else if (permission === "auth") {
                return artUser.isAuthenticated();
            }
        };
    }])
    .filter('artProfileUrl', function () {
        return function (user) {
            return '/creator/' + user.username;
        };
    })
    .filter('artCollectionUrl', function () {
        return function (user) {
            return '/@' + user.username + '/collection/';
        };
    })
    .filter('userFirstNamePossessive', ['artUser', function (artUser) {
        return function (user) {
            if (artUser.isYou(user)) {
                return 'your';
            } else {
                return user.first_name + "'s";
            }
        };
    }])
    .filter('userPossessive', ['artUser', function (artUser) {
        return function (user) {
            if (artUser.isYou(user)) {
                return 'your';
            } else {
                return user.username + "'s";
            }
        };
    }])
    .filter('profileLink', function() {

        return function(user) {

            if (user.link) {

                return user.link;

            } else if( user.id ) {

                return "/user/" + user.id;

            } else {

                return "/";
            }

        };

    });