creatorModule.controller('ctSharePublicPreviewController', [
    '$scope',
    '$timeout',
    'artShare',
    'ctSettCreator',
function(
    $scope,
    $timeout,
    artShare,
    ctSettCreator
){
    $scope.getSett = ctSettCreator.getSett.bind(ctSettCreator);
    $scope.copyLinkButtonText = 'Copy Link';
    $scope.copyButtonDisabled = false;
    $scope.afterUrlCopy = function () {
        $scope.textToCopy = $scope.getSharePreviewUrl();
        $scope.copyLinkButtonText = 'Copied!';
        $scope.copyButtonDisabled = true;
        $timeout(function () {
            $scope.copyLinkButtonText = 'Copy Link';
            $scope.copyButtonDisabled = false;
        }, 2000);
    };
    
    $scope.getPromoteText = function(){
        return "Check out the series I'm working on for NeonMob!";
    };

    $scope.getSharePreviewUrl = function(){
        return artShare.createShareUrl($scope.getSett().public_preview_url, channel="direct", source="preview-series");
    };
}]);
