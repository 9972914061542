packOpenModule.factory('EnvironmentCalculations', function(){

    var factoryDefinitionObject = {};

    factoryDefinitionObject.getHeight = function(element) {

        element = element || null;

        if (element.context) {
            return element.context.parentElement.clientHeight;
        }
        else {
            return false;
        }

    };

    factoryDefinitionObject.getWidth = function(element) {

        element = element || null;

        if (element.context) {
            return element.context.parentElement.clientWidth;
        }
        else {
            return false;
        }

    };

    factoryDefinitionObject.insertMiddle = function(array, object) {

        for (var i = array.length - 1; i > -1; i--) {

            if (array[i].flipped === false) {
                array[i].flipped = true;
            }

            if (array[i].middle === true) {
                array[i].middle = false;
            }

        }

        var estMiddle = (array.length - 1) / 2;
        var middle = Math.floor(estMiddle);

        var first = array[0];

        array.splice(middle + 1, 0, object);

        return array;

    };

    return factoryDefinitionObject;

});

