artModule.controller("artNotificationCenterController", [
    '$scope',
    'artConstants',
    'artFriendsService',
    'artNotificationCenter',
    'artConfig',
    'poRoute',
    'artUser',
function(
    $scope,
    artConstants,
    artFriendsService,
    artNotificationCenter,
    artConfig,
    poRoute,
    artUser
){
    $scope.milestonePageLink = artConfig['profile-milestones'];
    $scope.state = artNotificationCenter.getState();
    // Nitpick: Change this variable name to areFeaturesGated for consistency
    $scope.beginner = artUser.areFeaturesGated();
    $scope.showFriends = artUser.canIDo('friends');
    $scope.canTrade = artUser.canIDo('trade');

    $scope.$watch(artNotificationCenter.getState, function(newValue, oldValue){
        if(newValue !== oldValue){
            $scope.state = newValue;
        }
    });

    $scope.gotoPackOpenPage = poRoute.gotoPackOpenPage.bind(poRoute);

    $scope.getUnreadTradeCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
        artNotificationCenter,
        artConstants.TRADES_KEY
    );

    $scope.getUnreadMessageCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
        artNotificationCenter,
        artConstants.MESSAGES_KEY
    );

    $scope.getUnreadMessageAndTradeCount = function(){
      return $scope.getUnreadTradeCount() + $scope.getUnreadMessageCount();
    };

    $scope.getUnreadNotificationCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
        artNotificationCenter,
        artConstants.NOTIFICATIONS_KEY
    );

    $scope.getOnlineFriendsCount = artFriendsService.getOnlineFriendsCount.bind(artFriendsService);

    $scope.getTradeExpirationTime = function (trade) {
        return artNotificationCenter.getTradeExpirationTime(trade)
    }

}]).directive('artShowNotificationCenter', [
    "artNotificationCenter",
function(
    artNotificationCenter
) {
    var artShowNotificationCenter = {};

    artShowNotificationCenter.scope = {
        "notificationType":"@artShowNotificationCenter",
        "options": "=?artNotificationOptions"
    };

    artShowNotificationCenter.link = function(scope, element){
        element.click(function(){
            scope.$apply(function(){
                artNotificationCenter.show(scope.notificationType, scope.options);
            });
        });
    };

    return artShowNotificationCenter;

}]).directive('artHideNotificationCenter', [
    "artNotificationCenter",
function(
    artNotificationCenter
) {
    var artHideNotificationCenter = {};

    artHideNotificationCenter.link = function(scope, element){
        element.click(function(){
            scope.$apply(function(){
                artNotificationCenter.hide();
            });
        });
    };

    return artHideNotificationCenter;

}]).directive("artNotificationCenterButton", [
    "artConstants",
    "artNotificationCenter",
function(
    artConstants,
    artNotificationCenter
){

    var artNotificationCenterButton = {};

    artNotificationCenterButton.link = function(scope){

        scope.getUnreadNotificationCount = artNotificationCenter.getUnreadNotificationCountByType.bind(
            artNotificationCenter,
            artConstants.NOTIFICATIONS_KEY
        );
    };

    artNotificationCenterButton.templateUrl = 'partials/art/notifications/notification-center-button.partial.html';

    return artNotificationCenterButton;

}]);
