artModule.controller("artCollectionPrintsController", [
    "$scope",
    "$filter",
    "artConstants",
    "artSett",
    "artUser",
    "CollectionService",
    "artPieceService",
    "artSubscriptionService",
    "artResponsive",
    "nmPieceDetailService",
    "ArrayTo2dColumnArray",
    "artConfig",
    "ProfileUser",
    function ($scope,
              $filter,
              artConstants,
              artSett,
              artUser,
              CollectionService,
              artPieceService,
              artSubscriptionService,
              artResponsive,
              nmPieceDetailService,
              ArrayTo2dColumnArray,
              artConfig,
              ProfileUser) {

        $scope.profileLinks = artConfig.profileLinks;

        var self = this;
        var amtPerPage = 16;

        // exposed for tests
        self.pieces = [];
        self.filteredPieces = [];
        self.copyFilteredPieces = [];
        self.paginatedFilteredPieces = [];
        self.piecesToShow = [];
        var page = 0;
        $scope.selectedSortName = 'Sort by...';
        $scope.expanded = false;

        $scope.targetUser = null;
        $scope.artWidth = 220;
        $scope.columns = [];

        $scope.viewState = "loading";

        $scope.isOwner = false;
        $scope.isAuthenticated = artUser.isAuthenticated();
        $scope.canTrade = artUser.canIDo('trade');
        $scope.settVersion = artConstants.VERSION_TYPES.limited;

        $scope.filters = {
            selected: null,
            ownership: "all",
            duplicate: null,
            rarity: []
        };
        $scope.filterOptions = [];
        $scope.ownershipFilters = {};
        $scope.settSlug = ProfileUser.settSlug;
        $scope.notCollectedYet = false;
        function init() {
            CollectionService.fetchCollectionUser().then(function(user){
                $scope.targetUser = user;
                $scope.isOwner = artUser.isYou(user);
            });

            CollectionService.fetchPrints($scope.settSlug).then(function (results) {
                if(!results.sett){
                    $scope.sett = results.sett;
                    $scope.notCollectedYet = true;
                    $scope.viewState = "loaded";
                    return;
                }
                self.pieces = results.prints;
                $scope.sett = results.sett;
                $scope.viewState = "loaded";
                $scope.selectedSetToDisplay = results.sett;

                $scope.filters = {
                  filters: null,
                  view: "prints",
                  ownership: null,
                  rarity: []
                };

                CollectionService.setupFilters(self);
                $scope.filterOptions = self.filterOptions;
                $scope.ownershipFilters = self.ownershipFilters;
                $scope.selectedOwnershipFilter = self.ownershipFilters.unowned;
                $scope.duplicateFilter = self.duplicateFilter;
                $scope.favoriteFilter = self.favoriteFilter;

                $scope.sortOptions = CollectionService.createCollectionSeriesSort();

                $scope.applyFilters({skipAnalytics: true});
            });
        }

        $scope.getNextPage = function () {
            page++;
            applyPagination();
            setupArtWidthColumns();
        };

        function setupSortOption() {
            var sortExpression,
                sortOrder,
                originalArray;

            $scope.viewState = "loaded";
            originalArray  = self.filteredPieces;
            self.copyFilteredPieces = originalArray;

            if (!$scope.selectedSortObj) return;

            // Setup sort expression
            if($scope.selectedSortObj.id == "rarity") {

                sortExpression = $scope.selectedSortObj.id + '.value';
            } else {

                sortExpression  = $scope.selectedSortObj.id;
            }

            sortOrder = $scope.selectedSortObj.desc;

            applySortOption(self.copyFilteredPieces, sortExpression, sortOrder);
        };

        function applySortOption(array, sortExpression, sortOrder) {

            self.copyFilteredPieces = $filter('orderBy')(array, sortExpression, sortOrder);
        };

        function applyPagination() {
            if (($scope.viewState == "allPagesLoaded")) return;

            var amtFilteredPieces = self.copyFilteredPieces.length;
            var limit = page * amtPerPage;

            self.paginatedFilteredPieces = self.copyFilteredPieces.slice(0, limit);

            if (amtFilteredPieces == self.paginatedFilteredPieces.length)
                $scope.viewState = "allPagesLoaded";
        }

        // This is done for multiple rarity setup
        function setupRarityArray() {

            var rarity = $scope.filterOptions,
                rarityArr = [],
                item;

            if(rarity) {
                for(var i=0; i<rarity.length; i++) {
                    if (rarity[i].selected) {

                        rarityArr.push(rarity[i]);
                    }
                }
            };

            $scope.filters.rarity = rarityArr;
        };

        var currentOwnershipState = function() {
            var currentOwnership;

            if ($scope.selectedOwnershipFilter.id == 'all' && $scope.filters.ownership) {
                currentOwnership = 'unowned';
            }

            else if ($scope.selectedOwnershipFilter.id == 'unowned' && $scope.filters.ownership) {

                currentOwnership = 'owned';
            }

            else if($scope.selectedOwnershipFilter.id == 'owned' && $scope.filters.ownership) {

                currentOwnership = 'default';
            }

            else {

                currentOwnership = 'unowned';
            }

            return currentOwnership;
        };
        var resetOwnershipFilter = function() {

            $scope.filters.ownership = null;
            $scope.selectedOwnershipFilter = $scope.ownershipFilters.unowned;
            $scope.applyFilters();
        };

        var setOwnershipFilterState = function() {
            var ownership;
            ownership = currentOwnershipState();

            if (ownership == 'default') {

                resetOwnershipFilter();
                return;
            };

            $scope.selectedOwnershipFilter = $scope.ownershipFilters[ownership];
            $scope.filters.ownership = $scope.selectedOwnershipFilter.id;
        };

        $scope.toggleOwnershipFilter = function() {

            setOwnershipFilterState();
            $scope.applyFilters();
        };

        $scope.toggleFavoriteFilter = function() {

            $scope.favoriteFilter.selected = !$scope.favoriteFilter.selected;

            $scope.applyFilters();
        };

        $scope.selectSortOption = function(selectedSort) {

            if ( $scope.selectedSortObj && (selectedSort.id !== $scope.selectedSortObj.id) && ($scope.selectedSortObj.clickCount >=1) ) {

                $scope.selectedSortObj.clickCount = 0;
                $scope.selectedSortObj.desc = !$scope.selectedSortObj.desc;
            }

            $scope.selectedSortObj  = selectedSort;
            $scope.selectedSortName = selectedSort.name;

            setupSortOption();
            applyPagination();
            setupArtWidthColumns();
        };

        var resetSelectedSort = function() {

            $scope.selectedSortObj.desc = !$scope.selectedSortObj.desc;
            $scope.selectedSortObj.clickCount = 0;
            $scope.selectedSortObj = null;
            $scope.selectedSortName = 'Sort by...';

            self.copyFilteredPieces = self.filteredPieces;
        };

        var isNextSort = function() {

            return $scope.selectedSortObj.clickCount<=1;
        };

        $scope.toggleSort = function(event) {

            if(event) event.stopPropagation();

            if (!$scope.selectedSortObj) return;

            $scope.selectedSortObj.clickCount++;

            if (!isNextSort()) {

                resetSelectedSort();

                setupSortOption();
                applyPagination();
                setupArtWidthColumns();

                return;
            }

            $scope.selectedSortObj.desc = !$scope.selectedSortObj.desc;

            setupSortOption();
            applyPagination();
            setupArtWidthColumns();
        };

        $scope.handleClick = function($event) {

            $scope.expanded = !$scope.expanded;
        };

        $scope.applyFilters = function (options) {

            options = options || {};
            var skipAnalytics = !!options.skipAnalytics;

            // Multi selected rarity setup
            setupRarityArray();
            // filtering resets pagination
            page = 1;
            $scope.viewState = "loaded";

            // parse filters
            var ownership = $scope.filters.ownership;
            var duplicate = $scope.filters.duplicate && $scope.filters.duplicate.id == "duplicate";
            var favorite  = $scope.favoriteFilter && $scope.favoriteFilter.selected;

            var rarity = null;
            if ($scope.filters.rarity.length) {
                rarity = $scope.filters.rarity;
            };

            self.filteredPieces = [];

            var sett = $scope.selectedSetToDisplay;

            // apply filters
            var piece;

            for (var i = 0, j = -1, c = self.pieces.length; i < c; i++) {
                piece = self.pieces[i];

                if (ownership == 'owned' && !piece.own_count) continue;
                if (ownership == 'unowned' && piece.own_count) continue;
                if (duplicate && !piece.$duplicate) continue;
                if ((favorite  && !piece.favorite)) continue;
                // if (favorite  && ownership == 'unowned') continue;

                if (rarity) {
                    var matched = false;
                    for (var q=0; q < rarity.length; q++) {
                        if(rarity[q].id == piece.$rarity) {
                            matched = true;
                            break;
                        }
                    };

                    if (!matched) continue;
                };

                self.filteredPieces[++j] = piece;
            };
            setupSortOption();
            applyPagination();

            setupArtWidthColumns();
        };

        $scope.toggleFunc = function(currentFilterObj) {

            if($scope.filters.duplicate && $scope.filters.duplicate.id === currentFilterObj.id) {

                $scope.filters.duplicate = null;
                $scope.applyFilters();
            }
        };

        function setupArtWidthColumns() {
            var layout = getViewLayout(),
                piece;

            for (var i = 0, c = self.paginatedFilteredPieces.length; i < c; ++i) {
                piece = self.paginatedFilteredPieces[i];

                if (piece.asset_type === "image")
                    piece.$height = Math.ceil((layout.columnWidth / piece.piece_assets.image.medium.width) *
                        piece.piece_assets.image.medium.height);
                else
                    piece.$height = Math.ceil((layout.columnWidth / piece.piece_assets.video.medium.width) *
                        piece.piece_assets.video.medium.height);
            }

            $scope.artWidth = layout.columnWidth;

            $scope.columns = ArrayTo2dColumnArray(self.paginatedFilteredPieces, layout.columns);
        }

        function getViewLayout() {
            var width = artResponsive.getWindowWidth(),
                padding = 10,
                columns = 4;

            width = width > 960 ? 960 : width;
            columns = width <= 480 ? 2 : columns;
            padding = columns < 4 ? 5 : padding;

            return {
                columnWidth: Math.ceil(width / columns - padding * 2),
                columns: columns
            };
        }

        $scope.toggleFavorite = function (piece, event) {

            if (event) event.stopPropagation();
            artPieceService.toggleFavorite(piece);
        };

        $scope.toggleFavoriteSett = artSett.toggleFavoriteSett.bind(artSett);

        $scope.showPieceDetail = function (piece) {
            if(!piece.own_count) return;
            filterUnownedForDetail();
            nmPieceDetailService.startPieceDetailOverlay(
                $scope.targetUser,
                self.piecesToShow,
                piece.id
            );
        };

        $scope.getPrintCount = function(pieceId){

            return artPieceService.getPrintCount(artUser, pieceId);
        };

        function filterUnownedForDetail () {
            self.piecesToShow = [];
            for (var p=0; p<self.filteredPieces.length; p++) {
                if (self.filteredPieces[p].own_count) {
                    self.piecesToShow.push(self.filteredPieces[p]);
                }
            }
        }

        var resizeListener = artResponsive.onResize(setupArtWidthColumns);
        var updateCollectionListener = $scope.$on("updateCollection", init);
        artSubscriptionService.subscribe("pack-consumed", init);
        artSubscriptionService.subscribe("pack-open-finished", init);
        artSubscriptionService.subscribe("trade-accepted", init);

        // TODO - also listen for trades and badge earned

        $scope.$on("$destroy", function () {
            resizeListener();
            updateCollectionListener();
            artSubscriptionService.unsubscribe("pack-consumed", init);
            artSubscriptionService.unsubscribe("pack-open-finished", init);
            artSubscriptionService.unsubscribe("trade-accepted", init);
        });

        init();
    }
]).controller('artCollectionMilestoneController', [
  "$scope",
  "$stateParams",
  "CollectionService",
  function ($scope,
            $stateParams,
            CollectionService) {

    $scope.stateParams = $stateParams;

    $scope.viewState = "loading";

    $scope.badges = [];

    function init() {
        CollectionService.fetchMilestones($stateParams.settSlug).then(function (results) {
            if(!results.sett){
              return;
            }
            $scope.badges = results.badges;
            $scope.viewState = "loaded";
            $scope.selectedSetToDisplay = results.sett;
        });
    };

    init();

}]).controller('artCollectionNoPrintsController', [
  "$scope",
  "$window",
  "$stateParams",
  "artMessage",
  function ($scope,
            $window,
            $stateParams,
            artMessage) {

      $scope.stateParams = $stateParams;

      $scope.viewState = "loading";

      $scope.badges = [];

    function init() {
        artMessage.showAlert({
                    message: "Your collection is empty…",
                    subtext: "Open a few packs to start collecting!"
                }, function(canceled){
                $window.location.href = '/collect/';
        });
    }

      init();

}]);
