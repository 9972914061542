artModule.service('artShowStreaks', [
    '$http',
    'artOverlay',
function(
    $http,
    artOverlay
){
    var artShowStreaks = {};

    var streakDetail = {};

    artShowStreaks.showStreaks = function() {

        if (angular.equals({}, streakDetail) || artShowStreaks.streaksClaimedStatus()){

            return;
        }

        artOverlay.bindNameToTemplateUrl("streaks", "partials/streaks/overlay-streaks.partial.html", "modal");

        artOverlay.show("streaks",null,true);

        streakDetail.claimed = true;
    };

    artShowStreaks.fetchStreaksDetail = function() {

        $http({
            method: 'GET',
            url: '/data/streaks.json/'
        })
        .success(function(response) {

            streakDetail = response;
        })
        .error(function(response) {

            streakDetail = response;
        })
    };

    artShowStreaks.getStreaksDetail = function() {

        return streakDetail;
    };

    artShowStreaks.streaksClaimedStatus = function() {

        return streakDetail.claimed;
    };

    return artShowStreaks;

}]);
