artModule.factory("artGrowlerNotification", [
    '$timeout',
    'artNotificationCenter',
    'artResponsive',
    'artSubscriptionService',
    'artTitleNotify',
function(
    $timeout,
    artNotificationCenter,
    artResponsive,
    artSubscriptionService,
    artTitleNotify
){

    var artGrowlerNotification = {},
        _notifications = [],
        _timeoutPromise;

    artGrowlerNotification.DISPLAY_TIME = 8000;

    //This does not trigger a digest loop, thus you will need to manually handle that.
    artSubscriptionService.subscribe('show-growl-notifications', function(notifications){
        artGrowlerNotification.addNotifications(notifications);
    });

    //This does not trigger a digest loop, thus you will need to manually handle that.
    artSubscriptionService.subscribe('upsert-growl-notification', function(notification){
        artGrowlerNotification.updateOrAddNotification(notification);
    });

    //This does not trigger a digest loop, thus you will need to manually handle that.
    artSubscriptionService.subscribe('dismiss-growl-notification', function(notification){
        artGrowlerNotification.dismiss(notification, notification.type);
    });

    artGrowlerNotification.addNotifications = function(notifications){
        if (artResponsive.WINDOW_VISIBLE) {
            _notifications.push.apply(_notifications, notifications);
            _autoDismiss();
        }
    };

    artGrowlerNotification.updateOrAddNotification = function(notification){
        if (artResponsive.WINDOW_VISIBLE) {
            _autoDismiss();
            for(var i = 0; i< _notifications.length; i++){
                var n = _notifications[i];
                if(n.id === notification.id){
                    _notifications[i] = notification;
                    return;
                }
            }
            //default, we just add the notification!
            _notifications.push(notification);
        }
    };

    artGrowlerNotification.dismissAll = function(){
        _notifications = [];
    };

    function _autoDismiss(){

        if(_timeoutPromise){

            $timeout.cancel(_timeoutPromise);

        }

        _timeoutPromise = $timeout(function(){

            _timeoutPromise = null;
            artGrowlerNotification.dismissAll();

        }, artGrowlerNotification.DISPLAY_TIME);

    }

    artGrowlerNotification.dismiss = function(notification){
        artTitleNotify.stop();

        var index = _notifications.indexOf(notification);

        if(index > -1){

            _notifications.splice(index, 1);
            artNotificationCenter.markNotificationsAsRead([notification.id], notification.type);

        }

    };

    artGrowlerNotification.hasNotifications = function(){

        return _notifications.length > 0;

    };

    artGrowlerNotification.getNotifications = function(){

        return _notifications;

    };

    artGrowlerNotification.setAutoDismiss = function(autoDismiss){

        if(autoDismiss){

            _autoDismiss();

        } else if(_timeoutPromise){

            $timeout.cancel(_timeoutPromise);
            _timeoutPromise = null;

        }

    };

    return artGrowlerNotification;

}]).directive("artGrowlerNotificationPopup", function(){

    var artGrowlerNotificationPopup = {};

    artGrowlerNotificationPopup.controller = [
        '$scope',
        'artAnalytics',
        'artConstants',
        'artGrowlerNotification',
        'artNotificationCenter',
        'popoverMenuService',
        'artUser',
    function(
        $scope,
        artAnalytics,
        artConstants,
        artGrowlerNotification,
        artNotificationCenter,
        popoverMenuService,
        artUser
    ){

        $scope.getNotifications = function(){

            return artGrowlerNotification.getNotifications();

        };
        $scope.isProUser = artUser.getProUserStatus();
        $scope.dismissNotification = function(ev, notification){

            ev.preventDefault();

            if(notification.type !== 'friend-online') {
                artGrowlerNotification.dismiss(notification, notification.type);
            }

            if(notification.type === 'friend-online'){
                artAnalytics.track("Dismissed Friend Notification", {
                    userId: notification.friend.id
                });
            }

        };

        $scope.setAutoDismiss = function(autoDismiss){

            if( !popoverMenuService.isOpen() ){
                artGrowlerNotification.setAutoDismiss(autoDismiss);
            }

        };

        $scope.performAction = function(notification){

            if(notification.type === 'friend-online'){

                artNotificationCenter.show("conversation", {
                    recipient: notification.friend.id,
                    conversationNavState: 'closable'
                });

                artGrowlerNotification.dismissAll();

                artAnalytics.track("Clicked Friend", {
                    source: "growler-notification",
                    userId: notification.friend.id
                });
            } else if( notification.type == artConstants.MESSAGES_KEY ||
                notification.type == artConstants.TRADES_KEY) {

              artNotificationCenter.show("conversation", {
                  recipient: notification.actor.id,
                  conversationNavState: 'closable'
              });

              artGrowlerNotification.dismiss(notification, notification.type);
            }

        };

    }];

    artGrowlerNotificationPopup.templateUrl = "partials/art/notifications/growler-notification.partial.html";

    return artGrowlerNotificationPopup;

});
