packOpenModule.service('animationService', [

    '$q',
    '$window',
    'artResource',
    'artConfig',

    function (
        $q,
        $window,
        artResource,
        artConfig

    ) {
        var animServiceObject = {};

        var bodyMovin = $window.bodymovin;
        var animationData = [];

        function _showAnimation(animationObj) {
            var animationEle = animationObj.className;
            if(animationEle) {
                return bodyMovin.loadAnimation({
                    name: animationEle,
                    container: document.getElementById(animationEle),
                    renderer: 'svg',
                    loop: 0,
                    autoplay: true,
                    animationData: animationData
                });
            }
        }

        function _retriveJsonData(animationObj) {
            return artResource.retrieve(
                artConfig['po-animation-assets'] + '/' + animationObj.fileName, {
                    withCredentials: false
                }
            )
        }

        animServiceObject.triggerAnimate = function (animationObj) {
            return _retriveJsonData(animationObj)
                .then(function (data) {
                    animationData = data;
                    var animationRes = _showAnimation(animationObj);
                    var defer = $q.defer();
                    animationRes.addEventListener('complete', function (res) {
                        bodyMovin.destroy(animationObj.className);
                        defer.resolve(res);
                    });
                    animationRes.addEventListener('destroy', function () {
                        animationObj.callback && animationObj.callback();
                    });
                    return defer.promise;
                });
        }

        return animServiceObject;
    }
]);
