neonmobApp.directive('nmPieceDetail', function() {

    var directiveDefinitionObject = {};

    directiveDefinitionObject.controller = [
        "$scope",
        "$timeout",
        "artMessage",
        "artPieceService",
        "artResponsive",
        "artUser",
        "nmPieceDetailService",
        "nmDiscard",
    function(
        $scope,
        $timeout,
        artMessage,
        artPieceService,
        artResponsive,
        artUser,
        nmPieceDetailService,
        nmDiscard
    ){

        var _IMAGE_PADDING = 150;
        var _DESCRIPTION_WIDTH = 320 + 50; //desription div + buttons
        var _MAX_IMAGE_SIZE_BREAKPOINT = 1100;
        var _MIN_IMAGE_SIZE_BREAKPOINT = 300;

        $scope.canTrade = artUser.canIDo('trade');
        $scope.canFavorite = artUser.canIDo('favorite');

        var _clearData = function() {

            $scope.pieceDetail  = null;
        };

        var _resolveNewPiece = function(promise){

            promise.then(function(resolvedPiece){

                $scope.pieceDetail        = resolvedPiece;
                $scope.printStack         = _createPrintStack(resolvedPiece);
                $scope.targetUser         = nmPieceDetailService.getPieceOwner();
                $scope.currentPrintNumber = $scope.pieceDetail.print_num || null;
                nmPieceDetailService.changeWindowLocation($scope.pieceDetail);
            });

            return promise;

        };

        var _createPrintStack = function( piece ){

            //only the top needs to be shown, the rest are just phantom prints
            var stack   = [$scope.pieceDetail];
            for( var i = 1; i < $scope.getRequestUserPrintCount(); ++i){
                stack[i] = i;
            }

            return stack;
        };

        $scope.getMaxHeight = function(){

            var maxHeight = artResponsive.getWindowHeight() - _IMAGE_PADDING;

            if (maxHeight > _MAX_IMAGE_SIZE_BREAKPOINT) {

                return _MAX_IMAGE_SIZE_BREAKPOINT;

            } else if (maxHeight < _MIN_IMAGE_SIZE_BREAKPOINT) {

                return _MIN_IMAGE_SIZE_BREAKPOINT;

            } else {

                return maxHeight;

            }

        };

        $scope.getMaxWidth = function(){

            var maxWidth = artResponsive.getWindowWidth() - (_IMAGE_PADDING + _DESCRIPTION_WIDTH);

            if (maxWidth > _MAX_IMAGE_SIZE_BREAKPOINT) {

                return _MAX_IMAGE_SIZE_BREAKPOINT;

            } else if (maxWidth < _MIN_IMAGE_SIZE_BREAKPOINT) {

                return _MIN_IMAGE_SIZE_BREAKPOINT - 10;

            } else {

                return maxWidth;

            }

        };

        var _unregisterResize = artResponsive.onResize(function(){

            //create an async apply!
            $timeout(function(){
                //VOID
            });

        });

        var addItem = function(discardItem) {

            nmDiscard.resetTradeData();
            discardItem.print_count = 1;
            discardItem.owned_print_count = $scope.getRequestUserPrintCount();

            discardItem.totalCarats = discardItem.rarity.carats * discardItem.print_count;
            nmDiscard.addItem('bidder_offer', 'prints', discardItem);
            nmDiscard.singleCardDiscard($scope.printStack, $scope.isViewingAnothersPiece());
        };

        $scope.$on("$destroy", _unregisterResize);

        $scope.printsTooltip = false;

        $scope.getPrintStickerSlug = function(){

            var username = $scope.getTargetUserUsername(),
                prints = "";

            if ($scope.getTargetUserUsername() === artUser.getUsername()) {

                username = "You own:<br>";

            } else {

                username = username + " owns:<br>";

            }

            for (var i = 0; i < $scope.pieceDetail.prints.length; i++) {

                prints = prints + "#" + $scope.pieceDetail.prints[i].print_num + "<br>";

            }

            return username + prints;

        };

        $scope.isVerified = artUser.isVerified();

        $scope.isPublic = function(){

            return nmPieceDetailService.isPublic();

        };

        $scope.isAuthenticated = function(){

            return artUser.isAuthenticated();

        };

        $scope.targetUserHasPrints = function(count) {

            count = count || 0;
            return $scope.pieceDetail.prints && $scope.pieceDetail.prints.length > count;

        };

        $scope.isViewingAnothersPiece = function(){

            if ($scope.targetUser) {

                return ($scope.targetUser.username !== artUser.getUsername());

            } else {

                return false;
            }

        };

        $scope.getPrintCount = function(){

            return $scope.pieceDetail.prints.length;

        };

        $scope.hasMultiplePieces = function(){

            return nmPieceDetailService.hasCollection();

        };

        $scope.getLimitedText = function() {

            var printsTotal = Number($scope.pieceDetail.num_prints_total);

            if (Math.floor(printsTotal) === printsTotal) {

                return "/" + printsTotal + "";

            }

            return "";

        };

        $scope.getCollectionPermalink = function() {

            return $scope.pieceDetail.set.links.permalink;

        };

        $scope.getCollectionCreatorName = function() {

            return $scope.pieceDetail.set.creator.name;

        };

        $scope.getCollectionCreatorPermalink = function(){

            return $scope.pieceDetail.set.creator.link;

        };

        $scope.getPieceName = function(){

            return $scope.pieceDetail.name;

        };

        $scope.getRarityName = function(){

            return $scope.pieceDetail.rarity.name;

        };

        $scope.getCollectionName = function() {

            return $scope.pieceDetail.set.name;

        };

        $scope.requestUserHasPrints = function(){

            return $scope.getRequestUserPrintCount() > 0;

        };

        $scope.getRequestUserPrintCount = function(){

            return artPieceService.getPrintCount(artUser, $scope.pieceDetail.id);

        };

        $scope.getTargetUserPrintCount = function(){

            return $scope.pieceDetail.prints.length;

        };

        $scope.getTargetUserUsername = function() {

            return $scope.targetUser.username;

        };

        $scope.isFavorited = function() {

            return $scope.pieceDetail.favorite;

        };

        $scope.toggleFavorite = function(){

            artPieceService.toggleFavorite($scope.pieceDetail);

        };

        var _showCurrentPiece = function(){

            _clearData();

            var promise = nmPieceDetailService.fetchCurrentPiece();

            _resolveNewPiece(promise);

        };

        $scope.showNextPiece = function(){

            _clearData();

            var promise = nmPieceDetailService.fetchNextPiece();

            _resolveNewPiece(promise);
        };

        $scope.showPreviousPiece = function(){

            _clearData();

            var promise = nmPieceDetailService.fetchPreviousPiece();

            _resolveNewPiece(promise);
        };

        $scope.discardPiece = function(discardItem, event) {

            if( event ) {
                event.stopPropagation();
            }

            if (!artUser.isVerified()) {
                return artMessage.showAlert("You have to verify your email before you can do that :(");
            }
            var print_count = $scope.getRequestUserPrintCount();

            if(discardItem.prints_part_of_trade.length && (print_count == discardItem.prints_part_of_trade.length)) {

                if (discardItem.prints_part_of_trade.length) {

                    if (discardItem.prints_part_of_trade[0].bidder_trades > 0) {

                        return artMessage.showAlert("This card can’t be discarded at this time, as it’s part of an active trade you proposed. Please select a different card, or remove this card from your proposed trades.");
                    }

                    if (discardItem.prints_part_of_trade[0].responder_trades > 0) {

                        artMessage.showAlertWithCancel({
                            message: "Someone has offered to trade you for this card. Are you sure you want to discard it?",
                            subtext: "Discarding it will cancel the offered trade."
                            }, function(canceled){
                            if (canceled) return;

                                if (print_count === 1) {

                                    discardItem.last_print = true;
                                }
                                discardItem.responder_discard = true;
                                addItem(discardItem);
                        });

                        return;
                    }
                }
            };

            if (print_count == 1) {

                artMessage.showAlertWithCancel({
                    message: "Watch out!",
                    subtext: "You will discard your last copy of this card."
                }, function(canceled){
                    if (canceled) return;

                    discardItem.last_print = true;

                    if (discardItem.favorite) {

                        discardItem.favorite = false;
                        discardItem.toggleStatus = true;
                    }

                    addItem(discardItem);
                });

                return;
            }

            // pieceTraderClickTracker.resetData();
            var caratStr = (discardItem.rarity.carats === 1)  ? "carat":"carats";
            artMessage.showAlertWithCancel({
                message: "Are you sure you want to discard this card for " + discardItem.rarity.carats + " " + caratStr + " ?",
                subtext: "Discarding can't be reversed and this card will be permanently removed from your collection!"
            }, function(canceled){
                if (canceled) return;

                addItem(discardItem);
            });
        };


        $scope.pieceDetail = null;

        $scope.targetUser = null;

        $scope.printStack = null;

        _showCurrentPiece();

    }];

    directiveDefinitionObject.templateUrl = "partials/application/piece-detail/piece-detail.partial.html";

    return directiveDefinitionObject;

});
