artModule.directive('artTruncateWords', [
    "$filter",
function (
    $filter
) {
    var directiveDefinitionObject = {
        scope: {
            text: '=artTruncateWords',
            showAll: '=?',
            maxWords: '=?',
            wordTolerance: '=?',
            truncateFilters: '=?'
        },

        controller: ["$scope", function ($scope) {
            $scope.truncateFilters = $scope.truncateFilters || [];
            $scope.showAll = $scope.showAll || false; //default is truncated

            $scope.showLess = function () {
                $scope.showAll = false;
            };

            $scope.showMore = function () {
                $scope.showAll = true;
            };
            
            $scope.getDisplayText = function(){
                var displayText = $scope.text;
                for (var i=0; i < $scope.truncateFilters.length; i++) {
                    filter = $scope.truncateFilters[i];
                    displayText = $filter(filter)(displayText);
                }
                return displayText;
                
            };
        }],

        template: '<div ng-switch="getDisplayText()|willTruncateWords:maxWords:wordTolerance"> \
            <div ng-switch-when="true"> \
                <div ng-if="!showAll"> \
                    <div ng-bind-html="getDisplayText()|truncateWords:maxWords:wordTolerance|nowidows"></div> \
                    <button class="btn text-height subdued" ng-click="showMore()">●●●</button> \
                </div> \
                <div ng-if="showAll"> \
                    <div ng-bind-html="getDisplayText()|nowidows"></div> \
                    <button class="btn displayText-height subdued" ng-click="showLess()">Less</button> \
                </div> \
            </div> \
            <div ng-switch-when="false" ng-bind-html="getDisplayText()|nowidows"></div> \
        </div>'
    };
    return directiveDefinitionObject;
}]);
