artModule.controller('artCreditRewardController',[
	'$scope',
	'artCreditRewardService',
	function(
	$scope,
	artCreditRewardService
	){

		$scope.memo = "NeonGod be raining credits up in here!";

		$scope.getCreditData = artCreditRewardService.getCreditRewardData.bind(artCreditRewardService);
		$scope.getClaimedAmount = artCreditRewardService.getClaimedCredit.bind(artCreditRewardService);
		$scope.closeRewardModal = artCreditRewardService.closeRewardModal.bind(artCreditRewardService);
		$scope.claimCredit = artCreditRewardService.claimCredit.bind(artCreditRewardService);
}]);
