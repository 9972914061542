artModule.factory('artPrintUtils', function() {

    var _rarities = {

        chase:7,
        variant:6,
        extraRare:5,
        veryRare:4,
        rare:3,
        uncommon:2,
        common:1,
        none:0

    };

    var _RAD_QUALITIES = {

        rarities:{
            extraRare:true,
            veryRare:true,
            variant:true,
            chase:true
        },
        highestPrintNumber: 10
    };

    var PrintUtils = {

        isRad: function( print ){

            return this.isRare( print ) || this.isLowPrintNumber( print );

        },

        isLowPrintNumber: function( print ){

            if( !print  ){
                return false;
            }
            return print.print_num <= _RAD_QUALITIES.highestPrintNumber;

        },

        isRare: function( print ){

            if( !print  ){
                return false;
            }

            return print.rarity['class'] in _RAD_QUALITIES.rarities;

        },

        sort_by_rarity:function( items, get_print_pass, reverse_pass ){
            var get_print = get_print_pass || function( item ){
                    return item.piece;
                },
                reverse = reverse_pass || false;

            items.sort(function(print1, print2){

                var rarity1 = get_print( print1 ).rarity ? get_print( print1 ).rarity["class"] || "none" : "none",
                    rarity2 = get_print( print2 ).rarity ? get_print( print2 ).rarity["class"] || "none" : "none",
                    priority;

                priority = _rarities[rarity2] - _rarities[rarity1];

                //sort by name as a tie breaker.
                if(priority === 0 && get_print( print1 ).name){

                    priority = get_print( print1 ).name.localeCompare(get_print( print2 ).name);

                    //sort by print id as a tie breaker, these are unique, so this will always be ordered correctly!
                    if(priority === 0){

                        priority = get_print( print1 ).id-get_print( print2 ).id;

                    }

                }

                return priority;

            });

            if( reverse ){
                items.reverse();
            }

        },

        /**
         *
         * @param [sort_by_rarity=true] {Boolean} This will sort the result by rarity.
         */
        flattenPrints: function(story, sort_by_rarity) {
            var i = 0;
            var j = 0;
            var k = 0;
            var printCollection = [];
            var pack = null;

            // Create single array
            for(i=0; i< story.data.length; i++) {

                pack = story.data[i];

                for(j=0; j < pack.prints.length; j++){

                    var print = pack.prints[j];
                    print.owner = story.main_user;
                    print.set = pack.set;

                    printCollection.push(print);

                }

            }

            // Pandas
            if(sort_by_rarity !== false){

                PrintUtils.sort_by_rarity(printCollection);

            }

            // Push a fake piece.
            printCollection.push({piece: {id: -1}});
            var resultArray = [];
            // Some more
            for (k = 0; k < printCollection.length; k++) {
                if (printCollection[k+1]) {
                    if (printCollection[k].piece.id !== printCollection[k+1].piece.id) {
                        resultArray.push(printCollection[k]);
                    }
                }
            }

            return resultArray;
        }

    };

    return PrintUtils;

});
