artModule.directive('artShowRewards', function() {
    var directiveDefinitionObject;
    directiveDefinitionObject = {
        controller: [
            '$scope',
            'artShowRewards',
        function(
            $scope,
            artShowRewards
        ){
            $scope.showRewards = artShowRewards.bind(artShowRewards);
        }],

        link: function(scope, element) {
            angular.element(element).click(function(ev){
                scope.showRewards(ev.originalEvent.type);
                scope.$apply();
            });
        }
    };

    return directiveDefinitionObject;
});
