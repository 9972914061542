artModule.controller("artTradeFullViewController", [
    "$scope",
    "$tradeDetailEndpoint",
    "artNodeHttpService",
    "artActivityFeedFactory",
    function ($scope,
              $tradeDetailEndpoint,
              artNodeHttpService,
              artActivityFeedFactory) {

        $scope.bidder = {};
        $scope.responder = {};
        $scope.completed_on = '';
        $scope.shareUrl = '';
        $scope.viewState = "loading";

        function init() {
            $scope.viewState = "loading";

            $scope.getPieceAssetType = function(piece){
                var imageData = piece.large_image || piece.small_image;
                if(imageData.url.indexOf("gray") !== -1){
                    return "image";
                } else {
                    return piece.asset_type;
                }
            };

            artNodeHttpService.get($tradeDetailEndpoint).then(function (data) {
                if (!data) return $scope.viewState = "loaded";

                artActivityFeedFactory.processTradeDetails(data);

                $scope.bidder = data.bidder;
                $scope.responder = data.responder;
                $scope.completed = data.completed;
                $scope.shareUrl = data.share_url;
                $scope.viewState = "loaded";
            }, function(data) {
                $scope.viewState = "error";
            });
        }

        init();
    }]);
