neonmobApp.factory("SetChooserOverlay", function(){
	var name = "ng-invisible-overlay";
	return {
		show: function() {
            $("body .container.main .nm-set-window").append("<div class='" + name + "'></div>");
		},
		hide: function() {
			$(document).find("."+name+"").remove();
		}
	};
});