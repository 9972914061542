artModule.controller("artPackOpenedFullViewController", [
    "$scope",
    "$location",
    "$anchorScroll",
    "$timeout",
    "$packDetailEndpoint",
    "artNodeHttpService",
    "artActivityFeedFactory",
    function ($scope,
              $location,
              $anchorScroll,
              $timeout,
              $packDetailEndpoint,
              artNodeHttpService,
              artActivityFeedFactory) {

        $scope.viewState = "loading";
        $scope.packs = [];
        $scope.user = {};
        $scope.sett = {};

        function init() {
            $scope.viewState = "loading";

            artNodeHttpService.get($packDetailEndpoint).then(function (data) {
                if (!data) return $scope.viewState = "loaded";

                var selectedPackId = 0;

                var hash = $location.hash();
                if (hash)
                    selectedPackId = parseInt(hash.replace(/pack\-/g, ''), 10);

                artActivityFeedFactory.processPackOpenDetails(data, selectedPackId);

                $scope.user = data.user;
                $scope.sett = data.sett;
                $scope.packs = data.packs;
                $scope.viewState = "loaded";

                $scope.getPieceAssetType = function(piece){
                    var imageData = piece.large_image || piece.small_image;
                    if(imageData.url.indexOf("gray") !== -1){
                        return "image";
                    } else {
                        return piece.asset_type;
                    }
                };

                if (selectedPackId)
                    $timeout($anchorScroll);
            }, function(data) {
                $scope.viewState = "error";
            });
        }

        init();
    }]);
