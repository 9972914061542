artModule.controller('artCaratsController',[
	'$scope',
	'artUser',
function(
	$scope,
	artUser
){
	var you = artUser.toObject();

	$scope.carats = you.carats;

}]);
