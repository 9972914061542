artModule.factory('artSett', [
    '$http',
    '$q',
    '$rootScope',
    'artConfig',
    'artConstants',
    'artMessage',
    'artUser',
    'ImageService',
function(
    $http,
    $q,
    $rootScope,
    artConfig,
    artConstants,
    artMessage,
    artUser,
    ImageService
) {

    var artSett = {};

    artSett.getImageUrl = function(sett, size, imageType){

        var imageUrl = artConfig.defaultImageUrl;

        if(!sett){ //This typically occurs when waiting for a sett to load.
            return imageUrl;
        }

        if(imageType)
            size += "-" + imageType;

        if(sett.sett_assets && sett.sett_assets[size])
            return sett.sett_assets[size].url;

        return imageUrl;
    };

    artSett.getPermalink = function(sett){

        if (!sett) {
            return "javascript:;";
        }
        else {
            if(sett.public_url){
                return sett.public_url;
            } else if(sett.links){
                return sett.links.permalink;
            } else { //Legacy!
                return sett.link;
            }
        }

    };

    artSett.getUserSettLink = function(sett, user){
        // For logged out collector page
        if (!user) {
            return "";
        }

        //REVERSE for url name "profile-collection"
        return "/series/" + sett.name_slug + "/user/" + user.username + "/cards/";

    };

    artSett.getBackgroundUrl = function(sett){

        return sett.background_image || artSett.getImageUrl(sett, 'large', 'blur');

    };

    artSett.hasBackgroundImage = function(sett){

        return this.getBackgroundUrl(sett) !== artConfig.defaultImageUrl;

    };

    artSett.getSelfLink = function(sett){

        return sett.links.self;

    };

    artSett.getOpenPackUrl = function(sett){

        return sett.links['api-pack'];

    };

    artSett.preloadImages = function(sett, size, previewPieceCount){

        previewPieceCount = previewPieceCount || 4;
        var urls = [];
        if (sett.preview_0) urls.push(sett.preview_0);
        if (sett.preview_1 && previewPieceCount > 1) urls.push(sett.preview_1);
        if (sett.preview_2 && previewPieceCount > 2) urls.push(sett.preview_2);
        if (sett.preview_3 && previewPieceCount > 3) urls.push(sett.preview_3);

        if(size === "large"){
            urls.push(artSett.getImageUrl(sett, size, 'blur'));
        }
        urls.push(artSett.getImageUrl(sett, size));

        return ImageService.preloadAll(urls);

    };

    artSett.hasDetails = function(sett){

        return sett.preview_0 && sett.percent_sold_out;

    };

    artSett.getSettDetails = function(settOrSettUrl){

        var sett = null;

        if(angular.isString(settOrSettUrl)){

            sett = $http.get(settOrSettUrl).then(function(result){

                return result.data;

            });

        } else if(!artSett.hasDetails(settOrSettUrl)){

            sett = $http.get(artSett.getSelfLink(settOrSettUrl)).then(function(result){

                return result.data;

            });

        } else {

            sett = settOrSettUrl;

        }

        return $q.when(sett);

    };

    artSett.getSettDetailsAndPreloadImages = function(settOrSettUrl, size){

       return artSett.getSettDetails(settOrSettUrl).then(function(sett){

           var defer = $q.defer();

           artSett.preloadImages(sett, size)['finally'](function(){

               defer.resolve(sett);

           });

           return defer.promise;

        });

    };

    artSett.isSoldOut = function(sett){

        // as of 12/3/2014 we are rendering the sold out message for both discontinued, and sold out sets
        return sett && sett.percent_sold_out === 100 || artSett.isDiscontinued(sett);

    };

    artSett.isDiscontinued = function(sett){

        return sett.discontinued;

    };

    artSett.isReplicaSett = function(sett) {
        return sett.replica_parent;
    };

    artSett.isLimitedRelease = function(sett){
        return sett.limited_release;
    };

    artSett.isExclusive = function(sett){
      return sett.exclusivity === 0;
    };

    artSett.isComingSoonSett = function(sett) {
        return new Date(sett.released) > new Date() && new Date(sett.published) <= new Date();;
    }

    artSett.isReleased = function(sett){

        if (sett) return sett.status === artConstants['SETT_STATUS_OPTIONS'].published;
        return false;

    };

    artSett.publishedFilterType = function(sett) {
        if (sett.status === artConstants['SETT_STATUS_OPTIONS'].submitted) {
            return artConstants['SETT_FILTERS']['submitted'];
        }
        else if (sett.status === artConstants['SETT_STATUS_OPTIONS'].published) {
            return artConstants['SETT_FILTERS']['published'];
        }
        else if(sett.ledger.length > 0){
            var last_ledger = sett.ledger[0];

            if (last_ledger.activity_type === artConstants['SETT_LEDGER_TYPES']['sett_created']) {
                return artConstants['SETT_FILTERS']['created'];
            }
            else {
                return artConstants['SETT_FILTERS']['in-progress'];
            }
        }
    };

    artSett.isSubmitted = function(sett){
        if (sett) return sett.status === artConstants['SETT_STATUS_OPTIONS'].submitted;
        return false;
    };

    artSett.isEditing = function(sett){
        if (sett) return sett.status === artConstants['SETT_STATUS_OPTIONS'].editing;
        return false;
    };

    artSett.isPromoOnly = function(sett){
        return sett.sett_type === artConstants.SETT_TYPE_VALUES['promo-only'];
    };

    artSett.isAmateurSett = function(sett) {
        return sett.sett_type === artConstants.SETT_TYPE_VALUES['amateur'];
    };

    artSett.isLimitedSett = function(sett) {
        return sett.version === artConstants.VERSION_TYPES.limited;
    };

    artSett.isUnlimitedSett = function(sett) {
        return sett.version === artConstants.VERSION_TYPES.unlimited;
    };
    
    artSett.isAIAssisted = function(sett) {
        const AI_IDENTIFIED_URLS = [
            "/series/humanai-collection/",
            "/series/corrupted-waifus/",
            "/series/other-people/",
            "/series/what-if/",
            "/series/spooky-dudes/",
            "/series/girls-from-neo-tokyo/",
            "/series/through-a-frame/",
            "/series/royal-buildings-of-nedrios/",
            "/series/impossible-cities-garden/",
            "/series/kitty-cats/",
            "/series/elemental-monarchs-queens/",
            "/series/earth-36/",
            "/series/winter-cats/",
            "/series/this-letter-is-for-you/",
            "/series/seasonal-sweets-winterfest/",
            "/series/oh-christmas-tree/",
            "/series/the-passage/",
            "/series/the-gentledogs/",
            "/series/paradise-gardens/",
            "/series/koilorful/",
            "/series/cosmic-fantasy/",
            "/series/seasonal-sweets-new-years/",
            "/series/graffiti-pets/",
            "/series/unnatural-beauty/",
            "/series/the-way-shire/",
            "/series/owls-warriors-and-wizards/",
            "/series/uniform-lovers/",
            "/series/cowboy-compadres/",
            "/series/soul-saplings/",
            "/series/fantastic-scenes/",
            "/series/plant-people/",
            "/series/skulls-unlimited/",
            "/series/anime-style/",
            "/series/fuzzlets/",
            "/series/galaxy-fauna/",
            "/series/corrupted-waifus/",
            "/series/earths-faces/",
            "/series/fae-of-the-laurels/",
            "/series/fae-of-the-laurels-2/",
            "/series/space-mining/",
            "/series/birds-of-paradise/",
            "/series/santas-pets/",
            "/series/brigids-ther-tarot/",
            "/series/spiritus-mundi/",
            "/series/the-divine/",
            "/series/these-places-dont-exist/",
            "/series/these-caves-dont-exist/",
            "/series/future-relics/",
            "/series/cyberpunk-portraits/",
            "/series/christmas-collectibles/",
            "/series/nightmares/",
            "/series/japanese-abstract/",
            "/series/lapis-lazuli-oceanic-beauty/",
            "/series/arcane-design-5e/",
            "/series/surreal-neural-arcana/",
            "/series/mountains-rivers/",
            "/series/oz-placeholder/",
            "/series/ai-yes-limited/",
            "/series/ai-yes-unlimited/"
          ];

          try {
            if (sett) return AI_IDENTIFIED_URLS.includes(sett.permalink);
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    artSett.getSettTypeDisplay = function(settType){
        switch(settType) {
            case artConstants.SETT_TYPE_VALUES['promo-only']:
                return "Promo";
            case artConstants.SETT_TYPE_VALUES['reward']:
                return "Reward";
            case artConstants.SETT_TYPE_VALUES['amateur']:
                return "Amateur";
            case artConstants.SETT_TYPE_VALUES['normal']:
                return "Pro";
        }
    };

    artSett.getRarityTotals = function(sett) {
      if(!sett.core_stats ) return null;

        var totals  = {
            'core': {
                'total': 0,
                'owned': 0
            },
            'variant': {
                'total': 0,
                'owned': 0
            },
            'chase': {
                'total': 0,
                'owned': 0
            },
            'legendary': {
                'total': 0,
                'owned': 0
            }
        };

        var i;
        for (i = 0; i < sett.core_stats.length; i++) {
            totals.core.total += sett.core_stats[i].total;
            totals.core.owned += sett.core_stats[i].owned;
        }
        for (i = 0; i < sett.special_stats.length; i++) {
            totals[sett.special_stats[i].name].total += sett.special_stats[i].total;
            totals[sett.special_stats[i].name].owned += sett.special_stats[i].owned;
        }

        return totals;

    };

    artSett.isCollecting = function(sett){
      var totals = artSett.getRarityTotals(sett);
      return totals.core.owned + totals.variant.owned + totals.chase.owned + totals.legendary.owned > 0;
    };

    artSett.getCoreCompletionPercent = function(sett){
      var totals = artSett.getRarityTotals(sett);
      if(!totals) return 0;

      var decimalPlace = 100;
      var percent = totals.core.owned == 0 ? 0 : Math.floor(decimalPlace * totals.core.owned/totals.core.total);

      if( percent < 0 ) percent = 0;
      if( percent > 100 ) percent = 100;

      return percent;
    };

    artSett.getSpecialCompletionPercent = function(sett){
      var totals = artSett.getRarityTotals(sett);
      if(!totals) return 0;

      var decimalPlace = 100,
          owned = totals.variant.owned + totals.chase.owned + totals.legendary.owned,
          total = totals.variant.total + totals.chase.total + totals.legendary.total,
          percent = owned == 0 ? 0 : Math.floor(decimalPlace * owned/total);

      if( percent < 0 ) percent = 0;
      if( percent > 100 ) percent = 100;

      return percent;
    };


    artSett.hasChase = function(sett) {
        var totals = artSett.getRarityTotals(sett);
        return totals.chase.total > 0;
    };

    artSett.hasVariant = function(sett) {
        var totals = artSett.getRarityTotals(sett);
        return totals.variant.total > 0;
    };

    artSett.hasLegendary = function(sett) {
        var totals = artSett.getRarityTotals(sett);
        return totals.legendary.total > 0;
    };

    artSett.hasSpecialRarities = function(sett) {
        return artSett.hasChase(sett) || artSett.hasVariant(sett) || artSett.hasLegendary(sett);
    };

    artSett.getCorePieceCount = function(sett) {
        var totals = artSett.getRarityTotals(sett);
        return totals.core.total;
    };

    artSett.getCoreOwnedCount = function(sett) {
        var totals = artSett.getRarityTotals(sett);
        return totals.core.owned;
    };

    artSett.hasDailyFreebiesForSett = function(sett){
        if(!artUser.isAuthenticated() || !sett.daily_freebies || sett.freebies_discontinued){
            return false;
        }
        var dailySettFreebies = sett.daily_freebies < 0 ? Number.POSITIVE_INFINITY : sett.daily_freebies,
            settFreebiesRemaining = dailySettFreebies - artUser.getTodaysSettFreebiesCount(sett.id);

        return settFreebiesRemaining > 0 && artUser.getFreebiesRemaining() > 0;
    };

    artSett.settFreebiesRemaining = function(sett) {
        if(!artUser.isAuthenticated() || !sett.daily_freebies || sett.freebies_discontinued){
            return 0;
        }
        var dailySettFreebies = sett.daily_freebies < 0 ? Number.POSITIVE_INFINITY : sett.daily_freebies;
        return dailySettFreebies - artUser.getTodaysSettFreebiesCount(sett.id);
    }

    artSett.hasTieredPacks = function(sett) {
        if (!sett) return false;
        return sett.version === artConstants['SETT_VERSION_UNLIMITED'] || sett.version === artConstants['SETT_VERSION_LIMITED'];
    };

    artSett.toggleFavoriteSett = function(sett) {

        var url = "/api/setts/" + sett.id + "/favorite/";

        sett.favorite = !sett.favorite;
        $http.post(url)
        .success( function() {
            angular.element('.tip').tooltip('hide');
            $rootScope.$broadcast('sett-favorite-toggled', sett);
        })
        .error(function() {
            sett.favorite = !sett.favorite;
            artMessage.showAlert(
                "Sorry, we could not " + (sett.favorite ? "unfavorite" : "favorite") +
                " the set as there was an error. Please contact a dev.");
        });
    };

    return artSett;

}]).filter("artUserSettLink", [
    "artSett",
function(
    artSett
){
    return function(sett, user){
        return artSett.getUserSettLink(sett, user);
    };
}]).filter("isCollecting", [
    "artSett",
function(
    artSett
){
    return function(sett){
        return artSett.isCollecting(sett);
    };
}]);
