neonmobApp.directive('nmMilestonesProgress', function() {

    var directiveDefinitionObject = {};
    

    directiveDefinitionObject.controller = "MilestonesListController";

    directiveDefinitionObject.templateUrl = "partials/badges/milestones-progress.partial.html";


    return directiveDefinitionObject;

});

