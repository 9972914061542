packOpenModule.factory("Communication", [
    '$rootScope',
function(
    $rootScope
){

    var serviceDefinitionObject = {};

    serviceDefinitionObject.send = function(tag, data) {

        $rootScope.$broadcast(tag);

    };

    serviceDefinitionObject.subscribe = function(tag, callback){

        $rootScope.$on(tag, function(){

            callback();

        });

    };

    serviceDefinitionObject.unsubscribe = function(name){

        $rootScope.purgeListeners(name);

    };


    return serviceDefinitionObject;

}]);