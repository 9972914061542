creatorModule.directive("ctDisableField", [
    'artSubscriptionService',
    'ctSettCreator',
function(
    artSubscriptionService,
    ctSettCreator
){
    var ctDisableField = {};
    
    ctDisableField.scope = {
        type: "@ctDisableField",
    };

    ctDisableField.link = function(scope, element){
        var disabledStatus = ctSettCreator.getDisabledStatus();
        if (disabledStatus.disabled) {
            if (scope.type === 'all' || (scope.type === 'creator' && disabledStatus.type !== 'staff')) {
                element.addClass('disabled');
                element.prop('disabled', true);
            }
        }
        
        artSubscriptionService.subscribe('sett-submitted', function(){
            if (disabledStatus.type !== 'staff') {
                element.addClass('disabled');
                element.prop('disabled', true);
            }
        });

        artSubscriptionService.subscribe('sett-released', function(){
            if (scope.type === 'all' || (scope.type === 'creator' && disabledStatus.type !== 'staff')) {
                element.addClass('disabled');
                element.prop('disabled', true);
            }
        });

        scope.$on("$destroy", function () {
           artSubscriptionService.unsubscribe("sett-released");
           artSubscriptionService.unsubscribe("sett-submitted");
        });
    };
    return ctDisableField;
}]);