(function(){

artModule.filter('pieceUrl', [
    "artPieceService",
    "artUser",
function(
    artPieceService,
    artUser
){

    return function(piece, size, isPublic){

        return artPieceService.getImageData(artUser, piece, size, isPublic).url;

    };

}]).filter('pieceRarityClass', function(){

    return function(piece){

        return piece.rarity['class'];

    };

}).filter('pieceRarity', function(){

    return function(piece){
        return piece.rarity.name;

    };

}).filter('pieceRarityImage', function(){

    return function(piece){

        return piece.rarity.image;

    };

});

}());
